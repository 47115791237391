/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DELETE_REGISTRATION, FETCH_REGISTRATIONS } from '../store/registrations';
import {
  FullRepresentativeName,
  FormattedRegistrationViewRegData,
  FormattedRegistrationViewRepData,
  RegistrationStatusTypes,
  RegistrationProductAttributes,
} from '../utils/constants';
import { ensure, get_company_search } from '../utils/functions';
import { AccountJson } from '../store/accounts';
import { UserJson } from '../store/users';
import { RepresentativeJson } from '../store/representatives';
import { ProductJson } from '../store/products';
import { RegistrationProductJson } from '../store/registration_products';
import { KtwelveJson } from '../store/ktwelves';
import { ClassroomJson } from '../store/classrooms';
import { RootState } from '../store/configureStore';

import RegistrationsView from '../components/view/Secretary/RegistrationsView';
import { error } from 'react-notification-system-redux';
import { RegistrationClosureJson } from '../store/registration_closures';
import { format } from 'date-fns';

const defaultPagination = {
  pageCount: 0,
  totalCount: 0,
  pageSize: 10,
  pageNumber: 0,
};

const searchForOptions = [
  {
    value: 'q[account_user_name_cont]',
    label: 'Aluno',
  },
  {
    value: 'q[representatives_account_user_name_cont]',
    label: 'Responsável',
  },
  {
    value: 'q[code_cont]',
    label: 'RA'
  },
  {
    value: 'q[registration_products_code_cont]',
    label: 'Código Produto'
  },
  {
    value: 'q[contracts_contract_number_cont]',
    label: 'Número Contrato'
  }
];

const studentStatusOptions = [
  {
    value: 'true',
    label: 'Alunos ativos'
  },
  {
    value: 'false',
    label: 'Alunos inativos'
  }
]

type PaginationType = typeof defaultPagination;

export interface IDefaultFilterValues {
  newPagination: PaginationType;
  closed?: string;
  startsAt?: Date | null;
  company_filter: string;
  student_filter?: string;
  filter_option: string;
  status?: RegistrationStatusTypes
}

const RegistrationsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies },
  } = state;

  const defaultFilterValues = {
    newPagination: defaultPagination,
    closed: 'true',
    startsAt: null,
    company_filter: '',
    student_filter: '',
    filter_option: 'q[account_user_name_cont]'
  }

  const [registrations, setRegistrations] = React.useState<FormattedRegistrationViewRegData[]>([]);
  const [pagination, setPagination] = React.useState(defaultPagination);
  const [renderReady, setRenderReady] = React.useState(false);
  const [filters, setFilters] = React.useState<IDefaultFilterValues>(defaultFilterValues)

  const fetchRegistrationsMethod = async (filters: IDefaultFilterValues) => {
    try {
      setRenderReady(false);
      let yearFilter = {};
      if (filters.startsAt) {
        yearFilter = {
          'q[registration_products_product_year_eq]': format(new Date(filters.startsAt.getFullYear(), 0, 1, 1), 'yyyy-MM-dd'),
        };
      }
      let general_filter_object = {}
      if (filters.student_filter && filters.filter_option) {
        general_filter_object = {
          [filters.filter_option]: filters.student_filter
        }
      }
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter: filters.company_filter,
        current_company_id: company
      })

      const registrations = await dispatch(
        FETCH_REGISTRATIONS.request({
          params: {
            filters: {
              'q[account_company_id_in]': companies_array,
              include:
                'representatives,representatives.account.user,account.user,products.classroom,products.classroom.ktwelve_degree,registration_products.registration_closure',
              'page[number]': (filters.newPagination.pageNumber + 1).toString(),
              'page[size]': filters.newPagination.pageSize.toString(),
              'q[active_eq]': String(filters.closed),
              ...general_filter_object,
              ...yearFilter,
            },
          },
        }),
      );
      const {
        data: { data: registrationData, included: registrationIncluded, meta },
      } = registrations;
      const userAccountData = registrationData.map((registration) => {
        const { attributes } = registration;
        const getAccount = ensure(
          registrationIncluded.find((i) => i.type === 'accounts' && ~~i.id === attributes.account_id),
        ) as AccountJson;
        const userData = registrationIncluded.find((item) => {
          return item.type === 'users' && ~~item.id === getAccount.attributes.user_id;
        }) as UserJson;
        return {
          account_id: attributes.account_id,
          user_id: userData.id,
          ...userData['attributes'],
        };
      });
      const formattedData = registrationData.map((registration) => {
        const getAccountData = userAccountData.find(
          (item) => item.account_id * 1 === registration.attributes.account_id,
        );
        const account = registrationIncluded.find(
          (item) => item.type === 'accounts' && registration.attributes.account_id === ~~item.id,
        ) as AccountJson;

        const includedRepresentatives = registrationIncluded?.filter((item) => {
          return item.type === 'representatives' && item.attributes.registration_id === ~~registration.id;
        }) as RepresentativeJson[];
        const representativesAttrs: { [key in FullRepresentativeName]?: FormattedRegistrationViewRepData[] } = {
          financial_representatives: [],
          pedagogical_representatives: [],
          pedagogical_financial_representatives: [],
        };
        if (includedRepresentatives && includedRepresentatives?.length) {
          const repAlternatives: FullRepresentativeName[] = [
            'financial_representatives',
            'pedagogical_representatives',
            'pedagogical_financial_representatives',
          ];
          repAlternatives.forEach((type: FullRepresentativeName) => {
            const findRepresentativeType = includedRepresentatives.filter((item) => {
              return type.includes(item.attributes.kind);
            });
            if (findRepresentativeType && findRepresentativeType?.length) {
              const representativeAttr = findRepresentativeType.map((representative) => {
                const findRepresentativeAccount = registrationIncluded.find(
                  (item) => item.type === 'accounts' && ~~item.id === representative.attributes.account_id,
                ) as AccountJson;
                const findRepresentativeUser =
                  findRepresentativeAccount &&
                  (registrationIncluded.find(
                    (item) => item.type === 'users' && ~~item.id === findRepresentativeAccount.attributes.user_id,
                  ) as UserJson);
                return {
                  ...representative.attributes,
                  id: representative.id,
                  type: representative.type,
                  ...(findRepresentativeUser && { ...findRepresentativeUser.attributes }),
                };
              });
              if (representativeAttr?.length) {
                representativesAttrs[type] = [...representativeAttr];
              }
            }
          });
        }
        const classAttrs: { classroom: string; ktwelve: string } = { classroom: '', ktwelve: '' };
        const allRegProd = registrationIncluded
          .filter(
            (item) => item.type === 'registration_products' && item.attributes.registration_id === ~~registration.id,
          )
          .map((regProd) => ({ id: regProd.id, ...regProd.attributes })) as RegistrationProductAttributes[];
        const includedClosure = registrationIncluded.find(
          (incl) =>
            incl.type === 'registration_closures' &&
            allRegProd.map((item) => ~~item.id).includes(incl.attributes.registration_product_id),
        ) as RegistrationClosureJson;
        const includedRegProduct = registrationIncluded.find(
          (item) => item.type === 'registration_products' && item.attributes.registration_id === ~~registration.id,
        ) as RegistrationProductJson;
        if (includedRegProduct) {
          const includedProduct = registrationIncluded.find(
            (item) => item.type === 'products' && ~~item.id === includedRegProduct?.attributes.product_id,
          ) as ProductJson;
          const productClassroom = registrationIncluded.find(
            (item) => item.type === 'classrooms' && ~~item.id === includedProduct?.attributes.classroom_id,
          ) as ClassroomJson;
          const classroomKtwelve = registrationIncluded.find(
            (item) => item.type === 'ktwelve_degrees' && ~~item.id === productClassroom?.attributes.ktwelve_degree_id,
          ) as KtwelveJson;
          classAttrs.classroom = productClassroom?.attributes?.name;
          classAttrs.ktwelve = classroomKtwelve?.attributes?.name;
        }
        return {
          ...registration.attributes,
          account: {
            id: account.id,
            ...account.attributes,
          },
          id: registration.id,
          registration_products: allRegProd || [],
          user: {
            ...getAccountData,
          },
          representatives: {
            ...representativesAttrs,
          },
          classroom: { ...classAttrs },
          registration_closure: includedClosure
            ? {
              ...includedClosure?.attributes,
              id: includedClosure?.id,
            }
            : null,
        };
      });
      setPagination((current) => ({
        ...current,
        pageCount: meta.page_count,
        totalCount: meta.total_count,
      }));
      setRegistrations(formattedData);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro no carregamento de matrículas',
        }),
      );
    } finally {
      setRenderReady(true);
    }
  };

  const deleteRegistrationMethod = async (id: string) => {
    try {
      await dispatch(DELETE_REGISTRATION.request({ id }));
      await fetchRegistrationsMethod({
        ...defaultFilterValues,
        newPagination: pagination
      });
    } catch (err) {
      dispatch(
        error({
          message: 'Erro na exclusão da matrícula',
        }),
      );
    }
  };

  const initData = () => {
    fetchRegistrationsMethod({
      ...filters,
      newPagination: { ...defaultPagination },
    });
  };

  React.useEffect(() => {
    initData();
  }, []);

  const handlePageChange = (_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    fetchRegistrationsMethod({
      ...filters,
      newPagination: {
        ...pagination,
        pageNumber: newPage,
      },
    });
  };

  const handleChangePageSize = (e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value,
    });
    fetchRegistrationsMethod({
      ...filters,
      newPagination: {
        ...pagination,
        pageSize: e.target.value,
      },
    });
  };

  return (
    <RegistrationsView
      filters={filters}
      setFilters={setFilters}
      defaultPagination={defaultPagination}
      deleteRegistrationMethod={deleteRegistrationMethod}
      fetchRegistrationsMethod={fetchRegistrationsMethod}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
      pagination={pagination}
      registrations={registrations}
      renderReady={renderReady}
      searchForOptions={searchForOptions}
      studentStatusOptions={studentStatusOptions}
    />
  );
};

export default RegistrationsContainer;
