/* eslint-disable camelcase */
import { compose } from 'redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import {
  validation,
  TeacherClassTimeAttributes,
  ClassTimeSubjectAttributes,
  RoomAttributes,
  DefaultOptionType,
  NestedTeacherClassTimeAttributes,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { compact, head, isEmpty, map } from 'lodash';
import React from 'react';
import AutoCompleteComponent from '../input/form/autocomplete';
import { error } from 'react-notification-system-redux';
import { FETCH_TEACHER_SUBJECTS } from '../../store/teacher_subjects';
import { css } from '@emotion/react';

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 0;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,

  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,

  form: css`
    display: grid;
    row-gap: 1rem;
    background: #FDFEFF;
    padding: 1rem;
  `,

  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const form_name = 'teacherClassTimeForm';

export const TeacherClassTimeFormBody = (props: {
  teacher_class_times_form_values: TeacherClassTimeAttributes;
  all_teacher_class_times?: NestedTeacherClassTimeAttributes[];
  class_time_subject: ClassTimeSubjectAttributes;
  room: RoomAttributes;
  teacherSubjectOptions: DefaultOptionType[]
  setTeacherSubjectOptions: React.Dispatch<React.SetStateAction<DefaultOptionType[]>>
  field_name: string
}) => {
  const { teacher_class_times_form_values, all_teacher_class_times = [], room, class_time_subject, teacherSubjectOptions, setTeacherSubjectOptions, field_name} = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const fetchTeacherSubjectOptions = React.useCallback(
    async (value: string, teacher_subject_id?: string) => {
      try {
        setLoading(true);
        const used_teacher_subject_ids = compact(
          all_teacher_class_times
            .filter((item) => {
              const result_one = !item._destroy;
              const result_two = !(teacher_subject_id && item.teacher_subject_id.toString() === teacher_subject_id);
              return result_one && result_two;
            })
            .map((item) => item.teacher_subject_id),
        );
        let extra_attrs = {};
        if (!isEmpty(used_teacher_subject_ids)) {
          extra_attrs = {
            ...extra_attrs,
            'q[id_not_int]': used_teacher_subject_ids,
          };
        }
        if (!isEmpty(value)) {
          extra_attrs = {
            ...extra_attrs,
            'q[teacher_user_name_cont]': value,
          };
        }

        if (!isEmpty(teacher_subject_id)) {
          extra_attrs = {
            ...extra_attrs,
            'q[id_eq]': teacher_subject_id,
          };
        }

        const teacher_subjects = await dispatch(
          FETCH_TEACHER_SUBJECTS.request({
            params: {
              filters: {
                'q[company_id_eq]': room.company_id.toString(),
                'q[ktwelve_subject_id_eq]': class_time_subject.ktwelve_subject_id.toString(),
                'q[active_eq]': 'true',
                ...extra_attrs,
              },
            },
          }),
        );
        const {
          data: { data },
        } = teacher_subjects;
        const formattedData = map(data, ({ attributes, id }) => {
          return {
            label: attributes.teacher_name,
            value: id,
          };
        });
        setTeacherSubjectOptions(formattedData);
        setLoading(false);
        return formattedData
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de professor para essa disciplina',
          }),
        );
        return []
      }
    },
    [room, class_time_subject],
  );


  const init = React.useCallback(async () => {
    if (!isEmpty(teacher_class_times_form_values.id)) {
      setTeacherSubjectOptions([
        {
          label: teacher_class_times_form_values.teacher_name,
          value: teacher_class_times_form_values.teacher_subject_id,
        },
      ]);
    } else if (teacher_class_times_form_values.teacher_subject_id) {
      await fetchTeacherSubjectOptions('', teacher_class_times_form_values.teacher_subject_id.toString());
    } else {
      const teacher_subjects = await fetchTeacherSubjectOptions('')
      if(!isEmpty(teacher_subjects)){
        dispatch(
          change(
            form_name,
            `${field_name}.teacher_subject_id`,
            head(teacher_subjects)?.value
          )
        )
      }
    }
  }, [teacher_class_times_form_values]);
  React.useEffect(() => {
    init();
  }, []);
  return (
    <div style={{ display: 'grid' }}>
      <Field
        name='teacher_subject_id'
        label={'Professor'}
        placeholder={'Buscar professor'}
        component={AutoCompleteComponent}
        autoCompleteProps={{
          filterOptions: (x: any) => x,
          loading,
          getValueOnChange: true,
          fetchOptions: fetchTeacherSubjectOptions,
        }}
        options={teacherSubjectOptions}
        validate={[validation.required]}
      />
    </div>
  );
};

const TeacherClassTimeForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  room: RoomAttributes;
  class_time_subject: ClassTimeSubjectAttributes;
  all_teacher_class_times?: NestedTeacherClassTimeAttributes[]
  field_name: string
}) => {
  const { handleSubmit, onSubmit, close_form, room, class_time_subject, all_teacher_class_times = [], field_name } = props;
  const state = useSelector((state: RootState) => state);
  const teacher_class_times_form_values = getFormValues(form_name)(state) as TeacherClassTimeAttributes;
  const [teacherSubjectOptions, setTeacherSubjectOptions] = React.useState<DefaultOptionType[]>([]);

  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar Disciplina no horário</span>
      <form css={useStyles.form}>
        <>
          <TeacherClassTimeFormBody
            field_name={field_name}
            teacherSubjectOptions={teacherSubjectOptions}
            setTeacherSubjectOptions={setTeacherSubjectOptions}
            all_teacher_class_times={all_teacher_class_times}
            room={room}
            class_time_subject={class_time_subject}
            teacher_class_times_form_values={teacher_class_times_form_values}
          />
          <div css={useStyles.buttons}>
            <button
              onClick={(e) => {
                e.preventDefault();
                close_form();
              }}
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar horário </span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(TeacherClassTimeForm);
