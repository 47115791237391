/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  reduxForm,
  Field,
  change,
  FieldArray,
  reset,
  WrappedFieldArrayProps,
  FormSection,
  formValueSelector,
} from 'redux-form';
import { error } from 'react-notification-system-redux';

import { Theme } from '@mui/system';
import { css, useTheme } from '@emotion/react';

import InputComponent from '../input/form/input';
import { PhoneInputComponent } from '../input/form/masked';
import SelectComponent from '../input/form/select';
import AutoCompleteComponent from '../input/form/autocomplete';

import {
  colors,
  validation,
  userConnectionTypes,
  UserOptionsData,
  DefaultOptionType,
  CountryOptions,
  StudentStepFormData,
  ConnectionOption,
  CurrentRegistrationData,
  NestedRegistrationConnectionAttributes,
  NestedEmergencyContactAttributes,
  Role,
  UserFormFields,
  RegistrationStatusEnum,
  CompanyAttributes,
} from '../../utils/constants';

import { evaluate_permissions, getCompanyFilterOptions, removeSpecialSymbols } from '../../utils/functions';
import AddButton from '../shared/AddButton';
import { FETCH_REGISTRATIONS, FetchRegistrationsResponse } from '../../store/registrations';
import { RootState } from '../../store/configureStore';
import DeleteButton from '../shared/DeleteButton';
import { isEmpty } from 'lodash';
import UserForm from './UserForm';
import { Country } from 'country-state-city';
const formName = 'registration-studentstep';

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    wrapper: css`
      flex: 1;
    `,
    personalData: css`
      display: grid;
      row-gap: 1rem;
      ${theme.breakpoints?.down('md')} {
        padding: 0;
      }
      & > span {
        justify-self: flex-start;
        margin-bottom: 1rem;
      }
      & .user-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 1rem;
        & > div {
          flex-basis: 100%;
        }
        & #input-avatar {
          order: 1;
        }
        & #input-document_number {
          order: 2;
          flex-basis: 22%;
        }
        & #input-student_code {
          order: 3;
          flex-basis: 20%;
        }
        & #input-name {
          flex-basis: 25%;
          order: 4;
        }
        & div[id$="social_name"] {
          flex-basis: 32%;
          order: 5;
        }
        & #input-email {
          flex-basis: 33%;
          order: 6;
        }
        & #input-phone {
          flex-basis: 36%;
          order: 7;
        }
        & #input-gender {
          flex-basis: 15%;
          order: 8;
        }
        & div[id$="race_id"] {
          flex-basis: 15%;
          order: 9;
        }
        & .nationality-span {
          flex-basis: 10%;
          order: 10;
          align-self: center;
        }
        & #input-nationality_country {
          flex-basis: 24.5%;
          order: 11;
        }
        & #input-nationality_state {
          flex-basis: 24.5%;
          order: 12;
        }
        & #input-nationality_city {
          flex-basis: 20%;
          order: 13;
        }
        & #input-birthdate {
          flex-basis: 19.5%;
          order: 14;
        }
        & #input-address_form {
          order: 15;
        }
        & div[id$="street"] {
          flex-basis: 40%;
        }
        & div[id$="number"] {
          flex-basis: 24%;
        }
        & div[id$="complement"] {
          flex-basis: 35%;
        }
        & div[id$="neighbourhood"] {
          flex-basis: 30%;
        }
        & #input-address_form > div[id$="country_id"] {
          flex-basis: 20%;
        }
        & #input-address_form > div[id$="state_id"] {
          flex-basis: 25%;
        }
        & #input-address_form > div[id$="city_id"] {
          flex-basis: 22.5%;
        }
        & #user_alergies {
          order: 16;
        }
        & #user_deficiencies {
          order: 18;
        }
        & #other_user_data_separator {
          order: 17;
        }
        & #user_alergies .subtitle-one,
        & #user_deficiencies .subtitle-one {
          text-align: left;
        }
        & .zipcode {
          display: flex;
          max-width: 100%;
          width: 50rem;
          gap: 1rem;
          & > .search {
            display: flex;
            align-items: center;
            font-size: 1.25rem;
            cursor: pointer;
            margin-bottom: 1rem;
            border: none;
            gap: 1rem;
            width: fit-content;
            background: inherit;
            & > svg {
              font-size: 3rem;
              color: ${colors.darkBlue};
              &:hover {
                color: #5a5a72;
              }
            }
          }
        }
      }
    `,
    fieldArray: css`
      display: grid;
      row-gap: 1rem;
      ${theme.breakpoints?.down('md')} {
        padding: 0;
      }
      & > span {
        justify-self: flex-start;
        font-size: 1.75rem;
        margin-bottom: 1rem;
      }
      & > .grid {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        justify-content: space-between;
        & > .add {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          cursor: pointer;
          margin-bottom: 1rem;
          border: none;
          gap: 1rem;
          width: fit-content;
          background: inherit;
          & > svg {
            font-size: 3rem;
            color: ${colors.darkBlue};
            &:hover {
              color: #5a5a72;
            }
          }
        }
      }
    `,
    buttons: css`
      display: flex;
      justify-content: flex-end;
      padding: 0 2rem;
      text-align: center;
      gap: 1rem;
      margin-top: 4.5rem;
    `,
  };
};


const RegistrationConnectionForm = ({
  loading,
  connectionOptions,
  fetchConnectionOptionsMethod,
}: {
  loading: boolean;
  connectionOptions: DefaultOptionType[];
  fetchConnectionOptionsMethod: (id: string) => Promise<void>;
}) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field
          name='kind'
          label={'Tipo de relacionamento'}
          placeholder={'Tipo'}
          component={SelectComponent}
          options={userConnectionTypes}
        />
        <Field
          name='connection_id'
          label={'Nome ou CPF'}
          placeholder={'Nome ou CPF'}
          component={AutoCompleteComponent}
          autoCompleteProps={{
            filterOptions: (x: any) => x,
            loading,
            getValueOnChange: true,
            fetchOptions: fetchConnectionOptionsMethod,
          }}
          options={connectionOptions}
        />
      </div>
    </div>
  );
};

interface CustomRegistrationConnectionProps extends WrappedFieldArrayProps {
  currentRegistrationConnection: NestedRegistrationConnectionAttributes;
  currentRegistrationConnectionName: string;
  fetchRegistrationsMethod: (params: object) => Promise<FetchRegistrationsResponse | undefined>;
}

const RegistrationConnectionFormContainer = (props: CustomRegistrationConnectionProps) => {
  const { currentRegistrationConnectionName, fetchRegistrationsMethod, currentRegistrationConnection } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company: companyId },
  } = state;
  const [loading, setLoading] = React.useState(false);
  const [connectionOptions, setConnectionOptions] = React.useState<DefaultOptionType[]>([]);

  const fetchConnectionOptionsMethod = React.useCallback(async (id: string) => {
    try {
      setLoading(true);
      const isNumber = /^\d+$/.test(removeSpecialSymbols(id));
      const param = isNumber ? 'q[account_user_document_number_cont]' : 'q[account_user_name_cont]';
      const registrations = await fetchRegistrationsMethod({
        [param]: isNumber ? removeSpecialSymbols(id) : id,
        'q[account_company_id_eq]': companyId,
        'q[registration_products_product_year_eq]': new Date().getFullYear(),
      });
      if (registrations) {
        const {
          data: { data },
        } = registrations;
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.user_data.name,
            value: id,
          };
        });
        setConnectionOptions(formattedData);
        setLoading(false);
      }
    } catch (e) {
      dispatch(
        error({
          message: 'Erro ao carregar opções de relacionamento',
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    if (currentRegistrationConnection.id) {
      setLoading(true);
      setConnectionOptions([
        {
          value: currentRegistrationConnection.connection_id.toString(),
          label: currentRegistrationConnection.connection_name,
        },
      ]);
      setLoading(false);
    }
  }, []);

  return (
    <FormSection name={currentRegistrationConnectionName}>
      <RegistrationConnectionForm
        connectionOptions={connectionOptions}
        loading={loading}
        fetchConnectionOptionsMethod={fetchConnectionOptionsMethod}
      />
    </FormSection>
  );
};

interface CustomRenderRegistrationConnectionsProps extends WrappedFieldArrayProps {
  fetchRegistrationsMethod: (params: object) => Promise<FetchRegistrationsResponse | undefined>;
  registration_prefix: string
}

const renderRegistrationConnections = (props: CustomRenderRegistrationConnectionsProps) => {
  const dispatch = useDispatch();
  const { fields, registration_prefix } = props;
  const addRegistrationConnection = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteRegistrationConnection = React.useCallback(
    (
      currentRegistrationConnection: NestedRegistrationConnectionAttributes,
      registration_connection: string,
      index: number,
    ) => {
      currentRegistrationConnection.id
        ? dispatch(change(formName, `${registration_prefix}.${registration_connection}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar relacionamento</span>
        <AddButton onClick={addRegistrationConnection} tooltip='Adicionar relacionamento' />
      </div>
      {fields.map((registration_connection, index) => {
        const currentRegistrationConnection = fields.get(index) as NestedRegistrationConnectionAttributes;
        if (currentRegistrationConnection._destroy) {
          return null;
        }
        return (
          <div key={registration_connection} style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
            <RegistrationConnectionFormContainer
              currentRegistrationConnection={currentRegistrationConnection}
              currentRegistrationConnectionName={registration_connection}
              {...props}
            />
            <DeleteButton
              tooltip='Excluir relacionamento'
              onClick={() =>
                deleteRegistrationConnection(currentRegistrationConnection, registration_connection, index)
              }
            />
          </div>
        );
      })}
    </div>
  );
};

const EmergencyContactForm = (props: { countryOptions: CountryOptions[] }) => {
  const { countryOptions } = props;
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field name='name' placeholder={'Waliomar Bezerra'} label={'Nome *'} component={InputComponent} />
        <Field
          label={'Telefone'}
          placeholder={'(00) 0 0000-0000'}
          name='phone'
          component={PhoneInputComponent}
          validate={[validation.validPhone]}
          initialCountryOptions={countryOptions}
        />
      </div>
    </div>
  );
};

interface EmergencyContactsCustomProps extends WrappedFieldArrayProps {
  registration_prefix: string
}

const renderEmergencyContacts = (props: EmergencyContactsCustomProps) => {
  const dispatch = useDispatch();
  const { fields, registration_prefix } = props;
  const [countryOptions, setCountryOptions] = React.useState<CountryOptions[]>([]);

  const addEmergencyContact = React.useCallback(() => {
    fields.push({
      country_code: { value: '+55', label: '🇧🇷 +55', countryValue: 'BR' },
    });
  }, [fields]);

  const deleteEmergencyContact = React.useCallback(
    (currentEmergencyContact: NestedEmergencyContactAttributes, emergency_contact: string, index: number) => {
      currentEmergencyContact.id
        ? dispatch(change(formName, `${registration_prefix}.${emergency_contact}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );
  const fetchCountriesMethod = React.useCallback(async () => {
    try {
      const countryList = await Country.getAllCountries().map(({ flag, name, isoCode, phonecode }) => ({
        label: name,
        value: isoCode,
        flag,
        phonecode,
      }));
      countryList && setCountryOptions(countryList);
      return countryList;
    } catch (e) {
      dispatch(error({ message: 'Erro no carregamento dos países' }));
    }
  }, [setCountryOptions]);

  React.useEffect(() => {
    fetchCountriesMethod();
  }, []);

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar contato de emergência</span>
        <AddButton onClick={addEmergencyContact} tooltip='Adicionar contato de emergência' />
      </div>
      {fields.map((emergency_contact, index) => {
        const currentEmergencyContact = fields.get(index) as NestedEmergencyContactAttributes;
        if (currentEmergencyContact._destroy) {
          return null;
        }
        return (
          <div key={emergency_contact} style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
            <FormSection name={emergency_contact}>
              <EmergencyContactForm countryOptions={countryOptions} />
            </FormSection>
            <DeleteButton
              tooltip='Excluir contato de emergência'
              onClick={() => deleteEmergencyContact(currentEmergencyContact, emergency_contact, index)}
            />
          </div>
        );
      })}
    </div>
  );
};

const renderRegistrationsForm = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  const classes = useStyles()
  const dispatch = useDispatch()
  const fetchRegistrationsMethod = React.useCallback(async (params: object) => {
    try {
      const registrations = await dispatch(
        FETCH_REGISTRATIONS.request({
          params: {
            filters: {
              ...params,
            },
          },
        }),
      );
      return registrations;
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar informações de matrículas',
        }),
      );
    }
  }, []);
  return (
    <div>
      {fields.map((registration) => {
        return (
          <FormSection name={registration} key={registration}>
            <div css={classes.fieldArray}>
              <span className='subtitle-one'> Contatos de Emergência </span>
              <FieldArray name='emergency_contacts_attributes' registration_prefix={registration} component={renderEmergencyContacts} />
            </div>
            <hr />
            <div css={classes.fieldArray}>
              <span className='subtitle-one'>Relacionamentos</span>
              <FieldArray
                name='registration_connections_attributes'
                component={renderRegistrationConnections}
                fetchRegistrationsMethod={fetchRegistrationsMethod}
                registration_prefix={registration}
              />
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};


const renderAccountForm = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  return (
    <div>
      {fields.map((account_attribute) => {
        return (
          <FormSection name={account_attribute} key={account_attribute}>
            <FieldArray name='registrations_attributes' component={renderRegistrationsForm} validate={[validation.required]} />
          </FormSection>
        );
      })}
    </div>
  );
};

const StudentStepForm = (props: {
  currentUser: boolean;
  connectionOptions: ConnectionOption[];
  alergiesOptions: DefaultOptionType[];
  deficienciesOptions: DefaultOptionType[];
  setConnectionOptions: React.Dispatch<React.SetStateAction<ConnectionOption[]>>;
  handleSubmit: (arg: (args: StudentStepFormData) => void) => any;
  onSubmit: (arg: { data: StudentStepFormData; advance: boolean }) => void;
  setInitialValues: React.Dispatch<React.SetStateAction<Partial<StudentStepFormData>>>;
  currentRegistration: CurrentRegistrationData;
  defaultInitialValues: (company_id?: number) => {
    company_id: number | undefined;
    nationality_country: {
        phonecode: string;
        flag: string;
        label: string;
        value: string;
    };
    accounts_attributes: {
        company_id: number | undefined;
        active: boolean;
        registrations_attributes: {
            status: RegistrationStatusEnum;
            emergency_contacts_attributes: never[];
            registration_connections_attributes: never[];
        }[];
    }[];
}
  deleteUserAlergy: (id: string) => Promise<void>;
  deleteUserDeficiency: (id: string) => Promise<void>;
  deleteRegistrationConnection: (id: string) => Promise<void>;
  handleSelectUser:  (user: UserOptionsData) => Promise<void>
  thereIsDifferentValues: boolean;
  allowUserUpdate: boolean
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    onSubmit,
    setInitialValues,
    currentRegistration,
    defaultInitialValues,
    handleSelectUser,
    thereIsDifferentValues,
    allowUserUpdate
  } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company: companyId, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector(formName);
  const companyIdValue = formValues(state, 'company_id') as string;
  const user_id = formValues(state, 'user_id');
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === companyId,
  ) as CompanyAttributes;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use = currentRegistration?.id
    ? currentRegistration.company_id
    : is_above_school_director
    ? companyIdValue
    : companyId;

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: companyId,
    company_descendants,
    companies,
  });

  const handleClearDocumentField = () => {
    if (user_id) {
      setInitialValues(defaultInitialValues(~~companyIdValue));
      dispatch(reset(formName));
      dispatch(change(formName, 'document_number', null));
    } else {
      dispatch(change(formName, 'document_number', null));
    }
  };
  const handleSelectCompany = (_: React.ChangeEvent<any> | undefined, value: any) => {
    setInitialValues(defaultInitialValues(value))
  };

  const userFormData = [
    'avatar',
    'document_number',
    'name',
    'additional_data.social_name',
    'email',
    'phone',
    'gender',
    'race',
    'birthdate',
    'nationality_country',
    'nationality_state',
    'nationality_city',
    'user_alergies',
    'user_deficiencies'
  ] as UserFormFields[];
  const mustSelectCompanyId = is_above_school_director && !currentRegistration?.id && !companyIdValue;
  const cantRenderAllForm = is_above_school_director && !currentRegistration?.id && !companyIdValue;
  return (
    <form css={classes.wrapper}>
      {mustSelectCompanyId && (
        <div css={classes.personalData}>
          <span className='subtitle-one'> Unidade </span>
          <Field
            label={'Unidade'}
            placeholder={'Selecionar unidade'}
            name='company_id'
            component={SelectComponent}
            options={companies_options}
            onChange={handleSelectCompany}
            validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
          />
        </div>
      )}
      {!cantRenderAllForm && (
        <>
          <div css={classes.personalData}>
            <span className='subtitle-one'> Dados Pessoais </span>
            <div className='user-form'>
              <Field
                name='student_code'
                placeholder={'Registro acadêmico (RA)'}
                label={'Registro acadêmico *'}
                component={InputComponent}
                disabled
              />
              <UserForm
                data={userFormData}
                form_name={formName}
                handleClearDocumentField={handleClearDocumentField}
                handleSelectUser={handleSelectUser}
                company_id_to_use={company_id_to_use}
                disabled_document_number={!isEmpty(currentRegistration?.id)}
                document_clearable={!currentRegistration?.id && user_id}
                disable_all={!allowUserUpdate}
              />
            </div>
          </div>
          <hr />
          <FieldArray component={renderAccountForm} name='accounts_attributes' />
        </>
      )}

      <div css={classes.buttons}>
        <button
          onClick={handleSubmit((values: StudentStepFormData) => {
            onSubmit({
              data: { ...values },
              advance: false,
            });
          })}
          type='submit'
          className={`${thereIsDifferentValues ? 'blue' : 'gray'} small`}
        >
          <span className='save'>Salvar</span>
        </button>
        <button
          type='submit'
          className='green small'
          onClick={handleSubmit((values: StudentStepFormData) => {
            onSubmit({
              data: { ...values },
              advance: true,
            });
          })}
        >
          <span className='advance'> Avançar </span>
        </button>
      </div>
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: formName,
    enableReinitialize: true,
  }),
)(StudentStepForm);
