/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CalendarEventsForm from '../components/form/CalendarEventsForm';
import { RootState } from '../store/configureStore';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { CalendarEventAttributes, CompanyAttributes, Role } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';
import { CREATE_CALENDAR_EVENT, FETCH_CALENDAR_EVENT, UPDATE_CALENDAR_EVENT } from '../store/calendar_events';
import { parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const CalendarEventsFormContainer = (props: {
  calendar_event_id?: string
  onSave: () => void
  close_form: () => void
}) => {
  const { calendar_event_id, onSave, close_form} = props
  const dispatch = useDispatch();
  const history = useHistory();
  const state  = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    account: { companies }
  } = state
  const formValues = formValueSelector('classroomsForm');
  const [initialValues, setInitialValues] = React.useState<CalendarEventAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const companyIdValue = formValues(state, 'company_id') as string;
  const isUpdating = !isNil(initialValues?.id);
    const current_company = companies.find(
      (item: CompanyAttributes) => item.id === company,
    ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use = initialValues?.company_id
    ? initialValues.company_id
    : is_above_school_director
    ? companyIdValue
    : company;


  const loadCalendarEvents = React.useCallback(async () => {
    if (calendar_event_id) {
      const calendar = await dispatch(
        FETCH_CALENDAR_EVENT.request({
          id: calendar_event_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = calendar;
      const formattedCalendarEvent = {
        id,
        ...attributes,
        ...(attributes.starts_at ? { starts_at: parse(attributes.starts_at, 'yyyy-MM-dd', new Date(), { locale: ptBR }).toISOString() } : {}),
        ...(attributes.ends_at ? { ends_at: parse(attributes.ends_at, 'yyyy-MM-dd', new Date(), { locale: ptBR }).toISOString() } : {})
      };
      setInitialValues(formattedCalendarEvent);
    } else {
      setInitialValues({ company_id: company_id_to_use } as CalendarEventAttributes)
    }
  }, [calendar_event_id]);

  const initCalendarEventsForm = React.useCallback(async () => {
    setLoading(true);
    await loadCalendarEvents();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: CalendarEventAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as CalendarEventAttributes;
          await dispatch(
            UPDATE_CALENDAR_EVENT.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
                ...(rest.starts_at && { starts_at: new Date(rest.starts_at).toString() }),
                ...(rest.ends_at && { ends_at: new Date(rest.ends_at).toString() }),    
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_CALENDAR_EVENT.request({
              data: {
                ...data,
              },
            }),
          );
        }
        onSave()
        close_form()
        dispatch(
          success({
            message: 'Evento salvo com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar evento.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating, history],
  );

  React.useEffect(() => {
    initCalendarEventsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <CalendarEventsForm initialValues={initialValues} close_form={close_form} onSubmit={onSubmit} />;
};

export default CalendarEventsFormContainer;
