/* eslint-disable camelcase */
import React from 'react';

import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  PaginationType,
  Role,
  defaultPagination,
  SubjectAttributes,
  subjectFieldOptions,
  SubjectFieldEnum,
  subjectKindOptions,
  CompanyAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { IconModal } from '../../modal/Modal';
import TrashIcon from '../../icon/TrashIcon';
import { css, useTheme } from '@emotion/react';
import SubjectsFormContainer from '../../../containers/SubjectsFormContainer'
import TooltipButton from '../../shared/TooltipButton';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};


const SubjectsView = ({
  subjects,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchSubjectsMethod,
  fieldFilterValue,
  setFieldFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
  deleteSubject
}: {
  subjects: SubjectAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fieldFilterValue: SubjectFieldEnum | string;
  setFieldFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchSubjectsMethod: ({ newPagination, company_filter, field_filter }: {
    newPagination?: PaginationType;
    company_filter?: string;
    field_filter?: SubjectFieldEnum | string;
}) => Promise<void>
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
  deleteSubject: (id: string, { setLoading, handleClose }: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => Promise<void>

}) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const [create, setCreate] = React.useState(false)
  const [editDiscipline, setEditDiscipline] = React.useState<string[]>([])
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Nome', 'Área de conhecimento', 'Tipo'];

  const init = async () => {
    await fetchSubjectsMethod({})
  }

  const handleSaveDiscipline = async () => {
    setCreate(false)
    await init()
  }

  const handleOpenEditForm = (id: string) => {
     setEditDiscipline((prev) => {
      if (id && prev.includes(id)) {
        return prev.filter(item => item !== id)
      } else {
        return prev.concat(id)
      }
     })
  }

  const handleCloseEditForm = (id: any) => {
    setEditDiscipline((prevState) => prevState.filter((item) => item !== id))
  }

  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Disciplinas</span>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchSubjectsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchSubjectsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Área de conhecimento'
            small
            options={subjectFieldOptions}
            input={{
              value: fieldFilterValue,
              onChange: (e: any) => {
                setFieldFilterValue(e.target.value);
                fetchSubjectsMethod({
                  field_filter: e.target.value,
                });
              },
            }}
            clearable
            clearFieldFunction={() => {
              setFieldFilterValue('');
              fetchSubjectsMethod({
                field_filter: '',
              });
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align={'left'}  key={item}>{item}</TableCell>
                ))}
                <TableCell align='right'>
                  <button 
                    className='green small'
                    onClick={() => setCreate(prev => !prev)}
                    disabled={editDiscipline.length > 0}
                  >
                    <span>Criar Disciplina</span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
                  <Collapse in={create} timeout={'auto'} unmountOnExit>
                    <div>
                      <SubjectsFormContainer
                        onSave={handleSaveDiscipline}
                        close_form={() => setCreate(false)}
                      />
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
              {subjects.map((subject) => {
                const field = subjectFieldOptions.find((item) => item.value === subject.field)?.label;
                const kind = subjectKindOptions.find((item) => item.value === subject.kind)?.label
                return (
                  <React.Fragment key={subject.id}>
                    <TableRow>
                      {is_above_school_director && 
                      <TableCell>{subject.subject_company_name}</TableCell>
                      }
                      <TableCell>{subject.name}</TableCell>
                      <TableCell>{field}</TableCell>
                      <TableCell>{kind}</TableCell>
                      <TableCell align='right'>
                        <TooltipButton
                          tooltipProps={{
                            title: subject.id && editDiscipline.includes(subject.id)
                              ? 'Cancelar edição'
                              : 'Editar Disciplina'
                          }}
                          Icon={PenIcon}
                          iconButtonProps={{
                            disabled: create || (editDiscipline.length > 0 && !(subject.id && editDiscipline.includes(subject.id))),
                            onClick: () => subject.id && handleOpenEditForm(subject.id)
                          }}
                        />
                        <IconModal
                          icon={TrashIcon}
                          title={'Excluir disciplina'}
                          tooltipText='Excluir disciplina'
                          onConfirm={(props) => subject.id && deleteSubject(subject.id, props)}
                          message='Tem certeza que deseja remover o disciplina?'
                          iconProps={{style:{color: 'red'}}}
                          disabled={ create || editDiscipline.length > 0 }
                        />
                      </TableCell>
                    </TableRow>
                    {
                      editDiscipline.includes(subject.id) && (
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={columns.length + 2} >
                            <div>
                                <Collapse in={editDiscipline.includes(subject.id)} timeout='auto' unmountOnExit>
                                      <SubjectsFormContainer 
                                        close_form={ () => handleCloseEditForm(subject.id)}
                                        onSave={ async () => {
                                          handleCloseEditForm(subject.id)
                                          await init()
                                        }}
                                        subject_id={subject.id}
                                        company_id={subject.id}
                                      />
                                </Collapse>
                            </div>
                          </TableCell>
                        </TableRow>
                    )
                  }
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={(props) => <TablePaginationActions {...props} disableButton={ create || editDiscipline.length > 0} />}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SubjectsView;
