/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import { isBefore } from 'date-fns/esm';
import { filter, find, findIndex, head, includes, isEmpty, isNil, keys, map, omit } from 'lodash';
import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector, untouch } from 'redux-form';
import ProductForm from '../components/form/ProductsForm';
import { ClassroomJson, FETCH_CLASSROOMS } from '../store/classrooms';
import { RootState } from '../store/configureStore';
import { FETCH_CONTRACT_SETTINGS } from '../store/contract_settings';
import { FETCH_DISCOUNT_TYPES } from '../store/discount_types';
import { FETCH_DOCUMENTS } from '../store/documents';
import { PaymentCollectionJson, PaymentOptionJson } from '../store/payment_options';
import { CREATE_PRODUCT, FETCH_PRODUCT, ProductPeriodJson, UPDATE_PRODUCT } from '../store/products';
import { FETCH_PRODUCT_FAMILIES } from '../store/product_families';
import { DocumentRequirementJson } from '../store/registrations';
import { SubsidyJson } from '../store/subsidies';
import {
  CompanyAttributes,
  DefaultOptionType,
  DocumentRequirementsNestedAttributes,
  NestedProductItemsAttributes,
  NestedSubsidiesAttributes,
  PaymentCollectionNestedAttributes,
  PaymentOptionAttributes,
  PaymentOptionPortionsEnum,
  PaymentOptionsAttributes,
  PaymentOptionsNestedAttributes,
  periodOptions,
  ProductAttributes,
  ProductFamilyAttributes,
  ProductPeriodsAttributes,
  ProductPeriodsNestedAttributes,
  ProductsFormAttributes,
  Role,
  RoomsNestedAttributes,
  SubsidiesNestedAttributes,
} from '../utils/constants';
import {
  dateRange,
  evaluate_permissions,
  getMonthInteger,
  isValidDate,
  resetFields,
  toDate,
  verifyDifferentValues,
} from '../utils/functions';
import { ProductItemJson } from '../store/product_items';
import Loading from '../components/loading/Loading'

const productPeriodsUpdater = (
  currentProductPeriod: ProductPeriodsNestedAttributes[],
  initialCurrentPeriods: ProductPeriodsNestedAttributes[],
) => {
  const sorted: ProductPeriodsNestedAttributes[] = [];
  currentProductPeriod.forEach((productPeriod) => {
    const related = find(initialCurrentPeriods, (initial) => initial.id === productPeriod.id);
    if (isNil(related)) {
      sorted.push(productPeriod);
      return;
    }
    const omit = ['id', '_destroy'];
    const result = verifyDifferentValues(productPeriod, related, omit) as ProductPeriodsNestedAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as ProductPeriodsNestedAttributes[];
};

const documentRequirementsUpdater = (
  currentDocRequirements: DocumentRequirementsNestedAttributes[],
  initialDocRequirements: DocumentRequirementsNestedAttributes[],
) => {
  const sorted: DocumentRequirementsNestedAttributes[] = [];
  currentDocRequirements.forEach((docRequirement) => {
    const related = find(initialDocRequirements, (initial) => initial.id === docRequirement.id);
    if (isNil(related)) {
      sorted.push(docRequirement);
      return;
    }
    const omit = ['id', '_destroy'];
    const result = verifyDifferentValues(docRequirement, related, omit) as DocumentRequirementsNestedAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as DocumentRequirementsNestedAttributes[];
};

const productItemsUpdater = (
  currentProductItems: NestedProductItemsAttributes[],
  initialProductItems: NestedProductItemsAttributes[],
) => {
  const sorted: NestedProductItemsAttributes[] = [];
  currentProductItems.forEach((productItem) => {
    const related = find(initialProductItems, (initial) => initial.id === productItem.id);
    if (isNil(related)) {
      sorted.push(productItem);
      return;
    }
    const omit = ['id', '_destroy'];
    const result = verifyDifferentValues(productItem, related, omit) as NestedProductItemsAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedProductItemsAttributes[];
};

const subsidiesUpdater = (
  currentSubsidies: SubsidiesNestedAttributes[],
  initialSubsidies: SubsidiesNestedAttributes[],
) => {
  const sorted: SubsidiesNestedAttributes[] = [];
  currentSubsidies.forEach((subsidy) => {
    const related = find(initialSubsidies, (initial) => initial.id === subsidy.id);
    if (isNil(related)) {
      sorted.push(subsidy);
      return;
    }
    const result = verifyDifferentValues(subsidy, related, ['id', '_destroy']) as SubsidiesNestedAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as SubsidiesNestedAttributes[];
};

const roomsUpdater = (
  currentRooms: RoomsNestedAttributes[],
  initialRooms: RoomsNestedAttributes[],
) => {
  const sorted: RoomsNestedAttributes[] = [];
  currentRooms.forEach((room) => {
    const related = find(initialRooms, (initial) => initial.id === room.id);
    if (isNil(related)) {
      sorted.push(room);
      return;
    }
    const result = verifyDifferentValues(room, related, ['id', '_destroy']) as RoomsNestedAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as RoomsNestedAttributes[];
};

const paymentCollectionsUpdates = (
  currentPaymentCollection: PaymentCollectionNestedAttributes[],
  initialPaymentCollection: PaymentCollectionNestedAttributes[],
) => {
  const sorted: PaymentCollectionNestedAttributes[] = [];
  currentPaymentCollection.forEach((payment_collection) => {
    const related = find(initialPaymentCollection, (initial) => initial.id === payment_collection.id);
    if (isNil(related)) {
      sorted.push(payment_collection);
      return;
    }
    const result = verifyDifferentValues(payment_collection, related, [
      'id',
      '_destroy',
    ]) as PaymentCollectionNestedAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as PaymentCollectionNestedAttributes[];
};

const paymentOptionsUpdater = (
  currentPaymentOptions: PaymentOptionsNestedAttributes[],
  initialPaymentOptions: PaymentOptionsNestedAttributes[],
) => {
  const sorted: PaymentOptionsNestedAttributes[] = [];
  currentPaymentOptions.forEach((paymentOption) => {
    const related = find(initialPaymentOptions, (initial) => initial.id === paymentOption.id);
    if (isNil(related)) {
      sorted.push(paymentOption);
      return;
    }
    const result = verifyDifferentValues(paymentOption, related, [
      'id',
      '_destroy',
      'payment_collections_attributes',
    ]) as PaymentOptionsNestedAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentPaymentCollectionAttributes = paymentOption.payment_collections_attributes;
      const initialPaymentCollectionAttributes = related?.payment_collections_attributes;
      const payment_collections_attributes = paymentCollectionsUpdates(
        currentPaymentCollectionAttributes as PaymentCollectionNestedAttributes[],
        initialPaymentCollectionAttributes as PaymentCollectionNestedAttributes[],
      );
      const formattedPaymentOption = {
        ...result,
        payment_collections_attributes,
      };
      sorted.push(formattedPaymentOption);
    }
  });
  return sorted as PaymentOptionsNestedAttributes[];
};

interface IProductsFormContainerProps {
  close_form?: () => void;
  onSave?: () => void;
  product_id?: string
  company_id?: number
  copy?: boolean
}

const ProductsFormContainer: React.FC<IProductsFormContainerProps> = ({
  close_form,
  onSave,
  product_id,
  company_id,
  copy
}) => {
  const dispatch = useDispatch();
  const formValues = formValueSelector('productForm');
  const state = useSelector((state: RootState) => state);
  const [classroomOptions, setClassroomOptions] = React.useState<DefaultOptionType[]>([]);
  const [productFamilyOptions, setProductFamilyOptions] = React.useState<ProductFamilyAttributes[]>([]);
  const [contractSettingOptions, setContractSettingOptions] = React.useState<DefaultOptionType[]>();
  const [discountTypesOptions, setDiscountTypesOptions] = React.useState<DefaultOptionType[]>([]);
  const [documentOptions, setDocumentOptions] = React.useState<DefaultOptionType[]>([]);
  const [initialValues, setInitialValues] = React.useState<ProductsFormAttributes | null>(null);
  const [loading, setLoading] = React.useState(false)
  const {
    auth: { company, profile },
    account: { companies }
  } = state;
  const periodsAttributes = formValues(state, 'periods_attributes') as ProductPeriodsAttributes[];
  const subsidiesAttributes = formValues(state, 'subsidies_attributes') as NestedSubsidiesAttributes[];
  const paymentOptionsAttributes = formValues(state, 'payment_options_attributes') as PaymentOptionsAttributes[];
  const companyIdValue = formValues(state, 'company_id') as string;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use = company_id || (is_above_school_director
    ? companyIdValue
    : company);

  const loadDocuments = React.useCallback(async () => {
    try {
      const documents = await dispatch(FETCH_DOCUMENTS.request({
        params: {
          filters: {
            'page[size]': '100'
          }
        }
      }));
      const {
        data: { data },
      } = documents;
      const formattedDocuments = data.map((item) => {
        return {
          value: item.id,
          label: item.attributes.name,
        };
      });
      setDocumentOptions(formattedDocuments);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar tipos de documentos',
        }),
      );
    }
  }, []);

  const loadDiscountTypesOptions = React.useCallback(
    async (company_id = company_id_to_use) => {
      try {
        const discountTypes = await dispatch(
          FETCH_DISCOUNT_TYPES.request({
            params: {
              filters: {
                'q[company_id_eq]': company_id,
                'page[size]': '30',
              },
            },
          }),
        );
        const {
          data: { data },
        } = discountTypes;
        const formattedDiscountTypes = data.map((item) => {
          return {
            value: item.id,
            label: item.attributes.name,
          };
        });
        setDiscountTypesOptions(formattedDiscountTypes);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar tipos de descontos da escola',
          }),
        );
      }
    },
    [company_id_to_use],
  );

  const loadContractSettingOptions = React.useCallback(
    async (company_id = company_id_to_use) => {
      try {
        const contractSettings = await dispatch(
          FETCH_CONTRACT_SETTINGS.request({
            params: {
              filters: {
                'q[company_id_eq]': company_id,
                'page[size]': '30',
              },
            },
          }),
        );
        const {
          data: { data },
        } = contractSettings;
        const formattedContractSettings = data.map((item) => {
          return {
            value: item.id,
            label: item.attributes.description,
          };
        });
        setContractSettingOptions(formattedContractSettings);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar configurações de contrato',
          }),
        );
      }
    },
    [company_id_to_use],
  );

  const loadProductFamilyOptions = React.useCallback(async () => {
    try {
      const productFamilies = await dispatch(
        FETCH_PRODUCT_FAMILIES.request({
          params: {
            filters: {
              'page[size]': '30',
            },
          },
        }),
      );
      const {
        data: { data },
      } = productFamilies;
      const formattedProductFamilies = data.map((item) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });
      setProductFamilyOptions(formattedProductFamilies);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar família de produtos',
        }),
      );
    }
  }, [company_id_to_use]);

  const loadClassroomOptions = React.useCallback(
    async ({ name = '', company_id = company_id_to_use }: { name?: string; company_id?: string }) => {
      try {
        const classrooms = await dispatch(
          FETCH_CLASSROOMS.request({
            params: {
              filters: {
                'q[company_id_eq]': company_id,
                'page[size]': '100',
                'q[name_cont]': name,
              },
            },
          }),
        );
        const {
          data: { data },
        } = classrooms;
        const formattedClassrooms = data.map((item) => {
          const periodLabel = periodOptions.find((period) => period.value === item.attributes.period)?.label;
          return {
            value: item.id,
            label: `${item.attributes.name} - ${periodLabel} - ${item.attributes.year}`,
          };
        });
        setClassroomOptions(formattedClassrooms);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de turma',
          }),
        );
      }
    },
    [company_id_to_use],
  );

  const loadAssets = React.useCallback(async (company_id = company_id_to_use) => {
    try {
      await loadClassroomOptions({ company_id });
      await loadProductFamilyOptions();
      await loadContractSettingOptions(company_id);
      await loadDiscountTypesOptions(company_id);
      await loadDocuments();
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar recursos do formulário',
        }),
      );
    }
  }, []);

  const loadProduct = React.useCallback(async (company_id = company_id_to_use) => {
    if (product_id) {
      const isCopy = !isNil(copy);
      const product = await dispatch(
        FETCH_PRODUCT.request({
          id: product_id,
          params: {
            filters: {
              include:
                'subsidies,periods,payment_options.payment_collections,document_requirements,product_items,classroom',
            },
          },
        }),
      );
      const {
        data: { data, included },
      } = product;
      const subsidies_attributes = map(
        filter(included, (incl) => incl.type === 'subsidies') as SubsidyJson[],
        (subsidy) => {
          return {
            id: subsidy.id,
            ...subsidy.attributes,
          };
        },
      );
      const periods_attributes = map(
        filter(included, (incl) => incl.type === 'product_periods') as ProductPeriodJson[],
        (product_period) => {
          return {
            id: product_period.id,
            ...product_period.attributes,
            ends_at: toDate(product_period.attributes.ends_at).toISOString(),
            starts_at: toDate(product_period.attributes.starts_at).toISOString(),
          };
        },
      );
      const document_requirements_attributes = map(
        filter(included, (incl) => incl.type === 'document_requirements') as DocumentRequirementJson[],
        (document_requirement) => {
          return {
            id: document_requirement.id,
            ...document_requirement.attributes,
          };
        },
      );
      const product_items_attributes = map(
        filter(included, (incl) => incl.type === 'product_items') as ProductItemJson[],
        (product_item) => {
          return {
            id: product_item.id,
            ...product_item.attributes,
          };
        },
      );
      const payment_options_attributes = map(
        filter(included, (incl) => incl.type === 'payment_options') as PaymentOptionJson[],
        (payment_option) => {
          const payment_collections_attributes = map(
            filter(
              included,
              (incl) =>
                incl.type === 'payment_collections' && incl.attributes.payment_option_id === ~~payment_option.id,
            ) as PaymentCollectionJson[],
            (payment_collection) => {
              let formattedAttributes = {};
              if (payment_collection.attributes.expiration_date) {
                formattedAttributes = {
                  ...formattedAttributes,
                  expiration_date: toDate(payment_collection.attributes.expiration_date).toISOString(),
                };
              }

              if (payment_collection.attributes.punctuality_expiration_date) {
                formattedAttributes = {
                  ...formattedAttributes,
                  punctuality_expiration_date: toDate(
                    payment_collection.attributes.punctuality_expiration_date,
                  ).toISOString(),
                };
              }
              return {
                id: payment_collection.id,
                ...payment_collection.attributes,
                ...formattedAttributes,
              };
            },
          );
          return {
            id: payment_option.id,
            ...payment_option.attributes,
            payment_collections_attributes,
          };
        },
      );
      const classroom = find(included, (incl) => incl.type === 'classrooms') as ClassroomJson;
      const classroomOption = classroom ? { value: classroom.id, label: classroom.attributes.name } : null;
      const product_attributes = omit(data.attributes, ['full_price', 'available', 'code']);
      let newInitialValues: ProductsFormAttributes = {
        id: data.id,
        ...product_attributes,
        subsidies_attributes,
        periods_attributes,
        document_requirements_attributes,
        payment_options_attributes,
        product_items_attributes,
      };
      if (isCopy) {
        newInitialValues = {
          ...omit(newInitialValues, ['id']),
          subsidies_attributes: subsidies_attributes.map((item) => omit(item, ['id', 'created_at'])),
          periods_attributes: periods_attributes.map((item) => omit(item, ['id', 'created_at'])),
          document_requirements_attributes: document_requirements_attributes.map((item) =>
            omit(item, ['id', 'created_at']),
          ),
          payment_options_attributes: payment_options_attributes.map((item) => ({
            ...omit(item, ['id', 'created_at']),
            payment_collections_attributes: item.payment_collections_attributes.map((coll) => {
              return {
                ...omit(coll, ['id', 'created_at']),
              };
            }),
          })),
        } as ProductsFormAttributes;
      }
      classroomOption &&
        !isEmpty(classroomOption) &&
        setClassroomOptions((current) => {
          if (!current.some((item) => item.value === classroomOption.value)) {
            return current.concat(classroomOption);
          } else {
            return current;
          }
        });
      setInitialValues(newInitialValues);
    } else {
      const monthlyPaymentOption = {
        name: 'Mensal',
        description: 'Opção de pagamento mensal',
        portions: PaymentOptionPortionsEnum.MONTHLY,
        advance_discount_kind: 'none',
        advance_discount_value: 0,
        portion_discount_kind: 'none',
        portion_discount_value: 0,
        punctuality_discount_kind: 'none',
        punctuality_discount_value: 0,
        expiration_day: 1,
        punctuality_expiration_day: 1,
      } as PaymentOptionAttributes;
      const semiAnnualPaymentOption = {
        name: 'Semestral',
        description: 'Opção de pagamento semestral',
        portions: PaymentOptionPortionsEnum.SEMI_ANUALY,
        advance_discount_kind: 'none',
        advance_discount_value: 0,
        portion_discount_kind: 'none',
        portion_discount_value: 0,
        punctuality_discount_kind: 'none',
        punctuality_discount_value: 0,
        expiration_day: 1,
        punctuality_expiration_day: 1,
      } as PaymentOptionAttributes;
      const yearlyPaymentOption = {
        name: 'Anual',
        description: 'Opção de pagamento anual',
        portions: PaymentOptionPortionsEnum.YEARLY,
        advance_discount_kind: 'none',
        advance_discount_value: 0,
        portion_discount_kind: 'none',
        portion_discount_value: 0,
        punctuality_discount_kind: 'none',
        punctuality_discount_value: 0,
        expiration_day: 1,
        punctuality_expiration_day: 1,
      } as PaymentOptionAttributes;
      const paymentOptions = [monthlyPaymentOption, semiAnnualPaymentOption, yearlyPaymentOption];
      setInitialValues({
        payment_options_attributes: paymentOptions,
        subsidies_attributes: [],
        document_requirements_attributes: [],
        periods_attributes: [],
        company_id: company_id,
      });
    }
  }, []);

  const clearClassroomField = React.useCallback(() => {
    resetFields('productForm', ['classroom_id'], dispatch, change, untouch);
  }, []);

  const saveProductMethod = React.useCallback(
    async (data: ProductsFormAttributes) => {
      try {
        if (data.id) {
          const { id, ...rest } = data;
          await dispatch(
            UPDATE_PRODUCT.request({
              id: data.id,
              data: { ...rest },
            }),
          );
        } else {
          await dispatch(
            CREATE_PRODUCT.request({
              data: {
                ...data,
                company_id: company_id_to_use,
              },
            }),
          );
        }
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao salvar produto',
          }),
        );
      }
    },
    [company_id_to_use],
  );

  const initProductsForm = React.useCallback(
    async (company_id = company_id_to_use) => {
      setLoading(true);
      if (company_id) {
        await loadAssets(company_id);
        await loadProduct(company_id);
      }
      setLoading(false);
    },
    [company_id_to_use, location],
  );

  const onSubmit = React.useCallback(
    async (data: ProductsFormAttributes) => {
      try {
        setLoading(true);
        let {
          payment_options_attributes,
          subsidies_attributes,
          document_requirements_attributes,
          periods_attributes,
          rooms_attributes,
          product_items_attributes,
          // eslint-disable-next-line prefer-const
          ...rest
        } = data;
        let product_attributes = { ...rest };
        if (initialValues && initialValues.id) {
          const {
            payment_options_attributes: initialPaymentOptions,
            subsidies_attributes: initialSubsidies,
            document_requirements_attributes: initialDocRequirements,
            periods_attributes: initialProductPeriods,
            rooms_attributes: initialRoomsAttributes,
            product_items_attributes: initialProductItemsAttributes,
            ...rest
          } = initialValues;
          payment_options_attributes = paymentOptionsUpdater(payment_options_attributes, initialPaymentOptions);
          subsidies_attributes = subsidiesUpdater(subsidies_attributes, initialSubsidies);
          rooms_attributes = roomsUpdater(rooms_attributes ?? [], initialRoomsAttributes ?? [])
          document_requirements_attributes = documentRequirementsUpdater(
            document_requirements_attributes,
            initialDocRequirements,
          );
          product_items_attributes = productItemsUpdater(
            product_items_attributes ?? [],
            initialProductItemsAttributes ?? []
          )
          periods_attributes = productPeriodsUpdater(periods_attributes, initialProductPeriods);
          product_attributes = verifyDifferentValues(product_attributes, rest, [
            'id',
            'company_id',
            'code',
          ]) as ProductAttributes;
        }
        product_attributes = omit(product_attributes, ['invoices_through_product', 'ktwelve_degree_id'])
        const formattedData = {
          payment_options_attributes,
          subsidies_attributes,
          document_requirements_attributes,
          periods_attributes,
          rooms_attributes,
          product_items_attributes,
          ...product_attributes,
          company_id: company_id_to_use,
        };
        await saveProductMethod(formattedData);
        await onSave?.()
        setLoading(false);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao formatar dados de envio do produto',
          }),
        );
      }
    },
    [initialValues, company_id_to_use],
  );

  React.useEffect(() => {
    const activePeriod = head(periodsAttributes) as ProductPeriodsAttributes;
    const datesAreValid = activePeriod && isValidDate(activePeriod?.starts_at) && isValidDate(activePeriod.ends_at);
    const startingRange = [0];
    const monthsRange =
      activePeriod && datesAreValid
        ? startingRange.concat(
          dateRange(activePeriod.starts_at, activePeriod?.ends_at).map((item) => getMonthInteger(item)),
        )
        : startingRange;
    if (monthsRange.length && subsidiesAttributes?.length) {
      if (subsidiesAttributes.length) {
        subsidiesAttributes.forEach((subsidyAttr, index) => {
          const portions = filter(subsidyAttr.portions, (item) => includes(monthsRange, item));
          portions && dispatch(change('productForm', `subsidies_attributes[${index}].portions`, portions));
        });
      }
      if (paymentOptionsAttributes?.length) {
        const monthlyPaymentOption = find(
          paymentOptionsAttributes,
          (paymentOption) => paymentOption.portions === PaymentOptionPortionsEnum.MONTHLY,
        );
        const createdBeforeDate =
          monthlyPaymentOption && monthlyPaymentOption.created_at
            ? isBefore(new Date(monthlyPaymentOption.created_at), new Date('10-27-2022'))
            : null;
        if (monthlyPaymentOption && createdBeforeDate) {
          const index = findIndex(
            paymentOptionsAttributes,
            (paymentOption) => paymentOption.portions === PaymentOptionPortionsEnum.MONTHLY,
          );
          const portions = monthsRange.length;
          dispatch(change('productForm', `payment_options_attributes[${index}].portions`, portions));
        }
      }
    }
  }, [periodsAttributes]);

  React.useEffect(() => {
    initProductsForm();
  }, []);

  if (loading) {
    return <Loading />
  }

  return (
    <ProductForm
      classroomOptions={classroomOptions}
      productFamilyOptions={productFamilyOptions}
      contractSettingOptions={contractSettingOptions}
      discountTypesOptions={discountTypesOptions}
      documentOptions={documentOptions}
      initialValues={initialValues}
      onSubmit={onSubmit}
      loadClassroomOptions={loadClassroomOptions}
      clearClassroomField={clearClassroomField}
      periodsAttributes={periodsAttributes}
      initProductsForm={initProductsForm}
      close_form={close_form}
      copy={copy}
    />
  );
};

export default ProductsFormContainer;
