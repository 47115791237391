import { filter, get, isEmpty, isNumber, last, map, orderBy, toNumber } from 'lodash';
import { NestedOperationAttributes, OperationKindEnum, OperationOperableType } from './constants';
import React from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Tooltip } from '@mui/material';
import { AddCircle, Close, ArrowLeft, ArrowRight } from '@mui/icons-material';
import TooltipButton from '../components/shared/TooltipButton';

const handleOnMouseEnter = (event: React.MouseEvent<HTMLSpanElement | HTMLDivElement, MouseEvent>) => {
  const element = event.target as HTMLDivElement | HTMLSpanElement;
  const element_to_change = element.parentElement;
  if (element_to_change && !element_to_change.classList.contains('selected')) {
    element_to_change.classList.add('active');
  }
};

const handleOnMouseLeave = (event: React.MouseEvent<HTMLSpanElement | HTMLDivElement, MouseEvent>) => {
  const element = event.target as HTMLDivElement | HTMLSpanElement;
  const element_to_change = element.parentElement;
  if (element_to_change && !element_to_change.classList.contains('selected')) {
    element_to_change.classList.remove('active');
  }
};

export const new_operation_simulation = (props: {
  operation: Partial<NestedOperationAttributes>;
  selected_operation_id: string;
  setSelectedOperation?: React.Dispatch<React.SetStateAction<string>>;
  parent_operation?: Partial<NestedOperationAttributes>;
}) => {
  const { operation, selected_operation_id, setSelectedOperation, parent_operation } = props;
  const last_order = last(
    orderBy(
      filter(
        parent_operation?.children_attributes,
        (item: NestedOperationAttributes) => !item._destroy,
      ) as NestedOperationAttributes[],
      'order',
      'asc',
    ),
  )?.order;
  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const element = event.target as HTMLDivElement | HTMLSpanElement;
    if (element) {
      const operation_id_attribute = element.getAttribute('data-operation-id');
      if (operation_id_attribute && setSelectedOperation) {
        setSelectedOperation(operation_id_attribute?.toString());
      }
    }
  };
  let result = <div />;
  if (isEmpty(operation) || operation._destroy) {
    return null;
  }
  const this_result = map(
    orderBy(
      filter(get(operation, 'children_attributes') as NestedOperationAttributes[], (item) => !item._destroy),
      'order',
    ),
    (item) =>
      new_operation_simulation({
        operation: item,
        selected_operation_id,
        setSelectedOperation,
        parent_operation: operation,
      }),
  );
  const kind = operation?.kind;
  const maxPreffix = operation?.number_input && operation.number_input > 0 ? `MAX${operation.number_input}` : '';
  const maxTooltipTitlePreffix = !isEmpty(maxPreffix)
    ? `entre o(s) ${operation.number_input} maior(es) elemento(s)`
    : '';
  const is_same_operation = selected_operation_id.toString() === operation.id?.toString();
  if (isEmpty(operation.kind)) {
    const class_name = `operation-wrapper empty-operation ${is_same_operation ? 'selected active' : ''}`;
    result = (
      <div className={class_name} data-operation-id={operation.id}>
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title={['Selecione uma entrada'].join(' ')}>
          <span
            className={`empty-operation`}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            &#9633;
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: {
              ...(!(selected_operation_id.toString() === operation.id?.toString() && operation.parent_id)
                ? { display: 'none' }
                : {}),
            },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.ADDITION) {
    const class_name = `operation-wrapper ${OperationKindEnum.ADDITION} ${
      is_same_operation && operation.kind === OperationKindEnum.ADDITION ? 'selected active' : ''
    }`;
    result = (
      <div className={class_name} data-operation-id={operation.id}>
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title={['Operação de Adição', maxTooltipTitlePreffix].join(' ')}>
          <span
            className={`parenthesis`}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            {maxPreffix}(
          </span>
        </Tooltip>
        {this_result.reduce<EmotionJSX.Element>(
          (p, c, index): any => [p, index ? ' + ' : '', c],
          [] as unknown as EmotionJSX.Element,
        )}
        <Tooltip title={['Operação de Adição', maxTooltipTitlePreffix].join(' ')}>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            className={`closing parenthesis`}
            data-operation-id={operation.id}
          >
            )
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={AddCircle}
          tooltipProps={{
            className: 'add-operation',
            title: 'Adicionar Número ou Operação',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: {
              ...(!(selected_operation_id.toString() === operation.id?.toString() && operation.parent_id)
                ? { display: 'none' }
                : {}),
            },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.SUBTRACTION) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.SUBTRACTION} ${
          is_same_operation && operation.kind === OperationKindEnum.SUBTRACTION ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title={['Operação de Subtração', maxTooltipTitlePreffix].join(' ')}>
          <span
            className={`parenthesis`}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            {maxPreffix}(
          </span>
        </Tooltip>
        {this_result.reduce<EmotionJSX.Element>(
          (p, c, index): any => [p, index ? ' - ' : '', c],
          [] as unknown as EmotionJSX.Element,
        )}
        <Tooltip title={['Operação de Subtração', maxTooltipTitlePreffix].join(' ')}>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            className={`closing parenthesis`}
            data-operation-id={operation.id}
          >
            )
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={AddCircle}
          tooltipProps={{
            className: 'add-operation',
            title: 'Adicionar Número ou Operação',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: {
              ...(!(selected_operation_id.toString() === operation.id?.toString() && operation.parent_id)
                ? { display: 'none' }
                : {}),
            },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.MULTIPLICATION) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.MULTIPLICATION} ${
          is_same_operation && operation.kind === OperationKindEnum.MULTIPLICATION ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title={['Operação de Multiplicação', maxTooltipTitlePreffix].join(' ')}>
          <span
            className={`parenthesis`}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            {maxPreffix}(
          </span>
        </Tooltip>
        {this_result.reduce<EmotionJSX.Element>(
          (p, c, index): any => [p, index ? ' * ' : '', c],
          [] as unknown as EmotionJSX.Element,
        )}
        <Tooltip title={['Operação de Multiplicação', maxTooltipTitlePreffix].join(' ')}>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            className={`closing parenthesis`}
            data-operation-id={operation.id}
          >
            )
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={AddCircle}
          tooltipProps={{
            className: 'add-operation',
            title: 'Adicionar Número ou Operação',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: {
              ...(!(selected_operation_id.toString() === operation.id?.toString() && operation.parent_id)
                ? { display: 'none' }
                : {}),
            },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.DIVISION) {
    const class_name = `operation-wrapper ${OperationKindEnum.DIVISION} ${
      is_same_operation && operation.kind === OperationKindEnum.DIVISION ? 'selected active' : ''
    }`;

    result = (
      <div className={class_name} data-operation-id={operation.id}>
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title={['Operação de Divisão', maxTooltipTitlePreffix].join(' ')}>
          <span
            className={`parenthesis`}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            {maxPreffix}(
          </span>
        </Tooltip>
        {this_result.reduce<EmotionJSX.Element>(
          (p, c, index): any => [p, index ? ' / ' : '', c],
          [] as unknown as EmotionJSX.Element,
        )}
        <Tooltip title={['Operação de Divisão', maxTooltipTitlePreffix].join(' ')}>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            className={`closing parenthesis`}
            data-operation-id={operation.id}
          >
            )
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={AddCircle}
          tooltipProps={{
            className: 'add-operation',
            title: 'Adicionar Número ou Operação',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: {
              ...(!(selected_operation_id.toString() === operation.id?.toString() && operation.parent_id)
                ? { display: 'none' }
                : {}),
            },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.MAX_VALUE) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.MAX_VALUE} ${
          is_same_operation && operation.kind === OperationKindEnum.MAX_VALUE ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title={['Operação para escolher máximo valor'].join(' ')}>
          <span
            className={`parenthesis`}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            MAX(
          </span>
        </Tooltip>
        {this_result.reduce<EmotionJSX.Element>(
          (p, c, index): any => [p, index ? ' | ' : '', c],
          [] as unknown as EmotionJSX.Element,
        )}
        <Tooltip title={['Operação para escolher máximo valor'].join(' ')}>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            className={`closing parenthesis`}
            data-operation-id={operation.id}
          >
            )
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={AddCircle}
          tooltipProps={{
            className: 'add-operation',
            title: 'Adicionar Número ou Operação',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: {
              ...(!(selected_operation_id.toString() === operation.id?.toString() && operation.parent_id)
                ? { display: 'none' }
                : {}),
            },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.MIN_VALUE) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.MIN_VALUE} ${
          is_same_operation && operation.kind === OperationKindEnum.MIN_VALUE ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title={['Operação para escolher mínimo valor'].join(' ')}>
          <span
            className={`parenthesis`}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            MIN(
          </span>
        </Tooltip>
        {this_result.reduce<EmotionJSX.Element>(
          (p, c, index): any => [p, index ? ' | ' : '', c],
          [] as unknown as EmotionJSX.Element,
        )}
        <Tooltip title={['Operação para escolher mínimo valor'].join(' ')}>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            className={`closing parenthesis`}
            data-operation-id={operation.id}
          >
            )
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={AddCircle}
          tooltipProps={{
            className: 'add-operation',
            title: 'Adicionar Número ou Operação',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: {
              ...(!(selected_operation_id.toString() === operation.id?.toString() && operation.parent_id)
                ? { display: 'none' }
                : {}),
            },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.NUMBER_INPUT) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.NUMBER_INPUT} ${
          is_same_operation && operation.kind === OperationKindEnum.NUMBER_INPUT ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title='Número'>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            {operation.label || (operation.number_input ? operation.number_input.toString() : 'N')}
          </span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.EXAM_PLACEMENT_ORDER) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.EXAM_PLACEMENT_ORDER} ${
          is_same_operation && operation.kind === OperationKindEnum.EXAM_PLACEMENT_ORDER ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title='Avaliação por Ordem'>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            A{operation.number_input}
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.FORMULA_STEP) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.FORMULA_STEP} ${
          is_same_operation && operation.kind === OperationKindEnum.FORMULA_STEP ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title='Fórmula/Média por Ordem'>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            M{operation.number_input}
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.COMPOSITION_PERIOD_ORDER) {
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.COMPOSITION_PERIOD_ORDER} ${
          is_same_operation && operation.kind === OperationKindEnum.COMPOSITION_PERIOD_ORDER ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip title='Média Final de um Periodo por ordem'>
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            MFP{operation.number_input}
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
      </div>
    );
  } else if (kind === OperationKindEnum.OPERABLE_INPUT) {
    const operable_type =
      operation.operable_type === OperationOperableType.EXAM_PLACEMENT
        ? 'AE'
        : operation.operable_type === OperationOperableType.FORMULA
        ? 'FE'
        : '';
    result = (
      <div
        className={`operation-wrapper ${OperationKindEnum.OPERABLE_INPUT} ${
          is_same_operation && operation.kind === OperationKindEnum.OPERABLE_INPUT ? 'selected active' : ''
        }`}
        data-operation-id={operation.id}
      >
        <TooltipButton
          Icon={ArrowLeft}
          tooltipProps={{
            className: 'arrow-left',
            title: 'Diminuir Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                isNumber(operation.order) &&
                operation.order > 0
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <Tooltip
          title={`${
            operation.operable_type === OperationOperableType.EXAM_PLACEMENT
              ? 'Avaliação'
              : operation.operable_type === OperationOperableType.FORMULA
              ? 'Formula'
              : 'Avaliação ou Fórmula'
          } específica`}
        >
          <span
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleClick}
            data-operation-id={operation.id}
          >
            {operation.label || operable_type}
          </span>
        </Tooltip>
        <Tooltip
          style={{
            ...(isNaN(operation.weight as number) || toNumber((operation.weight as number) <= 1)
              ? { display: 'none' }
              : {}),
          }}
          title={`Peso ${operation.weight}`}
        >
          <span>(P{operation.weight})</span>
        </Tooltip>
        <TooltipButton
          Icon={ArrowRight}
          tooltipProps={{
            className: 'arrow-right',
            title: 'Aumentar Ordem',
            style: {
              ...(!(
                selected_operation_id.toString() === operation.id?.toString() &&
                operation.parent_id &&
                !isNaN(operation.order as number) &&
                toNumber(last_order) > toNumber(operation.order)
              )
                ? { display: 'none' }
                : {}),
            },
          }}
        />
        <TooltipButton
          Icon={Close}
          tooltipProps={{
            className: 'delete-operation',
            title: 'Remover número ou operação selecionada',
            style: { ...(!(selected_operation_id.toString() === operation.id?.toString()) ? { display: 'none' } : {}) },
          }}
        />
      </div>
    );
  }

  return result;
};
