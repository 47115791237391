/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TransactionAttributes, colors, TransactionEventOptions, PaginationType } from '../../utils/constants';
import { parseText } from '../../utils/functions';
import { format } from 'date-fns';
import { TableFooter, TablePagination, Tooltip } from '@mui/material';
import { orderBy } from 'lodash';
import TablePaginationActions from '../shared/TablePaginationActions';
import { css } from '@emotion/react';

export const useStyles = {
  table: css`
    & tbody > tr:nth-child(even) {
      background-color: ${colors.darkGrayBlue} !important;
    }

    & tbody > tr:nth-child(odd) {
      background-color: ${colors.grayBlue} !important;
    }

    & .include {
      display: flex;
      margin-left: auto;
      margin-bottom: 1rem;
    }

    & tfoot {
      background: white;

      & td {
        font-weight: bold;
        font-size: 1rem;
      }

      & td:last-of-type {
        color: ${colors.blue};
      }
    }

    & .MuiTableContainer-root {
      background: inherit;
      box-shadow: none;

      & .MuiTableCell-root {
        border-bottom: 5px solid ${colors.lightBlue};
      }

      & th {
        color: ${colors.darkGrayBlue};
      }

      & td {
        color: ${colors.darkBlue};

        & svg {
          color: ${colors.darkGrayBlue};
          cursor: pointer;
        }
      }

      & td.MuiTableCell-footer {
        border-bottom: none;
      }
    }
  `,
};


const TransactionsTableBody = ({transactions }: { transactions: TransactionAttributes[] }) => {
  return (
    <TableBody>
      {orderBy(transactions, (transaction) => new Date(transaction.event_date), 'desc').map((transaction) => {
        const eventLabel = TransactionEventOptions.find((opt) => opt.value === transaction.event)?.label;
        const eventDate = transaction.event_date && format(new Date(transaction.event_date), 'dd/MM/yyyy HH:mm');

        return (
          <React.Fragment key={transaction.id}>
            <TableRow>
              <TableCell align='center'>{eventLabel}</TableCell>
              <TableCell align='center'>{eventDate}</TableCell>
              <TableCell align='center'>
                <Tooltip title={transaction.description}>
                  <span>{parseText(transaction.description, 30)}</span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

const TransactionsTable =  ({
  transactions,
  pagination,
  handleChangePageSize,
  handlePageChange
}: {
  transactions: TransactionAttributes[]
  pagination?: PaginationType;
  handlePageChange?: (_: any, newPage: number) => void;
  handleChangePageSize?: (e: any) => void;
}) => {
  return (
    <Table css={useStyles.table} size='small' aria-label='transactions'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Evento</TableCell>
          <TableCell align='center'>Data do evento</TableCell>
          <TableCell align='center'>Descrição</TableCell>
        </TableRow>
      </TableHead>
      <TransactionsTableBody transactions={transactions} />
      {handlePageChange && pagination && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25, 50]}
              colSpan={3}
              count={pagination.totalCount}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNumber}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangePageSize}
              labelRowsPerPage='Itens Por página'
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default TransactionsTable;
