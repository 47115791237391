import { filter, find, get, isEmpty, keys, map, reduce } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { CompanyJson, DOWNLOAD_REGISTRATIONS, EXPORT_COMPANY_REGISTRATIONS, EXPORT_COMPANY_REPRESENTATIVES, EXPORT_INVOICES } from '../../store/companies';
import {
  colors,
  Company,
  companyTypes,
  ContractStatusEnum,
  contractStatusOptions,
  DefaultOptionType,
  FollowUpData,
  FollowUpStatusEnum,
  InvoiceChargeStatusEnum,
  invoiceChargeStatusOptions,
  InvoiceChargeStatusTypes,
  InvoiceFilters,
  InvoiceStatusEnum,
  invoiceStatusOptions,
  InvoiceStatusTypes,
  TransactionEventEnum,
} from '../../utils/constants';
import SelectMultipleComponent from '../input/form/selectmultiple';
import { ButtonModal, MenuItemModal } from '../modal/Modal';
import {
  FETCH_REGISTRATIONS,
  FetchRegistrationsResponse,
  RegistrationJson,
} from '../../store/registrations';
import { RegistrationProductJson } from '../../store/registration_products';
import { AccountJson } from '../../store/accounts';
import { AddressJson, UserJson } from '../../store/users';
import { RepresentativeJson, RepresentativeProductJson } from '../../store/representatives';
import { endOfDay, format, startOfDay } from 'date-fns';
import { RootState } from '../../store/configureStore';
import { formatToCurrency, generateId, parseText, toFixedNumber } from '../../utils/functions';
import { Radio } from '@mui/material';
import { CheckCircle, Close, Downloading, HourglassEmpty } from '@mui/icons-material';
import InvoiceValue from './InvoiceValues';
import { FETCH_INVOICES } from '../../store/invoices';
import InputComponent from '../input/form/input';
import DatePickerComponent from '../input/form/datepicker';
import { InvoiceItemJson } from '../../store/invoice_items';
import { CityJson } from '../../store/cities';
import { StateJson } from '../../store/states';
import { CREATE_TRANSACTION } from '../../store/transactions';
import useActionCable from '../../utils/useActionCable';
import { currentHost } from '../../utils/http';
import useChannel from '../../utils/useChannel';
import FollowUpComponent from './FollowUpComponent';


interface ExportInvoiceData {
  Unidade: string;
  'Código Fatura': string;
  Responsável: string;
  Aluno: string;
  'Status Fatura': string;
  'Status Cobrança': string;
  'Data Vencimento': string;
  'Data Desconto': string;
  Crédito: string;
  Valor: string;
  Desconto: string;
  Parcela: string;
  'Descrição items': string;
}

interface ExportUsersData {
  Status: string;
  Perfil: string;
  Unidade: string;
  CPF: string;
  Nome: string;
  Email: string;
  Telefone: string;
  'Data de Nasc': string;
  'Local de Nasc': string;
  CEP: string;
  Rua: string;
  Número: string;
  Complemento: string;
  Bairro: string;
  Cidade: string;
  Estado: string;
}

const yearOptions: DefaultOptionType[] = [
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2023',
    label: '2023',
  },
  {
    value: '2024',
    label: '2024',
  },
  {
    value: '2025',
    label: '2025',
  }
];

const userTypeOptions: DefaultOptionType[] = [
  {
    value: 'representatives',
    label: 'Responsáveis',
  },
  {
    value: 'students',
    label: 'Alunos',
  },
];

const productStatusOptions: DefaultOptionType[] = [
  {
    value: 'active',
    label: 'Ativos',
  },
  {
    value: 'inactive',
    label: 'Inativos',
  },
];


const getColsWidth = (data: ( ExportInvoiceData | ExportUsersData)[]) => {
  const max_width = data.reduce(
    (w, r) =>
      w.map((item, index) => {
        const keys_data = keys(data[0]);
        const value_for_this =
          r[keys_data[index] as keyof ( ExportInvoiceData | ExportUsersData)];
        const result = Math.max(value_for_this.length, item);
        return result;
      }),
    keys(data[0]).map((item) => item.length),
  );
  return max_width.map((item) => ({ wch: item }));
};

const defaultInvoiceFilterValues = {
  statusFilter: [],
  chargeStatusFilter: [],
  registrationFilter: '',
  representativeFilter: '',
  fromExpirationDateFilter: null,
  toExpirationDateFilter: null,
};

const ReportDownloadFollowUpComponent = (props: { report_id: string, years: string[], contract_status: string[], company_ids: (string|number)[], handleClose?: () => void }) => {
  const { report_id, years, contract_status, company_ids, handleClose } = props;
  const state = useSelector((state: RootState) => state);
  const {
    auth: { credentials },
  } = state;
  const access_token = get(credentials, 'access-token');
  const { actionCable } = useActionCable(`${currentHost}/cable?access_token=${access_token}`);
  const { subscribe, unsubscribe, connected } = useChannel<FollowUpData>(actionCable);
  const [currentData, setCurrentData] = React.useState<FollowUpData[]>([]);
  const [ready, setReady] = React.useState(false)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (report_id) {
      subscribe(
        { channel: 'ReportChannel', id: report_id, access_token },
        {
          received: (data) => {
            setCurrentData((current) => {
              const already_existent_report_data = find(
                current,
                (item) => item.id === data.id,
              );
              if (already_existent_report_data) {
                return current.map((item) => {
                  if (item.id === data.id) {
                    return {
                      ...item,
                      ...data,
                    };
                  }
                  return item;
                });
              } else {
                return current.concat(data);
              }
            });
            if (data.status === FollowUpStatusEnum.SUCCESSFUL && data.id === 'main') {
              dispatch(
                success({
                  message: 'Download do relatório concluido.',
                }),
              );
            } else if (data.status === 'error') {
              setCurrentData(current => current.map(item => ({...item, status: FollowUpStatusEnum.ERROR})))
              dispatch(
                error({
                  message: 'Erro no download de relatório.',
                }),
              );
            }
          },
        },
      );
    }
    return () => {
      unsubscribe();
    };
  }, [report_id]);

  const downloadRegistrationsMethod = async () => {
    try {
      const extraParams = {
        years,
        contract_status,
      }
      const response = await dispatch(
        DOWNLOAD_REGISTRATIONS.request({
          params: {
            filters: {
              company_ids: map(company_ids, (id) => ~~id),
              report_id: report_id,
              ...extraParams,
            },
          } 
        })
      )

      const aTag = document.createElement('a');
      const bom = '\uFEFF'; 
      const encodedCsv = `data:text/csv;charset=utf-8,${bom}${encodeURIComponent(response.data)}`;
      aTag.href = encodedCsv;
      aTag.download = `Matriculas.csv`;
      aTag.target      = '_blank';
      document.body.appendChild(aTag);
      aTag.click();
      handleClose?.()  
    } catch (err) {
      dispatch(
        error({
          message: 'Erro no download de relatório.',
        }),
      );
    }
  }
    React.useEffect(() => {
      if (connected && !ready) {
        setReady(true)
        downloadRegistrationsMethod()
      }
    }, [connected]);
    if(isEmpty(report_id) || !connected){
      return null
    }
    return (
      <FollowUpComponent
        data={currentData}
      />
    );  

};
const ExportCompanyDataOptions = ({
  allowedRole,
  isSchool,
  companyId,
  companyName,
  companyKind,
  message,
  onClose,
  options,
  type,
  is_invoices_view = false,
  is_registrations_view = true,
  defaultFilters = defaultInvoiceFilterValues,
}: {
  allowedRole: boolean;
  isSchool: boolean;
  companyName: string;
  companyKind: string;
  companyId: string | number;
  message: string;
  onClose: () => void;
  options: DefaultOptionType[];
  type: 'menu' | 'button';
  is_invoices_view: boolean;
  is_registrations_view: boolean;
  defaultFilters?: InvoiceFilters;
}) => {
  if (!allowedRole) {
    return null;
  }
  const dispatch = useDispatch();
  const [companyIds, setCompanyIds] = React.useState(options.length ? [] : [companyId]);
  const [report_id, setReportId] = React.useState('')
  const [yearsValue, setYearValue] = React.useState<string[]>([]);
  const [userTypeValue, setUserTypeValue] = React.useState([]);
  const [productStatusValue, setProductStatusValue] = React.useState([]);
  const [statusFilterValue, setStatusFilterValue] = React.useState<InvoiceStatusTypes[]>(defaultFilters.statusFilter);
  const [chargeStatusFilterValue, setChargeStatusFilterValue] = React.useState<InvoiceChargeStatusTypes[]>(
    defaultFilters.chargeStatusFilter,
  );
  const [registrationFilterValue, setRegistrationFilterValue] = React.useState(defaultFilters.registrationFilter);
  const [representativeFilterValue, setRepresentativeFilterValue] = React.useState(defaultFilters.representativeFilter);
  const [fromExpirationDateFilterValue, setFromExpirationDateFilterValue] = React.useState<Date | null>(
    defaultFilters.fromExpirationDateFilter,
  );
  const [toExpirationDateFilterValue, setToExpirationDateFilterValue] = React.useState<Date | null>(
    defaultFilters.toExpirationDateFilter,
  );
  const [contractStatus, setContractStatus] = React.useState<ContractStatusEnum[]>([]);
  const [sendMethod, setSendMethod] = React.useState<'email' | 'download' | 'test' |''>('');
  const [generatingReports, setGeneratingReports] = React.useState<{ id: string; status: string }[]>([]);
  const {
    account: { companies },
    auth: { company_descendants, user: { id: user_id } },
  } = useSelector((state: RootState) => state);
  const onOpen = () => {
    if (!companyId) {
      setCompanyIds([]);
    }
    setYearValue([]);
    setProductStatusValue([]);
    setContractStatus([]);
    setSendMethod('email');
    if (is_invoices_view) {
      setStatusFilterValue(defaultFilters.statusFilter);
      setChargeStatusFilterValue(defaultFilters.chargeStatusFilter);
      setRegistrationFilterValue(defaultFilters.registrationFilter);
      setRepresentativeFilterValue(defaultFilters.representativeFilter);
      setToExpirationDateFilterValue(defaultFilters.toExpirationDateFilter);
      setFromExpirationDateFilterValue(defaultFilters.fromExpirationDateFilter);
    }
  };

  const filteredChargeStatusOptions = invoiceChargeStatusOptions.filter((opt) =>
    [
      InvoiceChargeStatusEnum.NOT_CHARGEABLE,
      InvoiceChargeStatusEnum.RECEIVED,
      InvoiceChargeStatusEnum.CONFIRMED,
      InvoiceChargeStatusEnum.PENDING_PAYMENT,
      InvoiceChargeStatusEnum.EXPIRED,
      InvoiceChargeStatusEnum.REFUNDED,
    ].includes(opt.value as InvoiceChargeStatusEnum),
  );

  const ExportDataInput = ({ type, handleClose }: { type: 'representatives' | 'registrations' | 'invoices', handleClose?: () => void }) => {
    if (generatingReports.length > 0) {
      return (
        <InvoiceValue
          values={companyIds.map((company_id) => {
            const company = company_descendants.concat(companies).find((item: any) => ~~item.id === ~~company_id);
            const company_name = company.name;
            const generatingReport = generatingReports.find((item) => item.id === company_id);
            const generatingReportStatus = generatingReport?.status;
            const iconColor =
              generatingReportStatus === 'successful'
                ? colors.green
                : generatingReportStatus === 'failure'
                ? colors.lightRed
                : 'initial';
            return {
              label: company_name,
              value:
                generatingReportStatus === 'awaiting'
                  ? 'Aguardando geração'
                  : generatingReportStatus === 'loading'
                  ? 'Carregando...'
                  : generatingReportStatus === 'successful'
                  ? 'Geração concluída'
                  : 'Geração falhou',
              convert: false,
              ...(generatingReport && {
                iconFunction: () => null,
                iconTooltipText:
                  generatingReportStatus === 'awaiting'
                    ? 'Aguardando geração'
                    : generatingReportStatus === 'loading'
                    ? 'Carregando...'
                    : generatingReportStatus === 'successful'
                    ? 'Geração concluída'
                    : 'Geração falhou',
                icon:
                  generatingReportStatus === 'awaiting'
                    ? HourglassEmpty
                    : generatingReportStatus === 'loading'
                    ? Downloading
                    : generatingReportStatus === 'successful'
                    ? CheckCircle
                    : Close,
                iconProps: { style: { color: iconColor } },
              }),
            };
          })}
        />
      );
    }

    const handleCloseReportDownload = () => {
      setCompanyIds([]);
      setUserTypeValue([]);
      setProductStatusValue([]);
      setYearValue([])
      setReportId('')
      handleClose?.()
    }
    return (
      <div style={{ width: '100%', display: 'grid' }}>
        {!isEmpty(options) && (
          <SelectMultipleComponent
            placeholder={`Companhias`}
            allow_select_all
            options={options}
            input={{
              value: companyIds,
              onChange: (event: any) => {
                const value = event?.target?.value
                setCompanyIds(value);
              },
            }}
          />
        )}
        {type === 'representatives' && (
          <>
            <SelectMultipleComponent
              allow_select_all
              placeholder={'Tipo de usuário'}
              options={userTypeOptions}
              input={{
                value: userTypeValue,
                onChange: (event: any) => {
                  setUserTypeValue(event?.target?.value);
                },
              }}
            />
            <SelectMultipleComponent
              allow_select_all
              placeholder={'Status dos Produtos'}
              options={productStatusOptions}
              input={{
                value: productStatusValue,
                onChange: (event: any) => {
                  setProductStatusValue(event?.target?.value);
                },
              }}
            />
          </>
        )}
        {type === 'registrations' && (
          <>
            <SelectMultipleComponent
              placeholder={'Anos'}
              allow_select_all
              options={yearOptions}
              input={{
                value: yearsValue,
                onChange: (event: any) => {
                  setYearValue(event?.target?.value);
                },
              }}
            />
            <SelectMultipleComponent
              placeholder={'Status do Contrato'}
              options={contractStatusOptions}
              allow_select_all
              input={{
                value: contractStatus,
                onChange: (event: any) => {
                  setContractStatus(event?.target?.value);
                },
              }}
            />
          </>
        )}
        {type === 'invoices' && (
          <>
            <SelectMultipleComponent
              placeholder={`Status da fatura`}
              label={'Status da fatura'}
              allow_select_all
              options={invoiceStatusOptions}
              input={{
                value: statusFilterValue,
                onChange: (event: any) => {
                  setStatusFilterValue(event?.target?.value);
                },
              }}
            />
            <SelectMultipleComponent
              placeholder='Status cobrança'
              label={'Status da cobrança'}
              allow_select_all
              clearable
              options={filteredChargeStatusOptions}
              input={{
                value: chargeStatusFilterValue,
                onChange: (e: any) => {
                  setChargeStatusFilterValue(e.target.value);
                },
              }}
            />
            <InputComponent
              placeholder={`Buscar por aluno`}
              label='Buscar por aluno'
              input={{
                value: registrationFilterValue,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setRegistrationFilterValue?.(e.target.value);
                },
              }}
            />
            <InputComponent
              placeholder={`Buscar por responsável`}
              label='Buscar por responsável'
              input={{
                value: representativeFilterValue,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setRepresentativeFilterValue(e.target.value);
                },
              }}
            />
            <DatePickerComponent
              placeholder='Vencimento a partir de'
              label='Vencimento a partir de'
              datePickerProps={{
                allowSameDateSelection: true,
              }}
              clearFieldFunction={() => {
                setFromExpirationDateFilterValue(null);
              }}
              input={{
                value: fromExpirationDateFilterValue,
                name: 'year',
                onChange: (e: Date) => {
                  setFromExpirationDateFilterValue(e);
                },
              }}
            />
            <DatePickerComponent
              placeholder='Vencimento até'
              label='Vencimento até'
              datePickerProps={{
                allowSameDateSelection: true,
              }}
              clearFieldFunction={() => {
                setToExpirationDateFilterValue(null);
              }}
              input={{
                value: toExpirationDateFilterValue,
                name: 'year',
                onChange: (e: Date) => {
                  setToExpirationDateFilterValue(e);
                },
              }}
            />
          </>
        )}
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
          <div>
            <span>Email</span>
            <Radio
              onChange={() => setSendMethod('email')}
              checked={sendMethod === 'email'}
              placeholder='Email'
            />
          </div>
          <div>
            <span>Download</span>
            <Radio
              onChange={() => setSendMethod('download')}
              checked={sendMethod === 'download'}
              placeholder='Download'
            />
          </div>
        </div>
        <ReportDownloadFollowUpComponent years={yearsValue} contract_status={contractStatus} company_ids={companyIds} report_id={report_id} handleClose={handleCloseReportDownload} />
      </div>
    );
  };


  const formatUsersData = async ({
    response,
    users_type,
    product_status,
  }: {
    response: FetchRegistrationsResponse;
    users_type: ('students' | 'representatives')[];
    product_status: ('active' | 'inactive')[];
  }) => {
    const {
      data: { data, included },
    } = response;

    const formattedData = await data.reduce((acc, registration) => {
      const company = included.find((incl) => incl.type === 'companies') as CompanyJson;
      const student_account = included.find(
        (incl) => incl.type === 'accounts' && ~~incl.id === registration.attributes.account_id,
      ) as AccountJson;
      const student_user = included.find(
        (incl) => incl.type === 'users' && student_account.attributes.user_id === ~~incl.id,
      ) as UserJson;

      const student_address = included.find(
        (incl) => incl.type === 'addresses' && incl.attributes.user_id.toString() === get(student_user, 'id'),
      ) as AddressJson;
      const student_city = included.find(
        (incl) => incl.type === 'cities' && ~~incl.id === get(student_address, 'city_id'),
      ) as CityJson;

      const student_state = included.find(
        (incl) => incl.type === 'states' && ~~incl.id === get(student_address, 'state_id'),
      ) as StateJson;
      const registration_products = included.filter((incl) => {
        return incl.type === 'registration_products' && incl.attributes.registration_id === ~~registration.id;
      }) as RegistrationProductJson[];
      const representative_rows = users_type.includes('representatives')
        ? reduce(
            included.filter(
              (incl) => incl.type === 'representatives' && incl.attributes.registration_id === ~~registration.id,
            ) as RepresentativeJson[],
            (rep_acc, rep) => {
              const representative_products = included.filter(
                (incl) => incl.type === 'representative_products' && incl.attributes.representative_id === ~~rep.id,
              ) as RepresentativeProductJson[];
              const registration_product_ids = representative_products.map(
                (item) => item.attributes.registration_product_id,
              );
              const filtered_registration_products = registration_products.filter((item) =>
                registration_product_ids.includes(~~item.id),
              );
              const representative_account = included.find(
                (incl) => incl.type === 'accounts' && ~~incl.id === get(rep, 'attributes.account_id'),
              ) as AccountJson;
              const representative_user = included.find(
                (incl) => incl.type === 'users' && ~~incl.id === get(representative_account, 'attributes.user_id'),
              ) as UserJson;
              const representative_address = included.find(
                (incl) =>
                  incl.type === 'addresses' && incl.attributes.user_id.toString() === get(representative_user, 'id'),
              ) as AddressJson;
              const representative_city = included.find(
                (incl) => incl.type === 'cities' && ~~incl.id === get(representative_address, 'city_id'),
              ) as CityJson;

              const representative_state = included.find(
                (incl) => incl.type === 'states' && ~~incl.id === get(representative_address, 'state_id'),
              ) as StateJson;

              if (filtered_registration_products.length === 0 || !representative_account || !representative_user) {
                return rep_acc;
              }
              const status_label =
                product_status.includes('active') &&
                filtered_registration_products.some((item) => item.attributes.active)
                  ? 'Ativo'
                  : 'Inativo';
              const already_existing_rep_row = rep_acc.find(
                (item) =>
                  item.Nome &&
                  item.Nome === get(representative_user, 'attributes.name') &&
                  item.Perfil === 'Responsável',
              );
              if (already_existing_rep_row) {
                if (already_existing_rep_row.Status === 'Inativo' && status_label === 'Ativo') {
                  return rep_acc.map((item) => {
                    if (item.Nome === get(representative_user, 'attributes.name') && item.Perfil === 'Responsável') {
                      return {
                        ...item,
                        Status: 'Ativo',
                      };
                    } else {
                      return item;
                    }
                  });
                }
                return rep_acc;
              }
              return rep_acc.concat({
                Status: status_label,
                Perfil: 'Responsável',
                Unidade: get(company, 'attributes.name') || '',
                CPF: get(representative_user, 'attributes.document_number') || '',
                Nome: get(representative_user, 'attributes.name') || '',
                Email: get(representative_user, 'attributes.email') || '',
                Telefone: get(representative_user, 'attributes.phone') || '',
                'Data de Nasc': get(representative_user, 'attributes.birthdate') || '',
                'Local de Nasc': get(representative_user, 'attributes.birthplace') || '',
                CEP: get(representative_address, 'attributes.zipcode') || '',
                Rua: get(representative_address, 'attributes.street') || '',
                Número: get(representative_address, 'attributes.number') || '',
                Complemento: get(representative_address, 'attributes.complement') || '',
                Bairro: get(representative_address, 'attributes.neighbourhood') || '',
                Cidade: get(representative_city, 'attributes.name') || '',
                Estado: get(representative_state, 'attributes.name') || '',
              } as ExportUsersData);
            },
            [] as ExportUsersData[],
          )
        : [];

      const registration_status_label =
        product_status.includes('active') && registration_products.some((item) => item.attributes.active)
          ? 'Ativo'
          : 'Inativo';
      const user_row = users_type.includes('students')
        ? [
            {
              Status: registration_status_label,
              Perfil: 'Estudante',
              Unidade: get(company, 'attributes.name') || '',
              CPF: get(student_user, 'attributes.document_number') || '',
              Nome: get(student_user, 'attributes.name') || '',
              Email: get(student_user, 'attributes.email') || '',
              Telefone: get(student_user, 'attributes.phone') || '',
              'Data de Nasc': get(student_user, 'attributes.birthdate') || '',
              'Local de Nasc': get(student_user, 'attributes.birthplace') || '',
              CEP: get(student_address, 'attributes.zipcode') || '',
              Rua: get(student_address, 'attributes.street') || '',
              Número: get(student_address, 'attributes.number') || '',
              Complemento: get(student_address, 'attributes.complement') || '',
              Bairro: get(student_address, 'attributes.neighbourhood') || '',
              Cidade: get(student_city, 'attributes.name') || '',
              Estado: get(student_state, 'attributes.name') || '',
            } as ExportUsersData,
          ]
        : [];

      const result = user_row.concat(representative_rows);
      return acc.concat(result);
    }, [] as ExportUsersData[]);
    return formattedData;
  };
  const fetchInvoiceData = async (params: {
    id: string;
    page_number: number;
    page_size: number;
    registration_filter?: string;
    status_filter_value?: (InvoiceStatusTypes | string)[];
    charge_status_filter: InvoiceChargeStatusTypes[];
    from_expiration_date_filter?: Date | null;
    to_expiration_date_filter?: Date | null;
    representative_filter?: string;
  }) => {
    const {
      page_number,
      page_size,
      registration_filter = registrationFilterValue,
      representative_filter = representativeFilterValue,
      from_expiration_date_filter = fromExpirationDateFilterValue,
      to_expiration_date_filter = toExpirationDateFilterValue,
      charge_status_filter = chargeStatusFilterValue,
      status_filter_value = statusFilterValue,
      id,
    } = params;
    let representative_filter_object = {};
    if (representative_filter) {
      representative_filter_object = {
        'q[representative_account_user_name_cont]': representative_filter,
      };
    }
    const expiration_date_filter_object = {
      ...(from_expiration_date_filter && {
        'q[expiration_date_gteq]': startOfDay(new Date(from_expiration_date_filter)).toISOString(),
      }),
      ...(to_expiration_date_filter && {
        'q[expiration_date_lteq]': endOfDay(new Date(to_expiration_date_filter)).toISOString(),
      }),
    };
    let charge_status_filter_object = {};
    if (charge_status_filter.length) {
      charge_status_filter_object = { 'q[charge_status_in]': charge_status_filter };
    }
    let registration_filter_object = {};
    if (registration_filter) {
      registration_filter_object = { 'q[registration_account_user_name_cont]': registration_filter || '' };
    }
    let status_filter_object = {};
    if (status_filter_value) {
      status_filter_object = {
        'q[status_in]': status_filter_value || '',
      };
    }
    const {
      data: { data, included, meta },
    } = await dispatch(
      FETCH_INVOICES.request({
        params: {
          filters: {
            'q[company_id_in]': [id],
            'page[number]': page_number.toString(),
            'page[size]': page_size.toString(),
            include:
              'registration.contracts.billings.invoice_items,registration.contracts.product,registration.account.user,wallet_credits.wallet,representative.account.user,registration.account.company',
            ...expiration_date_filter_object,
            ...status_filter_object,
            ...charge_status_filter_object,
            ...registration_filter_object,
            ...representative_filter_object,
          },
        },
      }),
    );
    const formattedData = await data.reduce((acc, invoice) => {
      const registration = find(
        included,
        (incl) => incl.type === 'registrations' && ~~incl.id === invoice.attributes.registration_id,
      ) as RegistrationJson;
      const registration_account = find(
        included,
        (incl) => incl.type === 'accounts' && ~~incl.id === get(registration, 'attributes.account_id'),
      ) as AccountJson;
      const registration_account_user = find(
        included,
        (incl) => incl.type === 'users' && ~~incl.id === get(registration_account, 'attributes.user_id'),
      ) as UserJson;
      const invoice_company = find(
        included,
        (incl) => incl.type === 'companies' && ~~incl.id === get(registration_account, 'attributes.company_id'),
      ) as CompanyJson;
      const representative = find(
        included,
        (incl) => incl.type === 'representatives' && ~~incl.id === invoice.attributes.representative_id,
      ) as RepresentativeJson;
      const representative_account = find(
        included,
        (incl) => incl.type === 'accounts' && ~~incl.id === get(representative, 'attributes.account_id'),
      ) as AccountJson;
      const representative_account_user = find(
        included,
        (incl) => incl.type === 'users' && ~~incl.id === get(representative_account, 'attributes.user_id'),
      ) as UserJson;
      const status_label = invoiceStatusOptions.find((opt) => opt.value === invoice.attributes.status)?.label || '';
      const charge_status_label =
        invoiceChargeStatusOptions.find((opt) => opt.value === invoice.attributes.charge_status)?.label || '';
      const expiration_date_label = invoice.attributes.expiration_date
        ? format(new Date(invoice.attributes.expiration_date), 'dd-MM-yyyy')
        : '';
      const punctuality_expiration_date_label = invoice.attributes.punctuality_expiration_date
        ? format(new Date(invoice.attributes.punctuality_expiration_date), 'dd-MM-yyyy')
        : '';
      const invoice_items = filter(
        included,
        (incl) => incl.type === 'invoice_items' && incl.attributes.invoice_id === ~~invoice.id,
      ) as InvoiceItemJson[];
      const items_description = invoice_items.map((invoice_item) => invoice_item.attributes.description).join('//');
      const liquid_value = toFixedNumber(invoice.attributes.charge - invoice.attributes.conditional_discount_charge);
      const wallet_charge_title = `${
        invoice.attributes.wallet_charge > 0
          ? liquid_value - toFixedNumber(invoice.attributes.wallet_charge) <= 0
            ? 'Total'
            : 'Parcial'
          : ''
      }`;
      const charge_value =
        invoice.attributes.status === InvoiceStatusEnum.FINISHED
          ? invoice.attributes.charge +
            (invoice.attributes.accumulated_fines.accumulated_fees || 0) +
            (invoice.attributes.accumulated_fines.accumulated_penalty || 0)
          : invoice.attributes.charge;
      return acc.concat({
        Unidade: get(invoice_company, 'attributes.name'),
        'Código Fatura': invoice.attributes.code,
        Responsável: get(representative_account_user, 'attributes.name'),
        Aluno: get(registration_account_user, 'attributes.name'),
        'Status Fatura': status_label,
        'Status Cobrança': charge_status_label,
        'Data Vencimento': expiration_date_label,
        'Data Desconto': punctuality_expiration_date_label,
        Crédito: wallet_charge_title,
        Valor: formatToCurrency(charge_value),
        Desconto: formatToCurrency(invoice.attributes.conditional_discount_charge),
        Parcela: invoice.attributes.installment_number,
        'Descrição items': items_description,
      } as ExportInvoiceData);
    }, [] as ExportInvoiceData[]);
    return { data: formattedData, meta: meta };
  };
  const fetchRegistrationData = async (params: {
    id: string;
    page_number: number;
    page_size: number;
    years: string[];
    contract_statuses: ContractStatusEnum[];
    type?: 'registrations' | 'representatives' | 'invoices';
    users_type?: ('students' | 'representatives')[];
    product_status?: ('active' | 'inactive')[];
  }) => {
    const {
      id,
      page_number,
      page_size,
      years,
      type = 'registrations',
      users_type = [],
      product_status = [],
    } = params;
    let yearFilter = {};
    if (years.length > 0) {
      yearFilter = {
        'q[registration_products_product_year_in]': years,
      };
    }
    const include =
      type === 'registrations'
        ? [
            'account.company',
            'registration_products.representative_products',
            'registration_products.registration_product_subsidies.subsidy',
            'contracts',
            'products.periods',
            'account.user',
            'representatives.account.user',
            'registration_products.registration_closure.registration_closure_reason',
            'registration_products.payment_option',
          ].join(',')
        : [
            'account.company',
            'account.user.address',
            'account.user.address.city',
            'account.user.address.state',
            'registration_products.representative_products',
            'representatives.account.user.address',
            'representatives.account.user.address.city',
            'representatives.account.user.address.state',
          ].join(',');

    const response = await dispatch(
      FETCH_REGISTRATIONS.request({
        params: {
          filters: {
            'q[account_company_id_eq]': id,
            include,
            'page[number]': page_number.toString(),
            'page[size]': page_size.toString(),
            ...yearFilter,
          },
        },
      }),
    );
    const {
      data: { meta },
    } = response;
    const formattedData = await formatUsersData({
      response,
      users_type,
      product_status,
    });

    return { data: formattedData, meta };
  };
  const getDataFromCompany = React.useCallback(
    async (id, type: 'registrations' | 'invoices' | 'representatives') => {
      const per_page = 10;
      let current_page = 1;
      let total_pages = 0;
      let accumulated_data: ( ExportInvoiceData | ExportUsersData)[] = [];
      while (total_pages === 0 || total_pages >= current_page) {
        const { data, meta } =
          type === 'invoices'
            ? await fetchInvoiceData({
                id,
                page_number: current_page,
                page_size: per_page,
                to_expiration_date_filter: toExpirationDateFilterValue,
                from_expiration_date_filter: fromExpirationDateFilterValue,
                registration_filter: registrationFilterValue,
                representative_filter: representativeFilterValue,
                charge_status_filter: chargeStatusFilterValue,
                status_filter_value: statusFilterValue,
              })
            : await fetchRegistrationData({
                id,
                page_number: current_page,
                page_size: per_page,
                years: yearsValue,
                type,
                contract_statuses: contractStatus,
                users_type: userTypeValue,
                product_status: productStatusValue,
              });
        accumulated_data = accumulated_data.concat(data);
        if ([0, 1].includes(meta.page_count)) {
          break;
        }
        total_pages = meta.page_count;
        current_page = current_page + 1;
      }
      return accumulated_data;
    },
    [
      yearsValue,
      contractStatus,
      sendMethod,
      generatingReports,
      setGeneratingReports,
      toExpirationDateFilterValue,
      fromExpirationDateFilterValue,
      registrationFilterValue,
      representativeFilterValue,
      chargeStatusFilterValue,
      statusFilterValue,
      productStatusValue,
      userTypeValue,
    ],
  );
  const createUserEvent = async (event_label: string) => {
    await dispatch(CREATE_TRANSACTION.request({
      data: {
        event: TransactionEventEnum.USER_EVENT,
        transactable_id: user_id,
        transactable_type: 'User',
        event_date: new Date().toISOString(),
        description: `Usuário solicitou exportação de planilha: ${event_label}`
      }
    }))
  }
  const exportDataMethod = React.useCallback(
    async (
      {
        handleClose,
        setLoading,
        setLoadingMessage
      }: {
        setLoading: React.Dispatch<React.SetStateAction<boolean>>;
        handleClose: () => void;
        setLoadingMessage: React.Dispatch<React.SetStateAction<string>>
      },
      action: typeof EXPORT_COMPANY_REGISTRATIONS | typeof EXPORT_COMPANY_REPRESENTATIVES | typeof EXPORT_INVOICES,
      ids: (string | number)[],
    ) => {
      try {
        const isRegistrationType = action === EXPORT_COMPANY_REGISTRATIONS;
        const isRepresentativeType = action === EXPORT_COMPANY_REPRESENTATIVES;
        const isInvoiceType = action === EXPORT_INVOICES
        const surname = isRegistrationType
        ? 'Relatório de Matrículas'
        : isRepresentativeType
        ? 'Relatório de Alunos/Responsáveis'
        : 'Relatório de Faturas';
        const name = `${companyName} - ${surname}`;
        const all_companies = company_descendants.concat(companies)
        const company_names = map(ids, id => {
          const company_for_this_id = all_companies.find((item: any) => ~~item.id === ~~id)
          return company_for_this_id?.name
        }).join(',')
        const event_description_suffix = `${surname} para as escolas: ${company_names}`
        await createUserEvent(event_description_suffix)
        if (sendMethod === 'email') {
          setLoading(true)
          const extraParams = {
            ...(isRegistrationType && {
              years: yearsValue,
              contract_status: contractStatus,
            }),
            ...(isRepresentativeType && {
              user_types: userTypeValue,
              product_status: productStatusValue,
            }),
            ...(isInvoiceType) && {
              ...(registrationFilterValue ? {registration: registrationFilterValue} : {}),
              ...(representativeFilterValue ? {representative: representativeFilterValue} : {}),
              ...(fromExpirationDateFilterValue ? {from_expiration_date: startOfDay(fromExpirationDateFilterValue).toISOString()} : {}),
              ...(toExpirationDateFilterValue ? {to_expiration_date: endOfDay(toExpirationDateFilterValue).toISOString()} : {}),
              ...(!isEmpty(statusFilterValue)? { status: statusFilterValue as InvoiceStatusEnum[] } : {}),
              ...(!isEmpty(chargeStatusFilterValue)? { charge_status: chargeStatusFilterValue as InvoiceChargeStatusEnum[] } : {})
            }
          };
          await dispatch(
            action.request({
              data: {
                company_ids: map(ids, (id) => ~~id),
                ...extraParams,
              },
            }),
          )
          dispatch(
            success({
              title: 'Solicitação concluída',
              message: 'Aguarde o recebimento do email com as informações.',
            }),
          );
          } else if (sendMethod === 'download' && !isRegistrationType) {
          const type = isRegistrationType ? 'registrations' : isRepresentativeType ? 'representatives' : 'invoices';
          await setGeneratingReports(ids.map((id) => ({ id: id.toString(), status: 'awaiting' })));
          let final_result: { name: string; data: ( ExportInvoiceData | ExportUsersData)[] }[] =
            [];
          for (const company_id of ids) {
            try {
              setGeneratingReports((current) =>
                current.map((item) => {
                  if (item.id === company_id) {
                    return { id: company_id, status: 'loading' };
                  }
                  return item;
                }),
              );
              const company_name = companies.find((item: any) => ~~item.id === ~~company_id).name;
              const new_result = await getDataFromCompany(company_id, type);
              final_result = final_result.concat({ name: company_name, data: new_result });
              setGeneratingReports((current) =>
                current.map((item) => {
                  if (item.id === company_id) {
                    return { id: company_id, status: 'successful' };
                  }
                  return item;
                }),
              );
            } catch (error) {
              setGeneratingReports((current) =>
                current.map((item) => {
                  if (item.id === company_id) {
                    return { id: company_id, status: 'failure' };
                  }
                  return item;
                }),
              );
            }
          }
          const workbook = XLSX.utils.book_new();
          for (const data of final_result) {
            const ws = await XLSX.utils.json_to_sheet(data.data);
            ws['!cols'] = getColsWidth(data.data);
            await XLSX.utils.book_append_sheet(workbook, ws, parseText(data.name, 28));
          }
          await XLSX.writeFile(workbook, `${name}.xlsx`);
          setGeneratingReports([]);
          dispatch(
            success({
              title: 'Download iniciado',
              message: 'Download do relatório deve ter sido iniciado.',
            }),
          );
        }
        setLoading(false)
        setLoadingMessage('Carregando')
        setCompanyIds([]);
        setUserTypeValue([]);
        setProductStatusValue([]);
        setYearValue([])
        setReportId('')
        handleClose();
        onClose();
      } catch (err) {
        setLoading(false);
        setLoadingMessage('Erro')
        setReportId('')
        dispatch(
          error({
            message: 'Erro na exportação de dados',
          }),
        );
      }
    },
    [
      companyIds,
      companyKind,
      yearsValue,
      contractStatus,
      userTypeValue,
      productStatusValue,
      generatingReports,
      setGeneratingReports,
      sendMethod,
      statusFilterValue,
      chargeStatusFilterValue,
      fromExpirationDateFilterValue,
      toExpirationDateFilterValue,
      representativeFilterValue,
      registrationFilterValue
    ],
  );
  const companyLabel = companyTypes.find((company) => company.value === companyKind)?.label;
  const title =
    companyKind === Company.SCHOOL
      ? `da escola ${companyName}`
      : `das escolas pertencentes ao ${companyLabel} ${companyName}`;
  const isMenu = type === 'menu';
  const ModalElement = isMenu ? MenuItemModal : ButtonModal;
  const labelProps = {
    ...(!isMenu && {
      className: 'blue small',
    }),
  };
  const show_registration_exports = is_registrations_view;
  const show_invoice_exports = is_invoices_view;

  return (
    <>
      {show_registration_exports && (
        <>
          <ModalElement
            onOpen={onOpen}
            labelProps={labelProps}
            label='Exportar planilha de matrículas'
            title={`Exportar planilha de matrículas ${title}`}
            message={
              generatingReports.length > 0 ? 'Aguarde o fim do carregamento do relatório de matrículas' : message
            }
            disableConfirm={!isEmpty(report_id) || (companyKind !== Company.SCHOOL && isEmpty(companyIds)) || generatingReports.length > 0}
            onConfirm={(params) =>{
              if(sendMethod === 'download'){
                setReportId(generateId(5))
              } else {
                exportDataMethod(params, EXPORT_COMPANY_REGISTRATIONS, isSchool ? [companyId] : companyIds)
              }
            }}
          >
            <ExportDataInput type='registrations' />
          </ModalElement>
          <ModalElement
            onOpen={onOpen}
            labelProps={labelProps}
            label='Exportar planilha de responsáveis'
            title={`Exportar planilha de responsáveis do(a) ${companyName}`}
            message={
              generatingReports.length > 0 ? 'Aguarde o fim do carregamento do relatório de responsáveis' : message
            }
            disableConfirm={(companyKind !== Company.SCHOOL && isEmpty(companyIds)) || generatingReports.length > 0}
            onConfirm={(params) =>
              exportDataMethod(params, EXPORT_COMPANY_REPRESENTATIVES, isSchool ? [companyId] : companyIds)
            }
          >
            <ExportDataInput type='representatives' />
          </ModalElement>
        </>
      )}
      {show_invoice_exports && (
        <ModalElement
          onOpen={onOpen}
          labelProps={labelProps}
          label='Exportar planilha de faturas'
          title={`Exportar planilha de faturas ${title}`}
          message={generatingReports.length > 0 ? 'Aguarde o fim do carregamento do relatório de faturas' : message}
          disableConfirm={(companyKind !== Company.SCHOOL && isEmpty(companyIds)) || generatingReports.length > 0}
          onConfirm={(params) => exportDataMethod(params, EXPORT_INVOICES, isSchool ? [companyId] : companyIds)}
        >
          <ExportDataInput type='invoices' />
        </ModalElement>
      )}
    </>
  );
};

export default ExportCompanyDataOptions;
