/* eslint-disable camelcase */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change, reset, formValueSelector, isValid } from 'redux-form';
import { error, success } from 'react-notification-system-redux';
import { isEmpty, find, toString, isNil, keys, filter, map, head } from 'lodash';

import Loading from '../components/loading/Loading';
import { AccountJson, CREATE_ACCOUNT, UPDATE_ACCOUNT } from '../store/accounts';
import { Country, State, City } from 'country-state-city';
import { AddressJson, UPDATE_USER, CREATE_USER, UserJson, VERIFY_USER_HIERARCHY } from '../store/users';

import {
  removeSpecialSymbols,
  convertDocument,
  toDate,
  checkDocumentType,
  verifyDifferentValues,
} from '../utils/functions';

import { RootState } from '../store/configureStore';
import ResponsibleStepForm from '../components/form/ResponsibleStepForm';
import { FETCH_REPRESENTATIVE } from '../store/representatives';
import {
  CurrentRegistrationData,
  CurrentStudentData,
  UserOptionsData,
  ResponsibleStepFormInitialValues,
  ResponsibleStepFormData,
  NestedAccountAttributesRepresentativeForm,
  NestedRepresentativeAttributes,
  UserAttributes,
  AccountsAttributes,
  RepresentativeEnum,
  ConnectionEnum,
} from '../utils/constants';
import { FETCH_REGISTRATION } from '../store/registrations';
const form_name = 'registration-responsiblestep';

interface ResponsibleData extends UserOptionsData {
  accounts_attributes: NestedAccountAttributesRepresentativeForm[];
}

const representativesUpdater = (
  currentRepresentatives: NestedRepresentativeAttributes[],
  initialRepresentatives: NestedRepresentativeAttributes[],
) => {
  const sorted: NestedRepresentativeAttributes[] = [];
  currentRepresentatives.forEach((representative) => {
    const related = find(initialRepresentatives, (initial) => initial.id === representative.id);
    if (isNil(related)) {
      sorted.push(representative);
      return;
    }
    const omit = ['id', '_destroy', 'account_id'];
    const result = verifyDifferentValues(representative, related, omit) as NestedRepresentativeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedRepresentativeAttributes[];
};

const accountsUpdater = (
  currentAccountAttributes: NestedAccountAttributesRepresentativeForm[],
  initialAccountAttributes: NestedAccountAttributesRepresentativeForm[],
) => {
  const sorted: NestedAccountAttributesRepresentativeForm[] = [];
  currentAccountAttributes.forEach((accounts_attributes) => {
    const related = find(initialAccountAttributes, (initial) => initial.id === accounts_attributes.id);
    if (isNil(related)) {
      sorted.push(accounts_attributes);
      return;
    }
    const result = verifyDifferentValues(accounts_attributes, related, [
      'id',
      '_destroy',
      'representatives_attributes',
      'company_id',
      'user_id',
    ]) as NestedAccountAttributesRepresentativeForm;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentRepresentativeAttributes = accounts_attributes.representatives_attributes;
      const initialRepresentativeAttributes = filter(related?.representatives_attributes, (rep) => rep.id);
      const representatives_attributes = representativesUpdater(
        currentRepresentativeAttributes as NestedRepresentativeAttributes[],
        initialRepresentativeAttributes as NestedRepresentativeAttributes[],
      );
      const formattedRepresentative = {
        ...result,
        representatives_attributes,
      };
      sorted.push(formattedRepresentative);
    }
  });
  return sorted as NestedAccountAttributesRepresentativeForm[];
};

const defaultInitialValues = (company_id: number, registration_id: number) => ({
  nationality_country: {
    phonecode: '55',
    flag: '🇧🇷',
    label: 'Brazil',
    value: 'BR',
  },
  accounts_attributes: [
    {
      company_id,
      representatives_attributes: [
        {
          registration_id,
          kind: RepresentativeEnum.PEDAGOGICAL,
        },
      ],
    },
  ],
});

const ResponsibleStepFormContainer = (props: {
  setLoading: (value: boolean) => void;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  currentRegistration: CurrentRegistrationData;
  currentRepresentativeId: string;
  setCurrentRepresentativeId: React.Dispatch<React.SetStateAction<string>>;
  fetchRegistrationRepresentatives: () => Promise<void>;
}) => {
  const {
    setLoading,
    setTabValue,
    currentRegistration,
    currentRepresentativeId,
    setCurrentRepresentativeId,
    fetchRegistrationRepresentatives,
  } = props;
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [preLoadingOptions, setPreLoadingOptions] = React.useState<string[]>([]);
  const [initialValues, setInitialValues] = React.useState<ResponsibleStepFormInitialValues>(
    defaultInitialValues(~~currentRegistration.company_id, ~~currentRegistration.id),
  );
  const [allowUserUpdate, setAllowUserUpdate] = React.useState(true)
  const [currentUser, setCurrentUser] = React.useState(false);
  const [currentStudent, setCurrentStudent] = React.useState<CurrentStudentData | null>(null);
  const formValues = formValueSelector(form_name);

  const representativeAttributes = formValues(state, 'accounts_attributes[0].representatives_attributes[0]');
  const connectionValue = representativeAttributes?.connection;
  const isFormValid = isValid(form_name)(state);

  const insertResponsibleData = (user: ResponsibleData, can_update_user = true) => {
    const initial_country_options = Country.getAllCountries().map(
      ({ latitude, longitude, timezones, name, isoCode, ...rest }) => ({
        ...rest,
        label: name,
        value: isoCode,
      }),
    );
    setLoading(true);
    try {
      const { birthdate, document_number, id, birthplace, nationality, ...rest } = user;
      const date = birthdate ? toDate(birthdate) : '';
      const nationality_state = birthplace?.substring(birthplace.lastIndexOf('-') + 1);
      const nationality_city = birthplace?.substring(0, birthplace.lastIndexOf('-'));
      const countryInfo = initial_country_options.find((item) => item.label === nationality);
      const stateInfo = State.getStatesOfCountry(countryInfo?.value as string)
        .map(({ name, isoCode, countryCode }) => ({
          label: name,
          value: name,
          isoCode,
          countryCode,
        }))
        .find((item) => item.isoCode === nationality_state);
      const cityInfo =
        City.getCitiesOfState(stateInfo?.countryCode as string, stateInfo?.isoCode as string)
          .map(({ name }) => ({
            label: name,
            value: name,
          }))
          .find((item) => item.value === nationality_city) || nationality_city;
      const accounts_attributes = user.accounts_attributes || [
        {
          company_id: currentRegistration.company_id,
          id: user?.account?.id,
          ...(user?.id ? { user_id: ~~user.id } : {}),
          representatives_attributes: [
            {kind: RepresentativeEnum.PEDAGOGICAL, registration_id: currentRegistration.id},
          ]
        }
      ]
      const newInitialValues = {
        document_number: convertDocument(document_number),
        id,
        relationship: connectionValue,
        nationality_state: stateInfo,
        nationality_city: cityInfo,
        nationality_country: countryInfo,
        ...rest,
        accounts_attributes,
        ...(date && { birthdate: date.toISOString() }),
      } as ResponsibleStepFormInitialValues;
      setAllowUserUpdate(can_update_user)
      setInitialValues(newInitialValues);
      setCurrentUser(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(reset(form_name));
      dispatch(
        error({
          message: 'Erro no carregamento de dados do usuário, favor tentar novamente',
        }),
      );
    }
  };
  const verifyCurrentAndSelectedUser = React.useCallback(
    async (id: string) => {
      try {
        const result = await dispatch(
          VERIFY_USER_HIERARCHY.request({
            id
          }),
        );
        const { data } = result
        return data
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao checar hierarquida do usuário selecionado',
          }),
        );
        return false;
      }
    },
    [],
  );

  const handleSelectUser = React.useCallback(async (user: ResponsibleData) => {
    try {
      if (user) {
        setPreLoadingOptions((item) => item.concat('initialValues'));
        const can_update_user = await verifyCurrentAndSelectedUser(user.id)
        dispatch(reset(form_name));
        await insertResponsibleData(user, can_update_user);
        setPreLoadingOptions((cur) => cur.filter((item) => item !== 'initialValues'));
      }
    } catch (err) {
      dispatch(
        error({
          message: 'Erro após selecionar usuário por CPF',
          autoDismiss: 3,
        }),
      );
    }
  }, [setPreLoadingOptions]);

  const insertStudentAddress = React.useCallback(() => {
    const address_attributes = currentStudent?.address_attributes;
    const additional_data = currentStudent?.additional_data;
    if (!isEmpty(additional_data)) {
      additional_data &&
        Object.hasOwn(additional_data, 'personal_email') &&
        dispatch(change(form_name, 'additional_data.personal_email', additional_data.personal_email || ''));
    }
    address_attributes?.country_id &&
      dispatch(change(form_name, 'address_attributes.country_id', address_attributes.country_id + ''));
    address_attributes?.city_id &&
      dispatch(change(form_name, 'address_attributes.city_id', address_attributes.city_id + ''));
    address_attributes?.state_id &&
      dispatch(change(form_name, 'address_attributes.state_id', address_attributes.state_id + ''));
    address_attributes?.number && dispatch(change(form_name, 'address_attributes.number', address_attributes.number));
    address_attributes?.complement &&
      dispatch(change(form_name, 'address_attributes.complement', address_attributes.complement));
    address_attributes?.street && dispatch(change(form_name, 'address_attributes.street', address_attributes.street));
    address_attributes?.zipcode && dispatch(change(form_name, 'address_attributes.zipcode', address_attributes.zipcode));
    address_attributes?.neighbourhood &&
      dispatch(change(form_name, 'address_attributes.neighbourhood', address_attributes.neighbourhood));
    return {
      country_id: address_attributes?.country_id || '',
      state_id: address_attributes?.state_id || '',
      city_id: address_attributes?.city_id || '',
    };
  }, [currentStudent]);

  const fetchResponsibleStepData = React.useCallback(async () => {
    setPreLoadingOptions((item) => item.concat('current_student'));
    const registration = await dispatch(
      FETCH_REGISTRATION.request({
        id: currentRegistration.id,
        params: {
          filters: {
            include: ['account.user.address', 'account.user.accounts', 'account.company'].join(','),
          },
        },
      }),
    );

    const {
      data: {
        data: { attributes },
        included,
      },
    } = registration;
    const includedAccounts = map(filter(included, (incl) => incl.type === 'accounts') as AccountJson[], (item) => ({
      id: item.id,
      ...item.attributes,
    }));
    const includedUsers = map(filter(included, (incl) => incl.type === 'users') as UserJson[], (item) => ({
      id: item.id,
      ...item.attributes,
    }));

    const registration_account = includedAccounts.find(
      (item) => ~~item.id === attributes.account_id,
    ) as AccountsAttributes;
    const registration_user = includedUsers.find(
      (item) => ~~item.id === registration_account.user_id,
    ) as UserAttributes;
    const studentAccount = registration_account;
    const includedAddress = included.find((item) => item.type === 'addresses') as AddressJson;
    let address_attributes;
    if (includedAddress) {
      const {
        attributes: { city_id, state_id, country_id, ...rest },
      } = includedAddress;
      address_attributes = {
        id: includedAddress.id,
        city_id: toString(city_id),
        state_id: toString(state_id),
        country_id: toString(country_id),
        ...rest,
      };
    }

    const student_data = {
      ...registration_user,
      accounts_attributes: [
        {
          ...studentAccount
        }
      ],
      address_attributes,
    } as CurrentStudentData;
    setPreLoadingOptions((cur) => cur.filter((item) => item !== 'current_student'));
    setCurrentStudent(student_data);
  }, [currentRegistration]);
  const fetchRepresentativeData = async () => {
    setPreLoadingOptions((item) => item.concat('initialValues'));
    try {
      const response = await dispatch(
        FETCH_REPRESENTATIVE.request({
          id: currentRepresentativeId,
          params: {
            filters: {
              include: 'account.user.ethnicities,account.user.races,account.user.address',
            },
          },
        }),
      );
      const {
        data: { data, included },
      } = response;
      const account = included.find(
        (item) => item.type === 'accounts' && data.attributes.account_id === ~~item.id,
      ) as AccountJson;
      const user = included.find(
        (item) => item.type === 'users' && account.attributes.user_id === ~~item.id,
      ) as UserJson;
      const userAddress = included.find(
        (item) => item.type === 'addresses' && ~~user.id === item.attributes.user_id,
      ) as AddressJson;
      const address_attributes = {
        ...(userAddress && {
          id: userAddress.id,
          ...userAddress.attributes,
          city_id: toString(userAddress.attributes.city_id),
          state_id: toString(userAddress.attributes.state_id),
          country_id: toString(userAddress.attributes.country_id),
        }),
      };

      const accounts_attributes = [
        {
          id: account.id,
          representatives_attributes: [
            {
              id: data.id,
              ...data.attributes,
            },
          ],
        },
      ];
      const newInitialValues = {
        id: user.id,
        ...user.attributes,
        address_attributes,
        accounts_attributes,
      };
      const can_update_user = await verifyCurrentAndSelectedUser(user.id)
      insertResponsibleData(newInitialValues, can_update_user);
      setPreLoadingOptions((cur) => cur.filter((item) => item !== 'initialValues'));
    } catch (err) {
      dispatch(
        error({
          message: 'Erro no carregamento de dados iniciais do usuário',
          autoDismiss: 3,
        }),
      );
    }
  };

  const saveAccountMethod = React.useCallback(
    async (data: Partial<ResponsibleStepFormData>) => {
      const account_data = head(data.accounts_attributes) as NestedAccountAttributesRepresentativeForm
      const {id, ...rest} = account_data

      const isUpdatingAccount = id
      
      try {
        if (isUpdatingAccount) {
          await dispatch(
            UPDATE_ACCOUNT.request({
              id,
              data: { ...rest },
            }),
          );
        } else {
          await dispatch(
            CREATE_ACCOUNT.request({
              data: { ...account_data },
            }),
          );
        }
        await fetchRegistrationRepresentatives();
        dispatch(reset(form_name));
        setCurrentRepresentativeId('');
        setTabValue(0);
        setLoading(false);
        dispatch(
          success({
            message: `Conta do responsável ${isUpdatingAccount ? 'atualizada' : 'criada'} com sucesso!`,
            autoDismiss: 3,
          }),
        );
      } catch (er) {
        console.error(er)
        dispatch(
          error({
            title: 'Erro',
            message: `Erro ao salvar conta do responsável, favor entrar em contato com suporte ${er?.toString()}`,
            autoDismiss: 10
          }),

        );
      }
    },
    [],
  );


  const saveUserMethod = React.useCallback(
    async (data: object) => {
      try {
        const isUpdating = !isNil(initialValues.id);
        if (isUpdating && initialValues.id) {
          await dispatch(
            UPDATE_USER.request({
              id: initialValues.id,
              data,
            }),
          );
        } else {
          await dispatch(
            CREATE_USER.request({
              data,
            }),
          );
        }
        await fetchRegistrationRepresentatives();
        dispatch(
          success({
            message: `Usuário do responsável ${isUpdating ? 'atualizado' : 'criado'} com sucesso!`,
            autoDismiss: 3,
          }),
        );
        dispatch(reset(form_name));
        setCurrentRepresentativeId('');
        setTabValue(0);
        setLoading(false);
      } catch (e) {
        console.error(e)
        setLoading(false);
        dispatch(
          error({
            title: 'Erro',
            message: `Erro ao salvar usuário do responsável, favor entrar em contato com suporte ${e?.toString()}`,
            autoDismiss: 10
          }),
        );
      }
    },
    [initialValues, currentRepresentativeId],
  );

  const onSubmitResponsibleStep = async ({ data }: { data: ResponsibleStepFormData }) => {
    setLoading(true);
    const {
      name,
      email,
      gender,
      birthdate,
      phone,
      document_number,
      nationality_country,
      nationality_state,
      nationality_city,
      additional_data,
      id,
    } = data;
    const isUpdating = !isNil(id);

    const nationality = nationality_country && nationality_country['label'];
    const nationalityCity = `${
      nationality_city && nationality_city['label'] ? nationality_city['label'] : nationality_city
    }`;

    const nationalityState = `${nationality_state && nationality_state['isoCode'] ? nationality_state['isoCode'] : ''}`;
    const birthplace = [nationalityCity, nationalityState].filter((item) => !isEmpty(item)).join('-') || '';
    const formatted_document_number = removeSpecialSymbols(document_number);
    const document_type = checkDocumentType(document_number);
    let { address_attributes, accounts_attributes } = data;
    let user_attributes = {
      name,
      email,
      gender,
      phone,
      birthdate,
      document_type,
      document_number: formatted_document_number,
      nationality,
      birthplace,
      additional_data,
    };

    if (isUpdating && allowUserUpdate) {
      const initial_user_attributes = {
        name: initialValues.name,
        email: initialValues.email,
        gender: initialValues.gender,
        phone: initialValues.phone,
        birthdate: initialValues.birthdate,
        document_type: initialValues.document_type,
        document_number: initialValues.document_number,
        nationality: initialValues.nationality,
        birthplace: initialValues.birthplace,
        additional_data: initialValues.additional_data,
      };

      const initial_address_attributes = {
        ...initialValues.address_attributes,
      };
      user_attributes = verifyDifferentValues(user_attributes, initial_user_attributes) as typeof user_attributes;
      address_attributes = verifyDifferentValues(address_attributes, initial_address_attributes, [
        'user_id',
        'id',
      ]) as typeof address_attributes;
    }
    accounts_attributes = accountsUpdater(accounts_attributes, initialValues.accounts_attributes);

    const formattedData = {
      ...user_attributes,
      address_attributes,
      accounts_attributes,
    };
    if(allowUserUpdate){
      await saveUserMethod(formattedData);  
    } else {
      await saveAccountMethod(formattedData)
    }
  };

  const insertStudentData = async (
    connection: ConnectionEnum,
    previous_connection: ConnectionEnum | undefined,
    initial_connection: ConnectionEnum | undefined,
  ) => {
    if (
      connection === ConnectionEnum.SELF &&
      ((previous_connection === undefined && initial_connection !== ConnectionEnum.SELF) ||
        previous_connection !== undefined) &&
      currentStudent
    ) {
      setPreLoadingOptions((item) => item.concat('initialValues'));
      dispatch(reset(form_name));
      const account_id = currentStudent.accounts_attributes[0].id
      const accounts_attributes = [
        {
          id: account_id,
          representatives_attributes: [
            {
              account_id,
              connection,
              kind: RepresentativeEnum.PEDAGOGICAL,
              registration_id: ~~currentRegistration.id,
            },
          ],
        },
      ];
      const formattedData = {
        ...currentStudent,
        accounts_attributes,
      };
      await insertResponsibleData(formattedData as ResponsibleData);
      setPreLoadingOptions((cur) => cur.filter((item) => item !== 'initialValues'));
    }
  };
  const initForm = React.useCallback(async () => {
    await fetchResponsibleStepData();
    if (currentRepresentativeId) {
      await fetchRepresentativeData();
    }
  }, []);

  React.useEffect(() => {
    initForm();
  }, []);

  if (preLoadingOptions.length) {
    return <Loading />;
  }

  return (
    <ResponsibleStepForm
      onSubmit={onSubmitResponsibleStep}
      setTabValue={setTabValue}
      setCurrentUser={setCurrentUser}
      currentUser={currentUser}
      insertStudentAddress={insertStudentAddress}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      currentRepresentativeId={currentRepresentativeId}
      setCurrentRepresentativeId={setCurrentRepresentativeId}
      defaultInitialValues={defaultInitialValues(~~currentRegistration.company_id, ~~currentRegistration.id)}
      isFormValid={isFormValid}
      handleSelectUser={handleSelectUser}
      currentRegistration={currentRegistration}
      insertStudentData={insertStudentData}
      allowUserUpdate={allowUserUpdate}

    />
  );
};

export default ResponsibleStepFormContainer;
