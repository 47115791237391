import { createPromiseAction } from "redux-saga-promise-actions"
import { JsonFormat, JsonResponseFormat, SubjectPeriodRegistrationStatusEnum, WithRequestParams } from "../../utils/constants"
import { ExamPlacementJson } from "../exam_placements"
import { FormulaJson } from "../formulas"
import { AxiosResponse } from "axios"
import { SubjectPeriodJson } from "../subject_periods"
import { RegistrationResultJson } from "../registration_results"
import { ExamJson } from "../exams"

export interface SubjectPeriodRegistrationAttrs {
  comments: string
  descriptive_opinion_attachment_id: string
  descriptive_opinion_url: string
  frequency_approved: boolean
  frequency_data: number[]
  frequency_rate: number[]
  ktwelve_curriculum_registration_id: number
  result_input: number
  score_approved: boolean
  score: number
  status: SubjectPeriodRegistrationStatusEnum
  student_name: string
  subject_period_id: number
}

export type SubjectPeriodRegistrationJson = JsonFormat<'subject_period_registrations', SubjectPeriodRegistrationAttrs>
export type FetchSubjectPeriodRegistrationsResponse = JsonResponseFormat<SubjectPeriodRegistrationJson[], (ExamPlacementJson | FormulaJson | SubjectPeriodJson |
  RegistrationResultJson | ExamJson)[]>
export type FetchSubjectPeriodRegistrationResponse = JsonResponseFormat<SubjectPeriodRegistrationJson, (ExamPlacementJson | FormulaJson | SubjectPeriodJson |
  RegistrationResultJson | ExamJson
)[]>
export type FetchSubjectPeriodRegistrationsPayload = WithRequestParams

export interface FetchSubjectPeriodRegistrationPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateSubjectPeriodRegistrationPayload {
  data: object
}

export interface UpdateSubjectPeriodRegistrationPayload extends CreateSubjectPeriodRegistrationPayload {
  id: string
}

export interface DeleteSubjectPeriodRegistrationPayload {
  id: string
}


export const FETCH_SUBJECT_PERIOD_REGISTRATIONS = createPromiseAction('subject_period_registrations: FETCH_SUBJECT_PERIOD_REGISTRATIONS')<FetchSubjectPeriodRegistrationsPayload, FetchSubjectPeriodRegistrationsResponse, any>()
export const FETCH_SUBJECT_PERIOD_REGISTRATION = createPromiseAction('subject_period_registrations: FETCH_SUBJECT_PERIOD_REGISTRATION')<FetchSubjectPeriodRegistrationPayload, FetchSubjectPeriodRegistrationResponse, any>()
export const CREATE_SUBJECT_PERIOD_REGISTRATION = createPromiseAction('subject_period_registrations: CREATE_SUBJECT_PERIOD_REGISTRATION')<CreateSubjectPeriodRegistrationPayload, FetchSubjectPeriodRegistrationResponse, any>()
export const UPDATE_SUBJECT_PERIOD_REGISTRATION = createPromiseAction('subject_period_registrations: UPDATE_SUBJECT_PERIOD_REGISTRATION')<UpdateSubjectPeriodRegistrationPayload, FetchSubjectPeriodRegistrationResponse, any>()
export const DELETE_SUBJECT_PERIOD_REGISTRATION = createPromiseAction('subject_period_registrations: DELETE_SUBJECT_PERIOD_REGISTRATION')<DeleteSubjectPeriodRegistrationPayload, AxiosResponse, any>()
export const CALCULATE_SUBJECT_PERIOD_REGISTRATION_FREQUENCY = createPromiseAction('subject_period_registrations: CALCULATE_SUBJECT_PERIOD_REGISTRATION_FREQUENCY')<FetchSubjectPeriodRegistrationPayload, FetchSubjectPeriodRegistrationResponse, any>()
export const CALCULATE_SUBJECT_PERIOD_REGISTRATION_SCORE = createPromiseAction('subject_period_registrations: CALCULATE_SUBJECT_PERIOD_REGISTRATION_SCORE')<FetchSubjectPeriodRegistrationPayload, FetchSubjectPeriodRegistrationResponse, any>()
