import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import configureStore from '../configureStore'
import { warning, error } from 'react-notification-system-redux'
import { FETCH_ACCOUNT_SESSION_REQUEST } from '../accounts/index'
import { CompanyAttributes, DefaultAction, Paths, TransactionEventEnum } from '../../utils/constants'

import {
  updateCredentialsRequest,
  updateCredentialsSuccess,
  updateCredentialsFailure,
  SIGN_IN,
  SignInCredentials,
  AuthCredentials,
  SIGN_OUT,
  SET_COMPANY,
  SET_COMPANY_RELATIVES,
  SetCompanyRequestPayload,
} from '.'
import { signIn as signInRequest, signOut as signOutRequest } from './services'
import { resolvePromiseAction } from 'redux-saga-promise-actions'
import { fetchCompanies } from '../companies/services'
import { FetchCompaniesResponse } from '../companies'
import { CREATE_TRANSACTION } from '../transactions'

export function * signIn (action: DefaultAction<SignInCredentials, string>): any {
  const { payload } = action
  const { history, ...rest } = payload
  try {
    const response = yield call(signInRequest, rest)
    const {
      headers,
      data: {
        data: { attributes, id }
      }
    } = response
    yield put(updateCredentialsRequest({ ...headers }))
    if (attributes.profiles.length === 0) {
      yield put(
        warning({
          title: 'Acesso negado',
          message: 'Essa conta não tem um perfil autorizado no SOMA',
          autoDismiss: 5
        })
      )
      yield put(SIGN_OUT.request())
      history.push(Paths.HOME)
    } else {
      yield put(FETCH_ACCOUNT_SESSION_REQUEST({ id }))
      yield put(SIGN_IN.success({ ...attributes, id }))
      resolvePromiseAction(action, response)
      yield put(CREATE_TRANSACTION.request({
        data: {
          'description': 'Usuário acessou o SOMA após login',
          'event': TransactionEventEnum.USER_EVENT,
          'event_date': new Date().toISOString(),
          'transactable_type': 'User',
          'transactable_id': id
        }
      }))
    }
  } catch (e:unknown) {
    yield put(SIGN_IN.failure())
    resolvePromiseAction(action, e)
    yield put(
      error({
        title: 'Acesso negado',
        message: 'Conta não encontrada e/ou não autorizada',
        autoDismiss: 5
      })
    )
    history.push(Paths.HOME)
  }
}

export function * signOut (action: DefaultAction<any, string>): any {
  try {
    const state = yield select()
    const user_id = state?.auth?.user?.id
    if(user_id){
      yield put(CREATE_TRANSACTION.request({
        data: {
          'description': 'Tentativa de logout',
          'event': TransactionEventEnum.USER_EVENT,
          'event_date': new Date().toISOString(),
          'transactable_type': 'User',
          'transactable_id': user_id
        }
      }))
    }

    yield call(signOutRequest)
    yield put(SIGN_OUT.success())
    resolvePromiseAction(action, '')
  } catch (error) {
    console.error(error)
    yield put(SIGN_OUT.failure())
    window.location.reload()
    resolvePromiseAction(action, error)
  } finally {
    yield put(SIGN_OUT.success())
    const { persistor } = configureStore()
    persistor.pause()
    persistor.flush().then(() => {
      return persistor.purge()
    })
  }
}

export function * updateCredentials (
  { payload }: DefaultAction<AuthCredentials>
) {
  try {
    const headers = payload
    if (
      Object.prototype.hasOwnProperty.call(headers, 'access-token') &&
            typeof headers['access-token'] === 'string' &&
            headers['access-token'].length
    ) {
      yield put(updateCredentialsSuccess({ headers }))
    }
  } catch (e) {
    yield put(updateCredentialsFailure())
  }
}

export function * setCompany (action: DefaultAction<SetCompanyRequestPayload, string>) {
  const { payload: { company, profile } } = action
  try {
    let company_descendants = [] as CompanyAttributes[]
    let company_ancestors = [] as CompanyAttributes[]
    if(company.kind !== 'school'){
      const descendants_response: FetchCompaniesResponse = yield call(fetchCompanies,{ filters: {'include': 'profile_dashboards', 'q[id_in]': company.descendants_ids, 'page[size]': '50'}})
      const { data: { data: descendants_data } } = descendants_response
      company_descendants = descendants_data.map(item => ({ id: item.id, ...item.attributes }))
    }
    const ancestors_response: FetchCompaniesResponse = yield call(fetchCompanies,{ filters: {'q[id_in]': company.ancestors_ids, 'page[size]': '50'}})
    const { data: { data: ancestors_data }, headers } = ancestors_response
    company_ancestors = ancestors_data.map(item => ({ id: item.id, ...item.attributes }))
    yield put(updateCredentialsRequest(headers))
    const new_payload = {
      company_descendants,
      company_ancestors,
      company_id: company.id,
      profile
    }
    yield put(SET_COMPANY_RELATIVES(new_payload))
    yield put(SET_COMPANY.success(new_payload))
    resolvePromiseAction(action, new_payload)
  } catch (err) {
    yield put(SET_COMPANY.failure(err))
    resolvePromiseAction(action, err)
    yield put(
      error({
        title: 'Erro',
        message: 'Erro ao obter descendentes de unidade',
        autoDismiss: 5
      })
    )
  }
}

// Watchers

export function * watchAuth () {
  yield takeLatest(SIGN_IN.request, signIn)
  yield takeLatest(updateCredentialsRequest, updateCredentials)
  yield takeLatest(SIGN_OUT.request, signOut)
  yield takeLatest(SET_COMPANY.request, setCompany)
}

export default function * authSagas () {
  yield all([
    watchAuth()
  ])
}
