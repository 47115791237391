/* eslint-disable camelcase */
import React from 'react';

import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  ClassroomViewAttributes,
  PaginationType,
  periodOptions,
  PeriodTypes,
  Role,
  defaultPagination,
  CompanyAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';

import { css, useTheme } from '@emotion/react';
import ClassroomsFormContainer from '../../../containers/ClassroomsFormContainer';
import TooltipButton from '../../shared/TooltipButton';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};


const ClassroomsView = ({
  classrooms,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchClassroomsMethod,
  yearFilterValue,
  setYearFilterValue,
  periodFilterValue,
  setPeriodFilterValue,
  ktwelveFilterValue,
  setKtwelveFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
}: {
  classrooms: ClassroomViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  yearFilterValue: string;
  setYearFilterValue: React.Dispatch<React.SetStateAction<string>>;
  ktwelveFilterValue: string;
  setKtwelveFilterValue: React.Dispatch<React.SetStateAction<string>>;
  periodFilterValue: string;
  setPeriodFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchClassroomsMethod: ({
    newPagination,
    period,
    company_filter,
  }: {
    period?: PeriodTypes | string;
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const [create, setCreate] = React.useState(false)
  const [editClass, setEditClass] = React.useState<string[]>([])
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Nome', 'Ano', 'Período', 'Série'];

  const init = async () => {
    await fetchClassroomsMethod({})
  }

  const handleSaveClass = async () => {
    setCreate(false)
    await init()
  }

  const handleOpenEditForm = (id: string) => {
    setEditClass((prev) => {
      if(id && prev.includes(id)) {
        return prev.filter(item => item !== id)
      } else {
        return prev.concat(id)
      }
    })
  }

  const handleCloseEditForm = (id: any) => {
    setEditClass((prevState) => prevState.filter((item) => item !== id))
  }
  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Turmas</span>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchClassroomsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchClassroomsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por ano`}
            search
            small
            onSearch={() => {
              fetchClassroomsMethod({});
            }}
            input={{
              value: yearFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setYearFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Período'
            small
            options={periodOptions}
            input={{
              value: periodFilterValue,
              onChange: (e: any) => {
                setPeriodFilterValue(e.target.value);
                fetchClassroomsMethod({
                  period: e.target.value,
                });
              },
            }}
            clearable
            clearFieldFunction={() => {
              setPeriodFilterValue('');
              fetchClassroomsMethod({
                period: '',
              });
            }}
          />
          <InputComponent
            placeholder={`Buscar por série`}
            search
            small
            onSearch={() => {
              fetchClassroomsMethod({});
            }}
            input={{
              value: ktwelveFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKtwelveFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align={'left'} key={item}>{item}</TableCell>
                ))}
                <TableCell align='right'>
                  <button
                    className='green small' 
                    onClick={() => setCreate(prev => !prev)}
                    disabled={editClass.length > 0}
                  >
                    <span>Criar turma</span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={columns.length + 1} >
                  <Collapse in={create} timeout={'auto'} unmountOnExit>
                    <div>
                      <ClassroomsFormContainer
                        onSave={handleSaveClass}
                        close_form={() => setCreate(false)}
                      />
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
              {classrooms.map((classroom) => {
                const period = periodOptions.find((item) => item.value === classroom.period)?.label;
                return (
                  <React.Fragment key={classroom.id}>
                  <TableRow>
                    {is_above_school_director && <TableCell>{classroom.company}</TableCell>}
                    <TableCell>{classroom.name}</TableCell>
                    <TableCell>{classroom.year}</TableCell>
                    <TableCell>{period}</TableCell>
                    <TableCell>{classroom.ktwelve?.name}</TableCell>
                    <TableCell align='right'>
                      <TooltipButton
                        tooltipProps={{
                          title: classroom.id && editClass.includes(classroom.id)
                            ? 'Cancelar edição'
                            : 'Editar turma'
                        }}
                        Icon={PenIcon}
                        iconButtonProps={{
                          disabled: create || (editClass.length > 0 && !(classroom.id && editClass.includes(classroom.id))),
                          onClick: () => classroom.id && handleOpenEditForm(classroom.id)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  {classroom.id && 
                    editClass.includes(classroom.id) && (
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={columns.length + 2} >
                          <div>
                              <Collapse in={editClass.includes(classroom.id)} timeout='auto' unmountOnExit>
                                    <ClassroomsFormContainer 
                                      close_form={ () => handleCloseEditForm(classroom.id)}
                                      onSave={ async () => {
                                        handleCloseEditForm(classroom.id)
                                        await init()
                                      }}
                                      classroom_id={classroom.id}
                                      company_id={classroom.id}
                                    />
                              </Collapse>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  </React.Fragment>
                );

              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={(props) => <TablePaginationActions {...props} disableButton={ create || editClass.length > 0} />}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ClassroomsView;
