/* eslint-disable camelcase */
import { filter, find, head, isEmpty, isNil, keys, omit, toString } from 'lodash';
import React from 'react';
import { error, success, warning } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import CoordinatorsForm from '../components/form/CoordinatorsForm';
import { RootState } from '../store/configureStore';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import {
  Role,
  UserOptionsData,
  CoordinatorFormData,
  defaultCoordinatorFormInitialValues,
  NestedCoordinatorAttributes,
  NestedAccountAttributesCoordinatorForm,
  CompanyAttributes,
} from '../utils/constants';
import {
  checkDocumentType,
  convertDocument,
  evaluate_permissions,
  removeSpecialSymbols,
  resetFields,
  verifyDifferentValues,
} from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CoordinatorJson, FETCH_COORDINATOR, FETCH_COORDINATORS } from '../store/coordinators';
import { AccountJson, CREATE_ACCOUNT, UPDATE_ACCOUNT } from '../store/accounts';
import { change, formValueSelector, reset, untouch } from 'redux-form';
import { City, Country, State } from 'country-state-city';
import { toDate } from 'date-fns';
import { AddressJson, CREATE_USER, UPDATE_USER, UserJson, VERIFY_USER_HIERARCHY } from '../store/users';

const form_name = 'coordinatorForm';

const coordinatorsUpdater = (
  currentCoordinators: NestedCoordinatorAttributes[],
  initialCoordinators: NestedCoordinatorAttributes[],
) => {
  const sorted: NestedCoordinatorAttributes[] = [];
  currentCoordinators.forEach((coordinator) => {
    const related = find(initialCoordinators, (initial) => initial.id === coordinator.id);
    if (isNil(related)) {
      sorted.push(coordinator);
      return;
    }
    const omit = ['id', '_destroy', 'account_id'];
    const result = verifyDifferentValues(coordinator, related, omit) as NestedCoordinatorAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedCoordinatorAttributes[];
};

const accountsUpdater = (
  currentAccountAttributes: NestedAccountAttributesCoordinatorForm[],
  initialAccountAttributes: NestedAccountAttributesCoordinatorForm[],
) => {
  const sorted: NestedAccountAttributesCoordinatorForm[] = [];
  currentAccountAttributes.forEach((accounts_attributes) => {
    const related = find(initialAccountAttributes, (initial) => initial.id === accounts_attributes.id);
    if (isNil(related)) {
      sorted.push(accounts_attributes);
      return;
    }
    const result = verifyDifferentValues(accounts_attributes, related, [
      'id',
      '_destroy',
      'coordinator_attributes',
      'company_id',
    ]) as NestedAccountAttributesCoordinatorForm;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentCoordinatorAttributes = accounts_attributes.coordinators_attributes;
      const initialcoordinatorAttributes = filter(related?.coordinators_attributes, (rep) => rep.id);
      const coordinators_attributes = coordinatorsUpdater(
        currentCoordinatorAttributes as NestedCoordinatorAttributes[],
        initialcoordinatorAttributes as NestedCoordinatorAttributes[],
      );
      const formattedcoordinator = {
        ...result,
        coordinators_attributes,
      };
      sorted.push(formattedcoordinator);
    }
  });
  return sorted as NestedAccountAttributesCoordinatorForm[];
};

interface ICoordinatorsFormContainerProps {
  close_form?: () => void;
  onSave?: () => void;
  coordinator_id?: number;
  company_id?: number
}

const CoordinatorsFormContainer: React.FC<ICoordinatorsFormContainerProps> = ({
  close_form,
  onSave,
  coordinator_id,
  company_id
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    ui: {
      loading: { open },
    },
    account: { companies }
  } = state;
  const [loaded, setLoaded] = React.useState(false);
  const [allowUserUpdate, setAllowUserUpdate] = React.useState(true)
  const formValues = formValueSelector(form_name);
  const companyIdValue = formValues(state, 'company_id') as string;
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use: string = company_id || (is_above_school_director
    ? companyIdValue
    : company);
  const [initialValues, setInitialValues] = React.useState<Partial<CoordinatorFormData>>({
    ...defaultCoordinatorFormInitialValues(~~company_id_to_use),
  });
  const isUpdating = !isNil(initialValues?.id);
  const insertCoordinatorData = (user: UserOptionsData, can_update_user = true) => {
    const initial_country_options = Country.getAllCountries().map(
      ({ latitude, longitude, timezones, name, isoCode, ...rest }) => ({
        ...rest,
        label: name,
        value: isoCode,
      }),
    );
    try {
      setLoading(true)
      const { birthdate, document_number, id, birthplace, nationality, ...rest } = user;
      const date = birthdate ? toDate(~~birthdate) : '';
      const nationality_state = birthplace?.substring(birthplace.lastIndexOf('-') + 1);
      const nationality_city = birthplace?.substring(0, birthplace.lastIndexOf('-'));
      const countryInfo = initial_country_options.find((item) => item.label === nationality);
      const stateInfo = State.getStatesOfCountry(countryInfo?.value as string)
        .map(({ name, isoCode, countryCode }) => ({
          label: name,
          value: name,
          isoCode,
          countryCode,
        }))
        .find((item) => item.isoCode === nationality_state);
      const cityInfo =
        City.getCitiesOfState(stateInfo?.countryCode as string, stateInfo?.isoCode as string)
          .map(({ name }) => ({
            label: name,
            value: name,
          }))
          .find((item) => item.value === nationality_city) || nationality_city;
      const accounts_attributes = user.accounts_attributes || [
        {
          company_id: company_id_to_use,
          id: user?.account?.id,
          coordinators_attributes: [],
        },
      ];
      const newInitialValues = {
        document_number: convertDocument(document_number),
        id,
        nationality_state: stateInfo,
        nationality_city: cityInfo,
        nationality_country: countryInfo,
        company_id: company_id_to_use || user.account?.company_id,
        ...rest,
        accounts_attributes,
        ...(date && { birthdate: date.toISOString() }),
      } as Partial<CoordinatorFormData>;
      setInitialValues(newInitialValues);
      setAllowUserUpdate(can_update_user)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      dispatch(reset(form_name));
      dispatch(
        error({
          message: 'Erro no carregamento de dados do usuário, favor tentar novamente',
        }),
      );
    }
  };

  const checkExistingCoordinatorsForUser = React.useCallback(
    async (id: string, document: string) => {
      try {
        let allowed = false;
        const param = id ? 'q[account_user_id_eq]' : 'q[account_user_document_number_eq]';
        const coordinators = await dispatch(
          FETCH_COORDINATORS.request({
            params: {
              filters: {
                'q[account_company_id_eq]': company_id_to_use,
                [param]: id || document,
              },
            },
          }),
        );
        if (coordinators) {
          const {
            data: { data },
          } = coordinators;
          allowed = data.length === 0;
          if (!allowed) {
            resetFields(form_name, ['document_number'], dispatch, change, untouch);
            dispatch(
              warning({
                message: 'Já existe coordenador cadastrado para usuário com esse documento',
              }),
            );
          }
        }
        return allowed;
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao checar coordenadores para esse documento',
          }),
        );
        return false;
      }
    },
    [company_id_to_use],
  );
  const verifyCurrentAndSelectedUser = React.useCallback(
    async (id: string) => {
      try {
        const result = await dispatch(
          VERIFY_USER_HIERARCHY.request({
            id
          }),
        );
        const { data } = result
        return data
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao checar hierarquida do usuário selecionado',
          }),
        );
        return false;
      }
    },
    [company_id_to_use],
  );


  const handleSelectUser = React.useCallback(async (user: UserOptionsData) => {
    if (user) {
      const can_insert_user = await checkExistingCoordinatorsForUser(user.id, '');
      const can_update_user = await verifyCurrentAndSelectedUser(user.id)
      if (can_insert_user) {
        insertCoordinatorData(user, can_update_user);
      }
    }
  }, []);

  const loadCoordinator = React.useCallback(async () => {
    if (coordinator_id) {
      const coordinator = await dispatch(
        FETCH_COORDINATOR.request({
          id: coordinator_id,
          params: {
            filters: {
              include: 'account.user.ethnicities,account.user.races,account.user.address,account.coordinators',
            },
          },
        }),
      );

      const {
        data: { data, included },
      } = coordinator;
      const account = included.find(
        (item) => item.type === 'accounts' && data.attributes.account_id === ~~item.id,
      ) as AccountJson;
      const user = included.find(
        (item) => item.type === 'users' && account.attributes.user_id === ~~item.id,
      ) as UserJson;
      const can_update_user = await verifyCurrentAndSelectedUser(user.id)
      const userAddress = included.find(
        (item) => item.type === 'addresses' && ~~user.id === item.attributes.user_id,
      ) as AddressJson;
      const coordinators = included.filter(
        (item) => item.type === 'coordinators'
      ) as CoordinatorJson[]
      const address_attributes = {
        ...(userAddress && {
          id: userAddress.id,
          ...userAddress.attributes,
          city_id: toString(userAddress.attributes.city_id),
          state_id: toString(userAddress.attributes.state_id),
          country_id: toString(userAddress.attributes.country_id),
        }),
      };

      const accounts_attributes = [
        {
          id: account.id,
          coordinators_attributes: coordinators.map(item => ({id: item.id, ...item.attributes})).concat({id: data.id, ...data.attributes}),
        },
      ];
      const newInitialValues = {
        id: user.id,
        ...user.attributes,
        address_attributes,
        accounts_attributes,
      };
      insertCoordinatorData(newInitialValues, can_update_user);

    } else {
      setInitialValues({ ...defaultCoordinatorFormInitialValues(~~company_id_to_use) })
    }
  }, [coordinator_id]);

  const initCoordinatorsForm = React.useCallback(async () => {
    await loadCoordinator();
    setLoaded(true);
  }, []);

  const saveAccountMethod = React.useCallback(
    async (data: Partial<CoordinatorFormData>) => {
      const account_data = head(data.accounts_attributes) as NestedAccountAttributesCoordinatorForm
      const {id, ...rest} = account_data

      const isUpdatingAccount = id

      try {
        if (isUpdatingAccount) {
          await dispatch(
            UPDATE_ACCOUNT.request({
              id,
              data: { ...rest },
            }),
          );
        } else {
          await dispatch(
            CREATE_ACCOUNT.request({
              data: { ...account_data },
            }),
          );
        }
        dispatch(
          success({
            message: 'Conta do coordenador salva com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar conta do coordenador.',
          }),
        );
      }
    },
    [initialValues, company],
  );

  const saveUserMethod = React.useCallback(
    async (data: Partial<CoordinatorFormData>) => {
      const formatted_data = omit(data, ['company_id']);
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(formatted_data, initialValues, ['id']) as CoordinatorFormData;
          await dispatch(
            UPDATE_USER.request({
              id: initialValues?.id as string,
              data: { ...rest },
            }),
          );
        } else {
          await dispatch(
            CREATE_USER.request({
              data: { ...formatted_data },
            }),
          );
        }
        dispatch(
          success({
            message: 'Dados do coordenador salvo com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar dados do coordenador.',
          }),
        );
      }
    },
    [initialValues, company, isUpdating],
  );


  const onSubmit = async (data:Partial<CoordinatorFormData>) => {
    try {
      setLoading(true);
      const {
        name,
        email,
        gender,
        birthdate,
        phone,
        document_number,
        nationality_country,
        nationality_state,
        nationality_city,
        additional_data,
        id,
      } = data;
      const isUpdating = !isNil(id);

      const nationality = nationality_country && (nationality_country['label'] as string);
      const nationalityCity = `${
        nationality_city && nationality_city['label'] ? nationality_city['label'] : nationality_city
      }`;

      const nationalityState = `${nationality_state && nationality_state['isoCode'] ? nationality_state['isoCode'] : ''}`;
      const birthplace = [nationalityCity, nationalityState].filter((item) => !isEmpty(item)).join('-') || '';
      const formatted_document_number = removeSpecialSymbols(document_number as string);
      const document_type = checkDocumentType(document_number as string);
      let { address_attributes, accounts_attributes = [] } = data;
      let user_attributes = {
        name,
        email,
        gender,
        phone,
        birthdate,
        document_type,
        document_number: formatted_document_number,
        nationality,
        birthplace,
        additional_data,
      };

      if (isUpdating && allowUserUpdate) {
        const initial_user_attributes = {
          name: initialValues.name,
          email: initialValues.email,
          gender: initialValues.gender,
          phone: initialValues.phone,
          birthdate: initialValues.birthdate,
          document_number: initialValues.document_number,
          nationality: initialValues.nationality as string,
          birthplace: initialValues.birthplace as string,
          additional_data: initialValues.additional_data,
        };

        const initial_address_attributes = {
          ...initialValues.address_attributes,
        };
        user_attributes = verifyDifferentValues(user_attributes, initial_user_attributes) as typeof user_attributes;
        address_attributes = verifyDifferentValues(address_attributes, initial_address_attributes, [
          'user_id',
          'id',
        ]) as typeof address_attributes;
      }
      accounts_attributes = accountsUpdater(accounts_attributes, initialValues.accounts_attributes || []);
      const formattedData = {
        ...user_attributes,
        address_attributes,
        accounts_attributes,
      };
      if(allowUserUpdate){
        await saveUserMethod(formattedData);
      } else {
        await saveAccountMethod(formattedData)
      }
      await onSave?.()
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao preparar dados para salvar coordenador.',
        }),
      );
    }
  };

  React.useEffect(() => {
    initCoordinatorsForm();
  }, []);

  if (!loaded || open) {
    return <Loading />;
  }

  return (
    <CoordinatorsForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleSelectUser={handleSelectUser}
      setInitialValues={setInitialValues}
      allowUserUpdate={allowUserUpdate}
      close_form={close_form}
    />
  );
};

export default CoordinatorsFormContainer;
