/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import CoursesForm from '../components/form/CoursesForm';
import { CREATE_COURSE, FETCH_COURSE, UPDATE_COURSE } from '../store/courses';
import { CoursesAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';

interface ICoursesFormContainerProps {
  close_form?: () => void;
  onSave?: () => void;
  course_id?: number;
}

const CoursesFormContainer: React.FC<ICoursesFormContainerProps> = ({
  close_form,
  onSave,
  course_id
}) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<CoursesAttributes | null>(null);
  const [loading, setLoading] = React.useState(true);
  const isUpdating = !isNil(initialValues?.id);

  const loadCourse = React.useCallback(async () => {
    if (course_id) {
      const course = await dispatch(
        FETCH_COURSE.request({
          id: course_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = course;
      const formattedCourse = {
        id,
        ...attributes,
      };
      setInitialValues(formattedCourse);
    }
  }, [course_id]);

  const initCoursesForm = React.useCallback(async () => {
    setLoading(true);
    await loadCourse();
    setLoading(false);
  }, []);

  const onSubmit = React.useCallback(
    async (data: CoursesAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, ['id', 'company_id']) as CoursesAttributes;
          await dispatch(
            UPDATE_COURSE.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_COURSE.request({
              data: {
                ...data,
              },
            }),
          );
        }
        await onSave?.()
        dispatch(
          success({
            message: 'Curso salvo com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar curso.',
          }),
        );
      }
    },
    [initialValues, isUpdating],
  );

  React.useEffect(() => {
    initCoursesForm();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <CoursesForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      close_form={close_form}
    />
  )
};

export default CoursesFormContainer;
