/* eslint-disable camelcase */
import { compose } from 'redux';
import { change, Field, FieldArray, FormSection, getFormValues, reduxForm, WrappedFieldArrayProps } from 'redux-form';
import {
  ClassTimeKindEnum,
  classTimeKindOptions,
  colors,
  NestedClassTimeAttributes,
  RoomAttributes,
  RoomScheduleFormAttributes,
  roomScheduleWeekDayOptions,
  validation,
} from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import DatePickerComponent from '../input/form/datepicker';
import { css } from '@emotion/react';
import AddButton from '../shared/AddButton';
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { ClassTimeFormBody } from './ClassTimeForm';
import DeleteButton from '../shared/DeleteButton';
import { compact, find, findIndex, get, orderBy } from 'lodash';
import { format, isValid } from 'date-fns';

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 0;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfefe;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const form_name = 'roomScheduleForm';
const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue};
`;
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`;

interface CustomClassTimeFormProps extends WrappedFieldArrayProps {
  current_room_schedule: RoomScheduleFormAttributes;
  form: string;
  room: RoomAttributes;
}

const renderClassTimes = (props: CustomClassTimeFormProps) => {
  const dispatch = useDispatch();
  const { fields, current_room_schedule, room, ...rest } = props;
  const allClassTimes = fields.getAll() as NestedClassTimeAttributes[];
  const addClassTime = React.useCallback(() => {
    fields.push({
      room_id: current_room_schedule.room_id,
      company_id: room.company_id,
      active: false,
      ...(current_room_schedule.id ? { room_schedule_id: current_room_schedule.id } : {}),
    });
  }, [fields]);
  const deleteClassTime = React.useCallback(
    (currentClassTime: NestedClassTimeAttributes, class_time: string, index: number) => {
      currentClassTime.id ? dispatch(change(form_name, `${class_time}._destroy`, true)) : fields.remove(index);
    },
    [fields],
  );
  const orderedClassTimes = orderBy(allClassTimes, ct => ct.starts_at && new Date(ct.starts_at as Date|string), 'asc')
  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
          & div[id$='subject_id'] {
            width: 100%;
          }
        `}
      >
        <span>Adicionar Horário</span>
        <AddButton onClick={addClassTime} tooltip='Adicionar horário' />
      </div>
      <div style={{ display: 'grid' }}>
        {fields.map((class_time, index) => {
          const currentClassTime = allClassTimes[index] as NestedClassTimeAttributes;
          const getOrder = findIndex(orderedClassTimes, ct => ct.starts_at === currentClassTime.starts_at && ct.ends_at === currentClassTime.ends_at)
          if (currentClassTime._destroy) {
            return null;
          }
          return (
            <Accordion style={{ order: getOrder }} TransitionProps={{ unmountOnExit: true }} key={class_time} css={AccordionCss}>
              <AccordionSummary css={AccordionSummaryCss}>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <ExpandMore />
                  <span>
                    {`${compact([
                      currentClassTime.starts_at,
                      currentClassTime.ends_at,
                      find(
                        classTimeKindOptions,
                        (opt) => opt.value === (get(currentClassTime, 'kind') as ClassTimeKindEnum),
                      )?.label,
                    ])
                      .map((item) => {
                        return isValid(new Date(item)) ? format(new Date(item), 'H:mm') : item;
                      })
                      .join('-')}` || 'Novo horário'}
                  </span>
                </div>
                <DeleteButton
                  onClick={() => deleteClassTime(currentClassTime, class_time, index)}
                  tooltip='Remover Horário'
                />
              </AccordionSummary>
              <AccordionDetails>
                <div key={class_time}>
                  <FormSection name={class_time}>
                    <ClassTimeFormBody
                      form_name={rest.form}
                      class_time_form_values={currentClassTime}
                      class_time={class_time}
                      room={room}
                    />
                  </FormSection>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </>
  );
};

const RoomScheduleForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  room: RoomAttributes;
}) => {
  const { handleSubmit, onSubmit, close_form, room, ...rest } = props;
  const state = useSelector((state: RootState) => state);
  const room_schedule_form_values = getFormValues(form_name)(state) as RoomScheduleFormAttributes;

  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar Agendamento de sala</span>
      <form css={useStyles.form}>
        <>
          <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
            <Field
              name='weekday'
              component={SelectComponent}
              options={roomScheduleWeekDayOptions}
              label={'Dia da semana'}
              placeholder={'Selecione o dia da semana em que o horário será aplicado'}
              validate={[validation.required]}
            />
            <Field
              name={`schedule_date`}
              label={'Dia do agendamento'}
              placeholder={`01/01/${new Date().getFullYear()}`}
              component={DatePickerComponent}
              datePickerProps={{
                minDate: new Date(),
                showDropdownIcon: true,
              }}
            />
            <Field
              name={`starts_at`}
              label={'Dia inicial de aplicação do intervalo'}
              placeholder={`01/01/${new Date().getFullYear()}`}
              component={DatePickerComponent}
              datePickerProps={{
                ...(room_schedule_form_values.ends_at ? { maxDate: room_schedule_form_values.ends_at } : {}),
                showDropdownIcon: true,
              }}
            />
            <Field
              name={`ends_at`}
              label={'Dia final de aplicação do intervalo'}
              placeholder={`01/01/${new Date().getFullYear()}`}
              component={DatePickerComponent}
              datePickerProps={{
                ...(room_schedule_form_values.starts_at ? { minDate: room_schedule_form_values.starts_at } : {}),
                showDropdownIcon: true,
              }}
            />
          </div>
          <FieldArray
            name='class_times_attributes'
            component={renderClassTimes}
            room={room}
            current_room_schedule={room_schedule_form_values}
            {...rest}
          />
          <div css={useStyles.buttons}>
            <button
              onClick={(e) => {
                e.preventDefault();
                close_form();
              }}
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar agendamento </span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(RoomScheduleForm);
