/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { AxiosResponse } from 'axios'
import { JsonFormat, JsonResponseFormat, WithRequestParams, ChargeGenerationConfigType, PaymentServiceEnum, InvoiceChargeStatusEnum, InvoiceStatusEnum, Company } from '../../utils/constants'
import { ProfileDashboardJson } from '../profile_dashboards'

export interface CompanyConfigParams {
  asaas_api_key: string
  charge_generation_config: ChargeGenerationConfigType
  days_before_expiration_charge: number|string
  days_before_expiration_notification: number|string
  days_before_punctuality_notification: number|string
  days_interval_after_expiration_notification: number|string
  days_to_block_invoice_adjustment_after_generation: number|string
  email_sender: string
  enable_whatsapp_invoice_notification: boolean
  payment_service: PaymentServiceEnum
}

export interface CompanyAttrs {
  additional_data: {
    primary_color: string
    secondary_color: string
    city: string
    state: string
    number: string
    street: string
    zipcode: string
    complement: string
    neighbourhood: string
  },
  ancestry: string
  child_ids: number[],
  config_params: CompanyConfigParams
  dashboard_attachment_id: number
  dashboard_url: string
  email_header_banner_attachment_id: number
  email_header_banner_url: string
  descendants_ids: number[]
  ancestors_ids: number[]
  description: string
  document: string
  kind: Company,
  logo_attachment_id: number
  logo_url: string
  name: string
  parent_id: number | string
  registrations_list_url: string
}


export type CompanyJson = JsonFormat<'companies', CompanyAttrs, ProfileDashboardJson[]>

export type FetchCompaniesResponse = JsonResponseFormat<CompanyJson[], ProfileDashboardJson[]>
export type FetchCompanyResponse = JsonResponseFormat<CompanyJson, ProfileDashboardJson[]>

export type FetchCompaniesChildrenResponse = FetchCompanyResponse[]


export interface CreateCompanyPayload {
  data: FormData | object
}

export interface UpdateCompanyPayload extends CreateCompanyPayload {
  id: string
}

export type FetchCompaniesPayload = WithRequestParams
export interface FetchCompaniesChildrenPayload {
    ids: number[];
}

export interface FetchCompanyPayload extends WithRequestParams  {
  id: string;
}

export interface DeleteCompanyPayload {
  id: string;
}

export interface ImportCompanyDataPayload {
  id: string;
  data: {
    file_url: string
  }
}

export interface ExportCompanyRegistrationsPayload {
  data: {
    company_ids: (string|number)[]
  }
}
export type DownloadRegistrationPayload = WithRequestParams

export interface ExportCompanyRepresentativesPayload {
  data: {
    company_ids: (string|number)[]
  }
}

export interface ExportInvoicesPayload  {
  data: {
    company_ids: (string|number)[]
    charge_status?: InvoiceChargeStatusEnum[];
    status?: InvoiceStatusEnum[]
    to_expiration_date?: string;
    from_expiration_date?: string;
    representative?: string;
    registration?: string | undefined;
  }
}

export const CREATE_COMPANY = createPromiseAction('companies: CREATE_COMPANY')<CreateCompanyPayload, FetchCompanyResponse, any>()
export const DELETE_COMPANY_LOGO = createPromiseAction('companies: DELETE_COMPANY_LOGO')<DeleteCompanyPayload, AxiosResponse, any>()
export const EXPORT_COMPANY_REGISTRATIONS = createPromiseAction('companies: EXPORT_COMPANY_REGISTRATIONS')<ExportCompanyRegistrationsPayload, AxiosResponse, any>()
export const EXPORT_COMPANY_REPRESENTATIVES = createPromiseAction('companies: EXPORT_COMPANY_REPRESENTATIVES')<ExportCompanyRepresentativesPayload, AxiosResponse, any>()
export const FETCH_COMPANIES = createPromiseAction('companies: FETCH_COMPANIES')<FetchCompaniesPayload, FetchCompaniesResponse, any>()
export const FETCH_COMPANY = createPromiseAction('companies: FETCH_COMPANY')<FetchCompanyPayload, FetchCompanyResponse, any>()
export const FETCH_COMPANY_CHILDREN = createPromiseAction('companies: FETCH_COMPANY_CHILDREN')<FetchCompaniesChildrenPayload, FetchCompaniesChildrenResponse, any>()
export const IMPORT_COMPANY_DATA = createPromiseAction('companies: IMPORT_COMPANY_DATE')<ImportCompanyDataPayload, AxiosResponse, any>()
export const UPDATE_COMPANY = createPromiseAction('companies: UPDATE_COMPANY')<UpdateCompanyPayload, FetchCompanyResponse, any>()
export const DELETE_COMPANY_EMAIL_HEADER_BANNER = createPromiseAction('companies: DELETE_COMPANY_EMAIL_HEADER_BANNER')<DeleteCompanyPayload, AxiosResponse, any>()
export const EXPORT_INVOICES = createPromiseAction('companies: EXPORT_INVOICES')<ExportInvoicesPayload, AxiosResponse, any>()
export const DOWNLOAD_REGISTRATIONS = createPromiseAction('companies: DOWNLOAD_REGISTRATIONS')<DownloadRegistrationPayload, AxiosResponse, any>()
