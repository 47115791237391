/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ProductAttributes, RegistrationProductSubsidyAttributes, SubsidiesAttributes, colors } from '../../utils/constants';
import {  calculateDiscount, formatToCurrency, getPortionMonth, parseText } from '../../utils/functions';
import { Tooltip } from '@mui/material';
import { css } from '@emotion/react';

const useStyles = {
  table: css`
    & tbody > tr:nth-child(even) {
      background-color: ${colors.darkGrayBlue} !important;
    }

    & tbody > tr:nth-child(odd) {
      background-color: ${colors.grayBlue} !important;
    }

    & .include {
      display: flex;
      margin-left: auto;
      margin-bottom: 1rem;
    }

    & tfoot {
      background: white;

      & td {
        font-weight: bold;
        font-size: 1rem;
      }

      & td:last-of-type {
        color: ${colors.blue};
      }
    }

    & .MuiTableContainer-root {
      background: inherit;
      box-shadow: none;

      & .MuiTableCell-root {
        border-bottom: 5px solid ${colors.lightBlue};
      }

      & th {
        color: ${colors.darkGrayBlue};
      }

      & td {
        color: ${colors.darkBlue};

        & svg {
          color: ${colors.darkGrayBlue};
          cursor: pointer;
        }
      }

      & td.MuiTableCell-footer {
        border-bottom: none;
      }
    }
  `,
};

const RegistrationProductSubsidiesTable = ({
  registration_product_subsidies,
  subsidies,
  product
}: {
  registration_product_subsidies: RegistrationProductSubsidyAttributes[];
  subsidies: SubsidiesAttributes[]
  product: ProductAttributes
}) => {
  return (
    <Table css={useStyles.table} size='small' aria-label='registration-product-subsidies'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Nome do desconto</TableCell>
          <TableCell align='center'>Tipo do desconto</TableCell>
          <TableCell align='center'>Parcelas</TableCell>
          <TableCell align='center'>Desconto por parcela</TableCell>
          <TableCell align='center'>Valor total do desconto</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {registration_product_subsidies.map((rps) => {
        const subsidy = subsidies.find(subsidy => ~~subsidy.id === rps.subsidy_id) as SubsidiesAttributes
        const subsidyKindLabel = subsidy.kind === 'full_value' ? 'Valor cheio' : 'Porcentagem'
        const calculated_discount= rps.portions.reduce((acc, portion) => {
          const value = portion === 0 ? product.registration_fee : product.price
          return acc + calculateDiscount(subsidy.kind, rps.discount, value)
        }, 0)
        const translatedPortions = rps.portions.map(item => {
          if(item === 0){
            return 'Entrada'
          }
          return getPortionMonth(item)
        }).join(',')
        const discountValue = subsidy.kind === 'full_value' ? formatToCurrency(rps.discount) : `${rps.discount}%`
        return (
          <React.Fragment key={rps.id}>
            <TableRow>
              <TableCell align='center'>{subsidy.name}</TableCell>
              <TableCell align='center'>{subsidyKindLabel}</TableCell>
              <TableCell align='center'>
                <Tooltip title={translatedPortions}>
                  <span>{parseText(translatedPortions, 30)}</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>{discountValue}</TableCell>
              <TableCell align='center'>
                {formatToCurrency(calculated_discount)}
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
    </Table>
  );
};

export default RegistrationProductSubsidiesTable;
