import React from 'react';
import { Collapse, Theme } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

import TablePaginationActions from '../../shared/TablePaginationActions';
import {
  colors,
  defaultPagination,
  PaginationType,
  periodOptions,
  PeriodTypes,
  productTypeOptions,
  ProductTypes,
  ProductsViewAttributes,
  Role,
  CompanyAttributes,
} from '../../../utils/constants';
import SelectComponent from '../../input/form/select';
import DatePickerComponent from '../../input/form/datepicker';
import InputComponent from '../../input/form/input';
import PenIcon from '../../icon/PenIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { css, useTheme } from '@emotion/react';
import TooltipButton from '../../shared/TooltipButton';
import ProductsFormContainer from '../../../containers/ProductsFormContainer';

export const useStyles = () => {
  const theme = useTheme() as Theme;
  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

interface IProductsViewProps {
  products: ProductsViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  fetchProductsMethod: ({
    newPagination,
    year,
    period,
  }: {
    newPagination?: PaginationType | undefined;
    year?: Date | null;
    period?: PeriodTypes | string;
    type?: string;
    company_filter?: string;
  }) => Promise<void>;
  productTypeFilter: ProductTypes | string;
  setProductTypeFilter: React.Dispatch<React.SetStateAction<ProductTypes | string>>;
  yearFilterValue: Date | null;
  setYearFilterValue: React.Dispatch<React.SetStateAction<Date | null>>;
  ktwelveFilterValue: string;
  setKtwelveFilterValue: React.Dispatch<React.SetStateAction<string>>;
  classroomFilterValue: string;
  setClassroomFilterValue: React.Dispatch<React.SetStateAction<string>>;
  periodFilterValue: PeriodTypes | string;
  setPeriodFilterValue: React.Dispatch<React.SetStateAction<PeriodTypes | string>>;
  courseFilterValue: string;
  setCourseFilterValue: React.Dispatch<React.SetStateAction<string>>;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}

const ProductsView: React.FC<IProductsViewProps> = ({
  products,
  pagination,
  handleChangePageSize,
  handlePageChange,
  fetchProductsMethod,
  productTypeFilter,
  setProductTypeFilter,
  yearFilterValue,
  setYearFilterValue,
  ktwelveFilterValue,
  setKtwelveFilterValue,
  classroomFilterValue,
  setClassroomFilterValue,
  periodFilterValue,
  setPeriodFilterValue,
  courseFilterValue,
  setCourseFilterValue,
  nameFilterValue,
  setNameFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
}) => {
  const [create, setCreate] = React.useState(false)
  const [editProduct, setEditProduct] = React.useState<string[]>([])
  const [copyProduct, setCopyProduct] = React.useState<string[]>([])
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Nome', 'Descrição', 'Ano', 'Curso', 'Série', 'Turma'];

  const init = async () => {
    await fetchProductsMethod({})
  }

  const handleOpenCreateForm = () => {
    setCreate(prevState => !prevState)
  }

  const handleOpenEditForm = (id: string) => {
    setEditProduct(prevState => {
      if (id && prevState.includes(id)) {
        return prevState.filter(item => item !== id)
      } else {
        return prevState.concat(id)
      }
    })
  }

  const handleCloseEditForm = (id: string) => {
    setEditProduct(prevState => prevState.filter(item => item !== id))
  }

  const handleOpenCopyForm = (id: string) => {
    setCopyProduct(prevState => {
      if (id && prevState.includes(id)) {
        return prevState.filter(item => item !== id)
      } else {
        return prevState.concat(id)
      }
    })
  }

  const handleCloseCopyForm = (id: string) => {
    setCopyProduct(prevState => prevState.filter(item => item !== id))
  }

  const handleSaveProduct = async () => {
    setCreate(false)
    await init()
  }

  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Produtos</span>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchProductsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Tipo'
            small
            options={productTypeOptions}
            input={{
              value: productTypeFilter,
              onChange: (e: any) => {
                setProductTypeFilter(e.target.value);
                fetchProductsMethod({
                  type: e.target.value,
                });
              },
            }}
            clearable
            clearFieldFunction={() => {
              setProductTypeFilter('');
              fetchProductsMethod({
                type: '',
              });
            }}
          />
          <DatePickerComponent
            placeholder='Ano letivo'
            small
            datePickerProps={{
              views: ['year'],
              inputFormat: 'yyyy',
              allowSameDateSelection: true,
            }}
            input={{
              value: yearFilterValue,
              name: 'year',
              onChange: (e: Date) => {
                setYearFilterValue(e);
                fetchProductsMethod({
                  year: e,
                });
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por curso`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: courseFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setCourseFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por série`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: ktwelveFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKtwelveFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por turma`}
            search
            small
            onSearch={() => {
              fetchProductsMethod({});
            }}
            input={{
              value: classroomFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setClassroomFilterValue(e.target.value);
              },
            }}
          />
          <SelectComponent
            placeholder='Turno'
            small
            options={periodOptions}
            clearable
            clearFieldFunction={() => {
              setPeriodFilterValue('');
              fetchProductsMethod({
                period: '',
              });
            }}
            input={{
              value: periodFilterValue,
              onChange: (e: any) => {
                setPeriodFilterValue(e.target.value);
                fetchProductsMethod({
                  period: e.target.value,
                });
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table data-testid='products-table'>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
                <TableCell align='right'>
                  <button
                    className='green small'
                    onClick={handleOpenCreateForm}
                    style={{ whiteSpace: 'nowrap' }}
                    disabled={editProduct.length > 0 || copyProduct.length > 0}
                  >
                    <span>Criar Produto</span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <div>
                      <ProductsFormContainer
                        close_form={() => setCreate(false)}
                        onSave={handleSaveProduct}
                      />
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
              {products.map((product) => {
                return (
                  <React.Fragment key={product.id}>
                    <TableRow id={product.id}>
                      {is_above_school_director && <TableCell>{product.company}</TableCell>}
                      <TableCell> {product.name} </TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell>{product.year}</TableCell>
                      <TableCell>{product.course}</TableCell>
                      <TableCell>{product.ktwelve}</TableCell>
                      <TableCell>{product.classroom}</TableCell>
                      <TableCell align='center'>
                        <TooltipButton
                          tooltipProps={{
                            title: editProduct.includes(product.id)
                              ? 'Cancelar edição'
                              : 'Editar produto'
                          }}
                          Icon={PenIcon}
                          iconProps={{
                            style: { color: colors.blue }
                          }}
                          iconButtonProps={{
                            onClick: () => handleOpenEditForm(product.id),
                            disabled: (
                              create || copyProduct.length > 0 || (editProduct.length > 0 && !editProduct.includes(product.id))
                            )
                          }}
                        />

                        <TooltipButton
                          tooltipProps={{
                            title: copyProduct.includes(product.id)
                              ? 'Cancelar'
                              : 'Copiar produto'
                          }}
                          Icon={AddToPhotosIcon}
                          iconButtonProps={{
                            disabled: (
                              create ||
                              (editProduct.length > 0) ||
                              (copyProduct.length > 0 && !copyProduct.includes(product.id))
                            ),
                            onClick: () => handleOpenCopyForm(product.id)
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
                        <Collapse
                          in={editProduct.includes(product.id)}
                          timeout='auto'
                          unmountOnExit
                        >
                          <div>
                            <ProductsFormContainer
                              close_form={() => handleCloseEditForm(product.id)}
                              onSave={async () => {
                                handleCloseEditForm(product.id)
                                await init()
                              }}
                              product_id={product.id}
                              company_id={product.company_id}
                            />
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
                        <Collapse
                          in={copyProduct.includes(product.id)}
                          timeout='auto'
                          unmountOnExit
                        >
                          <div>
                            <ProductsFormContainer
                              close_form={() => handleCloseCopyForm(product.id)}
                              onSave={async () => {
                                handleCloseCopyForm(product.id)
                                await init()
                              }}
                              product_id={product.id}
                              company_id={product.company_id}
                              copy
                            />
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={(props) => (
                    <TablePaginationActions
                      {...props}
                      disableButton={create || editProduct.length > 0}
                    />
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ProductsView;
