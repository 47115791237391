import React from 'react';
import { css } from '@emotion/react';
import { Field, change, formValueSelector } from 'redux-form';
import MaskedInputComponent from '../input/form/masked';
import { RootState } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '../icon/SearchIcon';
import { DefaultOptionType, validation } from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { checkUndefined, fetchCepInfo } from '../../utils/functions';
import { concat, filter, find, includes, map } from 'lodash';
import { FETCH_CITIES, FetchCitiesResponse } from '../../store/cities';
import { FETCH_STATE, FETCH_STATES, FetchStatesResponse, StateJson } from '../../store/states';
import { error } from 'react-notification-system-redux';
import { FETCH_COUNTRIES, FETCH_COUNTRY } from '../../store/countries';
import TooltipButton from '../shared/TooltipButton';

interface AddressFormProps {
  form_name: string;
  insertStudentAddress?: () => {
    country_id: string;
    state_id: string;
    city_id: string;
  };
  disable_all?: boolean
}

const AddressForm = (props: AddressFormProps) => {
  const { form_name, insertStudentAddress, disable_all } = props;
  const AddressFormCss = css`
    display: flex;
    row-gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      flex-basis: 100%;
    }
  `;
  const [loadingZipcode, setLoadingZipcode] = React.useState(false);
  const [loadingDynamicOptions, setDynamicOptions] = React.useState<string[]>([]);
  const [address_country_options, setAddressCountryOptions] = React.useState<DefaultOptionType[]>([]);
  const [address_state_options, setAddressStateOptions] = React.useState<DefaultOptionType[]>([]);
  const [address_city_options, setAddressCityOptions] = React.useState<DefaultOptionType[]>([]);

  const dispatch = useDispatch();
  const formValues = formValueSelector(form_name);
  const state = useSelector((state: RootState) => state);

  const cepFieldValue = formValues(state, 'address_attributes.zipcode') as string | undefined;
  const countryValue = formValues(state, 'address_attributes.country_id') as string | undefined;
  const stateValue = formValues(state, 'address_attributes.state_id') as string | undefined;

  const fetchCitiesMethod = React.useCallback(
    async (cityName: string) => {
      setDynamicOptions((items) => concat(items, 'cities'));
      try {
        const cities = await dispatch(
          FETCH_CITIES.request({
            params: {
              filters: {
                'q[name_cont]': cityName,
              },
            },
          }),
        );
        return cities;
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar cidades',
          }),
        );
      } finally {
        setDynamicOptions((items) => filter(items, (x) => x !== 'cities'));
      }
    },
    [setDynamicOptions],
  );

  const fetchStatesMethod = React.useCallback(
    async (uf: string) => {
      try {
        setDynamicOptions((items) => concat(items, 'states'));
        const states = await dispatch(
          FETCH_STATES.request({
            params: {
              filters: {
                'q[abbreviation_cont]': uf,
              },
            },
          }),
        );
        return states;
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar estados',
          }),
        );
      } finally {
        setDynamicOptions((items) => filter(items, (x) => x !== 'states'));
      }
    },
    [setDynamicOptions],
  );

  const fetchCountryMethod = React.useCallback(
    async (id: string) => {
      try {
        setDynamicOptions((items) => concat(items, 'state'));
        const country = await dispatch(
          FETCH_COUNTRY.request({
            id: id,
            params: {
              filters: {
                include: 'states',
              },
            },
          }),
        );
        const {
          data: { included },
        } = country;
        const formattedData = map(included, ({ attributes, id }) => {
          return {
            label: attributes.abbreviation,
            value: id,
          };
        });
        setAddressStateOptions(formattedData);
      } catch (e) {
        dispatch(
          error({
            message: 'Erro no carregamento dos estados do país',
          }),
        );
      } finally {
        setDynamicOptions((items) => filter(items, (x) => x !== 'state'));
      }
    },
    [setDynamicOptions, setAddressStateOptions],
  );

  const fetchStateCitiesMethod = React.useCallback(
    async (id: string) => {
      setDynamicOptions((items) => concat(items, 'state_cities'));
      try {
        const state = await dispatch(
          FETCH_STATE.request({
            params: {
              filters: {
                include: 'cities',
              },
            },
            id,
          }),
        );
        const {
          data: { included },
        } = state;
        const formattedData = map(included, ({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id,
          };
        });
        setAddressCityOptions(formattedData);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar cidades do estado selecionado',
          }),
        );
      } finally {
        setDynamicOptions((items) => filter(items, (x) => x !== 'state_cities'));
      }
    },
    [setDynamicOptions, setAddressCityOptions],
  );

  const handleSelectCountryId = (_: React.ChangeEvent<any> | undefined, value: string) => {
    if (value && ((typeof value === 'string' && checkUndefined(value)) || typeof value === 'number')) {
      fetchCountryMethod(value);
    }
  };

  const handleSelectStateId = (_: React.ChangeEvent<any> | undefined, value: any) => {
    if (value && ((typeof value === 'string' && checkUndefined(value)) || typeof countryValue === 'number')) {
      fetchStateCitiesMethod(value);
    }
  };

  const loadZipCodeDetails = React.useCallback(
    async ({ uf, localidade }: { uf: string; localidade: string }) => {
      const countryInfo = find(address_country_options, (item) => item.label === 'Brasil') as DefaultOptionType;
      dispatch(change(form_name, 'address_attributes.country_id', countryInfo.value as string));
      handleSelectCountryId(undefined, countryInfo.value as string);
      const states = (await fetchStatesMethod(uf)) as FetchStatesResponse;
      const {
        data: { data },
      } = states;
      const findState = find(
        data,
        (item) => item.attributes.country_id === ~~countryInfo.value && includes(item.attributes.abbreviation, uf),
      ) as StateJson;
      if (findState) {
        dispatch(change(form_name, 'address_attributes.state_id', findState.id));
        handleSelectStateId(undefined, findState.id);
      }
      const cities = (await fetchCitiesMethod(localidade)) as FetchCitiesResponse;
      const {
        data: { data: cityData },
      } = cities;
      const findCity = find(
        cityData,
        (item) => item.attributes.state_id === ~~findState.id && item.attributes.name === localidade,
      );

      findCity && dispatch(change(form_name, 'address_attributes.city_id', findCity.id));
    },
    [address_country_options, address_state_options, address_city_options],
  );

  const handleCepSearch = React.useCallback(async () => {
    const formattedcepFieldValue = cepFieldValue?.replace(/[^0-9]/g, '');
    if (formattedcepFieldValue !== undefined) {
      try {
        setLoadingZipcode(true);
        const {
          data: { bairro, localidade, logradouro, uf },
        } = await fetchCepInfo(formattedcepFieldValue);
        bairro && dispatch(change(form_name, 'address_attributes.neighbourhood', bairro));
        logradouro && dispatch(change(form_name, 'address_attributes.street', logradouro));
        await loadZipCodeDetails({ uf, localidade });
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar informações de CEP',
          }),
        );
      } finally {
        setLoadingZipcode(false);
      }
    }
  }, [cepFieldValue, address_country_options]);

  const fetchCountriesMethod = React.useCallback(async () => {
    try {
      setDynamicOptions((items) => concat(items, 'countries'));
      const countries = await dispatch(FETCH_COUNTRIES.request({}));
      const {
        data: { data },
      } = countries;
      const formattedData = map(data, ({ attributes, id }) => {
        return {
          label: attributes.name,
          value: id,
        };
      });
      setAddressCountryOptions(formattedData);
    } catch (e) {
      dispatch(
        error({
          message: 'Erro no carregamendo das opções de países',
        }),
      );
    } finally {
      setDynamicOptions((items) => filter(items, (x) => x !== 'countries'));
    }
  }, [setDynamicOptions, setAddressCountryOptions]);

  const handleAddressItems = ({ country_id = countryValue, state_id = stateValue }) => {
    if (country_id) {
      fetchCountryMethod(country_id);
    }
    if (state_id) {
      fetchStateCitiesMethod(state_id);
    }
  };

  const initAddressForm = async () => {
    handleAddressItems({});
    await fetchCountriesMethod();
  };

  React.useEffect(() => {
    initAddressForm();
  }, []);

  return (
    <div css={AddressFormCss} id='input-address_form'>
      <span>Endereço</span>
      <div className='zipcode'>
        {insertStudentAddress && (
          <button
            id='insert-student-button'
            className='blue'
            style={{ marginBottom: '14px' }}
            onClick={(e) => {
              e.preventDefault();
              const { country_id, state_id } = insertStudentAddress();
              handleAddressItems({ country_id, state_id });
            }}
          >
            <span> Utilizar o mesmo do aluno </span>
          </button>
        )}
        <Field
          name='address_attributes.zipcode'
          label={'CEP'}
          placeholder={'58032-102'}
          component={MaskedInputComponent}
          disabled={disable_all}
          validate={[validation.required]}
          maskProps={{
            format: '#####-###',
            mask: '_',
          }}
        />
        <TooltipButton
          tooltipProps={{
            title: 'Pesquisar por CEP'
          }}
          Icon={SearchIcon}
          iconButtonProps={{
            className: 'search',
            disabled: loadingZipcode || disable_all,
            onClick: () => {
              if (cepFieldValue && cepFieldValue.replace(/[^0-9]/g, '').length === 8) {
                setLoadingZipcode(true);
                handleCepSearch();
              }
            }
          }}
        />
      </div>
      <Field
        name='address_attributes.street'
        label={'Rua'}
        placeholder={'Av. Gen. Edson Ramalho'}
        component={InputComponent}
        disabled={disable_all}
        validate={[validation.required]}
      />
      <Field
        name='address_attributes.number'
        label={'Número'}
        placeholder={'99'}
        component={InputComponent}
        disabled={disable_all}
        validate={[validation.required]}
      />
      <Field
        name='address_attributes.complement'
        label={'Complemento'}
        placeholder={'Apartamento 201'}
        component={InputComponent}
        disabled={disable_all}
      />
      <Field
        name='address_attributes.neighbourhood'
        label={'Bairro'}
        placeholder={'Jardim Oceania'}
        component={InputComponent}
        disabled={disable_all}
        validate={[validation.required]}
      />
      <Field
        name='address_attributes.country_id'
        label='País'
        placeholder={'Brasil'}
        loading={loadingDynamicOptions.includes('countries')}
        onChange={handleSelectCountryId}
        component={SelectComponent}
        options={address_country_options}
        disabled={disable_all}
        validate={[validation.required]}
      />
      <Field
        name='address_attributes.state_id'
        label='Estado'
        placeholder={'Paraiba'}
        onChange={handleSelectStateId}
        component={SelectComponent}
        options={address_state_options}
        disabled={disable_all}
        validate={[validation.required]}
      />
      <Field
        name='address_attributes.city_id'
        label={'Cidade'}
        placeholder={'João Pessoa'}
        component={SelectComponent}
        options={address_city_options}
        disabled={disable_all}
        validate={[validation.required]}
      />
    </div>
  );
};

export default AddressForm;
