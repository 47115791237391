/* eslint-disable camelcase */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { success, error } from 'react-notification-system-redux';
import {
  LocationState,
  CurrentRegistrationData,
  Paths,
  RegistrationFormData,
  Role,
  ElementView,
  CompanyAttributes} from '../utils/constants';
import {
  FetchRegistrationResponse,
  FETCH_REGISTRATION,
  UPDATE_REGISTRATION,
} from '../store/registrations';

import { RootState } from '../store/configureStore';


import { evaluate_permissions } from '../utils/functions';
import { formValueSelector } from 'redux-form';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import StudentStepContainer from './StudentStepFormContainer';
import ResponsibleStep from '../components/view/Secretary/Registration/ResponsibleStep';
import ProductStep from '../components/view/Secretary/Registration/ProductStep';
import DocumentStep from '../components/view/Secretary/Registration/DocumentStep';
import InvoiceStep from '../components/view/Secretary/Registration/InvoiceStep';
import SetupView from '../components/shared/SetupView';
import Loading from '../components/loading/Loading';

const RegistrationSetupContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
      auth: { company: companyId, profile},
      account: { companies }
  } = state
  const formValues = formValueSelector('registration-studentstep');
  const companyIdValue = formValues(state, 'company_id') as string;
  const location = useLocation<LocationState>();
  const [currentRegistration, setCurrentRegistration] = React.useState<CurrentRegistrationData>();
  const [renderReady, setRenderReady] = React.useState(false);
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === companyId,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use = currentRegistration?.id
    ? currentRegistration.company_id
    : is_above_school_director
    ? companyIdValue
    : companyId;
  const setLoading = (value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value))
  }
  

  const updateCurrentRegistration = async (res: FetchRegistrationResponse) => {
      const {
          data: {
              data: { attributes, id },
          },
      } = res;
      const formattedData = {
          ...attributes,
          id,
      } as CurrentRegistrationData;
      setCurrentRegistration(formattedData);
      dispatch(
        success({
            message: 'Matrícula carregada com sucesso!',
            autoDismiss: 1,
        }),
      );
  };

  const fetchAndUpdateRegistrationMethod = async (id: string = currentRegistration?.id as string, data?: RegistrationFormData | FormData) => {
    setLoading(true)
    try {
      if(data){
        await dispatch(
          UPDATE_REGISTRATION.request({
            id,
            data
          })
        )
      }
      const registration = await dispatch(
        FETCH_REGISTRATION.request({
            id,
        }),
      );
      await updateCurrentRegistration(registration)
      setLoading(false)
    } catch (er) {
      console.error('er',er)
      dispatch(
        error({
            message: 'Erro ao carregar e/ou atualizar matrícula',
            autoDismiss: 3,
        }),
      )
    }
  }


  const init = async () => {
    setRenderReady(false)
    if (location?.state?.registration_id) {
      await fetchAndUpdateRegistrationMethod(location.state.registration_id.toString())
    }
      
    setRenderReady(true)
  }


  React.useEffect(() => {
      init()
  }, []);


  React.useEffect(() => {
      if (currentRegistration !== undefined && currentRegistration.company_id !== company_id_to_use) {
        history.push(Paths.SECRETARY);
      }
  }, [company_id_to_use]);

  const elements_array = [
    {
      step: 0,
      element: StudentStepContainer,
      label: 'Aluno',
      props: {
        currentRegistration,
        setLoading,
        setCurrentRegistration,
        fetchAndUpdateRegistrationMethod,
        updateCurrentRegistration,
      },
    },
    {
      step: 1,
      element: ResponsibleStep,
      label: 'Responsáveis',
      props: {
        currentRegistration,
        setLoading,
      }
    },
    {
      step: 2,
      element: ProductStep,
      label: 'Produtos',
      props: {
        updateCurrentRegistration,
        currentRegistration,
        fetchAndUpdateRegistrationMethod,
        setLoading,
      }
    },
    {
      step: 3,
      element: DocumentStep,
      label: 'Documentos',
      props: {
        currentRegistration,
        fetchAndUpdateRegistrationMethod,
        setLoading
      }
    },
    {
      step: 4,
      element: InvoiceStep,
      label: 'Faturas',
      props: {
        currentRegistration
      }
    }
  ] as ElementView[]

  if(!renderReady){
    return <Loading />
  }

  return (
    <SetupView
      current_resource={currentRegistration as CurrentRegistrationData}
      element_array={elements_array}
      title={currentRegistration ? currentRegistration.user_data.name : 'Novo aluno'}
      tooltip_title='Atualizar matrícula'
    />
  )
}

export default RegistrationSetupContainer
