/* eslint-disable camelcase */
import { filter, find, get, isEmpty, isNil, keys, map } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import RoomSchedulesForm from '../components/form/RoomScheduleForm';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import {
  RoomScheduleAttributes,
  RoomAttributes,
  NestedClassTimeSubjectAttributes,
  NestedTeacherClassTimeAttributes,
  NestedClassTimeAttributes,
  RoomScheduleFormAttributes,
  NestedRoomClassTimeAttributes,
  RoomScheduleWeekDaysEnum,
  RoomScheduleStatusEnum,
  FollowUpStatusEnum,
  FollowUpData,
} from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_ROOM_SCHEDULE, FETCH_ROOM_SCHEDULE, UPDATE_ROOM_SCHEDULE } from '../store/room_schedules';
import { ClassTimeJson } from '../store/class_times';
import { ClassTimeSubjectJson } from '../store/class_time_subjects';
import { TeacherClassTimeJson } from '../store/teacher_class_times';
import { parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useActionCable from '../utils/useActionCable';
import useChannel from '../utils/useChannel';
import { currentHost } from '../utils/http';
import { RootState } from '../store/configureStore';
import { DefaultModalInterface } from '../components/modal/Modal';

import FollowUpComponent from '../components/shared/FollowUpComponent';


interface RoomScheduleFollowUpData extends FollowUpData {
  room_schedule_id?: string;

}

const teacherClassTimeUpdater = (
  currentTeacherClassTimes: NestedTeacherClassTimeAttributes[],
  initialTeacherClassTimes: NestedTeacherClassTimeAttributes[],
) => {
  const sorted: NestedTeacherClassTimeAttributes[] = [];
  currentTeacherClassTimes.forEach((teacher_class_time) => {
    const related = find(initialTeacherClassTimes, (initial) => initial.id === teacher_class_time.id);
    if (isNil(related)) {
      sorted.push(teacher_class_time);
      return;
    }
    const omit = ['id', '_destroy', 'class_time_subject_id'];
    const result = verifyDifferentValues(teacher_class_time, related, omit) as NestedTeacherClassTimeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedTeacherClassTimeAttributes[];
};

const roomClassTimeUpdater = (
  currentTeacherClassTimes: NestedRoomClassTimeAttributes[],
  initialTeacherClassTimes: NestedRoomClassTimeAttributes[],
) => {
  const sorted: NestedRoomClassTimeAttributes[] = [];
  currentTeacherClassTimes.forEach((teacher_class_time) => {
    const related = find(initialTeacherClassTimes, (initial) => initial.id === teacher_class_time.id);
    if (isNil(related)) {
      sorted.push(teacher_class_time);
      return;
    }
    const omit = ['id', '_destroy', 'class_time_id', 'room_id'];
    const result = verifyDifferentValues(teacher_class_time, related, omit) as NestedRoomClassTimeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedRoomClassTimeAttributes[];
};

const classTimeSubjectUpdater = (
  currentClassTimeSubject: NestedClassTimeSubjectAttributes[],
  initialClassTimeSubject: NestedClassTimeSubjectAttributes[],
) => {
  const sorted: NestedClassTimeSubjectAttributes[] = [];
  currentClassTimeSubject.forEach((class_time_subject) => {
    const related = find(initialClassTimeSubject, (initial) => initial.id === class_time_subject.id);
    if (isNil(related)) {
      sorted.push(class_time_subject);
      return;
    }
    const result = verifyDifferentValues(class_time_subject, related, [
      'id',
      '_destroy',
      'teacher_class_times_attributes',
      'class_time_id',
    ]) as NestedClassTimeSubjectAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentTeacherClassTime = class_time_subject.teacher_class_times_attributes;
      const initialTeacherClassTime = related?.teacher_class_times_attributes;
      const teacher_class_times_attributes = teacherClassTimeUpdater(
        currentTeacherClassTime as NestedTeacherClassTimeAttributes[],
        initialTeacherClassTime as NestedTeacherClassTimeAttributes[],
      );
      const formattedPaymentOption = {
        ...result,
        teacher_class_times_attributes,
      };
      sorted.push(formattedPaymentOption);
    }
  });
  return sorted as NestedClassTimeSubjectAttributes[];
};

const classTimesUpdater = (
  currentClassTimeSubject: NestedClassTimeAttributes[],
  initialClassTimeSubject: NestedClassTimeAttributes[],
) => {
  const sorted: NestedClassTimeAttributes[] = [];
  currentClassTimeSubject.forEach((class_time) => {
    const related = find(initialClassTimeSubject, (initial) => initial.id === class_time.id);
    if (isNil(related)) {
      sorted.push(class_time);
      return;
    }
    const result = verifyDifferentValues(class_time, related, [
      'id',
      '_destroy',
      'class_time_subjects_attributes',
      'company_id',
      'room_schedule_id',
    ]) as NestedClassTimeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentClassTimeSubject = class_time.class_time_subjects_attributes;
      const initialClassTimeSubject = related?.class_time_subjects_attributes;
      const class_time_subjects_attributes = classTimeSubjectUpdater(
        currentClassTimeSubject as NestedClassTimeSubjectAttributes[],
        initialClassTimeSubject as NestedClassTimeSubjectAttributes[],
      );
      const currentRoomClassTime = class_time.room_class_times_attributes;
      const initialRoomClassTime = related?.room_class_times_attributes;
      const room_class_times_attributes = roomClassTimeUpdater(currentRoomClassTime, initialRoomClassTime);
      const formattedPaymentOption = {
        ...result,
        class_time_subjects_attributes,
        room_class_times_attributes,
      };
      sorted.push(formattedPaymentOption);
    }
  });
  return sorted as NestedClassTimeAttributes[];
};

const RoomScheduleRunningFollowUpComponent = (props: { room_schedule_id: string; onSave: () => void; close_form: () => void }) => {
  const { room_schedule_id, close_form, onSave } = props;
  const state = useSelector((state: RootState) => state);
  const {
    auth: { credentials },
  } = state;
  const access_token = get(credentials, 'access-token');
  const { actionCable } = useActionCable(`${currentHost}/cable?access_token=${access_token}`);
  const { subscribe, unsubscribe, send, connected } = useChannel<RoomScheduleFollowUpData>(actionCable);
  const [currentData, setCurrentData] = React.useState<RoomScheduleFollowUpData[]>([]);
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (room_schedule_id) {
      subscribe(
        { channel: 'RoomScheduleChannel', id: ~~room_schedule_id, access_token },
        {
          received: (data) => {
            setCurrentData((current) => {
              const already_existent_class_time_data = find(
                current,
                (item) => item.id === data.id,
              );
              if (already_existent_class_time_data) {
                return current.map((item) => {
                  if (item.id === data.id) {
                    return {
                      ...item,
                      ...data,
                    };
                  }
                  return item;
                });
              } else {
                return current.concat(data);
              }
            });
            if (data.status === FollowUpStatusEnum.SUCCESSFUL && data.id === 'main') {
              dispatch(
                success({
                  message: 'Lançamento de horário concluído com sucesso.',
                }),
              );
              close_form?.();
              onSave();
            } else if (data.status === 'error') {
              setCurrentData(current => current.map(item => ({...item, status: FollowUpStatusEnum.ERROR})))
              dispatch(
                error({
                  message: 'Erro no lançamento de horário.',
                }),
              );
            }
          },
        },
      );
    }
    return () => {
      unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    if (connected) {
      send('run_room_schedule', { message: 'Execute Room Schedule' });
    }
  }, [connected]);
  return (
    <FollowUpComponent
      data={currentData}
    />
  );
};

const RoomSchedulesFormContainer = (props: {
  room_schedule_id?: number;
  room: RoomAttributes;
  onSave: () => void;
  close_form: () => void;
  is_copy?: boolean;
  week_day?: RoomScheduleWeekDaysEnum;
}) => {
  const { onSave, room_schedule_id, close_form, room, is_copy, week_day } = props;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<Partial<RoomScheduleFormAttributes> | null>(null);
  const [roomScheduleFollowUpId, setRoomScheduleFollowUpId] = React.useState<string>('');

  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const isUpdating = !isNil(initialValues?.id);

  const loadRoomSchedules = React.useCallback(async () => {
    if (room_schedule_id) {
      const subject = await dispatch(
        FETCH_ROOM_SCHEDULE.request({
          id: room_schedule_id,
          params: {
            filters: {
              include: ['class_times.class_time_subjects.teacher_class_times'].join(','),
            },
          },
        }),
      );
      const {
        data: {
          data: { id, attributes },
          included,
        },
      } = subject;
      const class_times_attributes = map(
        filter(included, (item) => item.type === 'class_times') as ClassTimeJson[],
        (item) => {
          const class_time_subjects_attributes = map(
            filter(
              included,
              (cts) => cts.type === 'class_time_subjects' && cts.attributes.class_time_id === ~~item.id,
            ) as ClassTimeSubjectJson[],
            (cts) => {
              const teacher_class_times_attributes = map(
                filter(
                  included,
                  (incl) => incl.type === 'teacher_class_times' && incl.attributes.class_time_subject_id === ~~cts.id,
                ) as TeacherClassTimeJson[],
                (tct) => ({
                  ...(is_copy ? {} : { id: tct.id }),
                  ...tct.attributes,
                }),
              );
              return {
                ...(is_copy ? {} : { id: cts.id }),
                ...cts.attributes,
                teacher_class_times_attributes,
              };
            },
          );

          return {
            ...item.attributes,
            ...(is_copy ? {} : { id: item.id }),
            class_time_subjects_attributes,
            room_class_times_attributes: [],
          };
        },
      );

      const formattedRoomSchedule = {
        ...attributes,
        ...(is_copy
          ? { status: 'pending', schedule_date: new Date(), starts_at: new Date() }
          : { id, starts_at: parse(attributes.starts_at, 'yyyy-MM-dd', new Date(), { locale: ptBR }).toISOString(), schedule_date: parse(attributes.schedule_date, 'yyyy-MM-dd', new Date(), { locale: ptBR }).toISOString() }),
        ends_at: parse(attributes.ends_at, 'yyyy-MM-dd', new Date(), { locale: ptBR }).toISOString(),
        class_times_attributes,
      };
      setInitialValues(formattedRoomSchedule as Partial<RoomScheduleFormAttributes>);
    } else {
      setInitialValues({
        room_id: ~~room.id,
        class_times_attributes: [
          {
            company_id: room.company_id,
            room_class_times_attributes: [],
            class_time_subjects_attributes: [],
            active: false,
          },
        ],
        ...(week_day ? { weekday: week_day } : {}),
      });
    }
  }, [room_schedule_id]);

  const initRoomSchedulesForm = React.useCallback(async () => {
    setLoading(true);
    await loadRoomSchedules();
    setLoading(false);
    setLoaded(true);
  }, [room_schedule_id]);

  const onSubmit = React.useCallback(
    async (data: RoomScheduleFormAttributes) => {
      setLoading(true);
      const { class_times_attributes, ...dataRest } = data;
      try {
        let room_schedule_id = initialValues?.id;
        let room_schedule_status = initialValues?.status;
        if (isUpdating && !is_copy) {
          const { class_times_attributes: initial_class_times_attributes, ...initialRest } =
            initialValues as RoomScheduleFormAttributes;
          const { id, ...rest } = verifyDifferentValues(dataRest, initialRest, [
            'id',
            'room_id',
          ]) as RoomScheduleAttributes;
          const updated_class_times_attributes = classTimesUpdater(
            class_times_attributes,
            initial_class_times_attributes,
          );
          const attrs = {
            ...rest,
            ...(rest.schedule_date && { schedule_date: new Date(rest.schedule_date).toString() }),
            ...(rest.starts_at && { starts_at: new Date(rest.starts_at).toString() }),
            ...(rest.ends_at && { ends_at: new Date(rest.ends_at).toString() }),
            class_times_attributes: updated_class_times_attributes,
          };
          const response = await dispatch(
            UPDATE_ROOM_SCHEDULE.request({
              id: initialValues?.id as string,
              data: attrs,
            }),
          );
          const {
            data: {
              data: {
                attributes: { status },
              },
            },
          } = response;
          room_schedule_status = status;
        } else {
          const response = await dispatch(
            CREATE_ROOM_SCHEDULE.request({
              data: {
                ...data,
                schedule_date: new Date(data.schedule_date).toString(),
                starts_at: new Date(data.starts_at).toString(),
                ends_at: new Date(data.ends_at).toString(),
                room_id: ~~room.id,
              },
            }),
          );
          const {
            data: {
              data: {
                id,
                attributes: { status },
              },
            },
          } = response;
          room_schedule_id = id;
          room_schedule_status = status;
        }
        dispatch(
          success({
            message: 'Lançamento de horário salvo com sucesso.',
          }),
        );
        setLoading(false);
        if (room_schedule_id && room_schedule_status === RoomScheduleStatusEnum.PENDING) {
          setRoomScheduleFollowUpId(room_schedule_id);
        } else {
          close_form?.();
          onSave();
        }
      } catch (er) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao salvar lançamento de horário.',
          }),
        );
      }
    },
    [initialValues, room, isUpdating],
  );

  React.useEffect(() => {
    initRoomSchedulesForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <>
      <DefaultModalInterface
        handleClose={() => {
          setRoomScheduleFollowUpId('')
          close_form?.()
          onSave()
        }}
        message='Acompanhamento de lançamento de horário'
        singleButton
        open={!isEmpty(roomScheduleFollowUpId)}
      >
        <RoomScheduleRunningFollowUpComponent room_schedule_id={roomScheduleFollowUpId} onSave={onSave} close_form={close_form} />
      </DefaultModalInterface>
      <RoomSchedulesForm close_form={close_form} initialValues={initialValues} room={room} onSubmit={onSubmit} />;
    </>
  );
};

export default RoomSchedulesFormContainer;
