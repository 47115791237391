/* eslint-disable camelcase */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

import { colors, DashboardViewData, ProfileDashboardsDashboardView } from '../../utils/constants';
import { isEmpty } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { BorderAll } from '@mui/icons-material';
import { css } from '@emotion/react';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PipelineInvestIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 133 110'>
      <path
        d='M38.1536 37.6263L10.6367 41.8059C11.4863 46.7057 13.6491 51.272 16.8874 55.0021C20.1256 58.7323 24.3135 61.4815 28.9899 62.9471C33.6663 64.4127 38.6498 64.5379 43.3917 63.3088C48.1336 62.0797 52.45 59.544 55.8655 55.981C59.2809 52.418 61.6631 47.9657 62.7496 43.1146C63.836 38.2635 63.5846 33.2016 62.0231 28.4864C60.4617 23.7713 57.6506 19.5856 53.8995 16.3904C50.1485 13.1953 45.6029 11.1145 40.7633 10.3772L38.1536 37.6263Z'
        fill='#C5D4E3'
      />
      <path
        d='M33.2207 29.1545L7.64844 14.065L7.97069 13.5119C10.38 9.39668 13.7992 5.98794 17.8933 3.61942C21.9873 1.25091 26.6161 0.00371616 31.3266 0C32.6755 0.00658052 34.0222 0.109322 35.3569 0.307371L35.9412 0.399532L33.2207 29.1545ZM9.38145 13.6144L32.1627 27.0545L34.6111 1.44444C33.5212 1.30956 32.4246 1.24107 31.3266 1.23949C26.9456 1.23898 22.6354 2.36595 18.7983 4.5154C14.9611 6.66485 11.7216 9.76682 9.38145 13.5323V13.6144Z'
        fill='#31314F'
      />
      <path
        d='M0.402248 37.0629L27.9194 32.873V32.7808L3.7374 18.5212C0.482306 24.0981 -0.700875 30.6757 0.402248 37.0629Z'
        fill='#C5D4E3'
      />
      <path
        d='M77.4211 107.716C97.2037 107.716 113.241 91.411 113.241 71.2981C113.241 51.1853 97.2037 34.8806 77.4211 34.8806C57.6385 34.8806 41.6016 51.1853 41.6016 71.2981C41.6016 91.411 57.6385 107.716 77.4211 107.716Z'
        fill='#60DFC8'
      />
      <path
        d='M94.622 88.2111C91.2116 91.6773 86.8669 94.0374 82.1372 94.9933C77.4075 95.9491 72.5053 95.4577 68.0503 93.5811C63.5953 91.7045 59.7877 88.5271 57.1088 84.4505C54.4299 80.3739 53 75.5812 53 70.6785C53 65.7757 54.4299 60.9831 57.1088 56.9066C59.7877 52.83 63.5953 49.6524 68.0503 47.7758C72.5053 45.8992 77.4075 45.4078 82.1372 46.3637C86.8669 47.3195 91.2116 49.6797 94.622 53.1458C99.1942 57.7965 101.763 64.1029 101.763 70.6785C101.763 77.254 99.1942 83.5604 94.622 88.2111Z'
        fill='#F6F8F9'
      />
      <path
        d='M85.4354 79.074C83.7783 80.759 81.6671 81.9065 79.3685 82.3714C77.07 82.8364 74.6873 82.5979 72.5221 81.6862C70.3568 80.7744 68.5061 79.2303 67.204 77.2492C65.9019 75.268 65.207 72.9388 65.207 70.556C65.207 68.1733 65.9019 65.8441 67.204 63.8629C68.5061 61.8818 70.3568 60.3377 72.5221 59.4259C74.6873 58.5141 77.07 58.2757 79.3685 58.7406C81.6671 59.2056 83.7783 60.3532 85.4354 62.0382C86.5367 63.1561 87.4104 64.4839 88.0065 65.9455C88.6026 67.4071 88.9096 68.9738 88.9096 70.556C88.9096 72.1383 88.6026 73.7051 88.0065 75.1667C87.4104 76.6283 86.5367 77.956 85.4354 79.074Z'
        fill='#60DFC8'
      />
      <path
        d='M80.0027 73.3465C79.4838 73.8729 78.8232 74.2311 78.1041 74.3759C77.3851 74.5207 76.6401 74.4457 75.963 74.1601C75.286 73.8745 74.7072 73.3913 74.3001 72.7716C73.893 72.1518 73.6758 71.4232 73.6758 70.678C73.6758 69.9327 73.893 69.2043 74.3001 68.5845C74.7072 67.9648 75.286 67.4816 75.963 67.196C76.6401 66.9104 77.3851 66.8352 78.1041 66.98C78.8232 67.1248 79.4838 67.483 80.0027 68.0094C80.3479 68.3596 80.6217 68.7755 80.8086 69.2334C80.9954 69.6913 81.0917 70.1822 81.0917 70.678C81.0917 71.1737 80.9954 71.6646 80.8086 72.1225C80.6217 72.5805 80.3479 72.9964 80.0027 73.3465Z'
        fill='#F6F8F9'
      />
      <path
        d='M127.287 70.2228L132.335 65.2851L129 61.208L125.665 65.2851L122.33 61.208L118.995 65.2851L115.66 61.208L106.34 70.3764L127.287 70.2228Z'
        fill='#C5D4E3'
      />
      <path
        d='M127.952 70.7452L133 75.6931L129.664 79.7702L126.329 75.6931L122.994 79.7702L119.659 75.6931L116.324 79.7702L107.004 70.6018L127.952 70.7452Z'
        fill='#C5D4E3'
      />
      <path d='M127.337 69.6244L77.4219 69.9255L77.4293 71.1958L127.344 70.8946L127.337 69.6244Z' fill='#31314F' />
      <path
        d='M118.995 66.2783L115.176 61.607L116.143 60.7978L118.995 64.2807L122.33 60.2036L125.665 64.2807L129 60.2036L132.819 64.8749L131.851 65.6842L129 62.2012L125.665 66.2783L122.33 62.2012L118.995 66.2783Z'
        fill='#31314F'
      />
      <path d='M115.211 60.716L105.891 69.8826L106.759 70.7955L116.079 61.6289L115.211 60.716Z' fill='#31314F' />
      <path d='M127.718 70.6833L126.852 69.7716L131.9 64.8135L132.766 65.7354L127.718 70.6833Z' fill='#31314F' />
      <path
        d='M129 80.5388L125.665 76.4617L122.33 80.5388L118.995 76.4617L116.143 79.9549L115.176 79.1354L118.995 74.4744L122.33 78.5515L125.665 74.4744L129 78.5515L131.851 75.0583L132.819 75.8778L129 80.5388Z'
        fill='#31314F'
      />
      <path d='M106.791 69.9729L105.922 70.8857L115.241 80.0541L116.109 79.1413L106.791 69.9729Z' fill='#31314F' />
      <path d='M131.9 75.9284L126.852 70.9806L127.718 70.0586L132.766 75.0065L131.9 75.9284Z' fill='#31314F' />
      <path
        d='M77.3732 107.603C70.5027 107.599 63.774 105.615 57.9681 101.88C52.1622 98.1454 47.5173 92.813 44.5724 86.502C41.6275 80.1911 40.5034 73.1605 41.3307 66.2262C42.158 59.2919 44.9025 52.7383 49.246 47.3261C53.5894 41.914 59.3536 37.8652 65.8694 35.6498C72.3851 33.4344 79.3854 33.1432 86.0574 34.81C92.7294 36.4768 98.7996 40.0332 103.563 45.0665C108.327 50.0998 111.589 56.4035 112.971 63.2459L111.742 63.502C109.986 54.8076 105.104 47.0914 98.0502 41.8617C90.9965 36.6321 82.278 34.265 73.5989 35.223C64.9198 36.181 56.904 40.3953 51.1185 47.0421C45.3329 53.689 42.1936 62.2905 42.3139 71.1655C42.4342 80.0405 45.8056 88.5508 51.7691 95.033C57.7326 101.515 65.8595 105.503 74.5612 106.218C83.263 106.932 91.9141 104.322 98.8234 98.8964C105.733 93.4711 110.404 85.6211 111.923 76.8807L113.162 77.1061C111.673 85.6549 107.269 93.3972 100.725 98.9739C94.18 104.551 85.9125 107.606 77.3732 107.603Z'
        fill='#31314F'
      />
      <path
        d='M77.3727 96.1097C71.5846 96.1085 65.976 94.0669 61.5025 90.3328C57.029 86.5987 53.9673 81.4031 52.8392 75.6313C51.7111 69.8595 52.5864 63.8686 55.3158 58.6792C58.0452 53.4899 62.4599 49.4234 67.8078 47.1723C73.1556 44.9213 79.1056 44.6251 84.6442 46.3343C90.1827 48.0434 94.9669 51.6522 98.1818 56.5457C101.397 61.4391 102.843 67.3144 102.275 73.1707C101.707 79.0269 99.159 84.5018 95.0656 88.6623C92.7486 91.0332 89.9914 92.9125 86.954 94.191C83.9167 95.4694 80.66 96.1216 77.3727 96.1097ZM77.3727 46.5183C71.8736 46.518 66.5444 48.4563 62.2934 52.0029C58.0424 55.5494 55.1324 60.4848 54.0593 65.9682C52.9862 71.4516 53.8165 77.1437 56.4085 82.0746C59.0005 87.0054 63.194 90.87 68.2744 93.0097C73.3548 95.1495 79.0077 95.4321 84.27 93.8094C89.5324 92.1867 94.0786 88.7591 97.1339 84.1106C100.189 79.4621 101.565 73.8803 101.026 68.3163C100.487 62.7523 98.0673 57.5505 94.1791 53.597C91.9774 51.3457 89.3582 49.5607 86.4734 48.3457C83.5885 47.1306 80.4953 46.5095 77.3727 46.5183Z'
        fill='#31314F'
      />
      <path
        d='M77.3702 83.3864C74.4771 83.3888 71.6728 82.3714 69.435 80.5072C67.1972 78.6431 65.6642 76.0477 65.0976 73.1634C64.531 70.279 64.9657 67.2842 66.3276 64.6891C67.6895 62.0941 69.8942 60.0594 72.5662 58.9319C75.2382 57.8043 78.2121 57.6537 80.9811 58.5056C83.7501 59.3575 86.1429 61.1592 87.7517 63.6037C89.3606 66.0483 90.0861 68.9844 89.8043 71.9117C89.5225 74.8391 88.2511 77.5765 86.2067 79.6575C85.0503 80.8434 83.6734 81.7837 82.1564 82.4239C80.6394 83.064 79.0126 83.3912 77.3702 83.3864ZM77.3702 59.2413C74.7659 59.2396 72.2417 60.1562 70.2278 61.8349C68.2139 63.5135 66.8347 65.8503 66.3256 68.4469C65.8165 71.0435 66.2091 73.7393 67.436 76.0747C68.663 78.4102 70.6485 80.2407 73.0542 81.2544C75.46 82.2681 78.1371 82.4023 80.6293 81.6339C83.1215 80.8656 85.2745 79.2424 86.7213 77.0409C88.1681 74.8394 88.8193 72.196 88.5638 69.5611C88.3082 66.9261 87.1617 64.4628 85.3199 62.5909C84.2786 61.5256 83.0399 60.681 81.6753 60.106C80.3106 59.531 78.8473 59.2371 77.3702 59.2413Z'
        fill='#31314F'
      />
      <path
        d='M77.3733 75.0892C76.5201 75.0782 75.689 74.8117 74.9838 74.3233C74.2785 73.8349 73.7304 73.1461 73.408 72.3429C73.0855 71.5397 73.0029 70.6577 73.1706 69.807C73.3382 68.9564 73.7487 68.1748 74.3506 67.5599C75.176 66.7554 76.2751 66.3062 77.4186 66.3062C78.5621 66.3062 79.6612 66.7554 80.4866 67.5599C81.0885 68.1748 81.499 68.9564 81.6666 69.807C81.8343 70.6577 81.7517 71.5397 81.4292 72.3429C81.1068 73.1461 80.5587 73.8349 79.8534 74.3233C79.1482 74.8117 78.3171 75.0782 77.4639 75.0892H77.3733ZM77.3733 67.5394C76.9683 67.537 76.5669 67.6174 76.1931 67.7757C75.8192 67.9341 75.4803 68.1673 75.1968 68.4614C74.9089 68.7518 74.6804 69.0973 74.5245 69.4779C74.3686 69.8585 74.2884 70.2668 74.2884 70.6791C74.2884 71.0915 74.3686 71.4997 74.5245 71.8803C74.6804 72.261 74.9089 72.6066 75.1968 72.897C75.785 73.4663 76.5661 73.7838 77.3783 73.7838C78.1904 73.7838 78.9715 73.4663 79.5597 72.897C79.9885 72.4567 80.2798 71.8973 80.3971 71.2892C80.5143 70.6811 80.4523 70.0514 80.2187 69.479C79.9851 68.9067 79.5904 68.4172 79.0842 68.0721C78.578 67.727 77.9828 67.5417 77.3733 67.5394Z'
        fill='#31314F'
      />
      <path
        d='M92.0916 17.8551C92.0936 19.4951 91.6171 21.0988 90.7222 22.4632C89.8273 23.8276 88.5542 24.8914 87.0643 25.5199C85.5744 26.1484 83.9347 26.3134 82.3526 25.994C80.7704 25.6745 79.3172 24.8851 78.1766 23.7254C77.036 22.5658 76.2594 21.0882 75.9452 19.4797C75.631 17.8711 75.7932 16.2039 76.4114 14.6891C77.0296 13.1744 78.0759 11.8802 79.4179 10.9703C80.7599 10.0605 82.3375 9.57586 83.9505 9.57789C86.1089 9.5806 88.1781 10.4535 89.7043 12.0052C91.2305 13.5569 92.089 15.6607 92.0916 17.8551Z'
        fill='#C5D4E3'
      />
      <path
        d='M38.8404 85.1688C38.8404 86.4715 38.4604 87.745 37.7485 88.8282C37.0366 89.9114 36.0247 90.7557 34.8408 91.2543C33.657 91.7528 32.3543 91.8832 31.0976 91.629C29.8408 91.3749 28.6865 90.7477 27.7804 89.8265C26.8743 88.9053 26.2573 87.7315 26.0073 86.4538C25.7573 85.176 25.8857 83.8516 26.376 82.648C26.8664 81.4444 27.6967 80.4158 28.7621 79.692C29.8276 78.9682 31.0801 78.5818 32.3615 78.5818C34.0798 78.5818 35.7278 79.2759 36.9428 80.5112C38.1578 81.7465 38.8404 83.4218 38.8404 85.1688Z'
        fill='#C5D4E3'
      />
      <path d='M119.197 17.876H107.277V19.1462H119.197V17.876Z' fill='#31314F' />
      <path d='M113.855 12.4568H112.605V24.5755H113.855V12.4568Z' fill='#31314F' />
      <path
        d='M115.177 45.248C114.557 45.248 113.951 45.0611 113.435 44.7111C112.92 44.361 112.519 43.8634 112.282 43.2813C112.044 42.6991 111.982 42.0585 112.103 41.4405C112.224 40.8225 112.522 40.255 112.961 39.8094C113.399 39.3638 113.957 39.0604 114.565 38.9374C115.173 38.8145 115.803 38.8776 116.376 39.1188C116.948 39.3599 117.438 39.7682 117.782 40.2921C118.126 40.816 118.31 41.432 118.31 42.0622C118.307 42.9063 117.976 43.715 117.389 44.3119C116.802 44.9088 116.007 45.2453 115.177 45.248ZM115.177 40.126C114.804 40.126 114.44 40.2384 114.13 40.4489C113.82 40.6594 113.578 40.9585 113.436 41.3085C113.293 41.6585 113.256 42.0437 113.328 42.4153C113.401 42.7869 113.581 43.1283 113.844 43.3962C114.108 43.6641 114.443 43.8466 114.809 43.9205C115.174 43.9944 115.553 43.9565 115.898 43.8115C116.242 43.6665 116.536 43.421 116.743 43.1059C116.95 42.7909 117.061 42.4205 117.061 42.0417C117.055 41.5372 116.855 41.0551 116.502 40.7002C116.149 40.3454 115.673 40.1465 115.177 40.1465V40.126Z'
        fill='#31314F'
      />
      <path
        d='M114.655 100.248C114.655 101.046 114.422 101.827 113.986 102.49C113.549 103.154 112.929 103.671 112.204 103.977C111.478 104.282 110.68 104.362 109.91 104.207C109.14 104.051 108.433 103.666 107.878 103.102C107.322 102.538 106.944 101.818 106.791 101.035C106.638 100.252 106.717 99.441 107.017 98.7035C107.318 97.966 107.826 97.3356 108.479 96.8921C109.132 96.4486 109.899 96.2119 110.685 96.2119C111.738 96.2119 112.747 96.6371 113.492 97.394C114.236 98.1509 114.655 99.1775 114.655 100.248Z'
        fill='#60DFC8'
      />
      <path d='M35.6947 68.3889H17.3164V69.6592H35.6947V68.3889Z' fill='#31314F' />
      <path d='M36.1182 73.491H25.6797V74.7612H36.1182V73.491Z' fill='#31314F' />
      <path d='M112.141 108.73H92.0898V110H112.141V108.73Z' fill='#31314F' />
    </SvgIcon>
  );
};




export const useStyles = (props: any) => ({
  view: css`
    width: inherit;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    height: fit-content;
    min-height: 100%;

    .react-pdf__Page__textContent {
      display: none;
    }

    .react-pdf__Page__canvas {
      width: 85% !important;
      margin: auto;
      height: auto !important;
    }
  `,
  goBack: css`
    color: white;
    cursor: pointer;
    margin-left: 2rem;

    &:hover {
      color: ${colors.darkBlue};
    }
  `,
  gridWrapper: css`
    display: grid;
    grid-template-columns: repeat(${props.grid_template_columns}, ${100 / props.grid_template_columns - 1}%);
    justify-content: space-between;
    padding: 2rem 8vw;
    row-gap: 1rem;

    .dashboard_name {
      font-size: 1.5vmax;
    }

    .dashboard_logo {
      width: 8vmax;
      aspect-ratio: 1 / 1;
    }

    .dashboard_button {
      border-radius: 12px;
      padding: 0.5rem 4rem;
    }
  `,
  gridItem: css`
    border: 1px dashed ${colors.grayBlue};
    border-radius: 16px;
    display: grid;
    justify-items: center;
    padding: 2rem 0;
    row-gap: 1rem;
    max-width: 24vw;
  `,
  iframe: css`
    .logoBar .logo .text {
      opacity: 0;
    }
  `,
});


const Iframe = ({ iframe }: { iframe: string }) => {
  return (
    <div id='content' style={{ display: 'flex', flexGrow: 1, width: '100%' }}>
      <div
        style={{
          width: '100%',
        }}
      >
        <iframe
          src={iframe}
          frameBorder='0'
          allowFullScreen
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </div>
  );
};

const DashboardLogo = (props: { logo: string; name: string }) => {
  const { logo, name } = props;
  if (!logo) {
    return <PipelineInvestIcon style={{ width: '100%', height: '100%' }} />;
  }
  return <img src={logo} alt={name} style={{ width: '100%', height: '100%' }} />;
};

const DashboardPanel = ({
  profile_dashboards,
  grid_template_columns = 4,
}: {
  profile_dashboards: ProfileDashboardsDashboardView[];
  grid_template_columns: number;
}) => {
  const classes = useStyles({ grid_template_columns })
  if (isEmpty(profile_dashboards)) {
    return (
      <div style={{ marginTop: '1vmax' }}>
        <span>Não há dashboards para esse perfil</span>
      </div>
    );
  }
  return (
    <div
      css={classes.gridWrapper}
    >
      {profile_dashboards.map((item) => {
        return (
          <div
            key={item.id}
            css={classes.gridItem}
          >
            <div className='dashboard_logo'>
              <DashboardLogo logo={item.dashboard_logo_url} name={item.name} />
            </div>
            <span className='dashboard_name'>{item.name}</span>
            <button
              onClick={() => item.handleSelect()}
              className='darkBlue medium dashboard_button'
            >
              <span style={{ fontSize: '1.25vmax' }}>Entrar</span>
            </button>
          </div>
        );
      })}
    </div>
  );
};

const DashboardView = (props: {
  profileDashboards: ProfileDashboardsDashboardView[];
  iframe: string;
  handleGoBack: () => void;
  dashboard_title: string;
  companyDashboardView: DashboardViewData;
  setCompanyDashboardView: React.Dispatch<React.SetStateAction<DashboardViewData>>;
}) => {
  const { iframe, profileDashboards, handleGoBack, dashboard_title, companyDashboardView, setCompanyDashboardView } =
    props;
  const classes = useStyles({});

  const grid_template_columns =
    profileDashboards.length > 3 ?
      (companyDashboardView.current &&
        (companyDashboardView.data.find((item) => item.id === companyDashboardView.current)?.items_per_row) ||
        4) : 4;
  return (
    <div css={classes.view}>
      {iframe !== '' ? (
        <>
          <Iframe iframe={iframe} />
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '2rem 0', position: 'relative' }}>
          <TooltipButton
            tooltipProps={{
              title: 'Alternar visualização',
              style: { position: 'absolute', right: '1%', top: '1%', display: profileDashboards.length > 3 ? 'block' : 'none' }
            }}
            Icon={BorderAll}
            iconButtonProps={{
              onClick: () =>
                setCompanyDashboardView((current_value) => ({
                  ...current_value,
                  data: current_value.data.reduce((acc, item) => {
                    if (item.id === current_value.current) {
                      return acc.concat({
                        id: item.id,
                        items_per_row: item.items_per_row === 3 ? 4 : 3,
                      });
                    }
                    return acc.concat(item);
                  }, [] as { id: string; items_per_row: number }[]),
                }))
            }}
          />

          <span className='title'>{dashboard_title}</span>
          <DashboardPanel grid_template_columns={grid_template_columns} profile_dashboards={profileDashboards} />
        </div>
      )}
      <div
        style={{
          alignItems: 'center',
          backgroundColor: colors.blue,
          bottom: '0',
          display: 'flex',
          height: '2rem',
          left: '0',
          position: 'absolute',
          right: '0',
        }}
      >
        <span onClick={handleGoBack} css={classes.goBack}>
          {' '}
          Voltar{' '}
        </span>
      </div>
    </div>
  );
};

export default DashboardView;
