/* eslint-disable camelcase */
import React from 'react'
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { SubjectAttributes, DefaultOptionType, Role, validation, subjectFieldOptions, subjectKindOptions, CompanyAttributes } from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import { css } from '@emotion/react';

const useStyles = {
  view: css`
    background: #FDFEFF;
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 0rem;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,

  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,

  form: css`
    display: grid;
    row-gap: 1rem;
    padding: 1rem;
  `,

  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

interface ISubjectsFormProps  {
  handleSubmit: (arg: () => void) => any
  onSubmit: () => void
  initialValues: SubjectAttributes
  close_form: () => void
}

const SubjectsForm = (props: ISubjectsFormProps) => {
  const { handleSubmit, onSubmit, initialValues, close_form } = props;
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector('subjectForm');
  const companyIdValue = formValues(state, 'company_id') as string;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;

  return (
    <div css={useStyles.view}>
      <span style={{paddingLeft: '1rem', paddingTop: '1rem'}} className={`${useStyles.title} title`}>Salvar disciplina</span>
      <form css={useStyles.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}

        {companyIdValue && (
          <>
            <div style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%', justifyContent: 'space-between' }}>
              <Field
                name='name'
                component={InputComponent}
                label={'Nome'}
                placeholder={'Insira o nome da disciplina'}
                validate={[validation.required]}
              />
              <Field
                name='field'
                component={SelectComponent}
                options={subjectFieldOptions}
                label={'Àrea'}
                placeholder={'Selecione a área'}
              />
              <Field
                name='kind'
                component={SelectComponent}
                options={subjectKindOptions}
                label={'Tipo'}
                placeholder={'Selecione o tipo'}
              />
            </div>
            <div css={useStyles.buttons}>
              <button type='button' onClick={close_form}className='red small'>
                <span> Cancelar </span>
              </button>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar disciplina </span>
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'subjectForm',
  }),
)(SubjectsForm);
