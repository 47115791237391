/* eslint-disable camelcase */
import React from 'react';
import { compose } from 'redux';
import {
  Field,
  FieldArray,
  FieldArrayFieldsProps,
  FormSection,
  WrappedFieldArrayProps,
  change,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import {
  DefaultOptionType,
  colors,
  InvoiceFormAttributes,
  InvoiceRenegotiationFormAttributes,
  NestedInvoiceItemAttributes,
  RenegotiationFormAttributes,
  validation,
  monetaryValueMaskProps,
  InvoicePaymentOptionEnum,
  NestedWalletCreditAttributes,
  WalletAttributes,
} from '../../utils/constants';
import { css } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs } from '@mui/material';
import TabPanel from '../shared/TabPanel';
import { CheckCircleOutline, ExpandMore, HighlightOffOutlined, Sync } from '@mui/icons-material';
import { InvoiceForm } from './InvoiceForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { head, isEmpty } from 'lodash';
import {
  compactSum,
  convertToDate,
  parseMonetaryValue,
  toFixedNumber,
  totalInvoiceValues,
} from '../../utils/functions';
import DatePickerComponent from '../input/form/datepicker';
import { addMonths } from 'date-fns';
import MaskedInputComponent from '../input/form/masked';
import InvoiceValue from '../shared/InvoiceValues';
import PenIcon from '../icon/PenIcon';
import { InvoiceItemBaseValues } from '../../store/invoice_items';
import { warning } from 'react-notification-system-redux';
import { UI_SET_LOADING_OPEN } from '../../store/ui';
import CheckboxComponent from '../input/form/checkbox';
import AddButton from '../shared/AddButton';
import SelectComponent from '../input/form/select';
import TooltipButton from '../shared/TooltipButton';

const tabStyle = css`& .Mui-selected': {
  background: '#FDFEFF',
  borderRadius: '1rem 1rem 0 0',
}`;

const accordionCss = css`
  background: inherit;
  border-bottom: 1px solid ${colors.grayBlue};
  box-shadow: none;
`;
const accordionSummaryCss = css`
  align-items: center;
  display: flex;
  gap: 1vmax;
  justify-content: flex-start;
  width: 100%;
`;

const accordionDetailsCss = css`
  background-color: white;
`;

interface CustomRenderInvoiceAttributes extends WrappedFieldArrayProps {
  representativeOptions: DefaultOptionType[];
  is_installment_renegotiation?: boolean;
  initial_invoice_reference: InvoiceFormAttributes;
  initial_invoice_renegotiations_attributes: InvoiceRenegotiationFormAttributes[];
  initial_values?: RenegotiationFormAttributes;
  set_initial_values?: React.Dispatch<React.SetStateAction<RenegotiationFormAttributes | null>>;
  wallet_credits: NestedWalletCreditAttributes[]
  wallets: WalletAttributes[]
  setLoading: (value: boolean) => Promise<void>
  [x: string]: any;
}

const totalInvoiceRenegotiationTotals = (
  invoice_renegotiations: InvoiceRenegotiationFormAttributes[],
) => {
  let invoice_renegotiation_total_current_charge = 0;
  let invoice_renegotiation_total_current_conditional_charge = 0;
  let invoice_renegotiation_total_renegotiation_allowance = 0;
  let invoice_renegotiation_total_anticipation_discount = 0;
  let invoice_renegotiation_total_contractual_addition = 0;
  let invoice_renegotiation_total_installment_addition = 0;
  invoice_renegotiations.forEach((invoice_renegotiation) => {
    const current_invoice_renegotiation_invoice = invoice_renegotiation.invoice_attributes;
    const current_invoice_renegotiation_invoice_items = current_invoice_renegotiation_invoice.invoice_items_attributes;
    const {
      current_charge,
      current_conditional_charge,
      current_renegotiation_allowance,
      current_anticipation_discount,
      current_contractual_addition,
      current_installment_addition,
    } = totalInvoiceValues({
      invoice_items: current_invoice_renegotiation_invoice_items,
      invoice: current_invoice_renegotiation_invoice,
      wallet_credits: []
    });

    invoice_renegotiation_total_current_charge += current_charge;
    invoice_renegotiation_total_current_conditional_charge += current_conditional_charge;
    invoice_renegotiation_total_renegotiation_allowance += current_renegotiation_allowance;
    invoice_renegotiation_total_contractual_addition += current_contractual_addition;
    invoice_renegotiation_total_anticipation_discount += current_anticipation_discount;
    invoice_renegotiation_total_installment_addition += current_installment_addition;
  });
  return {
    invoice_renegotiation_total_current_charge,
    invoice_renegotiation_total_current_conditional_charge,
    invoice_renegotiation_total_anticipation_discount,
    invoice_renegotiation_total_contractual_addition,
    invoice_renegotiation_total_renegotiation_allowance,
    invoice_renegotiation_total_installment_addition,
  };
};

const InvoiceRenegotiation = (props: {
  all_invoices_invoice_items: NestedInvoiceItemAttributes[];
  all_invoices: InvoiceFormAttributes[];
  edited: string[];
  expanded: string[];
  fields: FieldArrayFieldsProps<any>;
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  index: number;
  invoice_renegotiation: string;
  is_installment_renegotiation: boolean;
  isEditing: string;
  onChangeCharge: (
    current_charge: number,
    conditional_charge: number,
    current_invoice: InvoiceFormAttributes,
    installment_number: string,
  ) => void;
  representativeOptions: DefaultOptionType[];
  setEdited: React.Dispatch<React.SetStateAction<string[]>>;
  setIsEditing: React.Dispatch<React.SetStateAction<string>>;
  wallets: WalletAttributes[]
  [x: string]: any;
}) => {
  const {
    all_invoices_invoice_items,
    all_invoices,
    edited,
    expanded,
    fields,
    handleChange,
    index,
    invoice_renegotiation,
    is_installment_renegotiation,
    isEditing,
    onChangeCharge,
    representativeOptions,
    setEdited,
    setIsEditing,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const currentInvoiceRenegotiation = fields.get(index) as InvoiceRenegotiationFormAttributes;
  const currentInvoice = currentInvoiceRenegotiation.invoice_attributes;
  const [chargeValue, setChargeValue] = React.useState<number>(0);
  const [conditionalChargeValue, setConditionaChargeValue] = React.useState<number>(0);
  const installment_number = currentInvoice.installment_number as string;
  const name = `Parcela ${index + 1}`;

  const dateFieldsDisabled = index + 1 > 1;
  const parsedExpirationDate = convertToDate(currentInvoice.expiration_date);
  const parsedPunctualityExpirationDate =
    currentInvoice.punctuality_expiration_date && convertToDate(currentInvoice.punctuality_expiration_date);

  const isLast = index + 1 === fields.length;
  const isFirst = index === 0;

  const cantEditFirst = isFirst && fields.length < 2;
  const showEditingIcon = !isLast && !cantEditFirst && !edited.includes(installment_number) && isEditing === '';
  const allowEditing = !cantEditFirst && !edited.includes(installment_number) && isEditing === invoice_renegotiation;
  const chargeInputId = `charge_value_${index}`;
  const conditionalChargeInputId = `conditional_charge_value_${index}`;
  const fieldDisabled = isLast || cantEditFirst || !allowEditing;
  const setLoading = React.useCallback(async (value: boolean) => {
    await dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const handleEdit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true);
    e.stopPropagation();
    const get_charge_input_element = document.querySelector(`#${chargeInputId}`) as Element;
    const get_conditional_charge_input_element = document.querySelector(`#${conditionalChargeInputId}`) as Element;
    const new_charge = parseMonetaryValue(get_charge_input_element.getAttribute('value')?.toString() as string);
    const new_conditional_charge = get_conditional_charge_input_element
      ? parseMonetaryValue(get_conditional_charge_input_element.getAttribute('value')?.toString() as string)
      : 0;
    if (new_charge <= 10 || new_conditional_charge < 0) {
      dispatch(
        warning({
          message: 'Não se pode alterar a parcela para valores menores que R$10',
        }),
      );
    } else {
      await onChangeCharge(
        new_charge,
        new_conditional_charge,
        currentInvoice,
        currentInvoice.installment_number as string,
      );
      setEdited((current) => current.concat(installment_number));
    }
    setIsEditing('');
    setLoading(false);
  };

  const handleCancelEdit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setChargeValue(chargeValue);
    setConditionaChargeValue(conditionalChargeValue);
    setIsEditing('');
  };
  React.useEffect(() => {
    const { current_charge, current_conditional_charge } = totalInvoiceValues({
      invoice_items: currentInvoice.invoice_items_attributes,
      invoice: currentInvoice,
      wallet_credits: []
    });
    setChargeValue(toFixedNumber(current_charge));
    setConditionaChargeValue(toFixedNumber(current_conditional_charge));
  }, [fields]);
  const { current_conditional_charge } = totalInvoiceValues(
    {
      invoice_items: currentInvoice.invoice_items_attributes,
      invoice: currentInvoice,
      wallet_credits: []
    }
  );
  const punctuality_disabled = dateFieldsDisabled || !parsedExpirationDate;

  return (
    <Accordion
      key={invoice_renegotiation}
      expanded={expanded.includes(invoice_renegotiation)}
      onChange={handleChange(invoice_renegotiation)}
      css={accordionCss}
    >
      <AccordionSummary expandIcon={<ExpandMore />} css={accordionSummaryCss}>
        <div css={accordionSummaryCss} id={`invoice_renegotiation_${index}`}>
          <span>{name}</span>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            css={accordionSummaryCss}
          >
            <Field
              component={DatePickerComponent}
              id='expiration_date'
              label='Vencimento'
              name={`${invoice_renegotiation}.invoice_attributes.expiration_date`}
              placeholder={'31/11/2021'}
              required
              disabled={dateFieldsDisabled}
              validate={[validation.checkExpirationDate, validation.dateRequired]}
              datePickerProps={{
                minDate: parsedPunctualityExpirationDate || new Date(),
              }}
            />
            <div style={{ visibility: current_conditional_charge === 0 ? 'hidden' : 'initial' }}>
              <Field
                component={DatePickerComponent}
                id='punctuality_expiration_date'
                label='Vencimento desc condicional'
                name={`${invoice_renegotiation}.invoice_attributes.punctuality_expiration_date`}
                placeholder={'11/11/2021'}
                validate={[validation.checkPunctualityExpirationDate]}
                disabled={punctuality_disabled}
                datePickerProps={{
                  minDate: new Date(),
                  maxDate: parsedExpirationDate,
                }}
              />
            </div>
            <MaskedInputComponent
              placeholder={`Cobrança`}
              label='Valor de cobrança'
              input={{
                id: chargeInputId,
                disabled: fieldDisabled,
                value: chargeValue,
              }}
              disabled={fieldDisabled}
              maskProps={monetaryValueMaskProps}
              name='anticipation_discount'
              parse={parseMonetaryValue}
            />
            <div style={{ visibility: current_conditional_charge === 0 ? 'hidden' : 'initial' }}>
              <MaskedInputComponent
                placeholder={`Desc. condicional`}
                label='Valor de desc. condicional'
                disabled={fieldDisabled}
                input={{
                  id: conditionalChargeInputId,
                  disabled: fieldDisabled,
                  value: conditionalChargeValue,
                }}
                maskProps={monetaryValueMaskProps}
                parse={parseMonetaryValue}
              />
            </div>
            {showEditingIcon && (
              <TooltipButton
                tooltipProps={{
                  title: 'Editar valores da parcela'
                }}
                Icon={PenIcon}
                iconButtonProps={{
                  onClick: () => setIsEditing(invoice_renegotiation)
                }}
              />
            )}
            {allowEditing && (
              <>
                <TooltipButton
                  tooltipProps={{
                    title: 'Editar'
                  }}
                  Icon={CheckCircleOutline}
                  iconProps={{
                    style: { color: colors.green }
                  }}
                  iconButtonProps={{
                    onClick: handleEdit
                  }}
                />

                <TooltipButton
                  tooltipProps={{
                    title: 'Cancelar'
                  }}
                  Icon={HighlightOffOutlined}
                  iconProps={{
                    style: { color: colors.lightRed }
                  }}
                  iconButtonProps={{
                    onClick: handleCancelEdit
                  }}
                />
              </>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails css={accordionDetailsCss}>
        <FormSection name={`${invoice_renegotiation}.invoice_attributes`}>
          <InvoiceForm
            all_invoice_items={all_invoices_invoice_items}
            all_invoices={all_invoices}
            disable_fields={false}
            invoice_form_values={currentInvoice}
            is_renegotiation
            representativeOptions={representativeOptions}
            {...rest}
          />
        </FormSection>
      </AccordionDetails>
    </Accordion>
  );
};

const addInstallment = async ({
  fields_length,
  current_invoice_renegotiation_form_values,
  invoice_reference,
  initial_invoice_renegotiations_invoice_items_attributes,
  formattedInitialInvoiceReference
}:{
  fields_length: number
  current_invoice_renegotiation_form_values: InvoiceRenegotiationFormAttributes[]
  invoice_reference: InvoiceFormAttributes
  initial_invoice_renegotiations_invoice_items_attributes: NestedInvoiceItemAttributes[]
  formattedInitialInvoiceReference: InvoiceFormAttributes
}) => {
  const newSize = fields_length + 1;
  const { current_charge } = totalInvoiceValues({
    invoice: formattedInitialInvoiceReference,
    invoice_items: formattedInitialInvoiceReference.invoice_items_attributes,
    wallet_credits: []
  })
  const desired_value_for_each_invoice = toFixedNumber(current_charge/newSize)
  const proportion = desired_value_for_each_invoice/current_charge
  const first_invoice_renegotiation = current_invoice_renegotiation_form_values[0]
  const new_invoice_renegotiations_attributes: InvoiceRenegotiationFormAttributes[] = await [...new Array(newSize)].reduce(
    (invoice_renegotiation_acc: InvoiceRenegotiationFormAttributes[], _, index, self) => {
      const isLast = index + 1 === self.length;
      const current_invoice_renegotiation = current_invoice_renegotiation_form_values[index];
      let expiration_date = current_invoice_renegotiation?.invoice_attributes?.expiration_date;
      let punctuality_expiration_date =
        current_invoice_renegotiation?.invoice_attributes?.punctuality_expiration_date;
      if (isEmpty(current_invoice_renegotiation) || index > 0) {
        const first_invoice_renegotiation_expiration_date = convertToDate(
          first_invoice_renegotiation.invoice_attributes.expiration_date,
        );
        const first_invoice_renegotiation_punctuality_expiration_date = convertToDate(
          first_invoice_renegotiation.invoice_attributes.punctuality_expiration_date,
        );
        const monthsToAdd = index;
        if (first_invoice_renegotiation_expiration_date) {
          expiration_date = addMonths(first_invoice_renegotiation_expiration_date, monthsToAdd);
        }
        if (first_invoice_renegotiation_punctuality_expiration_date) {
          punctuality_expiration_date = addMonths(
            first_invoice_renegotiation_punctuality_expiration_date,
            monthsToAdd,
          );
        }
      }
      let renegotiation_allowance = toFixedNumber((invoice_reference.renegotiation_allowance || 0) / newSize);
      let anticipation_discount = toFixedNumber((invoice_reference.anticipation_discount || 0) / newSize);
      let contractual_addition = toFixedNumber((invoice_reference.contractual_addition || 0) / newSize);
      let installment_addition = toFixedNumber((invoice_reference.installment_addition || 0) / newSize);

      if (isLast) {
        const accumulated_values = invoice_renegotiation_acc.reduce(
          (acc, invoice_renegotiation) => {
            const {
              invoice_attributes: {
                renegotiation_allowance,
                anticipation_discount,
                installment_addition,
                contractual_addition,
              },
            } = invoice_renegotiation;
            return {
              renegotiation_allowance: acc.renegotiation_allowance + (renegotiation_allowance || 0),
              anticipation_discount: acc.anticipation_discount + (anticipation_discount || 0),
              installment_addition: acc.installment_addition + (installment_addition || 0),
              contractual_addition: acc.contractual_addition + (contractual_addition || 0),
            };
          },
          {
            renegotiation_allowance: 0,
            contractual_addition: 0,
            installment_addition: 0,
            anticipation_discount: 0,
          },
        );
        renegotiation_allowance = toFixedNumber(
          (invoice_reference.renegotiation_allowance || 0) - accumulated_values.renegotiation_allowance,
        );
        anticipation_discount = toFixedNumber(
          (invoice_reference.anticipation_discount || 0) - accumulated_values.anticipation_discount,
        );
        installment_addition = toFixedNumber(
          (invoice_reference.installment_addition || 0) - accumulated_values.installment_addition,
        );
        contractual_addition = toFixedNumber(
          (invoice_reference.contractual_addition || 0) - accumulated_values.contractual_addition,
        );
      }
      const new_invoice_item_attributes = (
        newSize <= 2
          ? initial_invoice_renegotiations_invoice_items_attributes
          : invoice_reference.invoice_items_attributes
      ).reduce((acc, invoice_item) => {
        const { id, base_values, code, ...rest } = invoice_item;
        let new_contract_full_billing_percentage_base = (base_values?.contract_full_billing_percentage_base || 0) * proportion

        let new_contract_conditional_discount_billing_percentage_base = (base_values?.contract_conditional_discount_billing_percentage_base ?? 0) * proportion

        const new_proportion = new_contract_full_billing_percentage_base/(invoice_item.base_values?.contract_full_billing_percentage_base||0)
        let new_fees = toFixedNumber((invoice_item.base_values?.initial_fees||0) * new_proportion)
        let new_penalty = toFixedNumber((invoice_item.base_values?.initial_penalty||0) * new_proportion)
        if (isLast) {
          const parent_invoice_item = formattedInitialInvoiceReference.invoice_items_attributes.find(
            (item) => item.id === invoice_item.parent_id?.toString(),
          );
          const accumulatedBillingRatios = invoice_renegotiation_acc.reduce(
            (acc, invoice_renegotiation) => {
              const invoice_item_from_same_parent =
                invoice_renegotiation.invoice_attributes.invoice_items_attributes.find(
                  (item: NestedInvoiceItemAttributes) => item.parent_id === invoice_item.parent_id,
                ) as NestedInvoiceItemAttributes;
              return {
                fees: acc.fees + (invoice_item_from_same_parent.base_values?.initial_fees || 0),
                penalty: acc.penalty + (invoice_item_from_same_parent.base_values?.initial_penalty || 0),
                full:
                  acc.full + (invoice_item_from_same_parent.base_values?.contract_full_billing_percentage_base || 0),
                conditional:
                  acc.conditional +
                  (invoice_item_from_same_parent.base_values?.contract_conditional_discount_billing_percentage_base ||
                    0),
              };
            },
            { full: 0, conditional: 0, fees: 0, penalty: 0 },
          );
          new_contract_full_billing_percentage_base =
            (parent_invoice_item?.base_values?.contract_full_billing_percentage_base || 0) -
            accumulatedBillingRatios.full;
          new_contract_conditional_discount_billing_percentage_base =
            (parent_invoice_item?.base_values?.contract_conditional_discount_billing_percentage_base || 0) -
            accumulatedBillingRatios.conditional;
          new_fees = (parent_invoice_item?.base_values?.initial_fees || 0) -
          accumulatedBillingRatios.fees;
          new_penalty = (parent_invoice_item?.base_values?.initial_penalty || 0) -
          accumulatedBillingRatios.penalty;
        }
        return acc.concat({
          ...rest,
          active: true,
          parent_id: invoice_item.parent_id,
          base_values: {
            ...base_values,
            initial_fees: new_fees,
            initial_penalty: new_penalty,
            contract_full_billing_percentage_base: new_contract_full_billing_percentage_base,
            contract_conditional_discount_billing_percentage_base:
              new_contract_conditional_discount_billing_percentage_base,
          } as InvoiceItemBaseValues,
        });
      }, [] as NestedInvoiceItemAttributes[]);
      return invoice_renegotiation_acc.concat({
        invoice_attributes: {
          ...(expiration_date && { expiration_date }),
          ...(punctuality_expiration_date && { punctuality_expiration_date }),
          installment_number: `${index + 1}/${newSize}`,
          representative_id: invoice_reference.representative_id,
          fees: invoice_reference.fees,
          penalty: invoice_reference.penalty,
          renegotiation_allowance,
          anticipation_discount,
          contractual_addition,
          installment_addition,
          registration_id: invoice_reference.registration_id,
          kind: 'multiple',
          payment_method: InvoicePaymentOptionEnum.BANK_BILLET,
          invoice_items_attributes: new_invoice_item_attributes,
        } as InvoiceFormAttributes,
      });
    },
    [] as InvoiceRenegotiationFormAttributes[],
  );

  return new_invoice_renegotiations_attributes;
}

const renderInvoiceRenegotiationsAttributes = (props: CustomRenderInvoiceAttributes) => {
  const {
    representativeOptions,
    fields,
    initial_invoice_renegotiations_attributes,
    is_installment_renegotiation,
    initial_invoice_reference,
    setLoading,
    ...rest
  } = props;
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [edited, setEdited] = React.useState<string[]>([]);
  const [isEditing, setIsEditing] = React.useState<string>('');
  const [newFieldsSize, setNewFieldsSize] = React.useState<number>(1)
  const formName = 'renegotiationForm';
  const state = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const formValues = formValueSelector(formName);
  const all_invoices = formValues(state, 'invoices_attributes') as InvoiceFormAttributes[];
  const current_invoice_renegotiation_form_values = fields.getAll() as InvoiceRenegotiationFormAttributes[];
  const initial_invoice_renegotiations_invoice_items_attributes = head(initial_invoice_renegotiations_attributes)
    ?.invoice_attributes.invoice_items_attributes as NestedInvoiceItemAttributes[];
  const first_invoice_renegotiation = current_invoice_renegotiation_form_values[0];
  const invoice_reference =
    fields.length < 2
      ? first_invoice_renegotiation.invoice_attributes
      : (formValues(state, 'invoice_reference') as InvoiceFormAttributes);
  const all_invoices_invoice_items = invoice_reference.invoice_items_attributes as NestedInvoiceItemAttributes[];


  const formattedInitialInvoiceReference = {
    ...initial_invoice_reference,
    renegotiation_allowance: invoice_reference.renegotiation_allowance,
    anticipation_discount: invoice_reference.anticipation_discount,
    contractual_addition: invoice_reference.contractual_addition,
    installment_addition: invoice_reference.installment_addition,
  } as InvoiceFormAttributes;
  const handleAddInstallment = async () => {
    setLoading(true);
    const formatted_new_invoice_renegotiations_attributes = await addInstallment({
      fields_length: newFieldsSize ? newFieldsSize - 1 : 1,
      formattedInitialInvoiceReference,
      current_invoice_renegotiation_form_values,
      initial_invoice_renegotiations_invoice_items_attributes,
      invoice_reference
    });
    if (fields.length === 1) {
      dispatch(
        change(formName, 'invoice_reference', head(current_invoice_renegotiation_form_values)?.invoice_attributes),
      );
    }
    dispatch(change(formName, 'invoice_renegotiations_attributes', formatted_new_invoice_renegotiations_attributes));
    setLoading(false)
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded((current) => {
      if (isExpanded) {
        return current.concat(panel);
      } else {
        return current.filter((item) => item !== panel);
      }
    });
  };

  const first_invoice = first_invoice_renegotiation.invoice_attributes;
  const {
    invoice_renegotiation_total_current_charge: total_current_charge,
    invoice_renegotiation_total_current_conditional_charge: total_current_conditional_charge,
  } = totalInvoiceRenegotiationTotals(current_invoice_renegotiation_form_values);

  const {
    invoice_renegotiation_total_current_charge: initial_charge,
    invoice_renegotiation_total_current_conditional_charge: initial_conditional_charge,
  } = totalInvoiceRenegotiationTotals([{ invoice_attributes: invoice_reference }]);
  const disableAddButton =
    !first_invoice.expiration_date ||
    (total_current_conditional_charge > 0 && !first_invoice.punctuality_expiration_date) ||
    !isEmpty(edited);
  const addButtonTitle = disableAddButton
    ? !isEmpty(edited)
      ? 'Não se pode adicionar parcelas após editar uma parcela'
      : 'Antes de adicionar uma parcela, por favor adicionar data de vencimento'
    : 'Clique para adicionar parcela';

  const onChangeCharge = (
    current_charge: number,
    conditional_charge: number,
    current_invoice: InvoiceFormAttributes,
    current_installment_number: string,
  ) => {
    const new_charge = current_charge;
    const new_conditional_charge = conditional_charge;
    if (new_charge <= 10 || new_conditional_charge < 0) {
      dispatch(
        warning({
          message: 'Não se pode alterar a parcela para valores menores que R$10',
        }),
      );
      return;
    }
    const result = totalInvoiceValues({invoice_items: current_invoice.invoice_items_attributes, invoice: current_invoice, wallet_credits: []});
    const new_full_proportion = new_charge / result.current_charge;
    const new_conditional_discount_proportion = new_conditional_charge / result.current_conditional_charge;
    const new_anticipation_discount = new_conditional_charge > 0 ? toFixedNumber(result.current_anticipation_discount * new_full_proportion) : 0;
    const new_renegotiation_allowance = toFixedNumber(result.current_renegotiation_allowance * new_full_proportion);
    const new_installment_addition = toFixedNumber(result.current_installment_addition * new_full_proportion);
    const new_contractual_addition = toFixedNumber(result.current_contractual_addition * new_full_proportion);
    const new_initial_fees = toFixedNumber(result.initial_fees * new_full_proportion)
    const new_initial_penalty = toFixedNumber(result.initial_penalty * new_full_proportion)
    const new_invoice_items_attributes = current_invoice.invoice_items_attributes.reduce(
      (acc, invoice_item, index, self) => {
        const base_values = invoice_item.base_values;
        const isLast = index + 1 === self.length;
        let new_billing_ratio = (base_values?.contract_full_billing_percentage_base || 0) * new_full_proportion

        let new_contract_conditional_billing_ratio =
          (base_values?.contract_conditional_discount_billing_percentage_base || 0) *
            new_conditional_discount_proportion

        const new_proportion = new_billing_ratio/(invoice_item.base_values?.contract_full_billing_percentage_base||0)
        let new_fees = toFixedNumber((base_values?.initial_fees||0) * new_proportion)
        let new_penalty = toFixedNumber((base_values?.initial_penalty||0) * new_proportion)
        if (isLast) {
          const accumulatedSoFar = acc.reduce(
            (acc, invoice_item) => {
              const { base_values } = invoice_item;
              return ({
                fees: acc.fees + (base_values?.initial_fees || 0),
                penalty: acc.penalty + (base_values?.initial_penalty || 0),
                full:
                  acc.full + (base_values?.contract_full_billing_percentage_base || 0) * (invoice_item.contract_full || 0),
                conditional:
                  acc.conditional +
                  (base_values?.contract_conditional_discount_billing_percentage_base || 0) *
                    (invoice_item.contract_conditional_discount || 0),
              });
            },
            { full: 0, conditional: 0, fees: 0, penalty: 0 },
          );
          const otherSum = compactSum([
            -new_renegotiation_allowance,
            new_contractual_addition,
            new_installment_addition,
            accumulatedSoFar.full,
            accumulatedSoFar.fees,
            accumulatedSoFar.penalty
          ]);
          new_fees = new_initial_fees - accumulatedSoFar.fees
          new_penalty = new_initial_penalty - accumulatedSoFar.penalty

          const full_charge_left = new_charge - otherSum - new_fees - new_penalty
          const conditional_charge_left =
            new_conditional_charge - accumulatedSoFar.conditional - new_anticipation_discount;
          new_billing_ratio = invoice_item.contract_full ? full_charge_left / (invoice_item.contract_full) : 0;
          new_contract_conditional_billing_ratio = invoice_item.contract_conditional_discount ?
            conditional_charge_left / (invoice_item.contract_conditional_discount) : 0;
        }
        return acc.concat({
          ...invoice_item,
          base_values: {
            ...invoice_item.base_values,
            initial_fees: new_fees,
            initial_penalty: new_penalty,
            contract_full_billing_percentage_base: new_billing_ratio,
            contract_conditional_discount_billing_percentage_base: new_contract_conditional_billing_ratio,
          } as InvoiceItemBaseValues,
        });
      },
      [] as NestedInvoiceItemAttributes[],
    );

    const new_invoice = {
      ...current_invoice,
      installment_addition: new_installment_addition,
      renegotiation_allowance: new_renegotiation_allowance,
      anticipation_discount: new_anticipation_discount,
      contractual_addition: new_contractual_addition,
      invoice_items_attributes: new_invoice_items_attributes,
      ...(new_conditional_charge === 0 && { punctuality_expiration_date: null }),
    } as InvoiceFormAttributes;
    const initial_invoice_items_attributes_billing_bases =
      formattedInitialInvoiceReference.invoice_items_attributes.map((item) => {
        return {
          id: item.id as string,
          accumulated_full: 0,
          accumulated_conditional: 0,
          fees: 0,
          penalty: 0,
        };
      });
    const accumulated_from_edited = edited.concat(new_invoice.installment_number as string).reduce(
      (acc, installment_number) => {
        let installment_addition = 0;
        let contractual_addition = 0;
        let renegotiation_allowance = 0;
        let anticipation_discount = 0;
        let invoice_items_attributes: NestedInvoiceItemAttributes[] = [];

        if (installment_number === current_installment_number) {
          installment_addition = new_invoice.installment_addition || 0;
          contractual_addition = new_invoice.contractual_addition || 0;
          renegotiation_allowance = new_invoice.renegotiation_allowance || 0;
          anticipation_discount = new_invoice.anticipation_discount || 0;
          invoice_items_attributes = new_invoice.invoice_items_attributes;
        } else {
          const { invoice_attributes } = current_invoice_renegotiation_form_values.find(
            (item) => item.invoice_attributes.installment_number === installment_number,
          ) as InvoiceRenegotiationFormAttributes;
          installment_addition = invoice_attributes.installment_addition || 0;
          contractual_addition = invoice_attributes.contractual_addition || 0;
          renegotiation_allowance = invoice_attributes.renegotiation_allowance || 0;
          anticipation_discount = invoice_attributes.anticipation_discount || 0;
          invoice_items_attributes = invoice_attributes.invoice_items_attributes;
        }
        const billing_ratios = invoice_items_attributes.reduce((this_acc, invoice_item) => {
          const base_values = invoice_item.base_values;
          return this_acc.map((item) => {
            if (~~item.id !== invoice_item.parent_id) {
              return item;
            } else {
              return {
                ...item,
                accumulated_full: item.accumulated_full + (base_values?.contract_full_billing_percentage_base || 0),
                accumulated_conditional:
                  item.accumulated_conditional +
                  (base_values?.contract_conditional_discount_billing_percentage_base || 0),
                fees: item.fees + (base_values?.initial_fees || 0),
                penalty: item.penalty + (base_values?.initial_penalty || 0)
              };
            }
          });
        }, acc.billing_ratios);
        return {
          installment: acc.installment + (installment_addition || 0),
          contractual: acc.contractual + (contractual_addition || 0),
          anticipation: acc.anticipation + (anticipation_discount || 0),
          renegotiation: acc.renegotiation + (renegotiation_allowance || 0),
          billing_ratios: billing_ratios,
        };
      },
      {
        installment: 0,
        renegotiation: 0,
        anticipation: 0,
        contractual: 0,
        billing_ratios: initial_invoice_items_attributes_billing_bases,
      },
    );
    const remaining_installment_addition =
      (invoice_reference.installment_addition || 0) - accumulated_from_edited.installment;
    const remaining_renegotiation_allowance =
      (invoice_reference.renegotiation_allowance || 0) - accumulated_from_edited.renegotiation;
    const remaining_anticipation_discount =
      (invoice_reference.anticipation_discount || 0) - accumulated_from_edited.anticipation;
    const remaining_contractual_addition =
      (invoice_reference.contractual_addition || 0) - accumulated_from_edited.contractual;
    const new_invoice_renegotiations_attributes = current_invoice_renegotiation_form_values.reduce(
      (acc, invoice_renegotiation, other_index, self) => {
        const isLast = self.length === other_index + 1;
        const isEdited = edited.includes(invoice_renegotiation.invoice_attributes.installment_number as string);
        if ((invoice_renegotiation.invoice_attributes.installment_number as string) === current_installment_number) {
          return acc.concat({
            invoice_attributes: new_invoice,
          });
        } else if (!isLast && !isEdited) {
          const splitSize = self.length - edited.length - 1;
          const invoice_items_attributes = invoice_renegotiation.invoice_attributes.invoice_items_attributes;
          const new_installment_addition = toFixedNumber(remaining_installment_addition / splitSize);
          const new_renegotiation_allowance = toFixedNumber(remaining_renegotiation_allowance / splitSize);
          const new_anticipation_discount = toFixedNumber(remaining_anticipation_discount / splitSize);
          const new_contractual_addition = toFixedNumber(remaining_contractual_addition / splitSize);
          const new_invoice_items_attributes = invoice_items_attributes.reduce((this_acc, invoice_item) => {
            const accumulatedBillingRatioForThis = accumulated_from_edited.billing_ratios.find(
              (ratio) => ~~ratio.id === invoice_item.parent_id,
            );
            const parent_invoice_item = formattedInitialInvoiceReference.invoice_items_attributes.find(
              (item) => item.id === invoice_item.parent_id?.toString(),
            );
            const parent_base_values = parent_invoice_item?.base_values;
            const new_contract_full_billing_ratio = toFixedNumber(
              ((parent_base_values?.contract_full_billing_percentage_base || 0) -
                (accumulatedBillingRatioForThis?.accumulated_full || 0)) /
                splitSize,
              3,
            );
            const new_contract_conditional_billing_ratio = toFixedNumber(
              ((parent_base_values?.contract_conditional_discount_billing_percentage_base || 0) -
                (accumulatedBillingRatioForThis?.accumulated_conditional || 0)) /
                splitSize,
              3,
            );
            const new_fees = toFixedNumber(
              ((parent_base_values?.initial_fees||0) - (accumulatedBillingRatioForThis?.fees||0))/splitSize
            )
            const new_penalty = toFixedNumber(
              ((parent_base_values?.initial_penalty||0) - (accumulatedBillingRatioForThis?.penalty||0))/splitSize
            )
            return this_acc.concat({
              ...invoice_item,
              base_values: {
                ...invoice_item.base_values,
                initial_fees: new_fees,
                initial_penalty: new_penalty,
                contract_full_billing_percentage_base: new_contract_full_billing_ratio,
                contract_conditional_discount_billing_percentage_base: new_contract_conditional_billing_ratio,
              } as InvoiceItemBaseValues,
            });
          }, [] as NestedInvoiceItemAttributes[]);
          return acc.concat({
            ...invoice_renegotiation,
            invoice_attributes: {
              ...invoice_renegotiation.invoice_attributes,
              anticipation_discount: new_anticipation_discount,
              installment_addition: new_installment_addition,
              renegotiation_allowance: new_renegotiation_allowance,
              contractual_addition: new_contractual_addition,
              invoice_items_attributes: new_invoice_items_attributes,
            },
          });
        } else if (isEdited) {
          return acc.concat(invoice_renegotiation);
        } else {
          const accumulated_values = acc.reduce(
            (acc, invoice_renegotiation) => {
              const {
                invoice_attributes: {
                  renegotiation_allowance,
                  anticipation_discount,
                  installment_addition,
                  contractual_addition,
                },
              } = invoice_renegotiation;
              return {
                renegotiation_allowance: acc.renegotiation_allowance + (renegotiation_allowance || 0),
                anticipation_discount: acc.anticipation_discount + (anticipation_discount || 0),
                installment_addition: acc.installment_addition + (installment_addition || 0),
                contractual_addition: acc.contractual_addition + (contractual_addition || 0),
              };
            },
            {
              renegotiation_allowance: 0,
              contractual_addition: 0,
              installment_addition: 0,
              anticipation_discount: 0,
            },
          );
          const {
            invoice_attributes: { invoice_items_attributes },
          } = invoice_renegotiation;
          const renegotiation_allowance: number = toFixedNumber(
            (invoice_reference.renegotiation_allowance || 0) - accumulated_values.renegotiation_allowance,
          );
          const anticipation_discount: number = toFixedNumber(
            (invoice_reference.anticipation_discount || 0) - accumulated_values.anticipation_discount,
          );
          const installment_addition: number = toFixedNumber(
            (invoice_reference.installment_addition || 0) - accumulated_values.installment_addition,
          );
          const contractual_addition: number = toFixedNumber(
            (invoice_reference.contractual_addition || 0) - accumulated_values.contractual_addition,
          );
          const new_invoice_items_attributes = invoice_items_attributes.reduce((this_acc, invoice_item) => {
            const invoice_renegotiation_acc = acc;
            const parent_invoice_item = formattedInitialInvoiceReference.invoice_items_attributes.find(
              (item) => item.id === invoice_item.parent_id?.toString(),
            );
            const accumulatedBillingRatios = invoice_renegotiation_acc.reduce(
              (acc, invoice_renegotiation) => {
                const invoice_item_from_same_parent =
                  invoice_renegotiation.invoice_attributes.invoice_items_attributes.find(
                    (item: NestedInvoiceItemAttributes) => item.parent_id === invoice_item.parent_id,
                  ) as NestedInvoiceItemAttributes;
                return {
                  fees: acc.fees + (invoice_item_from_same_parent.base_values?.initial_fees||0),
                  penalty: acc.penalty + (invoice_item_from_same_parent.base_values?.initial_penalty||0),
                  full:
                    acc.full + (invoice_item_from_same_parent.base_values?.contract_full_billing_percentage_base || 0),
                  conditional:
                    acc.conditional +
                    (invoice_item_from_same_parent.base_values?.contract_conditional_discount_billing_percentage_base ||
                      0),
                };
              },
              { full: 0, conditional: 0, fees: 0, penalty: 0 },
            );
            const new_contract_full_billing_percentage_base =
              (parent_invoice_item?.base_values?.contract_full_billing_percentage_base || 0) -
              accumulatedBillingRatios.full;
            const new_contract_conditional_discount_billing_percentage_base =
              (parent_invoice_item?.base_values?.contract_conditional_discount_billing_percentage_base || 0) -
              accumulatedBillingRatios.conditional;
            const new_fees = (parent_invoice_item?.base_values?.initial_fees||0)-accumulatedBillingRatios.fees
            const new_penalty = (parent_invoice_item?.base_values?.initial_penalty||0)-accumulatedBillingRatios.penalty
            return this_acc.concat({
              ...invoice_item,
              base_values: {
                ...invoice_item.base_values,
                initial_fees: new_fees,
                initial_penalty: new_penalty,
                contract_full_billing_percentage_base: new_contract_full_billing_percentage_base,
                contract_conditional_discount_billing_percentage_base:
                  new_contract_conditional_discount_billing_percentage_base,
              } as InvoiceItemBaseValues,
            });
          }, [] as NestedInvoiceItemAttributes[]);
          const new_renegotiation = {
            ...invoice_renegotiation,
            invoice_attributes: {
              ...invoice_renegotiation.invoice_attributes,
              renegotiation_allowance,
              anticipation_discount,
              installment_addition,
              contractual_addition,
              invoice_items_attributes: new_invoice_items_attributes,
            },
          } as InvoiceRenegotiationFormAttributes;
          return acc.concat(new_renegotiation);
        }
      },
      [] as InvoiceRenegotiationFormAttributes[],
    );
    const formatted = new_invoice_renegotiations_attributes;
    dispatch(change(formName, 'invoice_renegotiations_attributes', formatted));
  };
  const handleResetInstallments = () => {
    dispatch(change(formName, 'invoice_renegotiations_attributes', initial_invoice_renegotiations_attributes));
    setEdited([]);
    setIsEditing('');
  };

  return (
    <div>
      <div
        css={css`
          display: ${is_installment_renegotiation ? `flex` : `none`};
          justify-content: start;
          align-items: center;
          flex-wrap: wrap;
          & .inputArea {
            width: 4rem !important;
          }
        `}
      >
        <span style={{ flexBasis: '100%', textAlign: 'start' }}>Determinar número de parcelas</span>
        <SelectComponent
          small
          options={[...new Array(24)].map((_,index) => ({ label: index + 1, value: index + 1 }))}
          input={{
            value: newFieldsSize,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              if(~~e.target.value >= 0 && ~~e.target.value <= 24) {
                setNewFieldsSize(~~e.target.value)
              } else {
                setNewFieldsSize(0)
              }
            },
            onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
              if(~~e.target.value < 0 && ~~e.target.value > 24){
                e?.preventDefault()
              }
            }
          }}
        />
        <AddButton
          tooltip={addButtonTitle}
          disabled={disableAddButton}
          onClick={async () => {
            if (!disableAddButton) {
              handleAddInstallment();
            }
          }}
        />
      </div>
      <div
        css={css`
          display: ${is_installment_renegotiation ? `flex` : `none`};
          justify-content: start;
          align-items: center;
        `}
      >
        <span>Resetar parcelas</span>
        <TooltipButton
          tooltipProps={{
            title: 'Resetar Parcelas'
          }}
          Icon={Sync}
          iconProps={{
            style: { fontSize: '3vmax' }
          }}
          iconButtonProps={{
            onClick: handleResetInstallments
          }}
        />
      </div>{' '}
      {fields.map((invoice_renegotiation, index) => {
        return (
          <InvoiceRenegotiation
            all_invoices_invoice_items={all_invoices_invoice_items}
            all_invoices={all_invoices}
            edited={edited}
            expanded={expanded}
            fields={fields}
            handleChange={handleChange}
            index={index}
            invoice_renegotiation={invoice_renegotiation}
            is_installment_renegotiation={is_installment_renegotiation as boolean}
            isEditing={isEditing}
            key={invoice_renegotiation}
            onChangeCharge={onChangeCharge}
            representativeOptions={representativeOptions}
            setEdited={setEdited}
            setIsEditing={setIsEditing}
            {...rest}
          />
        );
      })}
      <div>
        <InvoiceValue
          values={[
            { label: 'Cobrança inicial', value: toFixedNumber(initial_charge) },
            { label: 'Desconto condicional total inicial', value: toFixedNumber(initial_conditional_charge) },
            { label: 'Cobrança total inserida', value: toFixedNumber(total_current_charge) },
            { label: 'Desconto condicional total inserido', value: toFixedNumber(total_current_conditional_charge) },
          ]}
        />
      </div>
    </div>
  );
};

const renderInvoicesAttributes = (props: CustomRenderInvoiceAttributes) => {
  const {
    representativeOptions,
    fields,
    initial_invoice_renegotiations_attributes,
    initial_values,
    set_initial_values,
    ...rest
  } = props;

  return (
    <>
      {fields.map((invoice, index) => {
        const currentInvoice = fields.get(index) as InvoiceFormAttributes;
        const handleClearBaseValues = (key: keyof InvoiceItemBaseValues, invoice_item_ids: string[]) => {
          if (initial_values && set_initial_values) {
            const new_renegotiation_form_attributes: RenegotiationFormAttributes = {
              ...initial_values,
              invoice_reference: {
                ...initial_values.invoice_reference,
                invoice_items_attributes: initial_values.invoice_reference?.invoice_items_attributes.map(
                  (invoice_item) => {
                    const base_values = invoice_item.base_values as InvoiceItemBaseValues;
                    const new_invoice_item_values = {
                      ...invoice_item,
                      base_values: {
                        ...base_values,
                        [key]: 0,
                      },
                    };
                    if (invoice_item_ids?.includes(invoice_item.parent_id?.toString() as string)) {
                      return new_invoice_item_values;
                    }
                    return invoice_item;
                  },
                ),
              } as InvoiceFormAttributes,
              invoices_attributes: initial_values.invoices_attributes.map((invoice, other_index) => {
                if (other_index !== index) {
                  return invoice;
                } else {
                  const newInvoiceItemAtributes = currentInvoice.invoice_items_attributes.map((invoice_item) => {
                    const base_values = invoice_item.base_values as InvoiceItemBaseValues;
                    const new_invoice_item_values = {
                      ...invoice_item,
                      base_values: {
                        ...base_values,
                        [key]: 0,
                      },
                    };
                    if (invoice_item_ids?.includes(invoice_item.id as string)) {
                      return new_invoice_item_values;
                    }
                    return invoice_item;
                  });
                  return {
                    ...invoice,
                    invoice_items_attributes: newInvoiceItemAtributes,
                  };
                }
              }),
              invoice_renegotiations_attributes: initial_invoice_renegotiations_attributes.map(
                (invoice_renegotiation) => {
                  return {
                    ...invoice_renegotiation,
                    invoice_attributes: {
                      ...invoice_renegotiation.invoice_attributes,
                      invoice_items_attributes: invoice_renegotiation.invoice_attributes.invoice_items_attributes.map(
                        (invoice_item) => {
                          const base_values = invoice_item.base_values as InvoiceItemBaseValues;
                          const new_invoice_item_values = {
                            ...invoice_item,
                            base_values: {
                              ...base_values,
                              [key]: 0,
                            },
                          };
                          if (invoice_item_ids?.includes(invoice_item.parent_id?.toString() as string)) {
                            return new_invoice_item_values;
                          }
                          return invoice_item;
                        },
                      ),
                    },
                  };
                },
              ),
            };
            set_initial_values(new_renegotiation_form_attributes);
          }
        };

        return (
          <Accordion key={invoice} css={accordionCss}>
            <AccordionSummary css={accordionSummaryCss}>
              <div css={accordionSummaryCss}>
                <ExpandMore />
                <span>{currentInvoice.code}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails css={accordionDetailsCss}>
              <FormSection name={invoice}>
                <InvoiceForm
                  invoice_form_values={currentInvoice}
                  is_renegotiation
                  allow_remove_values
                  representativeOptions={representativeOptions}
                  handleClearBaseValues={handleClearBaseValues}
                  {...rest}
                />
              </FormSection>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

const RenegotiationFormBody = ({
  handleSubmit,
  handleTabChange: handleExternalTabChange,
  isFormValid,
  onSubmit,
  representativeOptions,
  is_installment_renegotiation = false,
  initialValues,
  setInitialValues,
  setLoading,
  wallets,
}: {
  handleSubmit: (arg: () => void) => any;
  handleTabChange: (_: any, newValue: string) => void;
  isFormValid: boolean;
  onSubmit: () => void;
  representativeOptions: DefaultOptionType[];
  is_installment_renegotiation: boolean;
  initialValues: RenegotiationFormAttributes;
  setInitialValues: React.Dispatch<React.SetStateAction<RenegotiationFormAttributes | null>>;
  wallets: WalletAttributes[]
  setLoading: (value: boolean) => Promise<void>
}) => {
  const cancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleExternalTabChange(null, 'all_invoices');
  };

  const [tab, setTab] = React.useState('old_invoices');
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const firstTabLabel = 'Fatura(s) a ser(em) renegociada(s)';
  const secondTabLabel = 'Fatura(s) resultante(s)';
  return (
    <form>
      <Tabs css={tabStyle} value={tab} onChange={handleTabChange}>
        <Tab label={firstTabLabel} value={'old_invoices'} />
        <Tab label={secondTabLabel} value={'new_invoices'} />
      </Tabs>
      <TabPanel style={{ backgroundColor: colors.grayBlue }} value={tab} index={'old_invoices'}>
        <FieldArray
          name={'invoices_attributes'}
          representativeOptions={representativeOptions}
          component={renderInvoicesAttributes}
          is_installment_renegotiation={is_installment_renegotiation}
          initial_values={initialValues}
          initial_invoice_renegotiations_attributes={initialValues.invoice_renegotiations_attributes}
          set_initial_values={setInitialValues}
        />
      </TabPanel>
      <TabPanel style={{ backgroundColor: colors.grayBlue }} value={tab} index={'new_invoices'}>
        <Field
          name='cancel_when_expired'
          component={CheckboxComponent}
          placeholder={'Cancelar renegociação se alguma fatura vencer'}
        />
        <FieldArray
          initial_invoice_renegotiations_attributes={initialValues.invoice_renegotiations_attributes}
          initial_invoice_reference={initialValues.invoice_reference}
          name={'invoice_renegotiations_attributes'}
          representativeOptions={representativeOptions}
          component={renderInvoiceRenegotiationsAttributes}
          is_installment_renegotiation={is_installment_renegotiation}
          validate={[validation.validInvoiceRenegotiation]}
          wallets={wallets}
          setLoading={setLoading}
        />
      </TabPanel>
      <div style={{ display: 'flex', justifyContent: 'end', gap: '1vmax', marginTop: '1vmax' }}>
        <button onClick={cancelClick} className='small red'>
          <span>Cancelar</span>
        </button>
        <button type='submit' disabled={!isFormValid} onClick={handleSubmit(onSubmit)} className='small blue'>
          <span>Salvar</span>
        </button>
      </div>
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: 'renegotiationForm',
    enableReinitialize: true,
  }),
)(RenegotiationFormBody);
