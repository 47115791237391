/* eslint-disable camelcase */

import React from 'react';
import {
  colors,
  classTimeKindOptions,
  ClassTimeKindEnum,
  RoomStudentAttributes,
  StudentAbsenceAttributes,
  onConfirm,
  ClassTimeDisplayTableAttributes,
  ClassTimeSubjectAttributes,
  CompanyAttributes,
  TeacherAttributes,
  Role,
  ClassTimeAttributes,
  DefaultOptionType,
  profiles,
  RoomStudentRoomStatusEnum,
} from '../../utils/constants';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { FaListCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { error, success, warning } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { FETCH_CLASS_TIMES, UPDATE_CLASS_TIME } from '../../store/class_times';
import { endOfDay, format, isAfter, isBefore, isFuture, isPast, isSameDay, isValid, parseISO, startOfDay } from 'date-fns';
import { css } from '@emotion/react';
import { capitalize, filter, find, flatMap, flatten, head, isEmpty, map, orderBy, range, sample, size, uniq } from 'lodash';
import { ClassTimeSubjectJson } from '../../store/class_time_subjects';
import { TeacherClassTimeJson } from '../../store/teacher_class_times';
import DatePickerComponent from '../input/form/datepicker';
import { RoomClassTimeJson } from '../../store/room_class_times';
import Tab from '@mui/material/Tab';
import { Checkbox, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip } from '@mui/material';
import {
  CREATE_STUDENT_ABSENCE,
  DELETE_STUDENT_ABSENCE,
  StudentAbsenceJson,
  UPDATE_STUDENT_ABSENCE,
} from '../../store/student_absences';
import { IconModal } from '../modal/Modal';
import { Visibility } from '@mui/icons-material';
import TextAreaComponent from '../input/form/textarea';
import ClassPlanFormContainer from '../../containers/ClassPlanFormContainer';
import { RootState } from '../../store/configureStore';
import SelectComponent from '../input/form/select';
import { FETCH_ABSENCE_JUSTIFICATIONS } from '../../store/absence_justifications';
import TabPanel from '../shared/TabPanel';
import ClassTimeLogTable from './ClassTimeLogTable';
import { Document, StyleSheet, View, Page, Image, Text, pdf } from '@react-pdf/renderer';
import { parseText } from '../../utils/functions';
import TooltipButton from '../shared/TooltipButton';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 16,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  student_name: {
    fontSize: 8,
  },
  sub_header: {
    fontSize: 10,
  },
  bottom_line: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  footer_text: {
    borderTopColor: 'black',
    borderTopWidth: 1,
    fontSize: 10,
  },
  list_headers: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    fontSize: 8,
  },
});

const TableCss = css`
  margin-bottom: 15%;
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const GridCss = css`
  display: grid;
  grid-template-columns: 3.5% 13.78% 13.78% 13.78% 13.78% 13.78% 13.78% 13.78%;
  text-align: center;
  padding-right: 1rem;
`;

const ClassTimeGridCss = css`
  overflow: auto;
  max-height: 40cqh;
  ${GridCss}
  padding-right: unset;
`;

const DateControlCss = css`
  display: flex;
  justify-content: space-between;
  & .date-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 1rem;
    & svg {
      font-size: 2rem;
    }
    &:hover {
      color: ${colors.darkGrayBlue};
    }
  }
`;

const TabCss = css`
  & .Mui-selected {
    background: #fdfeff;
    border-radius: 1rem 1rem 0 0;
  }
`;
const pixelMinutesMultiplier = 3;
const addWeeksToDate = (dateObj: Date, numberOfWeeks: number) => {
  dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
  return dateObj;
};

function getMinutesBetweenDates(startDate: Date, endDate: Date) {
  const diff = endDate.getTime() - startDate.getTime();

  return diff / 60000;
}
const getClassTimeIdentifier = (class_time: ClassTimeDisplayTableAttributes, current_teacher_id = '') => {
  const date_identifier = format(parseISO(class_time.starts_at), 'dd/MM/yyyy');
  const room_class_times = class_time.room_class_times_attributes;
  const class_time_subjects = class_time.class_time_subjects_attributes;
  const room_class_time_identifier = room_class_times
    .map((item) => ~~item.room_id)
    .sort((a, b) => a - b)
    .join('/');
  const class_time_subject_identifier = class_time_subjects
    .sort((a, b) => a.curriculum_subject_id - b.curriculum_subject_id)
    .map((cts) => {
      const tct_identifier = map(cts.teacher_class_times_attributes, (item) => item.teacher_id)
        .filter((item) => {
          if (current_teacher_id) {
            return item === ~~current_teacher_id;
          }
          return item;
        })
        .sort((a, b) => a - b)
        .join('/');
      return tct_identifier;
    })
    .join('-');
  return [date_identifier, room_class_time_identifier, class_time_subject_identifier].join('-');
};

const JustificationForm = (props: { class_time: ClassTimeAttributes; student_absence: StudentAbsenceAttributes }) => {
  const { student_absence, class_time } = props;
  const [justificationOptions, setJustificationOptions] = React.useState<DefaultOptionType[]>([]);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();

  const fetchAbsenceJustificationOptions = React.useCallback(async () => {
    try {
      setLoading(true);
      const absence_justifications = await dispatch(
        FETCH_ABSENCE_JUSTIFICATIONS.request({
          params: {
            filters: {
              'q[company_id_eq]': class_time.company_id.toString(),
              'page[size]': '30',
            },
          },
        }),
      );
      if (!isEmpty(absence_justifications)) {
        const {
          data: { data },
        } = absence_justifications;
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id,
          };
        });
        setJustificationOptions(formattedData);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar opções de status',
        }),
      );
    }
  }, [class_time]);
  const init = async () => {
    await fetchAbsenceJustificationOptions();
  };
  const JustificationFormCss = css`
    ${`#absence-justification-${student_absence.id} { min-width: 50% }`}
  `;
  React.useEffect(() => {
    init();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <div css={JustificationFormCss}>
      <span>Comentários</span>
      <TextAreaComponent
        input={{
          defaultValue: student_absence?.justification,
          name: `${student_absence.id}-student-absence`,
        }}
      />
      <span>Tipo de justificativa</span>
      <SelectComponent
        placeholder='Selecionar Tipo de justificativa'
        small
        input={{
          label: 'Tipo de Justificativa',
          placeholder: 'Selecionar Tipo de justificativa',
          name: `absence-justification-${student_absence?.id}`,
          ...(student_absence?.absence_justification_id
            ? { defaultValue: student_absence?.absence_justification_id }
            : {}),
        }}
        options={justificationOptions}
      />
    </div>
  );
};

interface StateCompanies extends CompanyAttributes {
  teachers: TeacherAttributes[];
}

const DayClassTimes = (props: {
  class_times: ClassTimeDisplayTableAttributes[];
  setSelectedClassTimes: React.Dispatch<React.SetStateAction<string[]>>;
  setStudentAbsencesMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClassTimes: string[];
  handle_close: () => void;
}) => {
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    account: { companies },
  } = state;
  const current_teacher = find(
    find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers,
    (t) => t.teacher_company_id.toString() === company.toString(),
  );

  const { class_times, setSelectedClassTimes, setStudentAbsencesMode, handle_close, selectedClassTimes } = props;
  const earliest_class_time = head(orderBy(flatten(class_times), (i) => i.starts_at, 'asc'))?.starts_at as string;
  const latest_class_time = head(orderBy(flatten(class_times), (i) => i.ends_at, 'desc'))?.ends_at as string;
  const difference = getMinutesBetweenDates(new Date(earliest_class_time), new Date(latest_class_time));
  const DayClassTimeCss = css`
    position: relative;
    height: ${difference * pixelMinutesMultiplier}px;
    border: 1px solid white;
    display: grid;
  `;
  return (
    <div css={DayClassTimeCss}>
      {class_times.map((class_time, index) => {
        const starts_at_minutes_after_midnight = getMinutesBetweenDates(
          new Date(earliest_class_time),
          new Date(class_time.starts_at),
        );
        const ends_at_minutes_after_midnight = getMinutesBetweenDates(
          new Date(earliest_class_time),
          new Date(class_time.ends_at),
        );

        const difference = ends_at_minutes_after_midnight - starts_at_minutes_after_midnight;
        const kindOption = classTimeKindOptions.find((opt) => opt.value === class_time.kind);
        const room_class_times = map(class_time.room_class_times_attributes, (item) => item.room_name);
        const teacher_ids = flatten(
          map(class_time.class_time_subjects_attributes, (cts) =>
            map(cts.teacher_class_times_attributes.map((tct) => tct.teacher_id)),
          ),
        );
        const current_teacher_id = profile.role === Role.TEACHER && current_teacher && ~~current_teacher.id;
        const is_teacher_class_time = current_teacher_id && teacher_ids.includes(current_teacher_id);
        const class_time_identifier = getClassTimeIdentifier(class_time, current_teacher_id?.toString());
        const now_is_after_class_start = isPast(new Date(class_time.starts_at));
        const enable_click =
          (profile.role === Role.TEACHER ? current_teacher && is_teacher_class_time : true) &&
          class_time.kind === ClassTimeKindEnum.LECTURE;
        const hover_css = css`
          cursor: pointer;
          &:hover {
            transform: translateY(-0.1rem);
            box-shadow: 1px 6px 5px 0px rgba(0, 0, 0, 0.75);
          }
        `;
        const background_color =
          ClassTimeKindEnum.LECTURE === class_time.kind && class_time.pending && now_is_after_class_start
            ? colors.lightRed
            : colors.darkGrayBlue;
        const class_time_css = css`
          position: absolute;
          top: ${starts_at_minutes_after_midnight * pixelMinutesMultiplier}px;
          height: ${difference * pixelMinutesMultiplier}px;
          width: 100%;
          z-index: ${index};
          background: ${background_color};
          color: white;
          box-sizing: border-box;
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
          ${enable_click && hover_css}
        `;

        const onClickHandle = () => {
          if (enable_click) {
            setSelectedClassTimes((current) => {
              if (current.includes(class_time_identifier)) {
                return [];
              } else {
                return [class_time_identifier];
              }
            });
            if (!selectedClassTimes.includes(class_time_identifier)) {
              setStudentAbsencesMode(true);
            } else {
              handle_close();
            }
          }
        };
        return (
          <Tooltip
            key={class_time.id}
            disableHoverListener={!enable_click}
            title='Clique para marcar faltas e/ou ajustar plano de aula'
          >
            <div onClick={onClickHandle} className={class_time_identifier} css={class_time_css}>
              <span>
                {kindOption?.label}: {format(parseISO(class_time.starts_at), 'H:mm')} -{' '}
                {format(parseISO(class_time.ends_at), 'H:mm')}
                <br />
                {map(class_time.class_time_subjects_attributes, (cts) => {
                  if (cts.teacher_class_times_attributes.length) {
                    return cts.teacher_class_times_attributes.map((tct) => (
                      <span key={tct.id}>
                        {[tct.subject_name, tct.teacher_name].join(' - ')}
                        <br />
                      </span>
                    ));
                  } else {
                    return (
                      <span>
                        {cts.subject_name}
                        <br />
                      </span>
                    );
                  }
                })}
                <br />
                {class_time.kind === ClassTimeKindEnum.LECTURE &&
                  map(room_class_times, (item) => (
                    <React.Fragment key={item}>
                      {item}
                      <br />
                    </React.Fragment>
                  ))}
              </span>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const getWeekDates = (date: Date) => {
  return Array.from(Array(7).keys()).map((idx) => {
    const d = new Date(date);
    d.setDate(d.getDate() - d.getDay() + idx);
    return d;
  });
};

const ClassTimesGrid = ({
  class_times,
  selectedClassTimes,
  setSelectedClassTimes,
  setStudentAbsencesMode,
  handle_close,
}: {
  class_times: ClassTimeDisplayTableAttributes[][];
  setSelectedClassTimes: React.Dispatch<React.SetStateAction<string[]>>;
  selectedClassTimes: string[];
  setStudentAbsencesMode: React.Dispatch<React.SetStateAction<boolean>>;
  handle_close: () => void;
}) => {
  const earliest_class_time = head(orderBy(flatten(class_times), (i) => i.starts_at, 'asc'))?.starts_at as string;
  const latest_class_time = head(orderBy(flatten(class_times), (i) => i.ends_at, 'desc'))?.ends_at as string;

  React.useEffect(() => {
    if (isValid(new Date(earliest_class_time))) {
      const element = document.querySelector('.class_time_grid') as Element;
      element.scrollTo(0, 0);
    }
  }, [class_times]);
  return (
    <>
      <div css={ClassTimeGridCss} className='class_time_grid'>
        <div style={{ display: 'grid', alignContent: 'baseline' }}>
          {Array.from(Array(24).keys())
            .filter((item) => {
              const first_hour = new Date(head(orderBy(flatten(class_times), (i) => new Date(i.starts_at).getHours(), 'asc'))?.starts_at as string).getHours();
              const last_hour = new Date(head(orderBy(flatten(class_times), (i) => new Date(i.ends_at).getHours(), 'desc'))?.ends_at as string).getHours();
              return item >= first_hour && item <= last_hour;
            })
            .map((hour, index, self) => {
              const is_last = index + 1 === size(self);
              let minutes = '00';
              if (index === 0) {
                minutes = new Date(earliest_class_time).getMinutes().toString();
              } else if (is_last) {
                const last_minutes = new Date(latest_class_time).getMinutes();
                if (last_minutes > 0) {
                  return [
                    <div
                      style={{ height: `${last_minutes * pixelMinutesMultiplier}px`, position: 'relative' }}
                      key={hour}
                    >
                      <span style={{ position: 'absolute', left: 0, right: 0, top: 0 }}>{hour}:00</span>
                    </div>,
                    <div style={{ height: 0, position: 'relative' }} key={hour}>
                      <span style={{ position: 'absolute', left: 0, right: 0, top: 0 }}>
                        {hour}:{last_minutes.toString().padStart(2, '0')}
                      </span>
                    </div>,
                  ];
                }
              }
              return (
                <div
                  style={{
                    ...(is_last
                      ? { height: '0px' }
                      : { height: `${pixelMinutesMultiplier * 60 - ~~minutes * pixelMinutesMultiplier}px` }),
                    position: 'relative',
                  }}
                  key={hour}
                >
                  <span style={{ position: 'absolute', left: 0, right: 0, top: 0 }}>
                    {hour}:{minutes.padStart(2, '0')}
                  </span>
                </div>
              );
            })
            .flat()}
        </div>
        {range(0, 7).map((n) => {
          return (
            <DayClassTimes
              class_times={class_times[n]}
              setStudentAbsencesMode={setStudentAbsencesMode}
              handle_close={handle_close}
              key={`class_time_grid-${n}`}
              selectedClassTimes={selectedClassTimes}
              setSelectedClassTimes={setSelectedClassTimes}
            />
          );
        })}
      </div>
    </>
  );
};

interface StudentAbsenceList extends RoomStudentAttributes {
  student_absences: StudentAbsenceAttributes[];
}

const StudentAbsencesList = (props: { class_times: ClassTimeDisplayTableAttributes[], teacher_id?: string }) => {
  const { class_times, teacher_id } = props;
  const dispatch = useDispatch();
  const [room_students, setRoomStudents] = React.useState<StudentAbsenceList[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingStudent, setLoadingStudent] = React.useState<string[]>([]);
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile, user },
    account: { companies },
  } = state;
  const earlier_class_time_start = head(orderBy(class_times, ['starts_at'], ['asc']))?.starts_at as string;

    const generateClassScoresAndAbsencesPdf = React.useCallback(async () => {
      try {
        setLoading(true)
        const class_time_sample = sample(class_times) as ClassTimeDisplayTableAttributes
        const company_id = class_time_sample.company_id
        const teacher_name = sample(sample(class_time_sample.class_time_subjects_attributes)?.teacher_class_times_attributes)?.teacher_name
        const rooms = uniq(flatMap(class_times, item => flatMap(item.room_class_times_attributes, rct => rct.room_name)))
        const current_company = find(
          companies,
          (co: CompanyAttributes) => co.id.toString() === company_id?.toString(),
        ) as CompanyAttributes;
        
        const student_name_width = size(rooms) > 1 ? 30 : 40
        const doc = (
          <Document>
            <Page size={'A4'} style={styles.page}>
              <View style={styles.header}>
                <Image src={current_company.logo_url} style={styles.logo} />
                <Text style={styles.title}>{current_company.name}</Text>
              </View>
              <Text style={{ ...styles.sub_header }}>{teacher_name}</Text>
              <Text style={{ ...styles.sub_header, ...styles.bottom_line }}>
                Salas: {rooms.join(', ')} | Data: {format(parseISO(class_time_sample.starts_at), 'dd-MM-yyyy')}
              </Text>
              <View style={styles.list_headers}>
                <Text style={{ width: '5%' }}>Nº</Text>
                <Text style={{ width: `${student_name_width}%`, justifyContent: 'center', alignItems: 'center' }}>Nome</Text>
                {size(rooms) > 1 ? <Text style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>Sala</Text> : null}
                {class_times.map((class_time) => {
              return (
                <View key={class_time.starts_at} style={{ width: `${50/size(class_times)}%`, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <Text>{format(parseISO(class_time.starts_at), 'H:mm')}</Text>
                  <Text>{format(parseISO(class_time.ends_at), 'H:mm')}</Text>
                </View>
                );
                })}
              </View>
              {room_students.map((item, index) => (
                <View
                  style={{ ...styles.bottom_line, flexDirection: 'row', justifyContent: 'space-between', fontSize: 8 }}
                  key={item.id}
                >
                  <Text style={{ width: '5%' }}>{index + 1}</Text>
                  <Text style={{ width: `${student_name_width}%`, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    {parseText(item.student_name, 35)}
                  </Text>
                  {size(rooms) > 1 ? <Text style={{ width: '10%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    {item.room_name}
                  </Text> : null}
                  <Text style={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}> </Text>
                </View>
              ))}
              <Text style={styles.footer_text} fixed>
                Emitido em {format(new Date(), 'dd-MM-yyyy H:mm')}
              </Text>
            </Page>
          </Document>
        );
  
        const asPdf = pdf(doc); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const pdfBlob = await asPdf.toBlob();
        const url = URL.createObjectURL(pdfBlob);
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.download = `Diário de classe - ${rooms.join('-')} - ${format(parseISO(class_time_sample.starts_at), 'dd-MM-yyyy')}.pdf`;
        document.body.appendChild(aTag);
        aTag.click();
        setLoading(false)
        aTag.remove()
      } catch (err) {
        setLoading(false)
        console.error(err);
        dispatch(warning({ message: 'Erro ao gerar diário de classe' }));
      }
    }, [class_times, room_students, companies]);


  const fetchStudents = React.useCallback(async () => {
    try {
      setLoading(true);
      const class_time_ids = class_times.map((item) => item.id);
      const class_times_response = await dispatch(
        FETCH_CLASS_TIMES.request({
          params: {
            filters: {
              'q[id_in]': class_time_ids,
              include: ['room_students', 'student_absences'],
            },
          },
        }),
      );
      const {
        data: { included },
      } = class_times_response;
      const earliest_class_time = head(orderBy(flatten(class_times), (i) => i.starts_at, 'asc'))?.starts_at as string;
      const new_room_students = orderBy(
        map(
          filter(
            included,
            (incl) => {
              if(incl.type === 'room_students') {
                const is_after_start = isAfter(new Date(earliest_class_time), new Date(incl.attributes.starts_at))
                const is_before_end = isBefore(new Date(earliest_class_time), new Date(incl.attributes.ends_at))
                const is_same = isSameDay(new Date(incl.attributes.starts_at), new Date(earliest_class_time))
                return incl.attributes.room_status !== RoomStudentRoomStatusEnum.PRE_ENROLLED && (is_same || (is_after_start && is_before_end))
              }
              return false
            }
          ),
          (nrs) => {
            const student_absences = filter(
              included,
              (incl) => incl.type === 'student_absences' && incl.attributes.room_student_id === ~~nrs.id,
            ) as StudentAbsenceJson[];
            return {
              id: nrs.id,
              ...nrs.attributes,
              student_absences: student_absences.map((item) => ({ id: item.id, ...item.attributes })),
            };
          },
        ),
        'student_name',
      ) as StudentAbsenceList[];
      setRoomStudents(new_room_students);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar alunos',
        }),
      );
      setLoading(false);
    }
  }, [class_times]);

  const updateStudentAbsence = React.useCallback(
    async (props: {
      student_absence_id: string;
      justification: string;
      room_student_id: number;
      absence_justification_id?: string;
    }) => {
      try {
        const { student_absence_id, justification, room_student_id, absence_justification_id } = props;
        setLoadingStudent((current) => {
          return current.concat(room_student_id.toString());
        });
        const response = await dispatch(
          UPDATE_STUDENT_ABSENCE.request({
            id: student_absence_id,
            data: {
              justification,
              ...(absence_justification_id && { absence_justification_id }),
            },
          }),
        );
        const {
          data: { data },
        } = response;
        setRoomStudents((current) =>
          current.map((rts) => {
            if (~~rts.id === room_student_id) {
              return {
                ...rts,
                student_absences: rts.student_absences.map((st) => {
                  if (st.id === student_absence_id) {
                    return {
                      id: data.id,
                      ...data.attributes,
                    };
                  }
                  return st;
                }),
              };
            }
            return rts;
          }),
        );
        setLoadingStudent((current) => {
          return current.filter((item) => item !== room_student_id.toString());
        });
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao atualizar justificativa',
          }),
        );
      }
    },
    [setRoomStudents, room_students],
  );

  const createStudentAbsence = React.useCallback(
    async (props: { class_time_id: string; room_student_id: number }) => {
      try {
        const { class_time_id, room_student_id } = props;
        setLoadingStudent((current) => {
          return current.concat(room_student_id.toString());
        });
        const response = await dispatch(
          CREATE_STUDENT_ABSENCE.request({
            data: {
              class_time_id,
              room_student_id,
            },
          }),
        );
        const {
          data: { data },
        } = response;
        setRoomStudents((current) =>
          current.map((rts) => {
            if (~~rts.id === room_student_id) {
              return {
                ...rts,
                student_absences: rts.student_absences.concat([
                  {
                    id: data.id,
                    ...data.attributes,
                  },
                ]),
              };
            }
            return rts;
          }),
        );
        setLoadingStudent((current) => {
          return current.filter((item) => item !== room_student_id.toString());
        });
        dispatch(
          success({
            title: 'Falta',
            message: 'Falta Adicionada com sucesso!',
          }),
        );
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao criar falta',
          }),
        );
      }
    },
    [setRoomStudents, room_students],
  );

  const deleteStudentAbsence = React.useCallback(
    async (props: { student_absence_id: string; room_student_id: number }) => {
      try {
        const { student_absence_id, room_student_id } = props;
        setLoadingStudent((current) => {
          return current.concat(room_student_id.toString());
        });
        await dispatch(
          DELETE_STUDENT_ABSENCE.request({
            id: student_absence_id,
          }),
        );
        setRoomStudents((current) =>
          current.map((rts) => {
            if (~~rts.id === room_student_id) {
              return {
                ...rts,
                student_absences: filter(rts.student_absences, (sa) => sa.id !== student_absence_id),
              };
            }
            return rts;
          }),
        );
        setLoadingStudent((current) => {
          return current.filter((item) => item !== room_student_id.toString());
        });
        dispatch(
          success({
            title: 'Falta',
            message: 'Falta Removida com sucesso!',
          }),
        );
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao remover falta',
          }),
        );
      }
    },
    [setRoomStudents, room_students],
  );

  const updatePendingStatus = React.useCallback(async () => {
    if (
      earlier_class_time_start &&
      [Role.COORDINATOR, Role.TEACHER].includes(profile.role) &&
      isPast(new Date(earlier_class_time_start))
    ) {
      const profile_label = profiles.find((item) => item.name === profile.role)?.label;
      setLoading(true);
      for (const class_time of class_times.filter((item) => item.pending)) {
        try {
          await dispatch(
            UPDATE_CLASS_TIME.request({
              id: class_time.id,
              data: {
                pending: false,
                audit_comment: `Status de pendente removido após o(a) ${profile_label}(a) ${user.name} ter aberto a lista de frequência do horário`,
              },
            }),
          );
          dispatch(
            warning({
              message: 'Frequência visualizada e pendência retirada',
            }),
          );
        } catch (err) {
          dispatch(
            error({
              message: 'Erro ao atualizar pendência do horário',
            }),
          );
        }
      }
      setLoading(false);
    }
  }, [earlier_class_time_start]);

  const init = async () => {
    await updatePendingStatus();
    await fetchStudents();
  };

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const element = document.querySelector('#class_time_grid') as Element;
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [class_times]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <span className='subtitle-one'>Frequência</span>
      <TooltipButton
        tooltipProps={{
          title: 'Gerar Diário de Classe',
          style: {
            width: 'fit-content',
            display: !isEmpty(teacher_id) && [
              Role.SUPERUSER,
              Role.PEDAGOGICAL_ADMIN,
              Role.SCHOOL_MANAGER,
              Role.COORDINATOR,
            ].includes(profile.role)
              ? 'inline-flex'
              : 'none',
          },
        }}
        Icon={FaListCheck}
        iconButtonProps={{
          onClick: () => generateClassScoresAndAbsencesPdf(),
        }}
      />
      <Table id='student-absence-list' css={TableCss} size='small' aria-label='student-absence-list'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Nome</TableCell>
            <TableCell align='center'>Sala</TableCell>
            {class_times.map((class_time) => {
              return (
                <TableCell align='center' key={class_time.id}>
                  <Tooltip
                    title={class_time.class_time_subjects_attributes.map((item) => item.subject_name).join(', ')}
                  >
                    <span>
                      {[
                        format(parseISO(class_time.starts_at), 'H:mm'),
                        format(parseISO(class_time.ends_at), 'H:mm'),
                      ].join('-')}
                    </span>
                  </Tooltip>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {room_students.map((room_student) => {
            return (
              <React.Fragment key={room_student.id}>
                <TableRow>
                  <TableCell align='left'>{room_student.student_name}</TableCell>
                  <TableCell align='center'>{room_student.room_name}</TableCell>
                  {class_times.map((class_time) => {
                    const student_absence_for_this_class_time = find(
                      room_student.student_absences,
                      (sa) => sa.room_student_id === ~~room_student.id && ~~sa.class_time_id === ~~class_time.id,
                    );
                    const disable_checkbox = isFuture(new Date(earlier_class_time_start));
                    const checked = isEmpty(student_absence_for_this_class_time);
                    const onChangeHandle = async (e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!e.target.checked) {
                        await createStudentAbsence({
                          class_time_id: class_time.id,
                          room_student_id: ~~room_student.id,
                        });
                      } else if (e.target.checked && student_absence_for_this_class_time) {
                        await deleteStudentAbsence({
                          student_absence_id: student_absence_for_this_class_time.id,
                          room_student_id: ~~room_student.id,
                        });
                      }
                    };
                    const onConfirm: onConfirm = async (props) => {
                      const { setLoading, handleClose, setLoadingMessage } = props;
                      if (!isEmpty(student_absence_for_this_class_time) && student_absence_for_this_class_time) {
                        try {
                          setLoading(true);
                          setLoadingMessage('Salvando falta');
                          const text_area = document.querySelector(
                            `#input-${student_absence_for_this_class_time.id}-student-absence textarea`,
                          ) as React.HTMLProps<HTMLTextAreaElement>;

                          const absence_justification = document.querySelector(
                            `#input-absence-justification-${student_absence_for_this_class_time?.id} input`,
                          ) as HTMLInputElement;
                          const params = {
                            student_absence_id: student_absence_for_this_class_time?.id,
                            justification: text_area.value as string,
                            room_student_id: ~~room_student.id,
                            ...(absence_justification && absence_justification.value
                              ? { absence_justification_id: absence_justification.value }
                              : {}),
                          };
                          await updateStudentAbsence(params);
                          setLoading(false);
                          handleClose();
                        } catch (err) {
                          dispatch(
                            error({
                              message: 'Erro ao salvar falta',
                            }),
                          );
                          setLoading(false);
                          handleClose();
                        }
                      }
                    };
                    const with_absence_and_without_justification =
                      !isEmpty(student_absence_for_this_class_time) &&
                      !student_absence_for_this_class_time?.absence_justification_id;
                    const creditable_justificaton =
                      student_absence_for_this_class_time?.absence_justification_id &&
                      student_absence_for_this_class_time?.creditable_justification;
                    return (
                      <TableCell align='center' key={class_time.id}>
                        <Tooltip
                          disableInteractive={disable_checkbox}
                          disableHoverListener={disable_checkbox}
                          title={checked ? 'Adicionar Falta / Remover Presença' : 'Remover Falta / Adicionar Presença'}
                        >
                          <Checkbox
                            disabled={loadingStudent.includes(room_student.id) || disable_checkbox}
                            checked={checked}
                            onChange={onChangeHandle}
                          />
                        </Tooltip>
                        <IconModal
                          icon={Visibility}
                          iconProps={{
                            style: {
                              color: with_absence_and_without_justification
                                ? 'gray'
                                : creditable_justificaton
                                ? colors.green
                                : colors.lightRed,
                              display: isEmpty(student_absence_for_this_class_time) ? 'none' : 'block',
                            },
                          }}
                          disabled={disable_checkbox}
                          onConfirm={onConfirm}
                          title={'Justificativa da falta'}
                          tooltipText={'Ver Justificativa'}
                          confirmButtonText='Salvar'
                        >
                          <JustificationForm
                            class_time={class_time}
                            student_absence={student_absence_for_this_class_time as StudentAbsenceAttributes}
                          />
                        </IconModal>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const ClassTimeDisplayTable = ({ room_id, teacher_id }: { room_id?: string; teacher_id?: string }) => {
  const [loading, setLoading] = React.useState(true);
  const [class_times, setClassTimes] = React.useState<ClassTimeDisplayTableAttributes[][]>([]);
  const [dateFilterValue, setDateFilterValue] = React.useState<Date>(new Date());
  const [student_absences_mode, setStudentAbsencesMode] = React.useState(false);
  const [selectedClassTimes, setSelectedClassTimes] = React.useState<string[]>([]);
  const [tab, setTab] = React.useState('class_plan');
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    account: { companies },
  } = state;
  const current_teacher = find(
    find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers,
    (t) => t.teacher_company_id.toString() === company.toString(),
  );

  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const dispatch = useDispatch();
  const fetchClassTimes = React.useCallback(
    async (props: { date?: Date }) => {
      setSelectedClassTimes([]);
      const { date = dateFilterValue } = props;
      const week_dates = getWeekDates(date);
      setLoading(true);
      const final_data = [] as ClassTimeDisplayTableAttributes[][];
      try {
        for (const date of week_dates) {
          const starts_at_date = date.setHours(0, 0);
          const ends_at_date = date.setHours(23, 59);
          const use_teacher_id = profile.role === Role.TEACHER && current_teacher ? current_teacher.id : teacher_id
          const extra_params = {
            ...(room_id && { 'q[rooms_id_in]': room_id }),
            ...(use_teacher_id && { 'q[teachers_id_eq]': use_teacher_id }),
          };
          const response = await dispatch(
            FETCH_CLASS_TIMES.request({
              params: {
                filters: {
                  'q[starts_at_gteq]': startOfDay(new Date(starts_at_date)).toISOString(),
                  'q[ends_at_lteq]': endOfDay(new Date(ends_at_date)).toISOString(),
                  'page[size]': '30',
                  'q[active_eq]': 'true',
                  include: ['class_time_subjects', 'teacher_class_times', 'room_class_times'].join(','),
                  ...extra_params,
                },
              },
            }),
          );
          const {
            data: { data, included },
          } = response;

          const formatted_data = data.map((ct) => {
            const room_class_times_attributes = map(
              filter(
                included,
                (incl) => incl.type === 'room_class_times' && incl.attributes.class_time_id === ~~ct.id,
              ) as RoomClassTimeJson[],
              (rct) => ({
                id: rct.id,
                ...rct.attributes,
              }),
            );
            const class_time_subjects_attributes = map(
              filter(
                included,
                (cts) => cts.type === 'class_time_subjects' && cts.attributes.class_time_id === ~~ct.id,
              ) as ClassTimeSubjectJson[],
              (item) => {
                const teacher_class_times_attributes = map(
                  filter(
                    included,
                    (tct) => tct.type === 'teacher_class_times' && tct.attributes.class_time_subject_id === ~~item.id,
                  ) as TeacherClassTimeJson[],
                  (tct) => ({
                    id: tct.id,
                    ...tct.attributes,
                  }),
                );
                return {
                  id: item.id,
                  ...item.attributes,
                  teacher_class_times_attributes,
                };
              },
            );
            return { id: ct.id, ...ct.attributes, class_time_subjects_attributes, room_class_times_attributes };
          }) as ClassTimeDisplayTableAttributes[];
          final_data.push(formatted_data);
        }
        setClassTimes(final_data);
        setLoading(false);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar salas',
          }),
        );
        setLoading(false);
      }
    },
    [dateFilterValue, room_id, teacher_id],
  );

  const init = async () => {
    await fetchClassTimes({});
  };
  const week_dates = React.useCallback(() => getWeekDates(dateFilterValue), [dateFilterValue])();

  const setAndFetchClassTimes = React.useCallback(
    async (date: Date) => {
      setDateFilterValue(date);
      await fetchClassTimes({ date });
    },
    [setDateFilterValue],
  );

  const changeWeek = React.useCallback(
    async (value: number) => {
      const next_week = addWeeksToDate(dateFilterValue, value);
      setAndFetchClassTimes(next_week);
    },
    [dateFilterValue],
  );
  const initial_class_times = uniq(
    filter(flatten(class_times), (ct) => {
      const current_teacher_id = profile.role === Role.TEACHER && current_teacher && ~~current_teacher.id;
      const ct_identifier = getClassTimeIdentifier(ct, current_teacher_id?.toString());
      return selectedClassTimes.includes(ct_identifier) && ct.kind === ClassTimeKindEnum.LECTURE;
    }),
  );
  const handleSaveClassPlan = (class_time_subjects: ClassTimeSubjectAttributes[]) => {
    setClassTimes((current) => {
      return current.map((ct) => {
        return ct.map((ct) => {
          const class_time_subject = class_time_subjects.find((cts) => cts.class_time_id === ~~ct.id);
          if (class_time_subject) {
            return {
              ...ct,
              class_time_subjects_attributes: ct.class_time_subjects_attributes.map((cts) => {
                if (cts.id === class_time_subject.id) {
                  return {
                    ...cts,
                    ...class_time_subject,
                  };
                }
                return cts;
              }),
            };
          }
          return ct;
        });
      });
    });
  };

  const handleClose = async () => {
    setStudentAbsencesMode(false);
    setSelectedClassTimes([]);
    setTab('class_plan');
    await fetchClassTimes({});
  };

  React.useEffect(() => {
    init();
  }, []);

  if (loading || !tab) {
    return <Loading />;
  }
  return (
    <div>
      <Collapse
        style={{ marginBottom: '5%' }}
        in={student_absences_mode && !isEmpty(selectedClassTimes)}
        timeout='auto'
        unmountOnExit
      >
        <Tabs value={tab} css={TabCss} onChange={handleTabChange}>
          <Tab value={'class_plan'} label='Plano de Aula' />
          <Tab value={'frequency'} label='Frequência' />
          <Tab value={'class_time_log'} label='Histórico Alterações' />
        </Tabs>
        <TabPanel index={'class_plan'} value={tab}>
          <ClassPlanFormContainer
            class_times={initial_class_times}
            onSave={(data: ClassTimeSubjectAttributes[]) => handleSaveClassPlan(data)}
          />
        </TabPanel>
        <TabPanel index={'frequency'} value={tab}>
          <StudentAbsencesList class_times={initial_class_times} teacher_id={teacher_id} />
        </TabPanel>
        <TabPanel index={'class_time_log'} value={tab}>
          <ClassTimeLogTable class_times={initial_class_times} />
        </TabPanel>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={handleClose} className='red'>
            Fechar
          </button>
        </div>
      </Collapse>
      <div>
        <div css={DateControlCss}>
          <div className='date-button' role='button' onClick={() => changeWeek(-1)}>
            <FaArrowAltCircleLeft />
            <span>Semana Anterior</span>
          </div>
          <div>
            <span>Escolher semana de data específica</span>
            <DatePickerComponent
              small
              placeholder='Escolher data'
              datePickerProps={{
                allowSameDateSelection: true,
              }}
              input={{
                value: dateFilterValue,
                name: 'year',
                onChange: async (e: Date) => {
                  setAndFetchClassTimes(e);
                },
              }}
            />
          </div>
          <div className='date-button' role='button' onClick={() => changeWeek(1)}>
            <span>Próxima Semana</span>
            <FaArrowAltCircleRight />
          </div>
        </div>
        <div css={GridCss}>
          <div />
          {range(0, 7).map((n) => {
            return (
              <div style={{ display: 'grid' }} key={n}>
                <span>
                  <b>{capitalize(week_dates[n].toLocaleDateString('pt-BR', { month: 'long' }))}</b>
                </span>
                <h2>{week_dates[n].getDate()}</h2>
                <span>
                  <b>{capitalize(week_dates[n].toLocaleDateString('pt-BR', { weekday: 'long' }))}</b>
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <ClassTimesGrid
        selectedClassTimes={selectedClassTimes}
        setSelectedClassTimes={setSelectedClassTimes}
        setStudentAbsencesMode={setStudentAbsencesMode}
        handle_close={handleClose}
        class_times={class_times}
      />
    </div>
  );
};

export default ClassTimeDisplayTable;
