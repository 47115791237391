/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reduxForm,
  Field,
  reset,
  change,
  FormSection,
  FieldArray,
  WrappedFieldArrayProps,
  formValueSelector,
  getFormInitialValues,
} from 'redux-form';
import { compose } from 'redux';

import { Theme } from '@mui/system';

import withRaces from '../hoc/racesHoc';

import InputComponent from '../input/form/input';
import RadioComponent from '../input/form/radio';

import {
  colors,
  relationshipOptions,
  validation,
  ResponsibleStepFormData,
  UserOptionsData,
  CurrentResponsibleData,
  ResponsibleStepFormInitialValues,
  NestedRepresentativeAttributes,
  UserFormFields,
  ConnectionEnum,
  CurrentRegistrationData,
  RepresentativeFormUserAttributes,
} from '../../utils/constants';
import { convertToCNPJ, convertToCPF } from '../../utils/functions';
import UserForm from './UserForm';
import { get, isEmpty } from 'lodash';
import { RootState } from '../../store/configureStore';
import { css, useTheme } from '@emotion/react';
const form_name = 'registration-responsiblestep';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    formButtons: css`
      display: flex;
      justify-content: end;
      gap: 1rem;
      margin-top: 4.5rem;
    `,
    form: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 16px;
      display: grid;
      row-gap: 0.5rem;

      ${theme.breakpoints?.down('lg')} {
        padding: 1rem;
        row-gap: 0.25rem;
      }

      & .user-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 1rem;

        & > div {
          flex-basis: 100%;
        }

        & #input-nationality_country,
        & #input-nationality_state,
        & #input-nationality_city {
          flex-basis: 32.6666666%;
        }

        & #input-document_number,
        & #input-name,
        & #input-email,
        & div[id$="personal_email"] {
          flex-basis: 24.5%;
        }

        & #input-phone,
        & #input-gender,
        & #input-birthdate {
          flex-basis: 32.6666666%;
        }

        & #input-address_form {
          order: 11;

          & > div[id$="street"] {
            flex-basis: 40%;
          }

          & > div[id$="number"] {
            flex-basis: 24%;
          }

          & > div[id$="complement"] {
            flex-basis: 35%;
          }

          & > div[id$="neighbourhood"] {
            flex-basis: 30%;
          }

          & > div[id$="country_id"] {
            flex-basis: 20%;
          }

          & > div[id$="state_id"] {
            flex-basis: 25%;
          }

          & > div[id$="city_id"] {
            flex-basis: 22.5%;
          }
        }

        & .nationality-span {
          display: none;
        }
      }

      & hr {
        border-color: ${colors.grayBlue};
        width: 100%;
        margin: 2rem auto;

        ${theme.breakpoints?.down('lg')} {
          margin: auto;
        }
      }

      & .title {
        font-weight: bold;
        font-size: 1.75rem;
        justify-self: center;
        margin-bottom: 1rem;
      }

      & .sectionTitle {
        justify-self: flex-start;
        font-size: 1.75rem;
        margin: 1rem 0;

        ${theme.breakpoints?.down('lg')} {
          font-size: 1rem;
        }
      }

      & .zipcode {
        display: flex;
        max-width: 100%;
        width: 50rem;
        gap: 1rem;

        & > .search {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          cursor: pointer;
          margin-bottom: 1rem;
          border: none;
          gap: 1rem;
          width: fit-content;
          background: inherit;

          & > svg {
            font-size: 3rem;
            color: ${colors.darkBlue};

            &:hover {
              color: #5a5a72;
            }
          }
        }
      }

      & > .grid-6 {
        display: grid;
        grid-template-columns: 44% 20% 35%;
        justify-content: space-between;
      }

      & > .grid-7 {
        display: grid;
        grid-template-columns: 30% 24% 25% 20%;
        justify-content: space-between;
      }
    `,
    representativeFormContainer: css`
      display: grid;

      & .relationship-section {
        display: flex;
        align-items: center;
        gap: 1.5rem;
      }

      & .remaining-fields {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        justify-content: space-between;
      }
    `,
  };
};


function usePrevious(value: any) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface RepresentativesFormProps extends WrappedFieldArrayProps {
  insertStudentData: (
    connection: ConnectionEnum,
    previous_connection: ConnectionEnum | undefined,
    initial_connection: ConnectionEnum | undefined,
  ) => void;
}

const renderRepresentativesForm = (props: RepresentativesFormProps) => {
  const { fields, insertStudentData } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector(form_name);
  const representativeAttributes = formValues(state, 'accounts_attributes[0].representatives_attributes[0]');
  const initialValues = getFormInitialValues(form_name)(state) as RepresentativeFormUserAttributes;

  const initialRepresentativeAttributes = get(
    initialValues,
    'accounts_attributes[0].representatives_attributes[0]',
  ) as unknown as NestedRepresentativeAttributes;
  const connectionValue = representativeAttributes?.connection;
  const previousRelationship = usePrevious(connectionValue);
  if (fields.length === 0) {
    return null;
  }

  const handleConnectionChange = (value: ConnectionEnum) => {
    insertStudentData(value, previousRelationship, initialRepresentativeAttributes?.connection as ConnectionEnum);
  };

  return (
    <div>
      {fields.map((representative, index) => {
        const currentRepresentative = fields.get(index) as NestedRepresentativeAttributes;
        const currentConnectionValue = currentRepresentative.connection;
        return (
          <FormSection name={representative} key={representative}>
            <div css={classes.representativeFormContainer}>
              <div className='relationship-section'>
                {relationshipOptions.map((option) => {
                  return (
                    <div style={{ marginBottom: '14px' }} key={option.label}>
                      <Field
                        name='connection'
                        component={RadioComponent}
                        props={{ value: option.value }}
                        validate={[validation.required]}
                        onChange={() => {
                          handleConnectionChange(option.value);
                        }}
                      />
                      <span style={{ whiteSpace: 'nowrap' }}> {option.label} </span>
                    </div>
                  );
                })}
                <Field
                  label={'Descrição'}
                  placeholder={'Descrição do relacionamento'}
                  component={InputComponent}
                  name='connection_description'
                  validate={currentConnectionValue === 'other' ? [validation.required] : null}
                />
              </div>
              <div className='remaining-fields'>
                {currentConnectionValue === 'company' && (
                  <>
                    <Field
                      name='spokesperson'
                      placeholder={'Waliomar Bezerra'}
                      label={'Responsável *'}
                      component={InputComponent}
                      validate={[validation.required]}
                    />
                    <Field
                      name='occupation'
                      placeholder={'Cargo'}
                      label={'Cargo *'}
                      component={InputComponent}
                      validate={[validation.required]}
                    />
                  </>
                )}
              </div>
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};

interface AccountFormProps extends WrappedFieldArrayProps {
  insertStudentData: (
    connection: ConnectionEnum,
    previous_connection: ConnectionEnum | undefined,
    initial_connection: ConnectionEnum | undefined,
  ) => void;
}

const renderAccountForm = (props: AccountFormProps) => {
  const { fields, insertStudentData } = props;
  if (fields.length === 0) {
    return null;
  }
  return (
    <div>
      {fields.map((account_attribute) => {
        return (
          <FormSection name={account_attribute} key={account_attribute}>
            <FieldArray
              name='representatives_attributes'
              component={renderRepresentativesForm}
              insertStudentData={insertStudentData}
            />
          </FormSection>
        );
      })}
    </div>
  );
};

const ResponsibleForm = (props: {
  onSubmit: ({ data }: { data: ResponsibleStepFormData }) => void;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: (arg: (args: ResponsibleStepFormData) => void) => any;
  setCurrentUser: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: boolean;
  insertStudentAddress: () => {
    country_id: string;
    state_id: string;
    city_id: string;
  };
  setCurrentRepresentativeId: React.Dispatch<React.SetStateAction<string>>;
  setInitialValues: React.Dispatch<React.SetStateAction<ResponsibleStepFormInitialValues | null>>;
  defaultInitialValues: {
    country_code: {
      value: string;
      label: string;
      countryValue: string;
    };
    nationality_country: {
      phonecode: string;
      flag: string;
      label: string;
      value: string;
    };
    accounts_attributes: [];
  };
  currentResponsible: CurrentResponsibleData;
  connectionValue: string;
  isFormValid: boolean;
  handleSelectUser: (user_option: UserOptionsData) => Promise<void>
  currentRegistration: CurrentRegistrationData;
  insertStudentData: (
    connection: ConnectionEnum,
    previous_connection: ConnectionEnum | undefined,
    initial_connection: ConnectionEnum | undefined,
  ) => void;
  allowUserUpdate: boolean
}) => {
  const {
    setTabValue,
    onSubmit,
    handleSubmit,
    setCurrentUser,
    currentUser,
    insertStudentAddress,
    setCurrentRepresentativeId,
    setInitialValues,
    defaultInitialValues,
    currentResponsible,
    connectionValue,
    isFormValid,
    handleSelectUser,
    currentRegistration,
    insertStudentData,
    allowUserUpdate
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const company_id_to_use = currentRegistration.company_id;

  const userFormData = [
    'document_number',
    'name',
    'email',
    'additional_data.personal_email',
    'phone',
    'gender',
    'birthdate',
    'nationality_country',
    'nationality_state',
    'nationality_city',
  ] as UserFormFields[];
  const document_label = connectionValue === ConnectionEnum.COMPANY ? 'CNPJ' : 'CPF';
  const document_placeholder = connectionValue === ConnectionEnum.COMPANY ? 'CNPJ *' : 'CPF *';
  const document_number_format_function = connectionValue === ConnectionEnum.COMPANY ? convertToCNPJ : convertToCPF;

  const handleClearDocumentField = () => {
    if (currentUser) {
      dispatch(reset(form_name));
      setInitialValues(defaultInitialValues);
      setCurrentUser(false);
    } else {
      dispatch(change(form_name, 'document', null));
    }
  };

  return (
    <form css={classes.form}>
      <span className='title'> Cadastrar Responsável </span>
      <span className='sectionTitle'>Dados do Responsável</span>
      <FieldArray component={renderAccountForm} name='accounts_attributes' insertStudentData={insertStudentData} />
      <hr />
      <span className='sectionTitle'>Dados do Usuário</span>
      <div className='user-form'>
        <UserForm
          data={userFormData}
          form_name={form_name}
          document_number_format_function={document_number_format_function}
          handleClearDocumentField={handleClearDocumentField}
          handleSelectUser={handleSelectUser}
          company_id_to_use={company_id_to_use.toString()}
          disabled_document_number={!isEmpty(currentUser)}
          document_clearable={!currentResponsible}
          document_label={document_label}
          document_placeholder={document_placeholder}
          insertStudentAddress={insertStudentAddress}
          disable_all={!allowUserUpdate}
        />
      </div>
      <div css={classes.formButtons}>
        <button
          onClick={() => {
            setCurrentRepresentativeId('');
            setInitialValues(null);
            setTabValue(0);
          }}
          className='red small'
        >
          <span> Cancelar </span>
        </button>
        <button
          type='submit'
          disabled={!isFormValid}
          onClick={handleSubmit((values) => {
            onSubmit({
              data: values,
            });
          })}
          className='green small'
        >
          <span> Inserir </span>
        </button>
      </div>
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
  }),
  withRaces,
)(ResponsibleForm);
