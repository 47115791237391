/* eslint-disable camelcase */
import React from 'react';
import { Theme } from '@mui/system';

import { LoadingBackdrop } from '../../modal/Modal';
import {
  PaginationType,
  colors,
  DefaultOptionType,
  FormattedRegistrationViewRegData,
} from '../../../utils/constants';
import RegistrationsTable from '../../table/RegistrationsTable';
import RegistrationViewFilters from '../../filters/RegistrationViewFilters';
import SchoolExportListsComponent from '../../shared/SchoolExportListsComponent';
import { css, useTheme } from '@emotion/react';
import { IDefaultFilterValues } from '../../../containers/RegistrationsContainer';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    tabStyle: css`
      & .Mui-selected {
        background: #fdfeff;
        border-radius: 1rem 1rem 0 0;
      }
    `,
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableHeader: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    tableContainer: css`
      background: ${colors.lightBlue};
      padding: 1rem;
      border-radius: 4px;
      display: grid;
    `,
  };
};

interface IRegistrationsViewProps {
  filters: IDefaultFilterValues;
  setFilters: React.Dispatch<React.SetStateAction<IDefaultFilterValues>>
  defaultPagination: PaginationType;
  deleteRegistrationMethod: (id: string) => Promise<void>;
  fetchRegistrationsMethod: (filters: IDefaultFilterValues) => Promise<void>;
  handleChangePageSize: (e: any) => void;
  handlePageChange: (_: any, newPage: number) => void;
  pagination: PaginationType;
  registrations: FormattedRegistrationViewRegData[];
  renderReady: boolean;
  searchForOptions: DefaultOptionType[];
  studentStatusOptions: DefaultOptionType[];
}

const RegistrationsView: React.FC<IRegistrationsViewProps> = ({
  filters,
  setFilters,
  defaultPagination,
  deleteRegistrationMethod,
  fetchRegistrationsMethod,
  handleChangePageSize,
  handlePageChange,
  pagination,
  registrations,
  renderReady,
  searchForOptions,
  studentStatusOptions,
}) => {
  const classes = useStyles();

  return (
    <div css={classes.view}>
      <LoadingBackdrop open={!renderReady} />
      <div css={classes.tableHeader}>
        <span className='title'>Lista de Alunos</span>
      </div>

      <div css={classes.tableContainer} data-testid='registrations-table'>
        <RegistrationViewFilters
          filters={filters}
          setFilters={setFilters}
          searchForOptions={searchForOptions}
          studentStatusOptions={studentStatusOptions}
          fetchRegistrationsMethod={fetchRegistrationsMethod}
          pagination={pagination}
          defaultPagination={defaultPagination}
        />
        <RegistrationsTable
          registrations={registrations}
          handleChangePageSize={handleChangePageSize}
          handlePageChange={handlePageChange}
          pagination={pagination}
          deleteRegistrationMethod={deleteRegistrationMethod}
        />
      </div>
      <SchoolExportListsComponent is_registrations_view is_invoices_view={false} />
    </div>
  );
};

export default RegistrationsView;
