/* eslint-disable camelcase */
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import LockResetIcon from '@mui/icons-material/LockReset';
import TooltipButton from '../shared/TooltipButton';
import AccountFormContainer from '../../containers/AccountFormContainer'
import TablePaginationActions from '../shared/TablePaginationActions';

import { IconModal, LoadingBackdrop } from '../modal/Modal';

import {
  colors,
  profiles,
  FormattedUserData,
  PaginationType,
  ProfileTypes,
  Role,
  TransactionAttributes,
  defaultPagination,
} from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { Collapse } from '@mui/material';
import PenIcon from '../icon/PenIcon';
import { nameToInitials } from '../../utils/functions';
import { isNil, map, uniq } from 'lodash';
import { DELETE_USER, RECOVER_PASSWORD } from '../../store/users';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import TrashIcon from '../icon/TrashIcon';
import { History } from '@mui/icons-material';
import { FETCH_TRANSACTIONS } from '../../store/transactions';
import Loading from '../loading/Loading';
import TransactionsTable from '../table/TransactionsTable';
import DatePickerComponent from '../input/form/datepicker';
import { format } from 'date-fns';
import { css, useTheme } from '@emotion/react';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    tableHeader: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
    initials: css`
      background: lightblue;
      border-radius: 50%;
      display: flex;
      width: 2rem;
      height: 2rem;

      & > span {
        font-size: 1rem;
        margin: auto;
      }

      & > img {
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 50%;
      }
    `,
  };
};

const TransactionsRow = ({
  user,
  load_resources = false,
  loadingUsers,
  setLoadingUsers,
  open = false,
}: {
  user: FormattedUserData;
  loadingUsers: {
    id: string;
    message: string;
  }[];
  setLoadingUsers: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  load_resources?: boolean;
  open: boolean;
}) => {
  const initial_data = {
    transactions: [] as TransactionAttributes[],
  };
  const dispatch = useDispatch();
  const isLoading = loadingUsers.some((item) => item.id === user.id);
  const [data, setData] = React.useState(initial_data);
  const [loadingData, setLoadingData] = React.useState(load_resources);
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [fromEventDateFilterValue, setFromEventDateFilterValue] = React.useState<Date | null>(null);
  const [toEventDateFilterValue, settoEventDateFilterValue] = React.useState<Date | null>(null);
  const [descriptionFilterValue, setDescriptionFilterValue] = React.useState('');

  const fetchTransactions = React.useCallback(
    async ({
      newPagination = pagination,
      from_event_date_filter = fromEventDateFilterValue,
      to_event_date_filter = toEventDateFilterValue,
    }: {
      newPagination?: PaginationType;
      from_event_date_filter?: Date | null;
      to_event_date_filter?: Date | null;
    }) => {
      const user_id = user.id;
      try {
        setLoadingUsers((current) => current.concat({ id: user_id, message: 'Carregando histórico de transações' }));
        const event_date_filter_object = {
          ...(from_event_date_filter && {
            'q[event_date_gteq]': format(new Date(from_event_date_filter), 'yyyy-MM-dd'),
          }),
          ...(to_event_date_filter && {
            'q[event_date_lteq]': format(new Date(to_event_date_filter), 'yyyy-MM-dd'),
          }),
        };
        const response = await dispatch(
          FETCH_TRANSACTIONS.request({
            params: {
              filters: {
                'q[transactable_type_eq]': 'User',
                'q[transactable_id_eq]': user_id,
                'q[description_cont]': descriptionFilterValue,
                'q[s]': 'event_date desc',
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                ...event_date_filter_object,
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = response;
        const transactions = map(data, (item) => ({
          id: item.id,
          ...item.attributes,
        })) as TransactionAttributes[];
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setData({
          transactions,
        });
        dispatch(
          success({
            title: 'Transações',
            message: 'Transações carregadas!',
            autoDismiss: 3,
          }),
        );
        setLoadingUsers((current) => current.filter((item) => item.id !== user_id));
      } catch (err) {
        setLoadingUsers((current) => current.filter((item) => item.id !== user_id));
        dispatch(
          error({
            message: 'Erro no carregamento de transações',
          }),
        );
      }
      setLoadingData(false);
    },
    [user, pagination, descriptionFilterValue, toEventDateFilterValue, fromEventDateFilterValue],
  );

  const handlePageChange = (_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    fetchTransactions({
      newPagination: {
        ...pagination,
        pageNumber: newPage,
      },
    });
  };

  const handleChangePageSize = (e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value,
    });
    fetchTransactions({
      newPagination: {
        ...pagination,
        pageSize: e.target.value,
      },
    });
  };

  React.useEffect(() => {
    if (load_resources) {
      fetchTransactions({});
    }
  }, []);

  if (!open) {
    return null;
  }

  if (loadingData || isLoading) {
    return <Loading />;
  }

  if (data.transactions?.length === 0) {
    return (
      <div
        style={{
          background: '#fff',
          padding: '1rem 0',
          textAlign: 'center',
        }}
      >
        <p>Nenhum evento encontrado para este usuário.</p>
      </div>
    )
  }

  return (
    <div style={{ display: 'grid' }}>
      <div style={{ display: 'flex', margin: '1rem 0' }}>
        <InputComponent
          placeholder={`Buscar por descrição`}
          search
          small
          onSearch={() => {
            fetchTransactions({});
          }}
          input={{
            value: descriptionFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setDescriptionFilterValue(e.target.value);
            },
          }}
        />
        <DatePickerComponent
          small
          placeholder='Evento a partir de'
          datePickerProps={{
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            setFromEventDateFilterValue(null);
            fetchTransactions({
              from_event_date_filter: null,
            });
          }}
          input={{
            value: fromEventDateFilterValue,
            name: 'year',
            onChange: (e: Date) => {
              setFromEventDateFilterValue(e);
              fetchTransactions({
                from_event_date_filter: e,
              });
            },
          }}
        />
        <DatePickerComponent
          small
          placeholder='Evento até'
          datePickerProps={{
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            settoEventDateFilterValue(null);
            fetchTransactions({
              to_event_date_filter: null,
            });
          }}
          input={{
            value: toEventDateFilterValue,
            name: 'year',
            onChange: (e: Date) => {
              settoEventDateFilterValue(e);
              fetchTransactions({
                to_event_date_filter: e,
              });
            },
          }}
        />
      </div>
      <TransactionsTable
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        pagination={pagination}
        transactions={data.transactions}
      />
      ;
    </div>
  );
};

interface IUsersViewProps {
  renderReady: boolean;
  users: FormattedUserData[];
  pagination: PaginationType;
  defaultPagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilter: string;
  setNameFilter: React.Dispatch<React.SetStateAction<string>>;
  companyFilter: string;
  setCompanyFilter: React.Dispatch<React.SetStateAction<string>>;
  fetchUsersMethod: ({
    newPagination,
    oldPagination,
    profile,
    email,
  }: {
    newPagination?: PaginationType;
    oldPagination?: PaginationType;
    profile?: ProfileTypes | string;
    email?: string;
  }) => void;
  profileOptions:
  | {
    value: ProfileTypes;
    label: string | undefined;
  }[]
  | undefined;
  profileFilter: ProfileTypes | string;
  setProfileFilter: React.Dispatch<React.SetStateAction<ProfileTypes | string>>;
  profile: {
    id: string;
    label: string;
    role: ProfileTypes;
  };
  emailFilter: string;
  setEmailFilter: React.Dispatch<React.SetStateAction<string>>;
}

const UsersView: React.FC<IUsersViewProps> = ({
  renderReady,
  users,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilter,
  setNameFilter,
  companyFilter,
  setCompanyFilter,
  fetchUsersMethod,
  defaultPagination,
  profileOptions,
  profileFilter,
  setProfileFilter,
  profile,
  emailFilter,
  setEmailFilter,
}) => {
  const [expandedTransactions, setExpandedTransactions] = React.useState<number[]>([]);
  const [loadingUsers, setLoadingUsers] = React.useState<{ id: string; message: string }[]>([]);
  const [create, setCreate] = React.useState(false)
  const [editUser, setEditUser] = React.useState<string[]>([])

  const classes = useStyles();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const columns = isTabletOrMobile
    ? ['Usuário']
    : ['', 'Nome', 'Telefone', 'Email', 'Perfil', 'Tipo Doc.', 'N° Doc.', 'Estado', 'Cidade', 'Empresa'];

  const recoverPassword = React.useCallback(
    async (
      email: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          RECOVER_PASSWORD.request({
            data: {
              email,
            },
          }),
        );
        setLoading(false);
        handleClose();
      } catch (er) {
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro ao enviar email de redefinição de senha',
          }),
        );
      }
    },
    [],
  );

  const deleteUser = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_USER.request({
            id,
          }),
        );
        fetchUsersMethod({});
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro ao excluir usuário',
          }),
        );
      }
    },
    [users],
  );

  const init = async () => {
    await fetchUsersMethod({})
  }

  const handleOpenCreateForm = () => {
    setCreate(prevState => !prevState)
  }

  const handleCloseCreateForm = () => setCreate(false)

  const handleOpenEditForm = (id: string) => {
    setEditUser(prevState => {
      if (id && prevState.includes(id)) {
        return prevState.filter(item => item !== id)
      } else {
        return prevState.concat(id)
      }
    })
  }

  const handleCloseEditForm = (id: string) => {
    setEditUser(prevState => prevState.filter(item => item !== id))
  }

  const handleSaveUSer = async () => {
    setCreate(false)
    await init()
  }

  return (
    <div css={classes.view}>
      <LoadingBackdrop open={!renderReady} />
      <div css={classes.tableHeader}>
        <span className='title'>Lista de usuários</span>
      </div>
      <div css={classes.filters}>
        <InputComponent
          placeholder='Buscar por nome'
          small
          search
          onSearch={() => {
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: nameFilter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setNameFilter(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder='Buscar por email'
          small
          search
          onSearch={() => {
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: emailFilter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setEmailFilter(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder='Buscar por companhia'
          small
          search
          input={{
            value: companyFilter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyFilter(e.target.value);
            },
          }}
          onSearch={() => {
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
        />
        <SelectComponent
          placeholder='Buscar por perfil'
          small
          options={profileOptions}
          clearable
          clearFieldFunction={() => {
            setProfileFilter('');
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
              profile: '',
            });
          }}
          input={{
            value: profileFilter,
            onChange: (e: any) => {
              let value = '';
              if (e?.target?.value) {
                value = e.target.value;
              }
              setProfileFilter(value);
              fetchUsersMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                profile: value,
              });
            },
          }}
        />
      </div>
      <hr style={{ color: colors.darkGrayBlue, width: '100%' }} />
      <div css={classes.table}>
        <TableContainer component={Paper}>
          <Table data-testid='users-table'>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
                <TableCell align='right'>
                  <button
                    className='green small'
                    onClick={handleOpenCreateForm}
                    style={{ whiteSpace: 'nowrap' }}
                    disabled={editUser.length > 0}
                  >
                    <span>Criar usuário</span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <div>
                      <AccountFormContainer
                        close_form={handleCloseCreateForm}
                        onSave={handleSaveUSer}
                      />
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
              {users.map((row, index) => {
                const {
                  name,
                  phone,
                  email,
                  profiles: userProfiles,
                  document_type,
                  document_number,
                  state,
                  city,
                  company,
                  avatar_url,
                } = row;
                const isLast = index === users.length - 1;
                const profilesInfo = uniq(
                  userProfiles?.map((item) => {
                    return profiles.find((profile) => profile.name === item)?.label;
                  }),
                ).join(', ');
                const transactionsExpanded = expandedTransactions.includes(~~row.id);

                return (
                  <>
                    <TableRow key={row.id}>
                      {isTabletOrMobile ? (
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              gap: '0.5rem',
                              flexWrap: 'wrap',
                            }}
                          >
                            {name && (
                              <span>
                                <strong>Nome:</strong> {name}
                              </span>
                            )}
                            {phone && (
                              <span>
                                <strong>Telefone:</strong> {phone && phone.includes('undefined') ? '' : phone}
                              </span>
                            )}
                            {email && (
                              <span>
                                <strong>Email:</strong> {email}
                              </span>
                            )}
                            {profiles && profiles.length && (
                              <span>
                                <strong>Perfil:</strong>
                                {profilesInfo}
                              </span>
                            )}
                            {profiles && (
                              <span>
                                <strong>Documento:</strong>
                                {document_type} {document_number}
                              </span>
                            )}
                            {state && (
                              <span>
                                <strong>Estado:</strong> {state}
                              </span>
                            )}
                            {city && (
                              <span>
                                <strong>Cidade:</strong> {city}
                              </span>
                            )}
                            {company && (
                              <span>
                                <strong>Companhia:</strong> {company}
                              </span>
                            )}
                          </div>
                        </TableCell>
                      ) : (
                        <>
                          <TableCell>
                            <div css={classes.initials}>
                              {avatar_url ? <img alt={name} src={avatar_url} /> : <span> {nameToInitials(name)} </span>}
                            </div>
                          </TableCell>
                          <TableCell>{name}</TableCell>
                          <TableCell>{phone && phone.includes('undefined') ? '' : phone}</TableCell>
                          <TableCell>{email}</TableCell>
                          <TableCell>{profilesInfo}</TableCell>
                          <TableCell>{document_type}</TableCell>
                          <TableCell>{document_number}</TableCell>
                          <TableCell>{state}</TableCell>
                          <TableCell>{city}</TableCell>
                          <TableCell>{company}</TableCell>
                          <TableCell>
                            <div style={{ display: 'flex' }}>
                              <TooltipButton
                                tooltipProps={{
                                  title: editUser.includes(row.id)
                                    ? 'Cancelar edição'
                                    : 'Editar usuário'
                                }}
                                Icon={PenIcon}
                                iconProps={{
                                  style: { color: colors.blue }
                                }}
                                iconButtonProps={{
                                  onClick: () => handleOpenEditForm(row.id),
                                  disabled: create || (editUser.length > 0 && !editUser.includes(row.id))
                                }}
                              />

                              {profile.role === Role.SUPERUSER && (
                                <>
                                  {!isNil(row.email) && (
                                    <IconModal
                                      icon={LockResetIcon}
                                      title={`Enviar email de redefinição de senha para ${row.name}`}
                                      tooltipText='Redefinir senha'
                                      onConfirm={(props) => recoverPassword(row.email, props)}
                                      message='Deseja prosseguir?'
                                      disabled={create || editUser.length > 0}
                                    />
                                  )}
                                  <IconModal
                                    icon={TrashIcon}
                                    title={'Excluir usuário'}
                                    tooltipText='Excluir usuário'
                                    onConfirm={(props) => deleteUser(row.id, props)}
                                    message='Tem certeza que deseja excluir o usuário?'
                                    iconProps={{ style: { color: 'red' } }}
                                    disabled={create || editUser.length > 0}
                                  />
                                  <TooltipButton
                                    tooltipProps={{
                                      title: `${transactionsExpanded ? 'Ocultar' : 'Ver'} eventos do usuário`
                                    }}
                                    Icon={History}
                                    iconButtonProps={{
                                      disabled: create || editUser.length > 0,
                                      onClick: () =>
                                        setExpandedTransactions((current) => {
                                          if (current.includes(~~row.id)) {
                                            return current.filter((item) => item !== ~~row.id);
                                          } else {
                                            return current.concat(~~row.id);
                                          }
                                        })
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
                        <Collapse
                          in={editUser.includes(row.id)}
                          timeout='auto'
                          unmountOnExit
                        >
                          <div>
                            <AccountFormContainer
                              close_form={() => handleCloseEditForm(row.id)}
                              onSave={async () => {
                                handleCloseEditForm(row.id)
                                await init()
                              }}
                              user_id={row.id}
                            />
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse
                          in={expandedTransactions.includes(~~row.id)}
                          timeout='auto'
                          unmountOnExit
                          mountOnEnter
                        >
                          <TransactionsRow
                            loadingUsers={loadingUsers}
                            setLoadingUsers={setLoadingUsers}
                            load_resources
                            user={row}
                            open={expandedTransactions.includes(~~row.id)}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : ` 'mais de' ${to}`}`
                  }
                  ActionsComponent={(props) => (
                    <TablePaginationActions
                      {...props}
                      disableButton={create || editUser.length > 0}
                    />
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default UsersView;
