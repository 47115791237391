/* eslint-disable camelcase */
import React from 'react';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { DefaultOptionType, Role, validation, RoomFormAttributes, CompanyAttributes } from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { useDispatch, useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import AutoCompleteComponent from '../input/form/autocomplete';
import { find, isEmpty } from 'lodash';
import { FETCH_PRODUCTS } from '../../store/products';
import { error } from 'react-notification-system-redux';
import { FETCH_KTWELVE_CURRICULUMS } from '../../store/ktwelve_curriculums';
import { css } from '@emotion/react';

export const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfefe;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const form_name = 'roomForm';

interface CustomProductOption extends DefaultOptionType {
  ktwelve_curriculum_id?: number;
}
interface CustomKtwelveCurriculumOption extends DefaultOptionType {
  ktwelve_degree_id?: number;
}

const RoomForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: RoomFormAttributes;
  close_form: () => void;
}) => {
  const { handleSubmit, onSubmit, initialValues, close_form } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [productOptions, setProductOptions] = React.useState<DefaultOptionType[]>([]);
  const [ktwelveCurriculumOptions, setKtwelveCurriculumOptions] = React.useState<CustomKtwelveCurriculumOption[]>([]);
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector(form_name);
  const companyIdValue = formValues(state, 'company_id') as string;
  const year = formValues(state, 'year') as number;
  const ktwelveCurriculumIdValue = formValues(state, 'ktwelve_curriculum_id') as string;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;

  const fetchKtwelveCurriculumOptions = React.useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const ktwelve_curriculums = await dispatch(
          FETCH_KTWELVE_CURRICULUMS.request({
            params: {
              filters: {
                'q[label_start]': value,
                'q[company_id_eq]': companyIdValue,
                'q[year_eq]': year.toString(),
              },
            },
          }),
        );
        if (ktwelve_curriculums) {
          const {
            data: { data },
          } = ktwelve_curriculums;
          const formattedData = data.reduce((acc, { attributes, id }) => {
            return acc.concat({
              label: attributes.label,
              value: id,
              ktwelve_degree_id: attributes.ktwelve_degree_id,
            });
          }, [] as CustomKtwelveCurriculumOption[]);
          setKtwelveCurriculumOptions(formattedData);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de matriz/série',
          }),
        );
      }
    },
    [companyIdValue, year],
  );

  const fetchProductOptionsMethod = React.useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const current_ktwelve_curriculum = find(
          ktwelveCurriculumOptions,
          (kco) => kco.value === ktwelveCurriculumIdValue,
        ) as CustomKtwelveCurriculumOption;
        const products = await dispatch(
          FETCH_PRODUCTS.request({
            params: {
              filters: {
                'q[name_start]': value,
                'q[company_id_eq]': companyIdValue,
                'q[product_family_roomable_eq]': 'true',
                'q[year_eq]': year.toString(),
                'q[ktwelve_degree_id_eq]': current_ktwelve_curriculum.ktwelve_degree_id?.toString() as string,
              },
            },
          }),
        );
        if (products) {
          const {
            data: { data },
          } = products;
          const formattedData = data.reduce((acc, { attributes, id }) => {
            return acc.concat({
              label: attributes.name,
              value: id,
            });
          }, [] as CustomProductOption[]);
          setProductOptions(formattedData);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de produtos',
          }),
        );
      }
    },
    [companyIdValue, ktwelveCurriculumOptions, year, ktwelveCurriculumIdValue],
  );

  React.useEffect(() => {
    if (initialValues.id) {
      setProductOptions([
        {
          label: initialValues.product_name,
          value: initialValues.product_id.toString(),
        },
      ]);

      setKtwelveCurriculumOptions([
        {
          label: initialValues.ktwelve_degree_name,
          value: initialValues.ktwelve_curriculum_id.toString(),
        },
      ]);
    }
  }, []);
  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar Sala</span>
      <form css={useStyles.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}

        {companyIdValue && (
          <>
            <div style={{ display: 'grid', gridTemplateColumns: '25% 25% 25% 24%', justifyContent: 'space-between' }}>
              <Field
                name='name'
                component={InputComponent}
                label={'Nome'}
                placeholder={'Insira o nome da sala'}
                validate={[validation.required]}
              />
              {isEmpty(initialValues.id) && (
                <Field
                  name='year'
                  component={InputComponent}
                  label={'Ano'}
                  placeholder={'Insira o ano da matriz e do produto da sala'}
                  validate={[validation.required]}
                />
              )}
              {year && (
                <Field
                  name='ktwelve_curriculum_id'
                  label={'Selecionar série/matriz'}
                  placeholder={'Série/matriz'}
                  component={AutoCompleteComponent}
                  autoCompleteProps={{
                    disabled: !isEmpty(initialValues.ktwelve_curriculum_id),
                    filterOptions: (x: any) => x,
                    loading,
                    getValueOnChange: true,
                    fetchOptions: fetchKtwelveCurriculumOptions,
                  }}
                  options={ktwelveCurriculumOptions}
                  validate={[validation.required]}
                />
              )}
              {ktwelveCurriculumIdValue && (
                <Field
                  name='product_id'
                  label={'Produto'}
                  placeholder={'Produto'}
                  component={AutoCompleteComponent}
                  autoCompleteProps={{
                    disabled: !isEmpty(initialValues.product_id),
                    filterOptions: (x: any) => x,
                    loading,
                    getValueOnChange: true,
                    fetchOptions: fetchProductOptionsMethod,
                  }}
                  options={productOptions}
                  validate={[validation.required]}
                />
              )}
            </div>
            <div css={useStyles.buttons}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  close_form();
                }}
                className='red small'
              >
                <span> Cancelar </span>
              </button>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar sala </span>
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(RoomForm);
