/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import KtwelvesForm from '../components/form/KtwelvesForm';
import { RootState } from '../store/configureStore';
import { FETCH_COURSES } from '../store/courses';
import { CREATE_KTWELVE, FETCH_KTWELVE, UPDATE_KTWELVE } from '../store/ktwelves';
import { DefaultOptionType, KtwelvesAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';

interface IKtwelvesFormContainerProps {
  close_form?: () => void;
  onSave?: () => void;
  ktwelve_id?: number;
}

const KtwelvesFormContainer: React.FC<IKtwelvesFormContainerProps> = ({
  close_form,
  onSave,
  ktwelve_id
}) => {
  const dispatch = useDispatch();
  const {
    auth: { company },
  } = useSelector((state: RootState) => state);
  const [initialValues, setInitialValues] = React.useState<KtwelvesAttributes | null>(null);
  const [coursesOptions, setCourseOptions] = React.useState<DefaultOptionType[]>([]);
  const [loading, setLoading] = React.useState(true);
  const isUpdating = !isNil(initialValues?.id);

  const loadCourseOptions = React.useCallback(async () => {
    try {
      const courses = await dispatch(
        FETCH_COURSES.request({
          params: {
            filters: {
              'page[size]': '30',
            },
          },
        }),
      );
      const {
        data: { data },
      } = courses;
      const formattedCourses = data.map((item) => {
        return {
          value: ~~item.id,
          label: item.attributes.name,
        };
      });
      setCourseOptions(formattedCourses);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar as opções de curso',
        }),
      );
    }
  }, []);

  const loadKtwelves = React.useCallback(async () => {
    if (ktwelve_id) {
      const ktwelve = await dispatch(
        FETCH_KTWELVE.request({
          id: ktwelve_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = ktwelve;
      const formattedKtwelve = {
        id,
        ...attributes,
      };
      setInitialValues(formattedKtwelve);
    }
  }, [ktwelve_id]);

  const initKtwelvesForm = React.useCallback(async () => {
    setLoading(true);
    await loadCourseOptions();
    await loadKtwelves();
    setLoading(false)
  }, []);

  const onSubmit = React.useCallback(
    async (data: KtwelvesAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, ['id']) as KtwelvesAttributes;
          await dispatch(
            UPDATE_KTWELVE.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_KTWELVE.request({
              data,
            }),
          );
        }
        await onSave?.()
        dispatch(
          success({
            message: 'Série salva com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar série.',
          }),
        );
      }
    },
    [initialValues, company, isUpdating],
  );

  React.useEffect(() => {
    initKtwelvesForm();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <KtwelvesForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      coursesOptions={coursesOptions}
      close_form={close_form}
    />
  )
};

export default KtwelvesFormContainer;
