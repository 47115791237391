import React from 'react';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Loading from '../loading/Loading';
import { colors, onConfirm } from '../../utils/constants';
import TrashIcon from '../icon/TrashIcon';
import { Tooltip, TooltipProps } from '@mui/material';
import SomaSIcon from '../icon/SomaSIcon';
import { css } from '@emotion/react';
import TooltipButton from '../shared/TooltipButton';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
  },
  backdrop: {
    position: 'absolute',
  },
  paperScrollPaper: {
    overflow: 'visible',
  },
  paper: {
    background: 'none',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  paperAlt: {
    backgroundColor: colors.lightBlue,
    borderRadius: '16px',
    position: 'absolute',
    width: 'fit-content',
  },
  modalWrapper: {
    alignItems: 'center',
    background: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    maxHeight: '80vh',
    width: '50vw',
    overflow: 'auto',
    padding: '4rem',
    position: 'relative',
    '& button.actions': {
      border: 'none',
      margin: '2.5rem 1rem 0 1rem',
    },
  },
}));

const ModalComponent = (props: any) => {
  const classes = useStyles();
  const { children, open, onClose } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div style={getModalStyle()} className={classes.paperAlt}>
        {children}
      </div>
    </Modal>
  );
};

const childrenWithProps = (children: React.ReactNode, props: object) =>
  React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, { ...props });
    }
    return child;
  });

export const DefaultModalInterface = ({
  cancelButtonText = 'Cancelar',
  children,
  confirmButtonText = 'Confirmar',
  disableConfirm = false,
  handleClose,
  loadingFromOutside = false,
  message,
  onConfirm,
  open,
  singleButton = false,
  title,
  defaultLoadingMessage='Carregando'
}: {
  cancelButtonText?: string;
  children?: React.ReactNode;
  confirmButtonText?: string;
  disableConfirm?: boolean;
  handleClose: () => void;
  message?: string;
  onConfirm?: onConfirm;
  open: boolean;
  title?: string;
  loadingFromOutside?: boolean;
  singleButton?: boolean;
  defaultLoadingMessage?: string
}) => {
  const [loading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState(defaultLoadingMessage);

  const classes = useStyles();
  return (
    <ModalComponent open={open} onClose={handleClose}>
      <div className={classes.modalWrapper}>
        {loading || loadingFromOutside ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Loading loading_message={loadingMessage} />
          </div>
        ) : (
          <>
            <h2> {title} </h2>
            {!!message && <span style={{ whiteSpace: 'pre-line' }}>{message}</span>}
            {childrenWithProps(children, { handleClose, setLoading })}
            <div>
              <button className='red small actions' onClick={handleClose}>
                <span>{cancelButtonText}</span>
              </button>
              {!singleButton && onConfirm && (
                <button
                  disabled={disableConfirm}
                  className='green small actions'
                  onClick={() => onConfirm?.({ setLoading, handleClose, setLoadingMessage })}
                >
                  <span>{confirmButtonText}</span>
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export const MenuItemModal = ({
  children,
  confirmButtonText,
  disableConfirm,
  label,
  labelProps,
  message,
  onConfirm,
  title,
  onOpen,
}: {
  children?: React.ReactNode;
  confirmButtonText?: string;
  disableConfirm?: boolean;
  label: string;
  labelProps?: object;
  message: string;
  onConfirm: onConfirm;
  title?: string;
  onOpen?: () => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = async () => {
    setOpen(true);
    if (onOpen) {
      await onOpen();
    }
  };
  return (
    <>
      <MenuItem onClick={() => handleOpen()}>
        <span {...labelProps}>{label}</span>
      </MenuItem>
      <DefaultModalInterface
        disableConfirm={disableConfirm}
        title={title}
        open={open}
        message={message}
        onConfirm={onConfirm}
        handleClose={handleClose}
        confirmButtonText={confirmButtonText}
      >
        {children}
      </DefaultModalInterface>
    </>
  );
};

export const IconModal = ({
  cancelButtonText = 'Cancelar',
  children,
  confirmButtonText = 'Confirmar',
  disableConfirm = false,
  disabled,
  icon,
  iconProps,
  message,
  onConfirm,
  onOpen,
  singleButton = false,
  text,
  title,
  tooltipText,
  tooltipProps,
  defaultLoadingMessage
}: {
  cancelButtonText?: string;
  children?: React.ReactNode;
  confirmButtonText?: string;
  disableConfirm?: boolean;
  disabled?: boolean;
  icon: React.ElementType
  iconProps?: object;
  message?: string;
  onConfirm?: onConfirm;
  onOpen?: (args: {handleClose: () => void, }) => Promise<void>;
  singleButton?: boolean;
  text?: string;
  title: string;
  tooltipText?: NonNullable<React.ReactNode>;
  tooltipProps?: Omit<TooltipProps, 'children'|'title'>,
  defaultLoadingMessage?: string
}) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = async () => {
    setOpen(true);
    if (onOpen) {
      setLoading(true);
      await onOpen({ handleClose });
      setLoading(false);
    }
  };

  const Icon = icon;
  return (
    <>
      {tooltipText ? (
        <Tooltip  disableHoverListener={disabled} {...tooltipProps} title={tooltipText} >
          <span>
            <IconButton disabled={disabled} onClick={handleOpen}>
              <Icon {...iconProps} />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton onClick={handleOpen}>
          <Icon {...iconProps} />
        </IconButton>
      )}
      {text ? <span onClick={handleOpen}>{text}</span> : null}
      <DefaultModalInterface
        loadingFromOutside={loading}
        open={open}
        handleClose={handleClose}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        title={title}
        disableConfirm={disableConfirm}
        onConfirm={onConfirm}
        message={message}
        singleButton={singleButton}
        defaultLoadingMessage={defaultLoadingMessage}
      >
        {childrenWithProps(children, { handleClose, setLoading })}
      </DefaultModalInterface>
    </>
  );
};

export const ButtonModal = ({
  cancelButtonText = 'Cancelar',
  children,
  confirmButtonText = 'Confirmar',
  disableConfirm = false,
  disabled,
  label,
  labelProps,
  message,
  onConfirm,
  text,
  title,
  tooltipText,
  singleButton = false,
  onOpen = () => null,
  defaultLoadingMessage = "Carregando"
}: {
  cancelButtonText?: string;
  children?: React.ReactNode;
  confirmButtonText?: string;
  disableConfirm?: boolean;
  disabled?: boolean;
  label: string;
  labelProps?: object;
  message?: string;
  onConfirm: onConfirm;
  onOpen?: () => void;
  text?: string;
  title: string;
  tooltipText?: string;
  singleButton?: boolean;
  defaultLoadingMessage?: string
}) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = async () => {
    setOpen(true);
    if (onOpen) {
      setLoading(true);
      await onOpen();
      setLoading(false);
    }
  };

  return (
    <>
      {tooltipText ? (
        <Tooltip title={tooltipText}>
          <button disabled={disabled} onClick={handleOpen} {...labelProps}>
            <span>{label}</span>
          </button>
        </Tooltip>
      ) : (
        <button disabled={disabled} onClick={handleOpen} {...labelProps}>
          <span>{label}</span>
        </button>
      )}
      {text ? <span onClick={handleOpen}>{text}</span> : null}
      <DefaultModalInterface
        open={open}
        handleClose={handleClose}
        loadingFromOutside={loading}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        title={title}
        disableConfirm={disableConfirm}
        onConfirm={onConfirm}
        message={message}
        singleButton={singleButton}
        defaultLoadingMessage={defaultLoadingMessage}
      >
        {children}
      </DefaultModalInterface>
    </>
  );
};

export const DeleteItemModal = ({
  message,
  onConfirm,
  text,
  tooltipText,
  title = 'Excluir Item',
  confirmButtonText,
  children
}: {
  text?: string;
  message: string;
  onConfirm: onConfirm;
  tooltipText?: string;
  title?: string;
  confirmButtonText?: string;
  children?: React.ReactNode,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {tooltipText ? (
        <TooltipButton
          tooltipProps={{
            title: tooltipText
          }}
          Icon={TrashIcon}
          iconProps={{
            style: { color: colors.lightRed, cursor: 'pointer' }
          }}
          iconButtonProps={{
            onClick: () => setOpen(true)
          }}
        />
      ) : (
        <IconButton onClick={() => setOpen(true)}>
          <TrashIcon style={{ color: colors.lightRed, cursor: 'pointer' }} />
        </IconButton>
      )}
      {text ? <span onClick={() => setOpen(true)}>{text}</span> : null}
      <DefaultModalInterface
        open={open}
        handleClose={handleClose}
        message={message}
        onConfirm={onConfirm}
        confirmButtonText={confirmButtonText}
        title={title}
      >
        {childrenWithProps(children, { handleClose })}
      </DefaultModalInterface>
    </>
  );
};

const SomaIconElement = () => {
  return (
    <div
      css={css`
        align-items: center;
        background: white;
        display: flex;
        flex-direction: column;
        gap: 1vmax;
        min-height: 25vh;
        justify-content: center;
        padding: 5rem;
        & span {
          font-size: 2rem;
          font-weight: 600;
        }
      `}
    >
      <SomaSIcon style={{ fontSize: '5rem' }} />
      <span>Carregando</span>
      <Loading />
    </div>
  );
};

export const LoadingBackdrop = (props: { open: boolean; somaIcon?: boolean }) => {
  const classes = useStyles();
  const backdropProps = {
    disableEscapeKeyDown: true,
    disableAutoFocus: true,
    disableEnforceFocus: true,
    disableScrollLock: true,
    disablePortal: true,
  };
  return (
    <Dialog
      {...backdropProps}
      fullWidth
      className={classes.root}
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
        paper: classes.paper,
        paperFullWidth: classes.paper,
      }}
      BackdropProps={{
        classes: { root: classes.backdrop },
      }}
      style={{ position: 'absolute' }}
      {...props}
    >
      {props.somaIcon ? <SomaIconElement /> : <Loading />}
      {/* <Loading /> */}
    </Dialog>
  );
};

export default ModalComponent;
