import React from 'react'
import { css } from '@emotion/react';
import { colors, FollowUpStatusEnum } from '../../utils/constants';
import { CheckCircle, HourglassEmpty } from '@mui/icons-material';
import LoadingIndicator from './LoadingWithLabel';
import { FiAlertTriangle } from 'react-icons/fi';

interface FollowUpData {
  message: string;
  status: FollowUpStatusEnum;
}
const FollowUpLineCss = css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed ${colors.darkBlue};
    width: 100%;

    & .status_indicator {
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;


const FollowUpComponent = (props: {data: FollowUpData[]}) => {
  const {data} = props
  return (
    <div style={{ display: 'grid', 'width': '100%' }}>
      {data.map((item) => {
        const status = item.status
        return (
          <div
            key={item.message}
            css={FollowUpLineCss}
          >
            <span>{item.message}</span>
            <div className='status_indicator'>
              {status === 'awaiting' ? (
                <>
                  <HourglassEmpty />
                  <span>Aguardando</span>
                </>
              ) : status === 'running' ? (
                <>
                  <LoadingIndicator message='' />
                  <span>Processando...</span>
                </>
              ) : status === 'successful' ? (
                <>
                  <CheckCircle style={{ color: colors.green }} />
                  <span>Concluído</span>
                </>
              ) : status === 'error' ? (
                <>
                  <FiAlertTriangle style={{ color: colors.lightRed }} />
                  <span>Erro</span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default FollowUpComponent