/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import SubjectsForm from '../components/form/SubjectsForm';
import { CREATE_SUBJECT, FETCH_SUBJECT, UPDATE_SUBJECT } from '../store/subjects';
import { RootState } from '../store/configureStore';
import {SubjectAttributes, Role, CompanyAttributes } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';

interface ISubjectsFormContainer {
  close_form?: () => void
  onSave: () => void
  subject_id?: string
  company_id?: string
}

const SubjectsFormContainer: React.FC<ISubjectsFormContainer> = ({close_form, onSave, company_id, subject_id}) => {
  const dispatch = useDispatch();
  const state  = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    account: { companies }
  } = state
  const formValues = formValueSelector('subjectForm');
  const [initialValues, setInitialValues] = React.useState<SubjectAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const companyIdValue = formValues(state, 'company_id') as string;
  const isUpdating = !isNil(initialValues?.id);
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use = company_id ||
    (is_above_school_director
    ? companyIdValue
    : company);

 
  const loadSubjects = React.useCallback(async () => {
    if (subject_id) {
      const subject = await dispatch(
        FETCH_SUBJECT.request({
          id: subject_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = subject;
      const formattedSubject = {
        id,
        ...attributes,
      };
      setInitialValues(formattedSubject);
    } else {
      setInitialValues({ company_id: company_id_to_use } as SubjectAttributes)
    }
  }, [company_id_to_use]);

  const initSubjectsForm = React.useCallback(async () => {
    await loadSubjects();
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: SubjectAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as SubjectAttributes;
          await dispatch(
            UPDATE_SUBJECT.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_SUBJECT.request({
              data: {
                ...data,
                company_id: company_id_to_use,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Disciplina salva com sucesso.',
          }),
        );
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar disciplina.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating],
  );

  React.useEffect(() => {
    initSubjectsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <SubjectsForm
    initialValues={initialValues}
    onSubmit={onSubmit}
    close_form={close_form}
  />;
};

export default SubjectsFormContainer;
