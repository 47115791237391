/* eslint-disable camelcase */
import React from 'react'
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import InputComponent from '../../input/form/input'
import ProductFamiliesFormContainer from '../../../containers/ProductFamiliesFormContainer'
import TablePaginationActions from '../../shared/TablePaginationActions'
import TooltipButton from '../../shared/TooltipButton'
import { Theme } from '@mui/system'
import { css, useTheme } from '@emotion/react';
import { colors, ProductFamilyAttributes, PaginationType } from '../../../utils/constants'
import PenIcon from '../../icon/PenIcon'

export const useStyles = () => {
  const theme = useTheme() as Theme;
  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

interface IProductFamiliesViewProps {
  productFamilies: ProductFamilyAttributes[]
  pagination: PaginationType
  handlePageChange: (_: any, newPage: number) => void
  handleChangePageSize: (e: any) => void
  descriptionFilterValue: string
  setDescriptionFilterValue: React.Dispatch<React.SetStateAction<string>>
  nameFilterValue: string
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>
  fetchProductFamiliesMethod: ({ newPagination }: {
    newPagination?: PaginationType | undefined;
  }) => Promise<void>
}

const columns = ['Nome', 'Descrição']

const ProductFamiliesView: React.FC<IProductFamiliesViewProps> = ({
  productFamilies,
  pagination,
  handleChangePageSize,
  handlePageChange,
  descriptionFilterValue,
  setDescriptionFilterValue,
  nameFilterValue,
  setNameFilterValue,
  fetchProductFamiliesMethod
}) => {
  const [create, setCreate] = React.useState(false)
  const [editProduct, setEditProduct] = React.useState<number[]>([])
  const classes = useStyles()

  const init = async () => {
    await fetchProductFamiliesMethod({})
  }

  const handleOpenCreateForm = () => {
    setCreate(prevState => !prevState)
  }

  const handleOpenEditForm = (id: number) => {
    setEditProduct((prevState) => {
      if (id && prevState.includes(id)) {
        return prevState.filter((item) => item !== id)
      } else {
        return prevState.concat(id)
      }
    })
  }

  const handleCloseEditForm = (id: number) => {
    setEditProduct((prevState) => prevState.filter((item) => item !== id))
  }

  const handleSaveProduct = async () => {
    setCreate(false)
    await init()
  }

  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Famílias de produtos</span>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div
          css={classes.filters}
        >
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchProductFamiliesMethod({})
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value)
              }
            }}
          />
          <InputComponent
            placeholder={`Buscar por descrição`}
            search
            small
            onSearch={() => {
              fetchProductFamiliesMethod({})
            }}
            input={{
              value: descriptionFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionFilterValue(e.target.value)
              }
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align={'left'} key={item}>{item}</TableCell>
                ))}
                <TableCell align='right'>
                  <button
                    className='green small'
                    onClick={handleOpenCreateForm}
                    disabled={editProduct.length > 0}
                  >
                    <span>Criar família de produto</span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <div>
                      <ProductFamiliesFormContainer
                        close_form={() => setCreate(false)}
                        onSave={handleSaveProduct}
                      />
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
              {
                productFamilies.map(product_family => {
                  return (
                    <React.Fragment key={product_family.id}>
                      <TableRow>
                        <TableCell>
                          {product_family.name}
                        </TableCell>
                        <TableCell>
                          {product_family.description}
                        </TableCell>
                        <TableCell style={{textAlign: 'right'}}>
                          <TooltipButton
                            tooltipProps={{
                              title: editProduct.includes(Number(product_family.id))
                                ? 'Cancelar edição'
                                : 'Editar produto'
                            }}
                            Icon={PenIcon}
                            iconButtonProps={{
                              disabled: create || (editProduct.length > 0 && !editProduct.includes(Number(product_family.id))),
                              onClick: () => handleOpenEditForm(Number(product_family.id))
                            }}
                          />
                        </TableCell>
                      </TableRow>

                      {editProduct.includes(Number(product_family.id)) && (
                        <TableRow>
                          <TableCell style={{ padding: 0 }}  colSpan={columns.length + 2}>
                            <Collapse
                              in={editProduct.includes(Number(product_family.id))}
                              timeout='auto'
                              unmountOnExit
                            >
                              <div>
                                <ProductFamiliesFormContainer
                                  close_form={() => handleCloseEditForm(Number(product_family.id))}
                                  onSave={async () => {
                                    handleCloseEditForm(Number(product_family.id))
                                    await init()
                                  }}
                                  product_family_id={Number(product_family.id)}
                                />
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={(props) => {
                    return (
                      <TablePaginationActions
                        {...props}
                        disableButton={create || editProduct.length > 0}
                      />
                    )
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ProductFamiliesView
