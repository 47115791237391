/* eslint-disable camelcase */
import React from 'react';
import { compose } from 'redux';
import {
  Field,
  GenericField,
  change,
  formValueSelector,
  reduxForm,
  reset,
} from 'redux-form';
import {
  TeacherAttributes,
  TeacherFormData,
  DefaultOptionType,
  Role,
  UserFormFields,
  UserOptionsData,
  colors,
  defaultTeacherFormInitialValues,
  validation,
  CompanyAttributes,
} from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { RootState } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { isEmpty } from 'lodash';
import UserForm from './UserForm';
import { css } from '@emotion/react';
interface CompanyIdCustomProps {
  label: string;
  options: DefaultOptionType[];
  placeholder: string;
}

const FieldCustom = Field as new () => GenericField<CompanyIdCustomProps>;


const useStyles = {
  view: css`
    background: #FDFEFF;
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 0rem;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,

  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,

  form: css`
    display: grid;
    row-gap: 1rem;
    padding: 1rem;

    & .user-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 1rem;

      & > div {
        flex-basis: 100%;
      }

      & #input-nationality_country {
        flex-basis: 32.6666666%;
        order: 1;
      }

      & #input-nationality_state {
        flex-basis: 32.6666666%;
        order: 2;
      }

      & #input-nationality_city {
        flex-basis: 32.6666666%;
        order: 3;
      }

      & #input-document_number {
        order: 4;
        flex-basis: 24.5%;
      }

      & #input-name {
        flex-basis: 24.5%;
        order: 5;
      }

      & #input-email {
        flex-basis: 24.5%;
        order: 6;
      }

      & div[id$="personal_email"] {
        flex-basis: 24.5%;
        order: 7;
      }

      & #input-phone {
        flex-basis: 32.6666666%;
        order: 8;
      }

      & #input-gender {
        flex-basis: 32.6666666%;
        order: 9;
      }

      & #input-birthdate {
        flex-basis: 32.6666666%;
        order: 10;
      }

      & .nationality-span {
        display: none;
      }

      & #input-address_form {
        order: 11;
      }

      & div[id$="street"] {
        flex-basis: 40%;
      }

      & div[id$="number"] {
        flex-basis: 24%;
      }

      & div[id$="complement"] {
        flex-basis: 35%;
      }

      & div[id$="neighbourhood"] {
        flex-basis: 30%;
      }

      & #input-address_form > div[id$="country_id"] {
        flex-basis: 20%;
      }

      & #input-address_form > div[id$="state_id"] {
        flex-basis: 25%;
      }

      & #input-address_form > div[id$="city_id"] {
        flex-basis: 22.5%;
      }

      & .zipcode {
        display: flex;
        max-width: 100%;
        width: 50rem;
        gap: 1rem;

        & > .search {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          cursor: pointer;
          margin-bottom: 1rem;
          border: none;
          gap: 1rem;
          width: fit-content;
          background: inherit;

          & > svg {
            font-size: 3rem;
            color: ${colors.darkBlue};

            &:hover {
              color: #5a5a72;
            }
          }
        }
      }
    }
  `,

  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const form_name = 'teacherForm';

interface ITeachersFormProps {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: TeacherAttributes;
  setInitialValues: React.Dispatch<React.SetStateAction<Partial<TeacherFormData>>>;
  handleSelectUser: (user_option: UserOptionsData) => Promise<void>
  allowUserUpdate: boolean
  close_form: () => void
}
const TeachersForm = (props: ITeachersFormProps) => {
  const {
    handleSubmit,
    onSubmit,
    initialValues,
    setInitialValues,
    handleSelectUser,
    allowUserUpdate,
    close_form
  } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company: companyId, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector(form_name);
  const companyIdValue = formValues(state, 'company_id') as string;
  const userIdValue = formValues(state, 'user_id') as string;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === companyId,
  ) as CompanyAttributes;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use = is_above_school_director ? companyIdValue : companyId;

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: companyId,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;
  const userFormData = [
    'document_number',
    'name',
    'email',
    'additional_data.personal_email',
    'phone',
    'gender',
    'birthdate',
    'nationality_country',
    'nationality_state',
    'nationality_city',
  ] as UserFormFields[];
  const handleClearDocumentField = () => {
    if (initialValues.id) {
      setInitialValues({ ...defaultTeacherFormInitialValues(company_id_to_use) });
      dispatch(reset(form_name));
      dispatch(change(form_name, 'document_number', null));
    } else {
      dispatch(change(form_name, 'document_number', null));
    }
  };
  return (
    <div css={useStyles.view}>
      <span style={{paddingLeft: '1rem', paddingTop: '1rem'}} className={`${useStyles.title} title`}>Salvar professor</span>
      <form css={useStyles.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <FieldCustom
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              onChange={(event: any, value) => {
                dispatch(change(form_name, 'accounts_attributes[0].company_id', value));
              }}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}
        {companyIdValue ? (
          <>
            <div className='user-form'>
              <UserForm
                data={userFormData}
                form_name={form_name}
                handleClearDocumentField={handleClearDocumentField}
                handleSelectUser={handleSelectUser}
                company_id_to_use={company_id_to_use}
                disabled_document_number={!isEmpty(userIdValue)}
                document_clearable={!isEmpty(userIdValue)}
                disable_all={!allowUserUpdate}
              />
            </div>
            <div css={useStyles.buttons}>
              <button type='button' onClick={close_form} className='red small'>
                <span> Cancelar </span>
              </button>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar professor </span>
              </button>
            </div>
          </>
        ) : null}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'teacherForm',
  }),
)(TeachersForm);
