/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { colors, KtwelveSubjectAttributes, TeacherSubjectAttributes } from '../../utils/constants';
import { isEmpty, map, orderBy } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Collapse } from '@mui/material';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { error, success } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { FETCH_KTWELVE_SUBJECT } from '../../store/ktwelve_subjects';
import PenIcon from '../icon/PenIcon';
import TeacherSubjectsFormContainer from '../../containers/TeacherSubjectsFormContainer';
import { DELETE_TEACHER_SUBJECT, TeacherSubjectJson } from '../../store/teacher_subjects';
import { IconModal } from '../modal/Modal';
import { FETCH_CLASS_TIME_SUBJECTS } from '../../store/class_time_subjects';
import TrashIcon from '../icon/TrashIcon';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const TeacherSubjectsTable = ({ ktwelve_subject }: { ktwelve_subject: KtwelveSubjectAttributes }) => {
  const [teacher_subjects, setTeacherSubjects] = React.useState<TeacherSubjectAttributes[]>([]);
  const [create, setCreate] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [editTeacherSubject, setEditingTeacherSubject] = React.useState<number[]>([]);

  const dispatch = useDispatch();

  const fetchTeacherSubjects = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_KTWELVE_SUBJECT.request({
          id: ktwelve_subject.id,
          params: {
            filters: {
              include: 'teacher_subjects',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const teacher_subjects_attributes = map(
        included.filter((incl) => incl.type === 'teacher_subjects') as TeacherSubjectJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setTeacherSubjects(teacher_subjects_attributes);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar professores da disciplina na série',
        }),
      );
      setLoading(false);
    }
  }, [ktwelve_subject]);

  const verifyIfCanRemoveTeacherSubject = React.useCallback(
    async (args: {
      handleClose: () => void;
      teacher_subject_id: string;
    }) => {
      const { handleClose, teacher_subject_id } = args;
      try {
        const result = await dispatch(
          FETCH_CLASS_TIME_SUBJECTS.request({
            params: {
              filters: {
                'q[class_time_starts_at_lteq]': new Date().toISOString(),
                'q[class_time_active_eq]': 'true',
                'q[teacher_subjects_id_eq]': teacher_subject_id,
                'q[ktwelve_subject_id_eq]': ktwelve_subject.id
              }
            }
          })
        )
        const { data: { data } } = result;
        if (!isEmpty(data)) {
          dispatch(
            error({
              message: 'Não é possível remover professor dessa disciplina pois ele já deu aula nela. Recomenda-se apenas desativá-lo.',
            }),
          );
          handleClose();
        }
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao verificar histórico do professor.',
          }),
        );
        handleClose();
      }
    },
    [ktwelve_subject],
  );


  const deleteTeacherSubject = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_TEACHER_SUBJECT.request({
            id,
          }),
        );
        setLoading(false);
        dispatch(
          success({
            message: 'Matriz curricular removido com sucesso',
          }),
        );
        fetchTeacherSubjects();
        handleClose();
      } catch (e) {
        handleClose();
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao remover matriz curricular',
          }),
        );
      }
    },
    [],
  );

  const init = async () => {
    await fetchTeacherSubjects();

  }
  React.useEffect(() => {
    init()
  }, []);

  if (loading){
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='teacher-subjects'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Professor</TableCell>
          <TableCell align='center'>Disciplina</TableCell>
          <TableCell align='center'>Série</TableCell>
          <TableCell align='right'>
            <button disabled={editTeacherSubject.length > 0} onClick={() => setCreate(!create)} className='green small'>
              <span>Adicionar Professor </span>
            </button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <TeacherSubjectsFormContainer
                onSave={async () => {
                  await init();
                  setCreate(false);
                }}
                close_form={() => setCreate(false)}
                ktwelve_subject={ktwelve_subject}
                all_teacher_subject_teacher_ids={teacher_subjects.map(item => ~~item.id)}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {orderBy(teacher_subjects, 'teacher_name').map((teacher_subject) => {
          const close_editing_form = () => {
            setEditingTeacherSubject((current) => current.filter((item) => item !== ~~teacher_subject.id));
          };
          return (
            <React.Fragment key={teacher_subject.id}>
              <TableRow>
                <TableCell align='center'>{teacher_subject.teacher_name}</TableCell>
                <TableCell align='center'>{teacher_subject.subject_name}</TableCell>
                <TableCell align='center'>{teacher_subject.ktwelve_degree_name}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: editTeacherSubject.includes(~~teacher_subject.id)
                        ? 'Cancelar edição'
                        : 'Editar ',
                    }} 
                    Icon={PenIcon}
                    iconButtonProps={{
                      disabled:
                        create || (editTeacherSubject.length > 0 && !editTeacherSubject.includes(~~teacher_subject.id)),
                      onClick: () =>
                        setEditingTeacherSubject((current) => {
                          if (current.includes(~~teacher_subject.id)) {
                            return current.filter((item) => item !== ~~teacher_subject.id);
                          } else {
                            return current.concat(~~teacher_subject.id);
                          }
                        }),
                    }}
                  />{' '}
                  <IconModal
                    icon={TrashIcon}
                    onConfirm={(props) => deleteTeacherSubject(teacher_subject.id, { ...props })}
                    tooltipText='Remover professor da disciplina'
                    title='Remover professor da disciplina'
                    message='Deseja remover o professor desta disciplina?'
                    onOpen={(args) =>
                      verifyIfCanRemoveTeacherSubject({
                        ...args,
                        teacher_subject_id: teacher_subject.id,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={editTeacherSubject.includes(~~teacher_subject.id)} timeout='auto' unmountOnExit>
                    <TeacherSubjectsFormContainer
                      all_teacher_subject_teacher_ids={teacher_subjects.map(item => ~~item.id)}
                      ktwelve_subject={ktwelve_subject}
                      teacher_subject_id={teacher_subject.id}
                      onSave={fetchTeacherSubjects}
                      close_form={close_editing_form}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TeacherSubjectsTable;
