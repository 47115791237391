import React from 'react'
import RoomsTable from '../components/table/RoomsTable';


const RoomsContainer = () => {
  return (
    <RoomsTable/>
  );
};

export default RoomsContainer;
