/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import DiscountTypesForm from '../components/form/DiscountTypesForm';
import { RootState } from '../store/configureStore';
import { CREATE_DISCOUNT_TYPE, FETCH_DISCOUNT_TYPE, UPDATE_DISCOUNT_TYPE } from '../store/discount_types';
import { CompanyAttributes, DiscountTypeEnum, DiscountTypesAttributes, Role } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';

interface IDiscountTypesFormContainerProps {
  close_form?: () => void
  onSave: () => void
  discount_type_id?: string
  company_id?: string
}

const DiscountTypesFormContainer: React.FC<IDiscountTypesFormContainerProps> = ({ close_form, onSave, discount_type_id, company_id }) => {
  const dispatch = useDispatch();
  const formValues = formValueSelector('discountTypeForm');
  const state = useSelector((state: RootState) => state);
  const [loaded, setLoaded] = React.useState(false);
  const suggestedKind = formValues(state, 'suggested_kind') as DiscountTypeEnum;
  const companyIdValue = formValues(state, 'company_id') as string;
  const {
    auth: { company, profile },
    account: { companies }
  } = state;
  const [initialValues, setInitialValues] = React.useState<DiscountTypesAttributes | null>(null);
  const isUpdating = !isNil(initialValues?.id);
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const company_id_to_use = company_id || (is_above_school_director
      ? companyIdValue
      : company);

  const loadDiscountType = React.useCallback(async () => {
    if (discount_type_id) {
      const discount_type = await dispatch(
        FETCH_DISCOUNT_TYPE.request({
          id: discount_type_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = discount_type;
      const formattedDiscountType = {
        id,
        ...attributes,
      };
      setInitialValues(formattedDiscountType);
    } else {
      setInitialValues({ company_id: company_id_to_use } as DiscountTypesAttributes);
    }
  }, []);

  const initDiscountTypesForm = React.useCallback(async () => {
    await loadDiscountType();
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: DiscountTypesAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as DiscountTypesAttributes;
          await dispatch(
            UPDATE_DISCOUNT_TYPE.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_DISCOUNT_TYPE.request({
              data: {
                ...data,
                company_id: company_id_to_use,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Tipo de desconto salvo com sucesso.',
          }),
        );
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar tipo de desconto.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating],
  );

  React.useEffect(() => {
    initDiscountTypesForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }
  return (
    <DiscountTypesForm 
      initialValues={initialValues} 
      onSubmit={onSubmit} 
      suggested_kind={suggestedKind} 
      close_form={close_form}
    />
  );
};

export default DiscountTypesFormContainer;
