/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PiStudent, PiExam } from 'react-icons/pi';
import { CiViewTable, CiBoxList } from 'react-icons/ci';
import { RiCalendarScheduleLine } from 'react-icons/ri';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { Page, Text, Document, pdf, StyleSheet, Image, View } from '@react-pdf/renderer';
import {
  colors,
  RoomAttributes,
  Role,
  PaginationType,
  defaultPagination,
  ExamPlacementAttributes,
  FormulaAttributes,
  SubjectPeriodRegistrationAttributes,
  ExamAttributes,
  RegistrationResultAttributes,
  SubjectPeriodAttributes,
  CompanyAttributes,
  roomScheduleWeekDayOptions,
  RoomScheduleWeekDaysEnum,
  onConfirm,
  TeacherAttributes,
  CoordinatorAttributes,
  DefaultOptionType,
  periodOptions,
  RoomScheduleStatusEnum,
  FollowUpStatusEnum,
  RoomStudentAttributes,
} from '../../utils/constants';
import TooltipButton from '../shared/TooltipButton';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Collapse, Tab, TableFooter, TablePagination, Tabs } from '@mui/material';
import RoomsFormContainer from '../../containers/RoomsFormContainer';
import PenIcon from '../icon/PenIcon';
import {
  convertToCPF,
  evaluate_permissions,
  get_company_search,
  getCompanyFilterOptions,
  parseText,
} from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import TablePaginationActions from '../shared/TablePaginationActions';
import SelectComponent from '../input/form/select';
import RoomStudentsTable from './RoomStudentsTable';
import ClassTimeTable from './ClassTimeDisplayTable';
import RoomSchedulesTable from './RoomSchedulesTable';
import StudentResultsTable from './StudentsResultTable';
import { FETCH_ROOM, FETCH_ROOMS } from '../../store/rooms';
import { RoomStudentJson } from '../../store/room_students';
import { compact, filter, find, flatMap, isEmpty, map, size } from 'lodash';
import { format } from 'date-fns';
import { error, success, warning } from 'react-notification-system-redux';
import { IconModal } from '../modal/Modal';
import SelectMultipleComponent from '../input/form/selectmultiple';
import DatePickerComponent from '../input/form/datepicker';
import { CREATE_ROOM_SCHEDULE } from '../../store/room_schedules';
import TabPanel from '../shared/TabPanel';
import { UI_SET_LOADING_OPEN } from '../../store/ui';
import { FETCH_KTWELVE_CURRICULUMS } from '../../store/ktwelve_curriculums';
import { FETCH_PRODUCTS } from '../../store/products';
import AutoCompleteComponent from '../input/form/autocomplete';
import { UserJson } from '../../store/users';
import FollowUpComponent from '../shared/FollowUpComponent';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const FilterCss = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  & > span {
    margin-bottom: 14px;
  }
  & .inputArea {
    width: 12.5rem !important;
    padding-right: 3rem;
  }
`;

const ViewCss = css`
  width: inherit;
  min-height: 100%;
  height: fit-content;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  & .MuiBackdrop-root {
    position: inherit;
  };
  & .MuiPaper-root {
    background: none;
    box-shadow: none;
    overflow: hidden;
  },
`;

const TabStyleCss = css`
  & .Mui-selected {
    background: #fdfeff;
    border-radius: 1rem 1rem 0 0;
  }
`;

interface StateCompanies extends CompanyAttributes {
  teachers: TeacherAttributes[];
  coordinators: CoordinatorAttributes[];
}
interface ScheduleProccessData {
  room_id: string;
  week_day: RoomScheduleWeekDaysEnum;
  status: FollowUpStatusEnum;
}
export interface SubjectRegistrationPeriodData extends SubjectPeriodRegistrationAttributes {
  exams: ExamAttributes[];
  registration_results: RegistrationResultAttributes[];
}

export interface SubjectPeriodData extends SubjectPeriodAttributes {
  subject_period_registrations: SubjectRegistrationPeriodData[];
  formulas: FormulaAttributes[];
  exam_placements: ExamPlacementAttributes[];
  room_students: RoomStudentAttributes[]
}
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 16,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontSize: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  student_name: {
    fontSize: 8,
  },
  sub_header: {
    fontSize: 12,
  },
  bottom_line: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  footer_text: {
    borderTopColor: 'black',
    borderTopWidth: 1,
    fontSize: 10,
  },
  list_headers: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    fontSize: 12,
  },
});

const RoomSchedulesTableComponent = (props: { room: RoomAttributes }) => {
  const { room } = props;
  const [tab, setTab] = React.useState<RoomScheduleWeekDaysEnum>(RoomScheduleWeekDaysEnum.MONDAY);
  const handleTabChange = (_: any, newValue: RoomScheduleWeekDaysEnum) => {
    setTab(newValue);
  };
  return (
    <div>
      <Tabs css={TabStyleCss} value={tab} onChange={handleTabChange}>
        {roomScheduleWeekDayOptions.map((item) => (
          <Tab label={item.label} value={item.value} key={item.value} />
        ))}
      </Tabs>
      {roomScheduleWeekDayOptions.map((item) => (
        <TabPanel key={item.value} index={item.value} style={{ display: 'inherit', rowGap: '1rem' }} value={tab}>
          <RoomSchedulesTable room={room} week_day={item.value} />
        </TabPanel>
      ))}
    </div>
  );
};

const MultipleRoomsSchedule = (props: {
  selected_rooms_ids: string[];
  all_rooms: RoomAttributes[];
  setSelectedRooms: React.Dispatch<React.SetStateAction<string[]>>;
  fetchRoomsMethod: ({
    newPagination,
    company_filter,
    product_filter,
  }: {
    newPagination?: PaginationType;
    company_filter?: string;
    product_filter?: string;
  }) => Promise<void>;
}) => {
  const { selected_rooms_ids, all_rooms, setSelectedRooms, fetchRoomsMethod } = props;
  const [selected_week_days, setSelectedWeekDays] = React.useState<RoomScheduleWeekDaysEnum[]>(
    map(
      filter(
        roomScheduleWeekDayOptions,
        (opt) => ![RoomScheduleWeekDaysEnum.SATURDAY, RoomScheduleWeekDaysEnum.SUNDAY].includes(opt.value),
      ),
      (opt) => opt.value,
    ),
  );
  const [starts_at, setStartsAtDay] = React.useState(new Date());
  const [ends_at, setEndsAtDay] = React.useState(new Date(new Date().getFullYear(), 12, -1));
  const [scheduleProcessData, setScheduleProccessData] = React.useState<ScheduleProccessData[]>([]);
  const dispatch = useDispatch();
  const processRoom = React.useCallback(async (props: { room_id: string; week_day: RoomScheduleWeekDaysEnum }) => {
    const { room_id, week_day } = props;
    try {
      await setScheduleProccessData((current) =>
        current.map((item) => {
          if (item.room_id === room_id && item.week_day === week_day) {
            return { ...item, status: FollowUpStatusEnum.RUNNING };
          }
          return item;
        }),
      );
      const params = {
        room_id: ~~room_id,
        class_times_attributes: [],
        weekday: week_day,
        schedule_date: new Date().toString(),
        starts_at: starts_at.toString(),
        ends_at: ends_at.toString(),
        status: RoomScheduleStatusEnum.RUNNING
      };
      await dispatch(
        CREATE_ROOM_SCHEDULE.request({
          data: { ...params },
        }),
      );

      setScheduleProccessData((current) =>
        current.map((item) => {
          if (item.room_id === room_id && item.week_day === week_day) {
            return { ...item, status: FollowUpStatusEnum.SUCCESSFUL };
          }
          return item;
        }),
      );
    } catch (err) {
      setScheduleProccessData((current) =>
        current.map((item) => {
          if (item.room_id === room_id && item.week_day === week_day) {
            return { ...item, status: FollowUpStatusEnum.ERROR };
          }
          return item;
        }),
      );
    }
  }, []);

  const onConfirmHandleRoomsSchedule: onConfirm = React.useCallback(
    async ({ handleClose }) => {
      try {
        await setScheduleProccessData((current) => current.map((item) => ({ ...item, status: FollowUpStatusEnum.AWAITING })));
        for (const process_data of scheduleProcessData) {
          const { room_id, week_day } = process_data;
          await processRoom({ room_id, week_day });
        }
        setSelectedRooms([]);
        handleClose();
        fetchRoomsMethod({});
        dispatch(
          success({
            message: 'Horários removidos com sucesso!',
            autoDismiss: 3,
          }),
        );
      } catch (error) {
        dispatch(
          success({
            title: 'Erro na remoção dos horários',
            message: 'Favor entrar em contato com o suporte',
            autoDismiss: 3,
          }),
        );
      }
    },
    [setScheduleProccessData, scheduleProcessData],
  );

  const disabled_fields = !isEmpty(find(scheduleProcessData, (data) => data.status !== FollowUpStatusEnum.IDLE));

  return (
    <IconModal
      disabled={isEmpty(selected_rooms_ids)}
      title='Limpar horário de turmas selecionadas'
      icon={FaRegCalendarTimes}
      onConfirm={onConfirmHandleRoomsSchedule}
      onOpen={async () => {
        await setScheduleProccessData(
          flatMap(selected_rooms_ids, (room_id) => {
            return map(selected_week_days, (week_day) => ({ room_id, week_day, status: FollowUpStatusEnum.IDLE }));
          }),
        );
      }}
      tooltipText='Limpar horário de turmas selecionadas'
      disableConfirm={disabled_fields}
    >
      <div style={{ width: '100%' }}>
        <span>Selecionar dias da semana que serão limpos</span>
        <SelectMultipleComponent
          disabled={disabled_fields}
          placeholder={`Dias da semana`}
          allow_select_all
          options={roomScheduleWeekDayOptions}
          input={{
            name: 'weekdays',
            value: selected_week_days,
            onChange: (event: any) => {
              const value = event?.target?.value;
              setSelectedWeekDays(value);
              setScheduleProccessData(
                flatMap(selected_rooms_ids, (room_id) => {
                  return map(value, (week_day) => ({ room_id, week_day, status: FollowUpStatusEnum.IDLE }));
                }),
              );
            },
          }}
        />
        <div style={{ display: 'grid', gridTemplateColumns: '49.5% 49.5%', justifyContent: 'space-between' }}>
          <DatePickerComponent
            disabled={disabled_fields}
            placeholder='Dia inicial de aplicação do intervalo'
            label='Dia inicial de aplicação do intervalo'
            datePickerProps={{
              allowSameDateSelection: true,
              showDropdownIcon: true,
              disabled: disabled_fields,
              ...(ends_at ? { maxDate: ends_at } : {}),
            }}
            input={{
              name: 'starts_at',
              value: starts_at,
              onChange: (e: Date) => {
                setStartsAtDay(e);
              },
            }}
          />
          <DatePickerComponent
            disabled={disabled_fields}
            placeholder='Dia final de aplicação do intervalo'
            label='Dia final de aplicação do intervalo'
            datePickerProps={{
              allowSameDateSelection: true,
              showDropdownIcon: true,
              disabled: disabled_fields,
              ...(starts_at ? { minDate: starts_at } : {}),
            }}
            input={{
              name: 'ends_at',
              value: ends_at,
              onChange: (e: Date) => {
                setEndsAtDay(e);
              },
            }}
          />
        </div>
        <FollowUpComponent
          data={scheduleProcessData.map(item => {
            const room_name = find(all_rooms, (room) => room.id === item.room_id)?.name;
            const week_day_name = find(roomScheduleWeekDayOptions, (opt) => opt.value === item.week_day)?.label;

            return ({...item, message: [room_name, week_day_name].join(' - ')})}
          )}
        />
      </div>
    </IconModal>
  );
};

const RoomsTable = () => {
  const [expandedRoomAssets, setExpandedRoomStudents] = React.useState<number[]>([]);
  const [expandedClassTimes, setExpandedClassTimes] = React.useState<number[]>([]);
  const [expandedRoomSchedules, setExpandedRoomSchedules] = React.useState<number[]>([]);
  const [expandedStudentResults, setExpandedStudentResults] = React.useState<number[]>([]);
  const [yearFilterValue, setYearFilterValue] = React.useState<Date | null>(new Date(new Date().getFullYear(), 0));
  const [ktwelve_curriculum_options, setKtwelveCurriculumOptions] = React.useState<DefaultOptionType[]>([]);
  const [ktwelve_curriculum_filter_value, setKtwelveCurriculumFilterValue] = React.useState('');
  const [classroom_period_filter_value, setClassroomPeriodFilterValue] = React.useState('');
  const [product_options, setProductOptions] = React.useState<DefaultOptionType[]>([]);
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [rooms, setRooms] = React.useState<RoomAttributes[]>([]);
  const [product_filter_value, setProductFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');
  const [selected_rooms, setSelectedRooms] = React.useState<string[]>([]);
  const [editRoom, setEditingRoom] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const dispatch = useDispatch();
  const [loading_products, setLoadingProducts] = React.useState(false);

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find((item: CompanyAttributes) => item.id === company) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(
    profile.role as Role,
    current_company.kind,
  );
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const current_teacher = find(
    find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers,
    (t) => t.teacher_company_id.toString() === company.toString(),
  );
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const fetchRoomsMethod = React.useCallback(
    async ({
      classroom_period_filter = classroom_period_filter_value,
      ktwelve_curriculum_filter = ktwelve_curriculum_filter_value,
      company_filter = companyFilterValue,
      newPagination = pagination,
      product_filter = product_filter_value,
      year = yearFilterValue,
      initial_ktwelve_curriculum_options = ktwelve_curriculum_options,
      initial_product_options = product_options,
    }: {
      classroom_period_filter?: string;
      company_filter?: string;
      newPagination?: PaginationType;
      product_filter?: string;
      ktwelve_curriculum_filter?: string;
      year?: Date | null;
      initial_ktwelve_curriculum_options?: DefaultOptionType[];
      initial_product_options?: DefaultOptionType[];
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter: company_filter,
        current_company_id: company,
      });
      try {
        setSelectedRooms([]);
        let extra_params = {
          ...(classroom_period_filter ? { 'q[product_classroom_period_eq]': classroom_period_filter } : {}),
          ...(ktwelve_curriculum_filter
            ? { 'q[ktwelve_curriculum_id_eq]': ktwelve_curriculum_filter }
            : !isEmpty(initial_ktwelve_curriculum_options)
            ? { 'q[ktwelve_curriculum_id_in]': initial_ktwelve_curriculum_options.map((item) => item.value) }
            : {}),
          ...(product_filter
            ? { 'q[product_id_eq]': product_filter }
            : !isEmpty(initial_product_options)
            ? { 'q[product_id_in]': initial_product_options.map((item) => item.value) }
            : {}),
          ...(year ? { 'q[product_year_eq]': new Date(year).getFullYear().toString() } : {}),
        };
        if (profile.role === Role.TEACHER) {
          extra_params = {
            ...extra_params,
            ...(current_teacher ? { 'q[teachers_id_eq]': current_teacher.id, 'q[company_id_in]': company } : {}),
          };
        } else if (profile.role === Role.COORDINATOR) {
          const coordinators = find(
            companies as StateCompanies[],
            (co: StateCompanies) => co.id === company,
          )?.coordinators;
          extra_params = {
            ...extra_params,
            ...(coordinators && !isEmpty(coordinators)
              ? { 'q[course_id_in]': coordinators.map((item) => item.course_id), 'q[company_id_in]': company }
              : {}),
          };
        }
        const rooms = await dispatch(
          FETCH_ROOMS.request({
            params: {
              filters: {
                'q[company_id_in]': companies_array,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                ...extra_params,
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = rooms;
        const formattedRooms = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setRooms(formattedRooms);
        setSelectedRooms([]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar salas',
          }),
        );
      }
    },
    [
      pagination,
      company,
      companyFilterValue,
      product_filter_value,
      product_options,
      ktwelve_curriculum_options,
      ktwelve_curriculum_filter_value,
      classroom_period_filter_value,
      yearFilterValue,
      current_teacher,
    ],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchRoomsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchRoomsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const fetchKtwelveCurriculumOptions = React.useCallback(
    async (props: { company_filter?: string }) => {
      const { company_filter = companyFilterValue } = props;
      try {
        setKtwelveCurriculumFilterValue('');
        setProductFilterValue('');
        const companies_array = get_company_search({
          companies,
          company_descendants,
          company_filter,
          current_company_id: company,
        });
        let extra_params = {
          ...(yearFilterValue ? { 'q[year_eq]': new Date(yearFilterValue).getFullYear().toString() } : {}),
        };
        if (profile.role === Role.TEACHER) {
          extra_params = {
            ...extra_params,
            ...(current_teacher
              ? { 'q[ktwelve_subjects_teachers_id_eq]': current_teacher.id, 'q[company_id_in]': company }
              : {}),
          };
        } else if (profile.role === Role.COORDINATOR) {
          const coordinators = find(
            companies as StateCompanies[],
            (co: StateCompanies) => co.id === company,
          )?.coordinators;
          extra_params = {
            ...extra_params,
            ...(coordinators && !isEmpty(coordinators)
              ? { 'q[course_id_in]': coordinators.map((item) => item.course_id), 'q[company_id_in]': company }
              : {}),
          };
        }
        const ktwelve_response = await dispatch(
          FETCH_KTWELVE_CURRICULUMS.request({
            params: {
              filters: {
                'q[company_id_in]': companies_array,
                'page[size]': '100',
                ...extra_params,
              },
            },
          }),
        );
        const {
          data: { data },
        } = ktwelve_response;
        const formatted_ktwelve_curriculum_options = map(data, (item) => ({
          value: item.id,
          label: is_above_school_director
            ? [item.attributes.company_name, item.attributes.label].join(' - ')
            : item.attributes.label,
        }));
        setKtwelveCurriculumOptions(formatted_ktwelve_curriculum_options);
        return formatted_ktwelve_curriculum_options;
      } catch (err) {
        dispatch(
          error({
            message: 'Erro no carregamento de opções de série',
          }),
        );
      }
    },
    [yearFilterValue, companyFilterValue, company, current_teacher],
  );

  const fetchProductOptions = React.useCallback(
    async (props: {
      initial_ktwelve_curriculum_options?: DefaultOptionType[];
      ktwelve_curriculum_filter?: string;
      classroom_period_filter?: string;
      company_filter?: string;
      product_filter?: string;
    }) => {
      const {
        initial_ktwelve_curriculum_options = ktwelve_curriculum_options,
        ktwelve_curriculum_filter = ktwelve_curriculum_filter_value,
        classroom_period_filter = classroom_period_filter_value,
        company_filter = companyFilterValue,
        product_filter = product_filter_value,
      } = props;
      try {
        setLoadingProducts(true);
        setProductFilterValue('');
        const companies_array = get_company_search({
          companies,
          company_descendants,
          company_filter,
          current_company_id: company,
        });
        let extra_params = {
          ...(yearFilterValue ? { 'q[year_eq]': new Date(yearFilterValue).getFullYear().toString() } : {}),
          ...(classroom_period_filter ? { 'q[classroom_period_eq]': classroom_period_filter } : {}),
          ...(product_filter ? { 'q[name_cont]': product_filter } : {}),
          ...(ktwelve_curriculum_filter
            ? { 'q[ktwelve_degree_ktwelve_curriculums_id_eq]': ktwelve_curriculum_filter }
            : !isEmpty(initial_ktwelve_curriculum_options)
            ? {
                'q[ktwelve_degree_ktwelve_curriculums_id_in]': map(
                  initial_ktwelve_curriculum_options,
                  (item) => item.value,
                ),
              }
            : {}),
        };
        if (profile.role === Role.TEACHER) {
          extra_params = {
            ...extra_params,
            ...(current_teacher
              ? {
                  'q[ktwelve_degree_ktwelve_curriculums_ktwelve_subjects_teachers_id_eq]': current_teacher.id,
                  'q[company_id_in]': company,
                }
              : {}),
          };
        } else if (profile.role === Role.COORDINATOR) {
          const coordinators = find(
            companies as StateCompanies[],
            (co: StateCompanies) => co.id === company,
          )?.coordinators;
          extra_params = {
            ...extra_params,
            ...(coordinators && !isEmpty(coordinators)
              ? {
                  'q[ktwelve_degree_course_id_in]': coordinators.map((item) => item.course_id),
                  'q[company_id_in]': company,
                }
              : {}),
          };
        }
        const products_response = await dispatch(
          FETCH_PRODUCTS.request({
            params: {
              filters: {
                'q[company_id_in]': companies_array,
                'page[size]': '100',
                'q[product_family_roomable_eq]': 'true',
                ...extra_params,
              },
            },
          }),
        );
        const {
          data: { data },
        } = products_response;
        const formatted_ktwelve_curriculum_options = map(data, (item) => ({
          value: item.id,
          label: [item.attributes.name, item.attributes.year].join(','),
        }));
        setProductOptions(formatted_ktwelve_curriculum_options);
        setLoadingProducts(false);
        return formatted_ktwelve_curriculum_options;
      } catch (err) {
        setLoadingProducts(false);
        dispatch(
          error({
            message: 'Erro no carregamento de opções de série',
          }),
        );
      }
    },
    [
      yearFilterValue,
      companyFilterValue,
      company,
      ktwelve_curriculum_filter_value,
      classroom_period_filter_value,
      product_filter_value,
      current_teacher,
    ],
  );

  const loadAssets = React.useCallback(
    async (props: { company_filter?: string }) => {
      const { company_filter = companyFilterValue } = props;
      setLoading(true);
      const ktwelve_curriculum_options = await fetchKtwelveCurriculumOptions({ company_filter });
      const product_options = await fetchProductOptions({
        company_filter,
        initial_ktwelve_curriculum_options: ktwelve_curriculum_options,
      });
      await fetchRoomsMethod({
        company_filter,
        initial_product_options: product_options,
        initial_ktwelve_curriculum_options: ktwelve_curriculum_options,
      });
    },
    [company, ktwelve_curriculum_filter_value, companyFilterValue],
  );

  React.useEffect(() => {
    loadAssets({});
  }, []);

  const generateProtocolPdf = React.useCallback(async (room_id: string) => {
    try {
      setLoading(true)
      const response = await dispatch(
        FETCH_ROOM.request({
          id: room_id,
          params: {
            filters: {
              include: 'room_students',
            },
          },
        }),
      );
      const {
        data: {
          included = [],
          data: {
            attributes: { company_id, name, ktwelve_degree_name, course_name, company_name, product_year },
          },
        },
      } = response;
      const current_company = find(
        companies,
        (co: CompanyAttributes) => co.id.toString() === company_id.toString(),
      ) as CompanyAttributes;
      const room_students_attributes = filter(
        map(included.filter((incl) => incl.type === 'room_students') as RoomStudentJson[], (item) => ({
          id: item.id,
          ...item.attributes,
        })),
        (rs) => rs.active,
      );
      const doc = (
        <Document>
          <Page size={'A4'} style={styles.page}>
            <View style={styles.header}>
              <Image src={current_company.logo_url} style={styles.logo} />
              <Text style={styles.title}>{company_name}</Text>
            </View>
            <Text style={styles.sub_header}>
              {compact([
                ...(name ? [compact(['Sala', name]).join(': ')] : []),
                ...(ktwelve_degree_name ? [compact(['Série', ktwelve_degree_name]).join(': ')] : []),
              ]).join(' | ')}
            </Text>
            <Text style={{ ...styles.sub_header, ...styles.bottom_line }}>
              {compact([
                ...(course_name ? [compact(['Curso', course_name]).join(': ')] : []),
                ...(product_year ? [compact(['Ano', product_year]).join(': ')] : []),
              ]).join(' | ')}
            </Text>
            <View style={styles.list_headers}>
              <Text style={{ width: '10%' }}>Diário</Text>
              <Text style={{ width: '15%', justifyContent: 'center', alignItems: 'center' }}>RA</Text>
              <Text style={{ width: '40%', justifyContent: 'center', alignItems: 'center' }}>Nome</Text>
              <Text style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>Data</Text>
              <Text style={{ flexGrow: 1, justifyContent: 'flex-end', alignItems: 'flex-end', textAlign: 'right' }}>
                Assinatura
              </Text>
            </View>
            {room_students_attributes.map((item, index) => (
              <View
                style={{ ...styles.bottom_line, flexDirection: 'row', justifyContent: 'space-between', fontSize: 8 }}
                key={item.id}
              >
                <Text style={{ width: '10%' }}>{index + 1}</Text>
                <Text style={{ width: '15%', justifyContent: 'center', alignItems: 'center' }}>
                  {item.student_code}
                </Text>
                <Text style={{ width: '40%', justifyContent: 'center', alignItems: 'center' }}>
                  {parseText(item.student_name, 35)}
                </Text>
                <Text style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>{'   /   /   '}</Text>
                <Text style={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}> </Text>
              </View>
            ))}
            <Text style={styles.footer_text} fixed>
              Emitido em {format(new Date(), 'dd-MM-yyyy H:mm')}
            </Text>
          </Page>
        </Document>
      );

      const asPdf = pdf(doc); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const pdfBlob = await asPdf.toBlob();
      const url = URL.createObjectURL(pdfBlob);
      const aTag = document.createElement('a');
      aTag.href = url;
      aTag.download = 'PROTOCOLO.pdf';
      document.body.appendChild(aTag);
      aTag.click();
      setLoading(false)
      aTag.remove()
    } catch (err) {
      setLoading(false)
      console.error(err);
      dispatch(warning({ message: 'Erro ao gerar relatório, favor contatar suporte' }));
    }
  }, []);

  const generateCoordinatorReportPdf = React.useCallback(async (room_id: string) => {
    try {
      setLoading(true)
      const response = await dispatch(
        FETCH_ROOM.request({
          id: room_id,
          params: {
            filters: {
              include: ['room_students.ktwelve_curriculum_registration.registration.account.user'].join(','),
            },
          },
        }),
      );
      const {
        data: {
          included = [],
          data: {
            attributes: { company_id, name, ktwelve_degree_name, course_name, company_name, product_year },
          },
        },
      } = response;
      const current_company = find(
        companies,
        (co: CompanyAttributes) => co.id.toString() === company_id.toString(),
      ) as CompanyAttributes;
      const room_students_attributes = filter(
        map(included.filter((incl) => incl.type === 'room_students') as RoomStudentJson[], (item) => {
          const user = find(
            included,
            (this_included) =>
              this_included.type === 'users' && this_included.attributes.name === item.attributes.student_name,
          ) as UserJson;
          return {
            id: item.id,
            ...item.attributes,
            ...user.attributes,
          };
        }),
        (rs) => rs.active,
      );
      const doc = (
        <Document>
          <Page size={'A4'} style={styles.page}>
            <View style={styles.header}>
              <Image src={current_company.logo_url} style={styles.logo} />
              <Text style={styles.title}>{company_name}</Text>
            </View>
            <Text style={styles.sub_header}>
              {compact([
                ...(name ? [compact(['Sala', name]).join(': ')] : []),
                ...(ktwelve_degree_name ? [compact(['Série', ktwelve_degree_name]).join(': ')] : []),
              ]).join(' | ')}
            </Text>
            <Text style={{ ...styles.sub_header, ...styles.bottom_line }}>
              {compact([
                ...(course_name ? [compact(['Curso', course_name]).join(': ')] : []),
                ...(product_year ? [compact(['Ano', product_year]).join(': ')] : []),
              ]).join(' | ')}
            </Text>
            <View style={styles.list_headers}>
              <Text style={{ width: '10%' }}>Diário</Text>
              <Text style={{ width: '15%', justifyContent: 'center', alignItems: 'center' }}>RA</Text>
              <Text style={{ width: '40%', justifyContent: 'center', alignItems: 'center' }}>Nome</Text>
              <Text style={{ width: '20%', justifyContent: 'center', alignItems: 'center' }}>Nascimento</Text>
              <Text style={{ flexGrow: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>CPF</Text>
            </View>
            {room_students_attributes.map((item, index) => (
              <View
                style={{ ...styles.bottom_line, flexDirection: 'row', justifyContent: 'space-between', fontSize: 8 }}
                key={item.id}
              >
                <Text style={{ width: '10%' }}>{index + 1}</Text>
                <Text style={{ width: '15%', justifyContent: 'center', alignItems: 'center' }}>
                  {item.student_code}
                </Text>
                <Text style={{ width: '40%', justifyContent: 'center', alignItems: 'center' }}>
                  {parseText(item.student_name, 35)}
                </Text>
                <Text style={{ width: '20%', justifyContent: 'center', alignItems: 'center' }}>{format(new Date(item.birthdate), 'dd-MM-yyyy')}</Text>
                <Text style={{ flexGrow: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  {convertToCPF(item.document_number)}
                </Text>
              </View>
            ))}
            <Text style={styles.footer_text} fixed>
              Emitido em {format(new Date(), 'dd-MM-yyyy H:mm')}
            </Text>
          </Page>
        </Document>
      );

      const asPdf = pdf(doc); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const pdfBlob = await asPdf.toBlob();
      const url = URL.createObjectURL(pdfBlob);
      const aTag = document.createElement('a');
      aTag.href = url;
      aTag.download = `Relatório Coordenação.pdf`;
      document.body.appendChild(aTag);
      aTag.click();
      setLoading(false)
      aTag.remove()
    } catch (err) {
      setLoading(false)
      console.error(err);
      dispatch(warning({ message: 'Erro ao gerar relatório da coordenação, favor contatar suporte' }));
    }
  }, []);
  const selectAllRooms = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event?.target.checked) {
        setSelectedRooms(map(rooms, (room) => room.id));
      } else {
        setSelectedRooms([]);
      }
    },
    [rooms],
  );
  const handleSelectRoom = (props: { event: React.ChangeEvent<HTMLInputElement>; room_id: string }) => {
    const { event, room_id } = props;
    const {
      target: { checked },
    } = event;
    if (checked) {
      setSelectedRooms((current) => current.concat(room_id));
    } else {
      setSelectedRooms((current) => current.filter((item) => item !== room_id));
    }
  };

  return (
    <div css={ViewCss}>
      <span className='title'>Salas</span>
      <div css={FilterCss}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            input={{
              value: companyFilterValue,
              onChange: async (e: any) => {
                setCompanyFilterValue(e.target.value);
                await loadAssets({ company_filter: e.target.value });
              },
            }}
          />
        )}
        <DatePickerComponent
          placeholder='Ano letivo'
          small
          datePickerProps={{
            views: ['year'],
            inputFormat: 'yyyy',
            allowSameDateSelection: true,
          }}
          input={{
            value: yearFilterValue,
            name: 'year',
            onChange: async (e: Date) => {
              setYearFilterValue(e);
              await fetchRoomsMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                year: e,
              });
            },
          }}
        />
        <SelectComponent
          placeholder='Selecionar Série'
          small
          options={ktwelve_curriculum_options}
          clearable
          clearFieldFunction={async () => {
            setLoading(true);
            setKtwelveCurriculumFilterValue('');
            const product_options = await fetchProductOptions({
              ktwelve_curriculum_filter: '',
            });
            await fetchRoomsMethod({
              ktwelve_curriculum_filter: '',
              initial_product_options: product_options,
              newPagination: { ...defaultPagination },
            });
          }}
          input={{
            value: ktwelve_curriculum_filter_value,
            onChange: async (e: any) => {
              setLoading(true);
              const value = e?.target?.value || '';
              setKtwelveCurriculumFilterValue(value);
              const product_options = await fetchProductOptions({
                ktwelve_curriculum_filter: value,
              });
              await fetchRoomsMethod({
                ktwelve_curriculum_filter: value,
                initial_product_options: product_options,
                newPagination: { ...defaultPagination },
              });
            },
          }}
        />
        <SelectComponent
          placeholder='Selecionar Turno'
          small
          options={periodOptions}
          clearable
          clearFieldFunction={async () => {
            setLoading(true);
            setClassroomPeriodFilterValue('');
            const product_options = await fetchProductOptions({
              classroom_period_filter: '',
            });
            await fetchRoomsMethod({
              classroom_period_filter: '',
              initial_product_options: product_options,
              newPagination: { ...defaultPagination },
            });
          }}
          input={{
            value: classroom_period_filter_value,
            onChange: async (e: any) => {
              setLoading(true);
              const value = e?.target?.value || '';
              setClassroomPeriodFilterValue(value);
              const product_options = await fetchProductOptions({
                classroom_period_filter: value,
              });
              await fetchRoomsMethod({
                classroom_period_filter: value,
                initial_product_options: product_options,
                newPagination: { ...defaultPagination },
              });
            },
          }}
        />
        <AutoCompleteComponent
          options={product_options}
          small
          input={{ name: 'product', onBlur: () => null }}
          placeholder='Selecionar Produto'
          autoCompleteProps={{
            loading: loading_products,
            getValueOnChange: true,
            onChange: async (_: any, value: DefaultOptionType) => {
              setProductFilterValue(value.value.toString());
              await fetchRoomsMethod({
                product_filter: value.value.toString(),
                newPagination: { ...defaultPagination },
              });
            },
            getOptionLabel: (option: DefaultOptionType) => {
              return option?.label as string;
            },
            value: find(product_options, (opt) => opt.value === product_filter_value),
            onClearField: async () => {
              setProductFilterValue('');
              const product_options = await fetchProductOptions({ product_filter: '' });
              await fetchRoomsMethod({
                product_filter: '',
                initial_product_options: product_options,
                newPagination: { ...defaultPagination },
              });
            },
            fetchOptions: async (value: string) => {
              await fetchProductOptions({ product_filter: value });
            },
          }}
        />
        <div style={{ marginBottom: '15px' }}>
          <MultipleRoomsSchedule
            selected_rooms_ids={selected_rooms}
            all_rooms={rooms}
            setSelectedRooms={setSelectedRooms}
            fetchRoomsMethod={fetchRoomsMethod}
          />
        </div>
      </div>
      <Table css={TableCss} aria-label='rooms'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>
              <Checkbox
                onChange={selectAllRooms}
                checked={size(selected_rooms) === size(rooms) && !isEmpty(rooms) && !isEmpty(selected_rooms)}
                indeterminate={size(selected_rooms) < size(rooms) && !isEmpty(rooms) && !isEmpty(selected_rooms)}
                disabled={isEmpty(rooms)}
              />
            </TableCell>
            {is_above_school_director && <TableCell align='center'>Unidade</TableCell>}
            <TableCell align='center'>Ano Letivo</TableCell>
            <TableCell align='center'>Nome</TableCell>
            <TableCell align='center'>Produto</TableCell>
            <TableCell align='center'>Série</TableCell>
            {[Role.SUPERUSER, Role.PEDAGOGICAL_ADMIN, Role.SCHOOL_MANAGER, Role.COORDINATOR].includes(profile.role) && (
              <TableCell align='right'>
                {' '}
                <button disabled={editRoom.length > 0} onClick={() => setCreate(!create)} className='green small'>
                  <span> Adicionar Sala </span>
                </button>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
              <Collapse in={create} timeout='auto' unmountOnExit>
                <RoomsFormContainer
                  onSave={async () => {
                    await fetchRoomsMethod({ newPagination: defaultPagination });
                    setCreate(false);
                  }}
                  close_form={() => setCreate(false)}
                />
              </Collapse>
            </TableCell>
          </TableRow>
          {rooms.map((room) => {
            const close_editing_form = () => {
              setEditingRoom((current) => current.filter((item) => item !== ~~room.id));
            };
            return (
              <React.Fragment key={room.id}>
                <TableRow>
                  <TableCell align='left'>
                    <Checkbox
                      onChange={(event) => handleSelectRoom({ event, room_id: room.id })}
                      checked={selected_rooms.includes(room.id)}
                    />
                  </TableCell>
                  {is_above_school_director && <TableCell align='center'>{room.company_name}</TableCell>}
                  <TableCell align='center'>{room.product_year}</TableCell>
                  <TableCell align='center'>{room.name}</TableCell>
                  <TableCell align='center'>{room.product_name}</TableCell>
                  <TableCell align='center'>{room.ktwelve_degree_name}</TableCell>
                  <TableCell align='right'>
                    <TooltipButton
                      tooltipProps={{
                        title: 'Gerar protocolo',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'inline-flex'
                            : 'none',
                        },
                      }}
                      Icon={CiBoxList}
                      iconButtonProps={{
                        onClick: () => generateProtocolPdf(room.id),
                      }}
                    />
                    <TooltipButton
                      tooltipProps={{
                        title: 'Gerar relatório da coordenação',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'inline-flex'
                            : 'none',
                        },
                      }}
                      Icon={CiBoxList}
                      iconButtonProps={{
                        style: { color: colors.darkBlue },
                        onClick: () => generateCoordinatorReportPdf(room.id),
                      }}
                    />
                    <TooltipButton
                      tooltipProps={{
                        title: editRoom.includes(~~room.id) ? 'Cancelar edição' : 'Editar Sala',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'inline-flex'
                            : 'none',
                        },
                      }}
                      Icon={PenIcon}
                      iconButtonProps={{
                        disabled: create || (editRoom.length > 0 && !editRoom.includes(~~room.id)),
                        onClick: () =>
                          setEditingRoom((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomAssets.includes(~~room.id) ? 'Ocultar alunos' : 'Ver alunos',
                      }}
                      Icon={PiStudent}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomStudents((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedClassTimes.includes(~~room.id) ? 'Ocultar horários' : 'Ver horários',
                      }}
                      Icon={CiViewTable}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedClassTimes((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar agendamentos de horário'
                          : 'Ver agendamentos de horário',
                        style: {
                          display: [
                            Role.SUPERUSER,
                            Role.PEDAGOGICAL_ADMIN,
                            Role.SCHOOL_MANAGER,
                            Role.COORDINATOR,
                          ].includes(profile.role)
                            ? 'inline-flex'
                            : 'none',
                        },
                      }}
                      Icon={RiCalendarScheduleLine}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomSchedules((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar notas e frequência'
                          : 'Ver notas e frequência',
                      }}
                      Icon={PiExam}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedStudentResults((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={editRoom.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomsFormContainer
                        onSave={async () => {
                          close_editing_form();
                          await fetchRoomsMethod({ newPagination: defaultPagination });
                        }}
                        close_form={close_editing_form}
                        room_id={~~room.id}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomAssets.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomStudentsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedClassTimes.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <ClassTimeTable room_id={room.id} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomSchedules.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomSchedulesTableComponent room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedStudentResults.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <StudentResultsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
        {pagination && handlePageChange && handleChangePageSize && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                colSpan={8}
                count={pagination.totalCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.pageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangePageSize}
                labelRowsPerPage='Itens Por página'
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                }
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

export default RoomsTable;
