import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@mui/material';
import React from 'react';
import {
  CompanyAttributes,
  ContractAttributes,
  ContractStatusEnum,
  ContractStatusTypes,
  ContractsViewData,
  DefaultOptionType,
  InvoiceStatusEnum,
  OrderOptions,
  PaginationType,
  ParsedPaymentSimulationDataResult,
  PaymentSimulationBillings,
  PaymentSimulationPaymentInfo,
  PendingSignaturesStatusTypes,
  PeriodTypes,
  ProductTypes,
  RepresentativeAttributes,
  RepresentativeProductAttributes,
  Role,
  colors,
  contractStatusOptions,
  pendingSignatureStatusOptions,
  periodOptions,
  productTypeOptions,
} from '../../utils/constants';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';
import DatePickerComponent from '../input/form/datepicker';
import { Download, Email, MonetizationOn, NoteAdd, Receipt, ReplyAll, Sync } from '@mui/icons-material';
import SearchIcon from '../icon/SearchIcon';
import { IconModal } from '../modal/Modal';
import TablePaginationActions from '../shared/TablePaginationActions';
import LoadingWithLabel from '../shared/LoadingWithLabel';
import TrashIcon from '../icon/TrashIcon';
import { filter, find, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  blobToURL,
  checkPaymentOptionAndCollections,
  evaluate_permissions,
  generateId,
  getCompanyFilterOptions,
} from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import { format, parseISO } from 'date-fns';
import {
  CREATE_CONTRACT,
  DELETE_CONTRACT,
  FETCH_CONTRACT,
  GENERATE_BILLINGS,
  REFRESH_CONTRACT,
  RESEND_CONTRACT,
  SEND_CONTRACT,
} from '../../store/contracts';
import { error, success, warning } from 'react-notification-system-redux';
import { getBillingsSimulationData, getMonthAndYearFromBilling } from '../../utils/paymentSimulation';
import BillingTable from './BillingTable';
import { PaymentCollectionJson, PaymentOptionJson } from '../../store/payment_options';
import { BillingJson } from '../../store/billings';
import { InvoiceJson } from '../../store/invoices';
import { FETCH_REGISTRATION_PRODUCT } from '../../store/registration_products';
import { RepresentativeJson, RepresentativeProductJson } from '../../store/representatives';
import Loading from '../loading/Loading';
import { Document, Page } from 'react-pdf';
import { css, useTheme } from '@emotion/react';
import TooltipButton from '../shared/TooltipButton';

const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem 0rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

const ViewContract = (props: {
  handleClose?: () => Promise<void>;
  contract_url: string
}) => {
  const { contract_url, handleClose } = props
  const [pdfData, setPdfData] = React.useState<unknown>('')
  const [loadingPdf, setLoadingPdf] = React.useState(true)
  const [numPages, setNumPages] = React.useState<number | undefined>()
  const dispatch = useDispatch()

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  const getPdfData = async () => {
    try {
      const data = await fetch(contract_url)
      const blob = await data.blob()
      const url = await blobToURL(blob)
      setPdfData(url)
      setLoadingPdf(false)
    } catch (err) {
      handleClose?.()
      dispatch(
        error({
          message: 'Erro ao carregar contrato'
        })
      )
    }
  }

  React.useEffect(() => {
    getPdfData()
  }, [])
  if (loadingPdf) {
    return <LoadingWithLabel message='Carregando contrato' />
  }
  return (
    <div
      onContextMenu={(e) => e.preventDefault()}>
      <style>
        {`@media print {.pdf-container{display: none;}}`}
      </style>
      <Document
        renderMode='canvas'
        className='pdf-container'
        file={pdfData}
        onLoadSuccess={args => onDocumentLoadSuccess(args)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page renderTextLayer={false} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  )
}

const GenerateContractButton = ({
  representative_name,
  generateContract,
  disabled,
}: {
  representative_name: string;
  generateContract: () => Promise<void>;
  disabled: boolean;
}) => {
  const [generating, setGenerating] = React.useState(false);
  const generateContractMethod = React.useCallback(async () => {
    setGenerating(true);
    await generateContract();
    setGenerating(false);
  }, [generateContract, representative_name]);
  return (
    <div className='representative-name'>
      <span>{representative_name}</span>
      {generating ? (
        <LoadingWithLabel message='Gerando...' />
      ) : (
        <Tooltip
          title={
            disabled
              ? `Só é permitido gerar contrato se o anterior tiver sido excluído`
              : `Gerar novo contrato para o representante ${representative_name}`
          }
        >
          <span className='generate-button-span'>
            <IconButton disabled={disabled} onClick={generateContractMethod}>
              <NoteAdd style={{ color: disabled ? 'gray' : colors.blue }} />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

const GenerateContractButtons = ({
  setLoadingContracts,
  refreshResources,
  registration_id,
  product_id,
  registration_product_id,
  disabled,
}: {
  setLoadingContracts: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  refreshResources: () => Promise<void>;
  registration_id: string;
  product_id: string;
  registration_product_id: string;
  disabled: boolean;
}) => {
  const dispatch = useDispatch();
  const [representatives, setRepresentatives] = React.useState<RepresentativeAttributes[]>([]);
  const [representative_products, setRepresentativeProducts] = React.useState<RepresentativeProductAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);
  const createContractMethod = async ({
    product_id,
    representative_id,
    representative_product_id,
    registration_id,
  }: {
    product_id: string;
    representative_id: string;
    registration_product_id: string;
    representative_product_id: string;
    registration_id: string;
  }) => {
    const id = generateId(3);
    setLoadingContracts((current) => current.concat({ id, message: 'Gerando Contrato' }));
    try {
      await dispatch(
        CREATE_CONTRACT.request({
          data: {
            registration_id,
            product_id,
            representative_id,
            representative_product_id,
          },
        }),
      );
      dispatch(
        success({
          title: 'Contrato gerado',
          autoDismiss: 3,
        }),
      );
      setLoadingContracts((current) => current.filter((item) => item.id !== id));
      await refreshResources?.();
    } catch (err) {
      setLoadingContracts((current) => current.filter((item) => item.id !== id));
      dispatch(
        error({
          message: 'Erro na geração de contrato',
        }),
      );
    }
  };

  const fetchRepresentatives = React.useCallback(async () => {
    setLoading(true);
    const registration = await dispatch(
      FETCH_REGISTRATION_PRODUCT.request({
        id: registration_product_id,
        params: {
          filters: {
            include: ['representative_products.representative'].join(','),
          },
        },
      }),
    );
    const {
      data: { included },
    } = registration;
    const includedRepresentatives = map(
      filter(included, (item) => item.type === 'representatives') as RepresentativeJson[],
      (res) => ({ id: res.id, ...res.attributes }),
    );
    const includedRepresentativeProducts = map(
      filter(included, (item) => item.type === 'representative_products') as RepresentativeProductJson[],
      (res) => ({ id: res.id, ...res.attributes }),
    );
    setRepresentatives(includedRepresentatives);
    setRepresentativeProducts(includedRepresentativeProducts);
    setLoading(false);
  }, [registration_product_id]);

  React.useEffect(() => {
    fetchRepresentatives();
  }, [registration_id]);

  if (loading) {
    return <Loading />;
  }

  if (isEmpty(representatives) || isEmpty(representative_products)) {
    return <span>Não há representante para esse produto, por favor contatar o suporte</span>;
  }

  return (
    <div>
      <span className='product-contract-title'> Escolha responsável para gerar novo contrato </span>
      {representatives.map((representative) => {
        const representative_product = representative_products.find(
          (representative_product) =>
            representative_product.representative_id === ~~representative.id &&
            representative_product.registration_product_id === ~~registration_product_id,
        ) as RepresentativeProductAttributes;
        const representative_name = representative.representative_name;
        const createContract = async () => {
          await createContractMethod({
            product_id,
            representative_id: representative.id,
            registration_product_id,
            representative_product_id: representative_product.id,
            registration_id,
          });
        };
        return (
          <GenerateContractButton
            disabled={disabled}
            key={representative.id}
            representative_name={representative_name}
            generateContract={createContract}
          />
        );
      })}
    </div>
  );
};

const ContractStepTableActions = ({
  contract,
  expandedSimulations,
  loadingContracts,
  setExpandedSimulation,
  disable_actions,
  generateInvoices,
  verifyIfCanGenerateBillings,
  sendContract,
  resendContract,
  refreshContract,
  deleteContract,
  verifyIfCanDeleteContract,
  can_download_contract
}: {
  billings: PaymentSimulationBillings[];
  loadingContracts: {
    id: string;
    message: string;
  }[];
  contract: ContractAttributes;
  setExpandedSimulation: React.Dispatch<React.SetStateAction<number[]>>;
  expandedSimulations: number[];
  disable_actions: boolean;
  generateInvoices: ({
    setLoading,
    handleClose,
  }: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => Promise<void>;
  verifyIfCanGenerateBillings: (args: {
    handleClose: () => void;
    contract_id: string;
    payment_simulation_billings: PaymentSimulationBillings[];
  }) => Promise<void>;
  sendContract: ({
    registration_id,
    representative_id,
    contract_id,
  }: {
    registration_id: string;
    representative_id: string;
    contract_id: string;
  }) => Promise<void>;
  resendContract: ({ contract_id }: { contract_id: string }) => Promise<void>;
  refreshContract: ({ contract_id }: { contract_id: string }) => Promise<void>;
  deleteContract: (
    {
      setLoading,
      handleClose,
    }: {
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      handleClose: () => void;
    },
    id: string,
  ) => Promise<void>;
  verifyIfCanDeleteContract: (args: { handleClose: () => void; contract_id: string }) => Promise<void>;
  can_download_contract: boolean
}) => {
  const isLoading = loadingContracts.find((item) => item.id === contract.id);
  if (isLoading) {
    return <LoadingWithLabel message={isLoading.message} />;
  }
  const showGenerateBillingsIcon =
    contract.status === ContractStatusEnum.PENDING_SIGNATURE || contract.status === ContractStatusEnum.SIGNED;
  return (
    <div className='actions'>
      {!isEmpty(contract.payment_simulation) && (
        <TooltipButton
          tooltipProps={{
            title: `${expandedSimulations.includes(~~contract.id) ? 'Ocultar' : 'Ver'} simulação de pagamento do contrato`
          }}
          Icon={MonetizationOn}
          iconProps={{
            style: { color: colors.green }
          }}
          iconButtonProps={{
            onClick: () =>
              setExpandedSimulation((current) => {
                if (current.includes(~~contract.id)) {
                  return current.filter((item) => item !== ~~contract.id);
                } else {
                  return current.concat(~~contract.id);
                }
              })
          }}
        />

      )}
      {showGenerateBillingsIcon && (
        <IconModal
          icon={Receipt}
          onConfirm={generateInvoices}
          tooltipText='Gerar Faturas'
          title='Gerar fatura'
          message='Deseja gerar faturas para esse contrato?'
          onOpen={(args) =>
            verifyIfCanGenerateBillings({
              ...args,
              contract_id: contract.id,
              payment_simulation_billings: contract.payment_simulation.payment.billings,
            })
          }
          disabled={disable_actions}
        />
      )}
      <IconModal
        icon={SearchIcon}
        title='Visualizar Contrato'
        tooltipText='Visualizar Contrato'
        onConfirm={() => null}
        cancelButtonText='Fechar'
        singleButton
      >
        <ViewContract contract_url={contract.signed_file_url || contract.file_url} />
      </IconModal>
      {
        can_download_contract && (
          <TooltipButton
            tooltipProps={{
              title: 'Baixar Contrato'
            }}
            Icon={Download}
            iconButtonProps={{
              href: contract.signed_file_url || contract.file_url,
              target: '_blank',
              rel: 'noreferrer'
            }}
          />
        )
      }
      {contract.status === ContractStatusEnum.TO_SIGN_SERVICE && (
        <TooltipButton
          tooltipProps={{
            title: 'Enviar contrato'
          }}
          Icon={Email}
          iconButtonProps={{
            disabled: disable_actions,
            onClick: () =>
              sendContract({
                registration_id: contract.registration_id.toString(),
                representative_id: contract.representative_id.toString(),
                contract_id: contract.id,
              })
          }}
        />
      )}
      {contract.status !== ContractStatusEnum.TO_SIGN_SERVICE && contract.status !== ContractStatusEnum.SIGNED && (
        <TooltipButton
          tooltipProps={{
            title: 'Reenviar contrato'
          }}
          Icon={ReplyAll}
          iconButtonProps={{
            onClick: () =>
              resendContract({
                contract_id: contract.id,
              })
          }}
        />
      )}
      {contract.status !== ContractStatusEnum.TO_SIGN_SERVICE && (
        <TooltipButton
          tooltipProps={{
            title: 'Atualizar estado do contrato'
          }}
          Icon={Sync}
          iconButtonProps={{
            onClick: () =>
              refreshContract({
                contract_id: contract.id,
              })
          }}
        />
      )}
      <IconModal
        icon={TrashIcon}
        iconProps={{
          style: {
            color: colors.lightRed,
          },
        }}
        disabled={disable_actions}
        title={`Tem certeza que deseja excluir o contrato?`}
        tooltipText='Excluir contrato'
        onConfirm={(props) => deleteContract(props, contract.id)}
        onOpen={(args) => verifyIfCanDeleteContract({ ...args, contract_id: contract.id })}
      />
    </div>
  );
};

export const ContractsTableBody = ({
  contracts,
  handleSorting,
  handleChangePageSize,
  handlePageChange,
  product_id,
  refreshResources,
  registration_id,
  registration_product_id,
  pagination,
  order,
  orderBy,
  is_registrations_view = true,
}: {
  contracts: ContractAttributes[];
  handlePageChange?: (_: any, newPage: number) => void;
  handleChangePageSize?: (e: any) => void;
  handleSorting?: (event: React.MouseEvent<unknown>, property: keyof ContractAttributes) => void;
  pagination?: PaginationType;
  product_id?: string;
  refreshResources?: () => Promise<void>;
  registration_id?: string;
  registration_product_id?: string;
  is_registrations_view?: boolean;
  order?: OrderOptions;
  orderBy?: string;
}) => {
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile, company },
    account: { companies },
    ui: {
      loading: { open },
    },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;


  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const can_download_contract = evaluate_permissions.can_download_contract(profile.role)
  const columns = [
    ...(is_above_school_director && !is_registrations_view ? ['Unidade'] : []),
    'Número',
    'Data Criação',
    'Data Envio',
    'Data Assinatura',
    ...(!is_registrations_view ? ['Aluno'] : []),
    'Responsável',
    ...(!is_registrations_view ? ['Produto'] : []),
    'Status',
    'Ações',
  ];
  const classes = useStyles();
  const [loadingContracts, setLoadingContracts] = React.useState<{ id: string; message: string }[]>([]);
  const [expandedSimulations, setExpandedSimulations] = React.useState<number[]>([]);
  const disable_actions = open;
  const disableGenerateButton = contracts.length > 0 || disable_actions;

  const dispatch = useDispatch();

  const fetchContractDataMethod = React.useCallback(async (contract_id: string, include: string) => {
    const response = await dispatch(
      FETCH_CONTRACT.request({
        id: contract_id,
        params: {
          filters: {
            include,
          },
        },
      }),
    );
    return response;
  }, []);
  const fetchContractPaymentData = React.useCallback(async (contract_id: string) => {
    try {
      const response = await fetchContractDataMethod(
        contract_id,
        ['representative_product.registration_product.payment_option.payment_collections', 'billings'].join(','),
      );
      const {
        data: { included },
      } = response;
      const includedPaymentCollections = map(
        filter(included, (item) => item.type === 'payment_collections') as PaymentCollectionJson[],
        (res) => ({ id: res.id, ...res.attributes }),
      );
      const includedPaymentOption = find(included, (item) => item.type === 'payment_options') as PaymentOptionJson;
      const includedBillings = map(
        filter(
          included,
          (item) => item.type === 'billings' && item.attributes.billable_type === 'Contract',
        ) as BillingJson[],
        (res) => ({ id: res.id, ...res.attributes }),
      );

      return {
        payment_option: {
          id: includedPaymentOption.id,
          ...includedPaymentOption.attributes,
        },
        payment_collections: includedPaymentCollections,
        billings: includedBillings,
      };
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao obter informações da opção de pagamento do produto',
        }),
      );
      return {
        payment_option: null,
        payment_collections: [],
        billings: [],
      };
    }
  }, []);

  const fetchContractInvoicesData = React.useCallback(async (contract_id: string) => {
    try {
      const response = await fetchContractDataMethod(contract_id, 'invoices');
      const {
        data: { included },
      } = response;
      const includedInvoices = map(filter(included, (item) => item.type === 'invoices') as InvoiceJson[], (res) => ({
        id: res.id,
        ...res.attributes,
      }));

      return {
        invoices: includedInvoices,
      };
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao obter informações das faturas do contrato',
        }),
      );
      return {
        invoices: [],
      };
    }
  }, []);

  const verifyIfCanDeleteContract = React.useCallback(
    async (args: { handleClose: () => void; contract_id: string }) => {
      const { contract_id, handleClose } = args;
      try {
        const result = await fetchContractInvoicesData(contract_id);
        const there_is_paid_invoices = result.invoices.some((invoice) => invoice.status === InvoiceStatusEnum.FINISHED);
        if (there_is_paid_invoices) {
          dispatch(
            error({
              message: 'Não é possível excluir o contrato pois há faturas pagas para este contrato',
            }),
          );
          handleClose();
        }
      } catch (error) {
        handleClose();
      }
    },
    [],
  );

  const verifyIfCanGenerateBillings = React.useCallback(
    async (args: {
      handleClose: () => void;
      contract_id: string;
      payment_simulation_billings: PaymentSimulationBillings[];
    }) => {
      const { handleClose, contract_id, payment_simulation_billings } = args;
      try {
        const result = await fetchContractPaymentData(contract_id);
        const { payment_option, payment_collections, billings } = result;
        if (!payment_option) {
          dispatch(
            error({
              message: 'Erro ao obter informações da opção de pagamento do contrato',
            }),
          );
          handleClose();
        } else if (!isEmpty(billings)) {
          dispatch(
            warning({
              message: 'Não é possível gerar faturas para esse contrato pois já foram geradas anteriormente',
            }),
          );
          handleClose();
        } else if (
          !checkPaymentOptionAndCollections({ payment_option, payment_collections, payment_simulation_billings })
        ) {
          dispatch(
            error({
              message: 'Configurações da opção pagamento não estão adequadas para a geração das faturas desse contrato',
            }),
          );
          handleClose();
        }
      } catch (error) {
        handleClose();
      }
    },
    [],
  );

  const sendContract = React.useCallback(
    async ({
      registration_id,
      representative_id,
      contract_id,
    }: {
      registration_id: string;
      representative_id: string;
      contract_id: string;
    }) => {
      try {
        setLoadingContracts((current) => current.concat({ id: contract_id, message: 'Enviando' }));
        await dispatch(
          SEND_CONTRACT.request({
            data: {
              registration_id: ~~registration_id,
              representative_id: ~~representative_id,
            },
            id: contract_id,
          }),
        );

        dispatch(
          success({
            title: 'Envio de contrato',
            message: 'Contrato enviado com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
        refreshResources?.();
      } catch (err) {
        setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
        dispatch(
          error({
            message: 'Erro no envio do contrato',
          }),
        );
      }
    },
    [],
  );

  const resendContract = React.useCallback(async ({ contract_id }: { contract_id: string }) => {
    try {
      setLoadingContracts((current) => current.concat({ id: contract_id, message: 'Reenviando' }));
      await dispatch(
        RESEND_CONTRACT.request({
          id: contract_id,
        }),
      );
      dispatch(
        success({
          title: 'Reenvio de contrato',
          message: 'Contrato reenviado com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
      refreshResources?.();
    } catch (err) {
      setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
      dispatch(
        error({
          message: 'Erro no reenvio do contrato',
        }),
      );
    }
  }, []);

  const refreshContract = React.useCallback(async ({ contract_id }: { contract_id: string }) => {
    try {
      setLoadingContracts((current) => current.concat({ id: contract_id, message: 'Atualizando' }));
      await dispatch(
        REFRESH_CONTRACT.request({
          id: contract_id,
        }),
      );
      dispatch(
        success({
          title: 'Atualização de contrato',
          message: 'Contrato atualizado com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
      refreshResources?.();
    } catch (err) {
      setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
      dispatch(
        error({
          message: 'Erro na atualização do contrato',
        }),
      );
    }
  }, []);

  const generateBillings = React.useCallback(async ({ contract_id }: { contract_id: string }) => {
    try {
      setLoadingContracts((current) => current.concat({ id: contract_id, message: 'Gerando faturas' }));
      await dispatch(
        GENERATE_BILLINGS.request({
          id: contract_id,
        }),
      );
      dispatch(
        success({
          title: 'Geração de faturas',
          message: 'Faturas geradas com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
      refreshResources?.();
    } catch (err) {
      setLoadingContracts((current) => current.filter((item) => item.id !== contract_id));
      dispatch(
        error({
          message: 'Erro na geração de faturas',
        }),
      );
    }
  }, []);

  const deleteContractMethod = React.useCallback(async (id: string) => {
    try {
      setLoadingContracts((current) => current.concat({ id, message: 'Excluindo contrato' }));
      await dispatch(
        DELETE_CONTRACT.request({
          id,
        }),
      );
      setLoadingContracts((current) => current.filter((item) => item.id !== id));
    } catch (err) {
      setLoadingContracts((current) => current.filter((item) => item.id !== id));
      dispatch(
        error({
          message: 'Erro ao deletar contrato',
        }),
      );
    }
  }, []);

  const deleteContract = React.useCallback(
    async (
      {
        setLoading,
        handleClose,
      }: {
        setLoading: React.Dispatch<React.SetStateAction<boolean>>;
        handleClose: () => void;
      },
      id: string,
    ) => {
      setLoading(true);
      await deleteContractMethod(id);
      await refreshResources?.();
      setLoading(false);
      handleClose();
    },
    [],
  );

  return (
    <div>
      {is_registrations_view && registration_id && product_id && registration_product_id && refreshResources && (
        <GenerateContractButtons
          disabled={disableGenerateButton}
          setLoadingContracts={setLoadingContracts}
          refreshResources={refreshResources}
          registration_id={registration_id}
          product_id={product_id}
          registration_product_id={registration_product_id}
        />
      )}
      {!isEmpty(contracts) ? (
        <>
          {is_registrations_view && <span className='product-contract-title'>Contratos do produto</span>}
          <div css={classes.table}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((item) => {
                      if (item === 'Data Assinatura' && !is_registrations_view) {
                        return (
                          <TableCell key={item} align='center' sortDirection={orderBy === 'signed_at' ? order : false}>
                            <TableSortLabel
                              active={orderBy === 'signed_at'}
                              direction={orderBy === 'signed_at' ? order : 'asc'}
                              onClick={(e) => handleSorting?.(e, 'signed_at')}
                            >
                              Data Assinatura
                            </TableSortLabel>
                          </TableCell>
                        );
                      }
                      return <TableCell key={item}>{item}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className='products-contract-table-body'>
                  {contracts.map((contract, index) => {
                    const generateInvoices = async ({
                      setLoading,
                      handleClose,
                    }: {
                      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
                      handleClose: () => void;
                    }) => {
                      setLoading(true);
                      await generateBillings({
                        contract_id: contract.id,
                      });
                      setLoading(false);
                      handleClose();
                    };
                    const className = index !== 0 ? '' : 'highlighted-contract';
                    const statusInfo = contractStatusOptions?.find((item) => item.value === contract.status)?.label;
                    const payment_simulation = contract.payment_simulation;
                    let payment_info = {};
                    let parsed_result = {};
                    if (payment_simulation) {
                      const punctuality_discount_portions = payment_simulation.payment.billings
                        .filter((billing) => billing.punctuality_included)
                        .map((billing) => {
                          if (billing.kind === 'registration_fee') {
                            return 0;
                          } else {
                            const { month } = getMonthAndYearFromBilling(billing);
                            return month;
                          }
                        });
                      const { paymentInfo, parsedResult } = getBillingsSimulationData({
                        payment: payment_simulation.payment,
                        punctuality_discount_portions,
                        simulation_id: contract.id,
                      });
                      payment_info = paymentInfo;
                      parsed_result = parsedResult;
                    }
                    return (
                      <>
                        <TableRow className={className} key={contract.id}>
                          {is_above_school_director && !is_registrations_view && (
                            <TableCell>{contract.company_name}</TableCell>
                          )}
                          <TableCell>
                            {contract.status !== ContractStatusEnum.TO_SIGN_SERVICE && contract.contract_number}
                          </TableCell>
                          <TableCell>{format(parseISO(contract.created_at), 'dd-MM-yyyy HH:mm')}</TableCell>
                          <TableCell>
                            {!isEmpty(contract.sent_at) && format(parseISO(contract.sent_at), 'dd-MM-yyyy HH:mm')}
                          </TableCell>
                          <TableCell>
                            {!isEmpty(contract.signed_at) && format(parseISO(contract.signed_at), 'dd-MM-yyyy HH:mm')}
                          </TableCell>
                          {!is_registrations_view && <TableCell>{contract.student_name}</TableCell>}
                          <TableCell>{contract.representative_name}</TableCell>
                          {!is_registrations_view && <TableCell>{contract.product_name}</TableCell>}
                          <TableCell>{statusInfo}</TableCell>
                          <TableCell>
                            <ContractStepTableActions
                              billings={payment_simulation?.payment?.billings}
                              contract={contract}
                              deleteContract={deleteContract}
                              disable_actions={disable_actions}
                              expandedSimulations={expandedSimulations}
                              generateInvoices={generateInvoices}
                              loadingContracts={loadingContracts}
                              refreshContract={refreshContract}
                              resendContract={resendContract}
                              sendContract={sendContract}
                              setExpandedSimulation={setExpandedSimulations}
                              verifyIfCanDeleteContract={verifyIfCanDeleteContract}
                              verifyIfCanGenerateBillings={verifyIfCanGenerateBillings}
                              can_download_contract={can_download_contract}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                            <Collapse in={expandedSimulations.includes(~~contract.id)} timeout='auto' unmountOnExit>
                              <BillingTable
                                paymentInfo={payment_info as PaymentSimulationPaymentInfo[]}
                                result={parsed_result as ParsedPaymentSimulationDataResult}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
                {pagination && handlePageChange && handleChangePageSize && (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 15, 20]}
                        colSpan={columns?.length + 1}
                        count={pagination.totalCount}
                        rowsPerPage={pagination.pageSize}
                        page={pagination.pageNumber}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleChangePageSize}
                        labelRowsPerPage='Itens Por página'
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                        }
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <span>Não há contratos para esse produto</span>
      )}
    </div>
  );
};

const ContractsTable = ({
  data,
  searchForOptions,
  filterOption,
  setFilterOption,
  defaultPagination,
  pagination,
  fetchContractsMethod,
  filterValue,
  setFilterValue,
  statusFilterValue,
  setStatusFilterValue,
  ktwelveFilterValue,
  setKtwelveFilterValue,
  classroomFilterValue,
  setClassroomFilterValue,
  periodFilterValue,
  handleChangePageSize,
  handlePageChange,
  setPeriodFilterValue,
  yearFilterValue,
  setYearFilterValue,
  productTypeFilterValue,
  setProductTypeFilterValue,
  tab,
  pendingSignatureStatusFilterValue,
  setPendingSignatureStatusFilterValue,
  contractNumberFilterValue,
  setContractNumberFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}: {
  data: ContractsViewData;
  searchForOptions: DefaultOptionType[];
  filterOption: string;
  setFilterOption: React.Dispatch<React.SetStateAction<string>>;
  defaultPagination: PaginationType;
  pagination: PaginationType;
  fetchContractsMethod: ({
    newPagination,
    status,
  }: {
    newPagination?: PaginationType;
    status?: ContractStatusTypes | string;
    period?: PeriodTypes | string;
    year?: Date | null;
    productType?: ProductTypes | string;
    pendingSignatureStatus?: PendingSignaturesStatusTypes | string;
    company_filter?: string;
    order_filter?: string | undefined;
    order_direction_filter?: string | undefined;
  }) => void;
  filterValue: string;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  ktwelveFilterValue: string;
  setKtwelveFilterValue: React.Dispatch<React.SetStateAction<string>>;
  classroomFilterValue: string;
  setClassroomFilterValue: React.Dispatch<React.SetStateAction<string>>;
  periodFilterValue: PeriodTypes | string;
  setPeriodFilterValue: React.Dispatch<React.SetStateAction<PeriodTypes | string>>;
  statusFilterValue: string;
  setStatusFilterValue: React.Dispatch<React.SetStateAction<ContractStatusTypes | string>>;
  yearFilterValue: Date | null;
  setYearFilterValue: React.Dispatch<React.SetStateAction<Date | null>>;
  productTypeFilterValue: ProductTypes | string;
  setProductTypeFilterValue: React.Dispatch<React.SetStateAction<ProductTypes | string>>;
  tab: 'contracts' | 'signatures';
  pendingSignatureStatusFilterValue: string | PendingSignaturesStatusTypes;
  setPendingSignatureStatusFilterValue: React.Dispatch<React.SetStateAction<string | PendingSignaturesStatusTypes>>;
  contractNumberFilterValue: string;
  setContractNumberFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
  order: OrderOptions;
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<OrderOptions>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  const handleSorting = (event: React.MouseEvent<unknown>, property: keyof ContractAttributes) => {
    const isAsc = orderBy === property && order === 'asc';
    const new_order = isAsc ? 'desc' : 'asc';
    setOrder(new_order);
    setOrderBy(property);
    fetchContractsMethod({
      newPagination: defaultPagination,
      order_direction_filter: new_order,
      order_filter: property,
    });
  };

  const classes = useStyles();
  return (
    <div css={classes.table}>
      <span className='subtitle'>Filtros</span>
      <div css={classes.filters}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            input={{
              value: companyFilterValue,
              onChange: (e: any) => {
                setCompanyFilterValue(e.target.value);
                fetchContractsMethod({
                  newPagination: { ...defaultPagination },
                  company_filter: e.target.value,
                });
              },
            }}
          />
        )}
        <SelectComponent
          placeholder='Buscar por'
          small
          options={searchForOptions}
          input={{
            value: filterOption,
            onChange: (e: any) => {
              setFilterOption(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar por ${searchForOptions?.find((item) => item.value === filterOption)?.label || ''}`}
          search
          small
          onSearch={() => {
            fetchContractsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: filterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setFilterValue(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar por número`}
          search
          small
          onSearch={() => {
            fetchContractsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: contractNumberFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setContractNumberFilterValue(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar por série`}
          search
          small
          onSearch={() => {
            fetchContractsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: ktwelveFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setKtwelveFilterValue(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar por turma`}
          search
          small
          onSearch={() => {
            fetchContractsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: classroomFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setClassroomFilterValue(e.target.value);
            },
          }}
        />
        <SelectComponent
          placeholder='Turno'
          small
          options={periodOptions}
          clearable
          clearFieldFunction={() => {
            setPeriodFilterValue('');
            fetchContractsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
              period: '',
            });
          }}
          input={{
            value: periodFilterValue,
            onChange: (e: any) => {
              setPeriodFilterValue(e.target.value);
              fetchContractsMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                period: e.target.value,
              });
            },
          }}
        />
        <SelectComponent
          placeholder='Tipo do produto'
          small
          options={productTypeOptions}
          clearable
          clearFieldFunction={() => {
            setProductTypeFilterValue('');
            fetchContractsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
              productType: '',
            });
          }}
          input={{
            value: productTypeFilterValue,
            onChange: (e: any) => {
              setProductTypeFilterValue(e.target.value);
              fetchContractsMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                productType: e.target.value,
              });
            },
          }}
        />
        {tab === 'contracts' && (
          <SelectComponent
            placeholder='Status'
            small
            options={contractStatusOptions}
            clearable
            clearFieldFunction={() => {
              setStatusFilterValue('');
              fetchContractsMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                status: '',
              });
            }}
            input={{
              value: statusFilterValue,
              onChange: (e: any) => {
                const value = e?.target?.value || '';
                setStatusFilterValue(value);
                fetchContractsMethod({
                  newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                  status: value,
                });
              },
            }}
          />
        )}
        {tab === 'signatures' && (
          <SelectComponent
            placeholder='Status'
            small
            options={pendingSignatureStatusOptions}
            clearable
            clearFieldFunction={() => {
              setPendingSignatureStatusFilterValue('');
              fetchContractsMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                pendingSignatureStatus: '',
              });
            }}
            input={{
              value: pendingSignatureStatusFilterValue,
              onChange: (e: any) => {
                setPendingSignatureStatusFilterValue(e.target.value);
                fetchContractsMethod({
                  newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                  pendingSignatureStatus: e.target.value,
                });
              },
            }}
          />
        )}
        <div style={{ flex: 1, maxWidth: '14rem' }}>
          <DatePickerComponent
            placeholder='Ano letivo'
            small
            datePickerProps={{
              views: ['year'],
              inputFormat: 'yyyy',
              allowSameDateSelection: true,
            }}
            input={{
              value: yearFilterValue,
              name: 'year',
              onChange: (e: Date) => {
                setYearFilterValue(e);
                fetchContractsMethod({
                  newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                  year: e,
                });
              },
            }}
          />
        </div>
      </div>
      <ContractsTableBody
        contracts={data.contracts}
        handleSorting={handleSorting}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        is_registrations_view={false}
        pagination={pagination}
      />
    </div>
  );
};

export default ContractsTable;
