/* eslint-disable camelcase */
import React from 'react';

import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  discountKindOptions,
  PaginationType,
  Role,
  defaultPagination,
  DiscountTypesViewAttributes,
  CompanyAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import SelectComponent from '../../input/form/select';
import { css, useTheme } from '@emotion/react';
import DiscountTypesFormContainer from '../../../containers/DiscountTypesFormContainer';
import TooltipButton from '../../shared/TooltipButton';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
    buttonCreate: css`
      & button.small {
        padding: 1.1vh 3vw;
        font-size: 1vw;
    }
    `
  };
};


const DiscountTypesView = ({
  discountTypes,
  pagination,
  handleChangePageSize,
  handlePageChange,
  descriptionFilterValue,
  setDescriptionFilterValue,
  nameFilterValue,
  setNameFilterValue,
  fetchDiscountTypesMethod,
  setCompanyFilterValue,
  companyFilterValue,
}: {
  discountTypes: DiscountTypesViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  descriptionFilterValue: string;
  setDescriptionFilterValue: React.Dispatch<React.SetStateAction<string>>;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchDiscountTypesMethod: ({
    newPagination,
    company_filter,
  }: {
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}) => {
  const [create, setCreate] = React.useState(false)
  const [editDiscount, setEditDiscount] = React.useState<string[]>([])
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  const init = async () => {
    await fetchDiscountTypesMethod({})
  }

  const handleSaveDiscount = async () => {
    setCreate(false)
    await init()
  }

  const handleOpenEditForm = (id: string) => {
     setEditDiscount((prev) => {
      if (id && prev.includes(id)) {
        return prev.filter(item => item !== id)
      } else {
        return prev.concat(id)
      }
     })
  }

  const handleCloseEditForm = (id: any) => {
    setEditDiscount((prevState) => prevState.filter((item) => item !== id))
  }


  const columns = [
    ...(is_above_school_director ? ['Unidade'] : []),
    'Nome',
    'Descrição',
    'Tipo Sugerido',
    'Valor Sugerido'
  ];
  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Tipos de descontos</span>
      </div>      
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchDiscountTypesMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}

          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchDiscountTypesMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por descrição`}
            search
            small
            onSearch={() => {
              fetchDiscountTypesMethod({});
            }}
            input={{
              value: descriptionFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align={'left'} key={item}>{item}</TableCell>
                ))}
                <TableCell css={classes.buttonCreate} >
                  <button
                    className='green small' 
                    onClick={() => setCreate(prev => !prev)}
                    disabled={editDiscount.length > 0}
                  >
                    <span style={{}}>Criar tipo de desconto</span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <div>
                      <DiscountTypesFormContainer 
                        onSave={handleSaveDiscount}
                        close_form={() => setCreate(false)}
                      />
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
              {discountTypes.map((discount_type) => {
                const kind = discountKindOptions.find((item) => item.value === discount_type.suggested_kind)?.label;
                return (
                 <React.Fragment key={discount_type.id}>
                    <TableRow>
                      {is_above_school_director && (
                        <TableCell>{discount_type.company}</TableCell>
                      )}
                      <TableCell>{discount_type.name}</TableCell>
                      <TableCell>{discount_type.description}</TableCell>
                      <TableCell>{kind}</TableCell>
                      <TableCell>{discount_type.suggested_discount}</TableCell>
                      <TableCell align='right'>
                          <TooltipButton
                            tooltipProps={{
                              title: editDiscount.includes(discount_type.id)
                                ? 'Cancelar edição'
                                : 'Editar desconto'
                            }}
                            Icon={PenIcon}
                            iconButtonProps={{
                              disabled: create || (editDiscount.length > 0 && !editDiscount.includes(discount_type.id)),
                              onClick: () => handleOpenEditForm(discount_type.id)
                            }}
                          />
                      </TableCell>
                    </TableRow>

                  {
                    editDiscount.includes(discount_type.id) && (
                      <TableRow>
                        <TableCell  style={{ padding: 0 }}  colSpan={columns.length + 2} >
                          <div>
                              <Collapse in={editDiscount.includes(discount_type.id)} timeout='auto' unmountOnExit>
                                    <DiscountTypesFormContainer 
                                      close_form={ () => handleCloseEditForm(discount_type.id)}
                                      onSave={ async () => {
                                        handleCloseEditForm(discount_type.id)
                                        await init()
                                      }}
                                      discount_type_id={discount_type.id}
                                      company_id={discount_type.id}
                                    />
                              </Collapse>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DiscountTypesView;
