/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LiaSchoolSolid } from "react-icons/lia";
import { colors, ClassTimeAttributes, classTimeKindOptions, RoomScheduleAttributes, RoomAttributes } from '../../utils/constants';
import { format, parseISO } from 'date-fns';
import { map } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Collapse } from '@mui/material';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import PenIcon from '../icon/PenIcon';
import { FETCH_ROOM_SCHEDULE } from '../../store/room_schedules';
import { ClassTimeJson } from '../../store/class_times';
import ClassTimesFormContainer from '../../containers/ClassTimesFormContainer';
import ClassTimeSubjectsTable from './ClassTimeSubjectsTable';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const ClassTimesTable = ({ room_schedule, room }: { room_schedule: RoomScheduleAttributes, room: RoomAttributes }) => {
  const [editClassTime, setEditingRoomStudent] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const [class_times, setClassTimes] = React.useState<ClassTimeAttributes[]>([]);
  const [expandedClassTimeSubjects, setExpandedClassTimeSubjects] = React.useState<number[]>([]);

  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const fetchClassTimes = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_ROOM_SCHEDULE.request({
          id: room_schedule.id,
          params: {
            filters: {
              include: 'class_times',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const room_students_attributes = map(
        included.filter((incl) => incl.type === 'class_times') as ClassTimeJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setClassTimes(room_students_attributes);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar periodos',
        }),
      );
      setLoading(false);
    }
  }, [room_schedule]);

  const init = async () => {
    await fetchClassTimes();

  }
  React.useEffect(() => {
    init()
  }, []);

  if (loading){
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='class-times'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Tipo</TableCell>
          <TableCell align='center'>Início</TableCell>
          <TableCell align='center'>Fim</TableCell>
          <TableCell align='right'>
            {' '}
            <button disabled={editClassTime.length > 0} onClick={() => setCreate(!create)} className='green small'>
              <span> Adicionar Horário</span>
            </button>
          </TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <ClassTimesFormContainer
                room={room}
                onSave={async () => {
                  await fetchClassTimes();
                  setCreate(false);
                }}
                close_form={() => setCreate(false)}
                room_schedule={room_schedule}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {class_times.map((class_time) => {
          const kindOption = classTimeKindOptions.find(opt => opt.value === class_time.kind)
          const close_editing_form = () => {
            setEditingRoomStudent((current) => current.filter((item) => item !== ~~class_time.id));
          };
          return (
            <React.Fragment key={class_time.id}>
              <TableRow>
                <TableCell align='center'>{kindOption?.label}</TableCell>
                <TableCell align='center'>{format(parseISO(class_time.starts_at), 'H:mm')}</TableCell>
                <TableCell align='center'>{format(parseISO(class_time.ends_at), 'H:mm')}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedClassTimeSubjects.includes(~~class_time.id)
                        ? 'Ocultar disciplinas do horário'
                        : 'Ver disciplinas do horário',
                    }}
                    Icon={LiaSchoolSolid}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedClassTimeSubjects((current) => {
                          if (current.includes(~~class_time.id)) {
                            return current.filter((item) => item !== ~~class_time.id);
                          } else {
                            return current.concat(~~class_time.id);
                          }
                        }),
                    }}
                  />
                  <TooltipButton
                    tooltipProps={{
                      title: editClassTime.includes(~~class_time.id)
                        ? 'Cancelar edição'
                        : 'Editar Horário',
                    }}
                    Icon={PenIcon}
                    iconButtonProps={{
                      disabled:
                        create || (editClassTime.length > 0 && !editClassTime.includes(~~class_time.id)),
                      onClick: () =>
                        setEditingRoomStudent((current) => {
                          if (current.includes(~~class_time.id)) {
                            return current.filter((item) => item !== ~~class_time.id);
                          } else {
                            return current.concat(~~class_time.id);
                          }
                        }),
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                  <Collapse in={expandedClassTimeSubjects.includes(~~class_time.id)} timeout='auto' unmountOnExit>
                    <ClassTimeSubjectsTable room={room} class_time={class_time} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={editClassTime.includes(~~class_time.id)} timeout='auto' unmountOnExit>
                    <ClassTimesFormContainer room={room} onSave={fetchClassTimes} close_form={close_editing_form} room_schedule={room_schedule} class_time_id={class_time.id} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ClassTimesTable;
