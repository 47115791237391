/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { colors, RoomAttributes, ClassTimeSubjectAttributes, TeacherClassTimeAttributes } from '../../utils/constants';
import { map } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Collapse } from '@mui/material';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import PenIcon from '../icon/PenIcon';
import { TeacherClassTimeJson } from '../../store/teacher_class_times';
import { FETCH_CLASS_TIME_SUBJECT } from '../../store/class_time_subjects';
import TeacherClassTimeFormContainer from '../../containers/TeacherClassTimesFormContainer';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const TeacherClassTimesTable = ({ room, class_time_subject }: { room: RoomAttributes, class_time_subject: ClassTimeSubjectAttributes }) => {
  const [editClassTimeSubject, setEditingClassTimeSubject] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const [teacher_class_times, setTeacherClassTimes] = React.useState<TeacherClassTimeAttributes[]>([]);

  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const fetchClassTimeSubjects = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_CLASS_TIME_SUBJECT.request({
          id: class_time_subject.id,
          params: {
            filters: {
              include: 'teacher_class_times',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const teacher_class_times = map(
        included.filter((incl) => incl.type === 'teacher_class_times') as TeacherClassTimeJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setTeacherClassTimes(teacher_class_times);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar professores da disciplina no horário',
        }),
      );
      setLoading(false);
    }
  }, [class_time_subject]);

  const init = async () => {
    await fetchClassTimeSubjects();

  }
  React.useEffect(() => {
    init()
  }, []);

  if (loading){
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='teacher-class-times'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Professor</TableCell>
          <TableCell align='right'>
            {' '}
            <button disabled={editClassTimeSubject.length > 0} onClick={() => setCreate(!create)} className='green small'>
              <span> Adicionar professor na disciplina do horário</span>
            </button>
          </TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <TeacherClassTimeFormContainer
                room={room}
                onSave={async () => {
                  await fetchClassTimeSubjects();
                  setCreate(false);
                }}
                close_form={() => setCreate(false)}
                class_time_subject={class_time_subject}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {teacher_class_times.map((teacher_class_time) => {
          const close_editing_form = () => {
            setEditingClassTimeSubject((current) => current.filter((item) => item !== ~~teacher_class_time.id));
          };
          return (
            <React.Fragment key={teacher_class_time.id}>
              <TableRow>
                <TableCell align='center'>{teacher_class_time.teacher_name}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: editClassTimeSubject.includes(~~teacher_class_time.id)
                        ? 'Cancelar edição'
                        : 'Editar Professor da disciplina no horário',
                    }}
                    Icon={PenIcon}
                    iconButtonProps={{
                      disabled:
                        create || (editClassTimeSubject.length > 0 && !editClassTimeSubject.includes(~~teacher_class_time.id)),
                      onClick: () =>
                        setEditingClassTimeSubject((current) => {
                          if (current.includes(~~teacher_class_time.id)) {
                            return current.filter((item) => item !== ~~teacher_class_time.id);
                          } else {
                            return current.concat(~~teacher_class_time.id);
                          }
                        }),
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={editClassTimeSubject.includes(~~teacher_class_time.id)} timeout='auto' unmountOnExit>
                    <TeacherClassTimeFormContainer
                      room={room}
                      onSave={fetchClassTimeSubjects}
                      close_form={close_editing_form}
                      class_time_subject={class_time_subject}
                      teacher_class_time_id={teacher_class_time.id}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TeacherClassTimesTable;
