/* eslint-disable camelcase */
import React from 'react';

import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  PaginationType,
  Role,
  defaultPagination,
  CoordinatorAttributes,
  CompanyAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import TrashIcon from '../../icon/TrashIcon';
import { IconModal } from '../../modal/Modal';
import TooltipButton from '../../shared/TooltipButton';
import { RiChatHistoryFill, RiChatHistoryLine, RiFileHistoryLine } from 'react-icons/ri';
import ClassPlanLogTable from '../../table/ClassPlanLogTable';
import ExamsLog from '../../table/ExamsLogTable';
import StudentAbsenceLogTable from '../../table/StudentAbsenceLogTable';
import { css, useTheme } from '@emotion/react';
import CoordinatorsFormContainer from '../../../containers/CoordinatorsFormContainer';

export const useStyles = () => {
  const theme = useTheme() as Theme;

  return {
    view: css`
      width: inherit;
      min-height: 100%;
      height: fit-content;
      display: flex;
      padding: 2rem;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      ${theme.breakpoints?.down('lg')} {
        padding: 2rem 1rem;
      }

      & .MuiBackdrop-root {
        position: inherit;
      }

      & .MuiPaper-root {
        background: none;
        box-shadow: none;
        overflow: hidden;
      }
    `,
    tableTitle: css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    `,
    button: css`
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      background: ${colors.green};
      padding: 1rem 4rem;
      border: none;
    `,
    filters: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      & > span {
        margin-bottom: 14px;
      }

      & .inputArea {
        width: 10rem !important;
        padding-right: 3rem;
      }
    `,
    table: css`
      background: ${colors.lightBlue};
      padding: 2rem;
      border-radius: 4px;
      display: grid;

      & .subtitle {
        text-align: start;
        margin-bottom: 1rem;
      }

      & .MuiTableContainer-root {
        background: inherit;
        box-shadow: none;

        & .MuiTableCell-root {
          border-bottom: 1px solid ${colors.grayBlue};
        }

        & th {
          color: ${colors.darkGrayBlue};
        }

        & td {
          font-size: 12px;
          color: ${colors.darkBlue};

          ${theme.breakpoints?.down('lg')} {
            font-size: 0.75rem;
          }

          & svg {
            cursor: pointer;
            margin: 0 0.25rem;
          }
        }

        & td.MuiTableCell-footer {
          border-bottom: none;
        }
      }
    `,
  };
};

interface ICoordinatorsViewProps {
  coordinators: CoordinatorAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  courseFiltervalue: string;
  setCourseFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchCoordinatorsMethod: ({
    newPagination,
    company_filter,
  }: {
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
  deleteCoordinator: (id: string, { setLoading, handleClose }: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => Promise<void>
}

const CoordinatorsView: React.FC<ICoordinatorsViewProps> = ({
  coordinators,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchCoordinatorsMethod,
  courseFiltervalue,
  setCourseFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
  deleteCoordinator
}) => {
  const classes = useStyles();
  const [expandedExamLog, setExpandedExamLog] = React.useState<number[]>([]);
  const [expandedPresenceLog, setExpandedPresenceLog] = React.useState<number[]>([]);
  const [expandedClassPlanLog, setExpandedClassPlanLog] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false)
  const [editCoordinator, setEditCoordinator] = React.useState<number[]>([])

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Nome', 'Curso', 'Código do Curso'];

  const init = async () => {
    await fetchCoordinatorsMethod({})
  }

  const handleOpenCreateForm = () => {
    setCreate(prevState => !prevState)
  }

  const handleOpenEditForm = (id: number) => {
    setEditCoordinator(prevState => {
      if (id && prevState.includes(id)) {
        return prevState.filter((item) => item !== id)
      } else {
        return prevState.concat(id)
      }
    })
  }

  const handleCloseEditForm = (id: number) => {
    setEditCoordinator(prevState => prevState.filter((item) => item !== id))
  }

  const handleSaveCoordinator = async () => {
    setCreate(false)
    await init()
  }

  return (
    <div css={classes.view}>
      <div css={classes.tableTitle}>
        <span className='title'>Coordenadores</span>
      </div>
      <div css={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div css={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchCoordinatorsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchCoordinatorsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por curso`}
            search
            small
            onSearch={() => {
              fetchCoordinatorsMethod({});
            }}
            input={{
              value: courseFiltervalue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setCourseFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item,) => {
                  return (
                    <TableCell align={item === 'Código do Curso' ? 'center' : 'left'} key={item}>{item}</TableCell>
                  )
                })}
                <TableCell align='right'>
                  <button
                    className='green small'
                    onClick={handleOpenCreateForm}
                    disabled={editCoordinator.length > 0}
                  >
                    <span>Criar coordenador</span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <div>
                      <CoordinatorsFormContainer
                        close_form={() => setCreate(false)}
                        onSave={handleSaveCoordinator}
                      />
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
              {coordinators.map((coordinator) => {
                return (
                  <React.Fragment key={coordinator.id}>
                    <TableRow>
                      {is_above_school_director && <TableCell align='left'>{coordinator.coordinator_company_name}</TableCell>}
                      <TableCell align='left'>{coordinator.coordinator_name}</TableCell>
                      <TableCell align='left'>{coordinator.course_name}</TableCell>
                      <TableCell align='center'>{coordinator.course_code}</TableCell>
                      <TableCell align='right'>
                        <TooltipButton
                          tooltipProps={{
                            title: expandedExamLog.includes(~~coordinator.id)
                              ? 'Ocultar log de alterações em planos de aula'
                              : 'Ver log de alterações em planos de aula',
                          }}
                          Icon={RiFileHistoryLine}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedClassPlanLog((current) => {
                                if (current.includes(~~coordinator.id)) {
                                  return current.filter((item) => item !== ~~coordinator.id);
                                } else {
                                  return current.concat(~~coordinator.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedExamLog.includes(~~coordinator.id)
                              ? 'Ocultar log de alterações em notas'
                              : 'Ver log de alterações em notas',
                          }}
                          Icon={RiChatHistoryLine}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedExamLog((current) => {
                                if (current.includes(~~coordinator.id)) {
                                  return current.filter((item) => item !== ~~coordinator.id);
                                } else {
                                  return current.concat(~~coordinator.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedPresenceLog.includes(~~coordinator.id)
                              ? 'Ocultar log de alterações em faltas'
                              : 'Ver log de alterações em faltas',
                          }}
                          Icon={RiChatHistoryFill}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedPresenceLog((current) => {
                                if (current.includes(~~coordinator.id)) {
                                  return current.filter((item) => item !== ~~coordinator.id);
                                } else {
                                  return current.concat(~~coordinator.id);
                                }
                              }),
                          }}
                        />

                        <TooltipButton
                          tooltipProps={{
                            title: editCoordinator.includes(Number(coordinator.id))
                              ? 'Cancelar edição'
                              : 'Editar coordenador'
                          }}
                          Icon={PenIcon}
                          iconButtonProps={{
                            onClick: () => handleOpenEditForm(Number(coordinator.id)),
                            disabled: create || (editCoordinator.length > 0 && !editCoordinator.includes(Number(coordinator.id)))
                          }}
                        />
                        <IconModal
                          icon={TrashIcon}
                          title={'Excluir coordenador'}
                          tooltipText='Excluir coordenador'
                          onConfirm={(props) => deleteCoordinator(coordinator.id, props)}
                          message='Tem certeza que deseja remover o coordenador?'
                          iconProps={{ style: { color: 'red' } }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
                        <Collapse
                          in={editCoordinator.includes(Number(coordinator.id))}
                          timeout='auto'
                          unmountOnExit
                        >
                          <div>
                            <CoordinatorsFormContainer
                              close_form={() => handleCloseEditForm(Number(coordinator.id))}
                              onSave={async () => {
                                handleCloseEditForm(Number(coordinator.id))
                                await init()
                              }}
                              coordinator_id={Number(coordinator.id)}
                              company_id={Number(coordinator.coordinator_company_id)}
                            />
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedClassPlanLog.includes(~~coordinator.id)} timeout='auto' unmountOnExit>
                          <ClassPlanLogTable user_id={coordinator.user_id.toString()} company_id={coordinator.coordinator_company_id.toString()} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedExamLog.includes(~~coordinator.id)} timeout='auto' unmountOnExit>
                          <ExamsLog user_id={coordinator.user_id.toString()} company_id={coordinator.coordinator_company_id.toString()} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedPresenceLog.includes(~~coordinator.id)} timeout='auto' unmountOnExit>
                          <StudentAbsenceLogTable user_id={coordinator.user_id.toString()} company_id={coordinator.coordinator_company_id.toString()} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>

                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={(props) => {
                    return (
                      <TablePaginationActions
                        {...props}
                        disableButton={create || editCoordinator.length > 0}
                      />
                    )
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CoordinatorsView;
