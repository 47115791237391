/* eslint-disable camelcase */
import { compose } from 'redux';
import { change, Field, FieldArray, FormSection, GenericField, getFormValues, reduxForm, WrappedFieldArrayProps } from 'redux-form';
import { validation, classTimeKindOptions, colors, NestedClassTimeSubjectAttributes, ClassTimeFormAttributes, RoomAttributes, DefaultOptionType, ClassTimeKindEnum } from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import AddButton from '../shared/AddButton';
import { css } from '@emotion/react';
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import DeleteButton from '../shared/DeleteButton';
import { ClassTimeSubjectFormBody } from './ClassTimeSubjectForm';
import { find, isEmpty } from 'lodash';
import { isValid } from 'date-fns';
import TimePickerComponent from '../input/form/timepicker';

interface TimePickerFieldProps {
  label: string;
  placeholder: string;
  disabled: boolean
  datePickerProps: object
}

const FieldCustom = Field as new () => GenericField<TimePickerFieldProps>;


const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 0;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue}
`
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`


interface CustomClassTimeSubjectFormProps extends WrappedFieldArrayProps {
  class_time: string;
  current_class_time: ClassTimeFormAttributes;
  room: RoomAttributes
  form_name: string
}

const ClassTimeSubjectAccordion = (props: {
  class_time_subject: string
  currentClassTimeSubject: NestedClassTimeSubjectAttributes
  index: number
  deleteClassTimeSubject: (currentClassTimeSubject: NestedClassTimeSubjectAttributes, class_time_subject: string, index: number) => void
  room: RoomAttributes
  allClassTimeSubjects: NestedClassTimeSubjectAttributes[]
  form_name: string
  field_name: string
}) => {
  const {class_time_subject, currentClassTimeSubject, index, deleteClassTimeSubject, room, allClassTimeSubjects, form_name, field_name} = props
  const [ktwelveSubjectOptions, setKtwelveSubjectOptions] = React.useState<DefaultOptionType[]>([])
  const subjectLabel = currentClassTimeSubject?.ktwelve_subject_id ? (currentClassTimeSubject.subject_name || find(ktwelveSubjectOptions, kso => kso.value === currentClassTimeSubject.ktwelve_subject_id)?.label) : 'Nova Disciplina'
  return(
    <Accordion TransitionProps={{ unmountOnExit: true }} key={class_time_subject} css={AccordionCss}>
    <AccordionSummary css={AccordionSummaryCss}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ExpandMore />
        <span>{subjectLabel}</span>
      </div>
      <DeleteButton
        onClick={() => deleteClassTimeSubject(currentClassTimeSubject, class_time_subject, index)}
        tooltip='Remover disciplina'
      />
    </AccordionSummary>
    <AccordionDetails>
      <div key={class_time_subject}>
        <FormSection name={class_time_subject}>
          <ClassTimeSubjectFormBody
            class_time_subject={class_time_subject}
            field_name={field_name}
            class_time_subject_form_values={currentClassTimeSubject}
            room={room}
            all_class_time_subjects={allClassTimeSubjects}
            ktwelveSubjectOptions={ktwelveSubjectOptions}
            setKtwelveSubjectOptions={setKtwelveSubjectOptions}
            form_name={form_name}
          />
        </FormSection>
      </div>
    </AccordionDetails>
  </Accordion>

  )
}


const renderClassTimeSubjects = (props: CustomClassTimeSubjectFormProps) => {
  const dispatch = useDispatch();
  const { fields, current_class_time, room, class_time, form_name } = props;
  const allClassTimeSubjects = fields.getAll() as NestedClassTimeSubjectAttributes[]
  const addClassTimeSubject = React.useCallback(() => {
    fields.push({
      class_time_id: current_class_time.id
  })}, [fields]);
  const deleteClassTimeSubject = React.useCallback(
    (currentClassTimeSubject: NestedClassTimeSubjectAttributes, class_time_subject: string, index: number) => {
      currentClassTimeSubject.id
        ? dispatch(change(form_name, [class_time, class_time_subject ,'_destroy'].join('.'), true))
        : fields.remove(index);
    },
    [fields],
  );

  return (
    <div>
      <div
        css={css`
          display: ${current_class_time.kind === ClassTimeKindEnum.INTERVAL ? 'none' : 'flex'};
          align-items: center;
          gap: 1rem;
          & div[id$='subject_id'] {
            width: 100%;
          }
        `}
      >
        <span>Adicionar Disciplina</span>
        <AddButton onClick={addClassTimeSubject} tooltip='Adicionar disciplina' />
      </div>
      {fields.map((class_time_subject, index) => {
        const currentClassTimeSubject = allClassTimeSubjects[index] as NestedClassTimeSubjectAttributes;
        if (currentClassTimeSubject._destroy) {
          return null;
        }
        return (
          <ClassTimeSubjectAccordion
            key={class_time_subject}
            currentClassTimeSubject={currentClassTimeSubject}
            class_time_subject={class_time_subject}
            field_name={`${class_time}.${class_time_subject}`}
            room={room}
            allClassTimeSubjects={allClassTimeSubjects}
            index={index}
            form_name={form_name}
            deleteClassTimeSubject={deleteClassTimeSubject}
          />
        )
      })}
    </div>
  );
};

export const ClassTimeFormBody = (props: {
  class_time_form_values: ClassTimeFormAttributes;
  room: RoomAttributes;
  class_time?: string;
  form_name: string
}) => {
  const { class_time_form_values, room, class_time, form_name} = props
  const dispatch = useDispatch()

  const onAccept = (value: Date) => {
    if(isValid(value)) {
      const minutes_to_add = class_time_form_values.kind === ClassTimeKindEnum.INTERVAL ? room.interval_length : room.class_time_length
      const new_ends_at_date = new Date(new Date(value).getTime() + minutes_to_add * 60000);
      dispatch(
        change(
          form_name, `${class_time}.ends_at`, new_ends_at_date 
        )
      )  
    }
  }
  const onBlur = (e:React.ChangeEvent<any>|undefined, arg_two: any, arg_three: Date) => {
    onAccept(arg_three)
    e?.preventDefault()
  }
  return(
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%', justifyContent: 'space-between' }}>
        <Field
          name='kind'
          component={SelectComponent}
          options={classTimeKindOptions}
          label={'Tipo de horário'}
          placeholder={'Selecione o tipo de horário'}
          validate={[validation.required]}
        />
        <FieldCustom
          name={`starts_at`}
          label={'Início da aula'}
          placeholder={`07:00`}
          component={TimePickerComponent}
          disabled={isEmpty(class_time_form_values.kind)}
          onBlur={onBlur}
          datePickerProps={{
            disabled: isEmpty(class_time_form_values.kind),
            onAccept: onAccept,
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name={`ends_at`}
          label={'Fim da aula'}
          placeholder={`08:00`}
          component={TimePickerComponent}
          onBlur={(e: React.ChangeEvent<any>|undefined) => e?.preventDefault()}
          disabled={class_time_form_values.kind !== ClassTimeKindEnum.LUNCH}
          datePickerProps={{
            ...(class_time_form_values.starts_at ? { minTime: new Date(class_time_form_values.starts_at) } : {}),
            disabled: class_time_form_values.kind !== ClassTimeKindEnum.LUNCH,
          }}
          validate={[validation.dateRequired]}
        />
      </div>
      <div>
        <FieldArray
          name='class_time_subjects_attributes'
          component={renderClassTimeSubjects}
          form_name={form_name}
          current_class_time={class_time_form_values}
          room={room}
          class_time={class_time}
        />
      </div>
    </div>
  )
}

const form_name = 'roomClassTimeForm';

const ClassTimeForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  room: RoomAttributes
}) => {
  const { handleSubmit, onSubmit, close_form, room } = props;
  const state = useSelector((state: RootState) => state);
  const class_time_form_values = getFormValues(form_name)(state) as ClassTimeFormAttributes;

  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar Horário</span>
      <form css={useStyles.form}>
        <>
          <ClassTimeFormBody
            form_name={form_name}
            class_time_form_values={class_time_form_values}
            room={room}
          />
          <div css={useStyles.buttons}>
            <button
              onClick={(e) => {
                e.preventDefault();
                close_form();
              }}
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar horário </span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(ClassTimeForm);
