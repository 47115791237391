/* eslint-disable camelcase */
import React from 'react';
import Loading from '../components/loading/Loading';
import { isNil } from 'lodash';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import ProductFamiliesForm from '../components/form/ProductFamiliesForm';
import { CREATE_PRODUCT_FAMILY, FETCH_PRODUCT_FAMILY, UPDATE_PRODUCT_FAMILY } from '../store/product_families';
import { ProductFamilyAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';

interface IProductFamiliesFormContainerProps {
  close_form?: () => void;
  onSave?: () => void;
  product_family_id?: number
}

const ProductFamiliesFormContainer: React.FC<IProductFamiliesFormContainerProps> = (props) => {
  const { close_form, onSave, product_family_id } = props
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<ProductFamilyAttributes | null>(null);
  const [loading, setLoading] = React.useState(true);
  const isUpdating = !isNil(initialValues?.id);

  const loadProductFamily = React.useCallback(async () => {
    if (product_family_id) {
      const product_family = await dispatch(
        FETCH_PRODUCT_FAMILY.request({
          id: product_family_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = product_family;
      const formattedProductFamily = {
        id,
        ...attributes,
      };
      setInitialValues(formattedProductFamily);
    }
  }, [product_family_id]);

  const initProductFamiliesForm = React.useCallback(async () => {
    setLoading(true);
    await loadProductFamily();
    setLoading(false);
  }, []);

  const onSubmit = React.useCallback(
    async (data: ProductFamilyAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as ProductFamilyAttributes;
          await dispatch(
            UPDATE_PRODUCT_FAMILY.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_PRODUCT_FAMILY.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Família de produtos salvo com sucesso.',
          }),
        );
        await onSave?.()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar família de produtos.',
          }),
        );
      }
    },
    [initialValues, isUpdating],
  );

  React.useEffect(() => {
    initProductFamiliesForm();
  }, []);
  if (loading) {
    return <Loading />;
  }

  return (
    <ProductFamiliesForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      close_form={close_form}
    />
  )
};

export default ProductFamiliesFormContainer;
