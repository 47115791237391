/* eslint-disable camelcase */
import { compose } from 'redux';
import {
  change,
  Field,
  FieldArray,
  FormSection,
  GenericField,
  getFormValues,
  reduxForm,
  WrappedFieldArrayProps,
} from 'redux-form';
import {
  validation,
  colors,
  NestedClassTimeSubjectAttributes,
  ClassTimeSubjectAttributes,
  RoomAttributes,
  DefaultOptionType,
  NestedTeacherClassTimeAttributes,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import AddButton from '../shared/AddButton';
import { css } from '@emotion/react';
import { compact, concat, find, head, isEmpty, map } from 'lodash';
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import DeleteButton from '../shared/DeleteButton';
import AutoCompleteComponent from '../input/form/autocomplete';
import { FETCH_KTWELVE_SUBJECTS } from '../../store/ktwelve_subjects';
import { error } from 'react-notification-system-redux';
import { TeacherClassTimeFormBody } from './TeacherClassTimeForm';
import { FETCH_TEACHER_SUBJECTS } from '../../store/teacher_subjects';
import Loading from '../loading/Loading';

interface KtwelveSubjectId {
  label: string;
  placeholder: string;
  disabled?: boolean;
  autoCompleteProps: object;
  options: DefaultOptionType[];
}

const FieldCustom = Field as new () => GenericField<KtwelveSubjectId>;

const useStyles = {
  view: css`
    width: inherit;
    height: fit-content;
    flex-grow: 1;
    display: flex;
    padding: 2rem 0;
    flex-direction: column;
    position: relative;

    & .MuiBackdrop-root {
      position: inherit;
    }

    & .MuiPaper-root {
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1rem;
  `,
  form: css`
    display: grid;
    row-gap: 1rem;
    background: #fdfeff;
    padding: 1rem;
  `,
  buttons: css`
    margin: 2rem 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
  `,
};

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue};
`;
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`;

const form_name = 'classTimeSubjectForm';

const TeacherClassTimeAccordion = (props: {
  teacher_subject: string;
  currentTeacherClassTime: NestedTeacherClassTimeAttributes;
  current_class_time_subject: ClassTimeSubjectAttributes;
  deleteTeacherClassTime: (
    currentTeacherClassTime: NestedTeacherClassTimeAttributes,
    teacher_subject: string,
    index: number,
  ) => void;
  room: RoomAttributes;
  allTeacherClassTimes: NestedTeacherClassTimeAttributes[];
  index: number;
}) => {
  const {
    teacher_subject,
    currentTeacherClassTime,
    deleteTeacherClassTime,
    room,
    allTeacherClassTimes,
    current_class_time_subject,
    index,
  } = props;
  const [teacherSubjectOptions, setTeacherSubjectOptions] = React.useState<DefaultOptionType[]>([]);
  // const [expanded, setExpanded] = React.useState(false);
  const teacherLabel = currentTeacherClassTime?.teacher_subject_id
    ? currentTeacherClassTime.teacher_name ||
      find(teacherSubjectOptions, (tso) => tso.value === currentTeacherClassTime.teacher_subject_id)?.label
    : 'Novo Professor na disciplina';
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} key={teacher_subject} css={AccordionCss}>
      <AccordionSummary css={AccordionSummaryCss}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ExpandMore />
          <span>{teacherLabel}</span>
        </div>
        <DeleteButton
          onClick={() => deleteTeacherClassTime(currentTeacherClassTime, teacher_subject, index)}
          tooltip='Remover Professor'
        />
      </AccordionSummary>
      <AccordionDetails>
        <div key={teacher_subject}>
          <FormSection name={teacher_subject}>
            <TeacherClassTimeFormBody
              teacher_class_times_form_values={currentTeacherClassTime}
              class_time_subject={current_class_time_subject}
              room={room}
              field_name={teacher_subject}
              all_teacher_class_times={allTeacherClassTimes}
              teacherSubjectOptions={teacherSubjectOptions}
              setTeacherSubjectOptions={setTeacherSubjectOptions}
            />
          </FormSection>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface CustomTeacherClassTimeFormProps extends WrappedFieldArrayProps {
  class_time_subject: string;
  current_class_time_subject: ClassTimeSubjectAttributes;
  room: RoomAttributes;
  loading_initial_teacher: boolean;
}

const renderTeacherClassTimesAttributes = (props: CustomTeacherClassTimeFormProps) => {
  const dispatch = useDispatch();
  const { fields, current_class_time_subject, room, class_time_subject, loading_initial_teacher } = props;
  const allTeacherClassTime = fields.getAll() as NestedTeacherClassTimeAttributes[];
  const addTeacherClasstime = React.useCallback(() => {
    fields.push({
      class_time_subject_id: current_class_time_subject.id,
    });
  }, [fields]);

  const deleteTeacherClassTime = React.useCallback(
    (currentTeacherClassTime: NestedTeacherClassTimeAttributes, teacher_subject: string, index: number) => {
      currentTeacherClassTime.id
        ? dispatch(change(form_name, [class_time_subject, teacher_subject, '_destroy'].join('.'), true))
        : fields.remove(index);
    },
    [fields],
  );
  if (loading_initial_teacher) {
    return <Loading />;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
          & div[id$='subject_id'] {
            width: 100%;
          }
        `}
      >
        <span>Adicionar Professor</span>
        <AddButton onClick={addTeacherClasstime} tooltip='Adicionar professor' />
      </div>
      {fields.map((teacher_subject, index) => {
        const currentTeacherClassTime = allTeacherClassTime[index] as NestedTeacherClassTimeAttributes;
        if (currentTeacherClassTime._destroy) {
          return null;
        }
        return (
          <TeacherClassTimeAccordion
            room={room}
            currentTeacherClassTime={currentTeacherClassTime}
            allTeacherClassTimes={allTeacherClassTime}
            key={teacher_subject}
            teacher_subject={teacher_subject}
            current_class_time_subject={current_class_time_subject}
            deleteTeacherClassTime={deleteTeacherClassTime}
            index={index}
          />
        );
      })}
    </div>
  );
};

export const ClassTimeSubjectFormBody = (props: {
  class_time_subject_form_values: NestedClassTimeSubjectAttributes;
  all_class_time_subjects?: NestedClassTimeSubjectAttributes[];
  class_time_subject: string;
  room: RoomAttributes;
  ktwelveSubjectOptions: DefaultOptionType[];
  setKtwelveSubjectOptions: React.Dispatch<React.SetStateAction<DefaultOptionType[]>>;
  form_name: string;
  field_name: string;
}) => {
  const {
    class_time_subject_form_values,
    all_class_time_subjects = [],
    room,
    class_time_subject,
    ktwelveSubjectOptions,
    setKtwelveSubjectOptions,
    form_name: this_form_name,
    field_name,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [loading_initial_teacher, setLoadingInitialTeacher] = React.useState(false);
  const dispatch = useDispatch();
  const fetchKtwelveSubjectOptionsMethod = React.useCallback(
    async (value: string, ktwelve_subject_id?: string) => {
      try {
        setLoading(true);
        const used_ktwelve_subject_ids = compact(
          all_class_time_subjects
            .filter((item) => {
              const result_one = !item._destroy;
              const result_two = !(ktwelve_subject_id && item.ktwelve_subject_id.toString() === ktwelve_subject_id);
              return result_one && result_two;
            })
            .map((item) => item.ktwelve_subject_id),
        );
        let extra_attrs = {};
        if (!isEmpty(used_ktwelve_subject_ids)) {
          extra_attrs = {
            ...extra_attrs,
            'q[id_not_in]': used_ktwelve_subject_ids,
          };
        }

        if (!isEmpty(value)) {
          extra_attrs = {
            ...extra_attrs,
            'q[subject_name_cont]': value,
          };
        }

        if (!isEmpty(ktwelve_subject_id)) {
          extra_attrs = {
            ...extra_attrs,
            'q[id_eq]': ktwelve_subject_id,
          };
        }
        const ktwelve_subjects = await dispatch(
          FETCH_KTWELVE_SUBJECTS.request({
            params: {
              filters: {
                'q[company_id_eq]': room.company_id.toString(),
                'q[curriculum_year_eq]': room.product_year.toString(),
                'q[ktwelve_curriculum_id_eq]': room.ktwelve_curriculum_id.toString(),
                ...extra_attrs,
              },
            },
          }),
        );
        if (ktwelve_subjects) {
          const {
            data: { data },
          } = ktwelve_subjects;
          const formattedData = data.map(({ attributes, id }) => {
            return {
              label: attributes.subject_name,
              value: id,
            };
          });
          setKtwelveSubjectOptions(formattedData);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de disciplina',
          }),
        );
      }
    },
    [room],
  );

  const init = React.useCallback(async () => {
    if (class_time_subject_form_values.ktwelve_subject_id && class_time_subject_form_values.subject_name) {
      setKtwelveSubjectOptions([
        {
          label: class_time_subject_form_values.subject_name,
          value: class_time_subject_form_values.ktwelve_subject_id.toString(),
        },
      ]);
    }
  }, [class_time_subject_form_values]);

  const handleKtwelveSubjectChange = React.useCallback(
    async (value: string) => {
      try {
        setLoadingInitialTeacher(true);
        if (value) {
          const teacher_subjects = await dispatch(
            FETCH_TEACHER_SUBJECTS.request({
              params: {
                filters: {
                  'q[company_id_eq]': room.company_id.toString(),
                  'q[ktwelve_subject_id_eq]': value,
                  'q[active_eq]': 'true',
                },
              },
            }),
          );
          const {
            data: { data },
          } = teacher_subjects;
          const teacher_subject = head(data);
          if (!isEmpty(teacher_subject) && teacher_subject) {
            if (isEmpty(class_time_subject_form_values.teacher_class_times_attributes)) {
              dispatch(
                change(this_form_name, `${field_name}.teacher_class_times_attributes`, [
                  {
                    teacher_subject_id: teacher_subject?.id,
                    teacher_name: teacher_subject.attributes.teacher_name,
                    ...(class_time_subject_form_values.id
                      ? { class_time_subject_id: class_time_subject_form_values.id }
                      : {}),
                  },
                ]),
              );
            } else {
              const new_class_time_attributes = concat(
                [
                  {
                    teacher_subject_id: ~~teacher_subject.id,
                    teacher_name: teacher_subject.attributes.teacher_name,
                    ...(class_time_subject_form_values?.id
                      ? { class_time_subject_id: ~~class_time_subject_form_values.id }
                      : {}),
                  },
                ],
                compact(
                  map(class_time_subject_form_values.teacher_class_times_attributes, (item) => {
                    if (item.id) {
                      return { ...item, _destroy: true };
                    } else {
                      return null;
                    }
                  }) as Partial<NestedTeacherClassTimeAttributes>[],
                ),
              );
              dispatch(
                change(this_form_name, `${field_name}.teacher_class_times_attributes`, new_class_time_attributes),
              );
            }
          }
        }
        const current_selected_ktwelve_subject = find(ktwelveSubjectOptions, opt => opt.value.toString() === value.toString())
        if(current_selected_ktwelve_subject){
          dispatch(
            change(this_form_name, `${field_name}.subject_name`, current_selected_ktwelve_subject.label),
          )
        }
        setLoadingInitialTeacher(false);
      } catch (er) {
        setLoadingInitialTeacher(false);
        dispatch(error({ message: 'Erro ao obter dados de professor da disciplina' }));
      }
    },
    [class_time_subject_form_values, field_name, ktwelveSubjectOptions],
  );
  const handleClearField = () => {
    dispatch(change(this_form_name, `${field_name}.ktwelve_subject_id`, null));
  };

  React.useEffect(() => {
    init();
  }, []);
  return (
    <div style={{ display: 'grid' }}>
      <FieldCustom
        name='ktwelve_subject_id'
        label={'Disciplina'}
        placeholder={'Disciplina'}
        onChange={(_, value: string) => handleKtwelveSubjectChange(value)}
        component={AutoCompleteComponent}
        autoCompleteProps={{
          filterOptions: (x: any) => x,
          loading,
          getValueOnChange: true,
          fetchOptions: fetchKtwelveSubjectOptionsMethod,
          onClearField: handleClearField,
          clearable: true,
        }}
        options={ktwelveSubjectOptions}
        validate={[validation.required]}
      />
      <FieldArray
        name='teacher_class_times_attributes'
        loading_initial_teacher={loading_initial_teacher}
        component={renderTeacherClassTimesAttributes}
        class_time_subject={class_time_subject}
        current_class_time_subject={class_time_subject_form_values}
        room={room}
      />
    </div>
  );
};

const ClassTimeSubjectForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  room: RoomAttributes;
  form_name: string;
  field_name: string;
}) => {
  const { handleSubmit, onSubmit, close_form, room, form_name, field_name } = props;
  const state = useSelector((state: RootState) => state);
  const [ktwelveSubjectOptions, setKtwelveSubjectOptions] = React.useState<DefaultOptionType[]>([]);
  const class_time_subject_form_values = getFormValues(form_name)(state) as NestedClassTimeSubjectAttributes;

  return (
    <div css={useStyles.view}>
      <span className={`${useStyles.title} title`}>Salvar Disciplina no horário</span>
      <form css={useStyles.form}>
        <>
          <ClassTimeSubjectFormBody
            form_name={form_name}
            ktwelveSubjectOptions={ktwelveSubjectOptions}
            setKtwelveSubjectOptions={setKtwelveSubjectOptions}
            class_time_subject={''}
            room={room}
            class_time_subject_form_values={class_time_subject_form_values}
            field_name={field_name}
          />
          <div css={useStyles.buttons}>
            <button
              onClick={(e) => {
                e.preventDefault();
                close_form();
              }}
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar horário </span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(ClassTimeSubjectForm);
