import React from 'react';
import { CompanyAttributes, DefaultOptionType, PaginationType, Role } from '../../utils/constants';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';
import DatePickerComponent from '../input/form/datepicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { css } from '@emotion/react';
import { IDefaultFilterValues } from '../../containers/RegistrationsContainer';

const useStyles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  subtitle: css`
    text-align: start;
    margin-bottom: 1rem;
  `,
  filters: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;

    & .inputArea {
      width: 10rem !important;
      padding-right: 3rem;
    }
  `,
};

interface IRegistrationViewFiltersProps {
  filters: IDefaultFilterValues;
  setFilters: React.Dispatch<React.SetStateAction<IDefaultFilterValues>>;
  searchForOptions: DefaultOptionType[];
  studentStatusOptions: DefaultOptionType[];
  fetchRegistrationsMethod: (filters: IDefaultFilterValues) => Promise<void>;
  pagination: PaginationType;
  defaultPagination: PaginationType;
}

const RegistrationViewFilters: React.FC<IRegistrationViewFiltersProps> = ({
  filters,
  setFilters,
  searchForOptions,
  studentStatusOptions,
  fetchRegistrationsMethod,
  pagination,
  defaultPagination,
}) => {
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const current_company = companies.find(
    (item: CompanyAttributes) => item.id === company,
  ) as CompanyAttributes;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role, current_company.kind);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  return (
    <div css={useStyles.container}>
      <span css={useStyles.subtitle}>Filtros</span>
      <div css={useStyles.filters}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            clearFieldFunction={() => {
              setFilters({ ...filters, company_filter: '' })
              fetchRegistrationsMethod({
                ...filters,
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                company_filter: '',
              });
            }}
            clearable
            input={{
              value: filters?.company_filter ?? '',
              onChange: (e: any) => {
                setFilters({ ...filters, company_filter: e.target?.value })
                fetchRegistrationsMethod({
                  ...filters,
                  newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                  company_filter: e.target?.value ?? '',
                });
              },
            }}
          />
        )}
        <SelectComponent
          placeholder='Status do aluno'
          small
          options={studentStatusOptions}
          input={{
            value: filters?.closed,
            onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
              setFilters({ ...filters, closed: e.target.value })
              fetchRegistrationsMethod({
                ...filters,
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                closed: e.target.value
              })
            }
          }}
        />
        <SelectComponent
          placeholder='Buscar por'
          small
          clearFieldFunction={() => {
            setFilters({
              ...filters,
              filter_option: '',
              student_filter: '',
            })
            fetchRegistrationsMethod({
              ...filters,
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
              filter_option: '',
              student_filter: ''
            });
          }}
          clearable
          options={searchForOptions}
          input={{
            value: filters?.filter_option,
            onChange: (e: any) => {
              let value = ''
              if (e?.target?.value) {
                value = e.target.value
              }
              setFilters({
                ...filters,
                filter_option: value,
                student_filter: '',
              })
            },
          }}
        />

        <InputComponent
          placeholder={`Buscar por ${searchForOptions?.find((item) => item.value === filters?.filter_option)?.label || ''}`}
          search
          small
          onSearch={() => {
            fetchRegistrationsMethod({
              ...filters,
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: filters?.student_filter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              let value = ''
              if (e?.target?.value) {
                value = e.target.value
              }
              setFilters({
                ...filters,
                student_filter: value
              })
            },
          }}
        />

        <DatePickerComponent
          placeholder='Ano letivo'
          small
          datePickerProps={{
            views: ['year'],
            inputFormat: 'yyyy',
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            setFilters({ ...filters, startsAt: null })
            fetchRegistrationsMethod({
              ...filters,
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
              startsAt: null,
            });
          }}
          input={{
            value: filters?.startsAt,
            name: 'year',
            onChange: (e: Date) => {
              const value = new Date(e.getFullYear(), 0, 1, 1);
              setFilters({ ...filters, startsAt: value });
              fetchRegistrationsMethod({
                ...filters,
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                startsAt: value,
              });
            },
          }}
        />
      </div>
    </div>
  );
};

export default RegistrationViewFilters;
