import { persistStore, persistReducer } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { promiseMiddleware } from 'redux-saga-promise-actions'
import storage from 'redux-persist/lib/storage'

import rootReducer, { rootSaga } from './index'
import { addTokenToRequest, onAuthErrors } from './middlewares'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'account']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware()
let devTools = false
if(process.env.NODE_ENV !== 'production'){
  devTools = true
}

const store = ({ initialState }: any = {}) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
      {
        immutableCheck: false,
        serializableCheck: false,
      }
    ).concat([promiseMiddleware, onAuthErrors, sagaMiddleware, addTokenToRequest]),
    preloadedState: initialState,
    devTools
  })
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
const storeObject = store().store
export type RootState = ReturnType<typeof persistedReducer>
export type AppDispatch = typeof storeObject.dispatch
export default store
